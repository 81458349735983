'use-strict'
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { Bool } from '../../../models/common/bool.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { ModuleComponentLoaderServiceStatic } from '../../../services/staticServices/commonStaticServices/moduleComponentLoaderServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from '../../../services/staticServices/stringServiceStatic.service';

@Component({
  selector: 'app-my-stuff',
  templateUrl: './myStuff.component.html',
  styleUrls: ['./myStuff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyStuffComponent implements OnInit, AfterViewInit, OnDestroy
{
  public redirectionService! : RedirectionService;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public isAuthorized = false;
  public isClosed = true;
  public isDesktopvar = false;
  public isEmployee = false;
  public isLoggedIn = false;
  public isLogingTasksExecuted = false;
  public isMgmt = false;
  public isMobilevar = false;
  public isMouseIn = false;
  public isMyStuffClosedBool : Bool = new Bool();
  public isMyStuffOpen = false;
  public isOnLine = false;
  public isPhotoMgmt = false;
  public isPremium = false;
  public isProfilePics = false;
  public isReviewer = false;
  public isSingletap = true;
  public isSuperUser = false;
  public isTabletvar = false;
  public isValued = false;
  public isViewMember = false;
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public message = '';
  public pagerBreadcrum : PagerBreadcrum = new PagerBreadcrum();
  public sitUser : SitUser = new SitUser();
  public signedInUser : SitUser = new SitUser();
  public signedInUserId = 0;
  public timer : any;
  public timerArray : any[] = [];
  constructor (
    private commonServiceService: CommonServiceService,
    private router : Router)
  {
    this.redirectionService = this.commonServiceService.getRedirectionService();
    this.isMyStuffClosedBool.isClosed = true; // so that the ui will be displayed
  }

  ngOnInit () : void
  {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0)
    {
      this.signedInUserId = this.loginSuccess.signedInUserId;
    }
    //  -------------------------------------------------------------------------
    // TODO: remove once the issue is resolved
    // NOTE: This is a temporary solution to an unsubscribe error
    //       We do not emit loginSuccess upon successful login from the LoginComponent
    //       We use this emitter to indicate that login is successful, and any subsequent
    //       computation that are necessary upon successful login is carried out here.
    // --------------------------------------------------------------------------
    // EmitterSubjectService.clearMessageEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    //  {
    //    // debugger;
    //    this.message = '';
    //    this.executeLoginSuccessTasks();
    //    this.ngAfterViewInit();
    //  } );
  }
  // ---------------------------------------------------------------------------------
  ngAfterViewInit ()
  {
  }
  // ---------------------------------------------------------------
  ngOnDestroy ()
  {
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    this.timerArray.forEach( ( timer ) => clearInterval( timer ) );
  }
  // ---------------------------------------------------------------------------------
  public executeLoginSuccessTasks () : any
  {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    // debugger;
    this.isAuthorized = this.loginSuccess.isAuthorized;
    this.isEmployee = this.loginSuccess.isEmployee;
    this.isMgmt = this.loginSuccess.isMgmt;
    this.isReviewer = this.loginSuccess.isReviewer;
    this.isSuperUser = this.loginSuccess.isSuperUser;
    this.signedInUserId = this.loginSuccess.signedInUserId;
    this.isLoggedIn = this.loginSuccess.signedInUserId > 0 ? true : false;
    this.isOnLine = navigator.onLine;
    this.isValued = this.loginSuccess.isValued;
    this.isPremium = this.loginSuccess.isPremium;
    if ( this.loginSuccess.profileName.length === 0 && this.loginSuccess.email.length > 0)
    {
      this.loginSuccess.profileName = StringServiceStatic.getUserNameFromEmail(this.loginSuccess.email);
    }
    // debugger;

    return true;
  }
  // ---------------------------------------------------------------------------------
  toggleIsClosed () : any
  {
    this.isMyStuffClosedBool.isClosed = !this.isMyStuffClosedBool.isClosed;
    // debugger;
    this.emitIsMyStuffOpen( this.isMyStuffClosedBool.isClosed );
  }
  // ---------------------------------------------------------------------------------
  emitIsMyStuffOpen (value: any) : any
  {
    this.isMyStuffOpen = value;
    // debugger;
    EmitterSubjectService.emitIsMyStuffOpen( this.isMyStuffOpen );
  }
  // ---------------------------------------------------------------------------------
  resetLoginSuccess () : any
  {
    this.isLoggedIn = false;
    this.isOnLine = false;
    this.isPremium = false;
    this.isValued = false;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess ) )
    {
      this.loginSuccess.action = '';
      this.loginSuccess.blogId = 0;
      this.loginSuccess.businessKey = '';
      this.loginSuccess.code = '';
      this.loginSuccess.controller = '';
      this.loginSuccess.email = '';
      this.loginSuccess.isAuthorized = this.isAuthorized = false;
      this.loginSuccess.isEmailConfirmed = false;
      this.loginSuccess.isEmployee = this.isEmployee = false;
      this.loginSuccess.isForgotPassword = false;
      this.loginSuccess.isMgmt = this.isMgmt = false;
      this.loginSuccess.isPic = false;
      this.loginSuccess.isPremium = this.isPremium = false;
      this.loginSuccess.isReviewer = this.isReviewer = false;
      this.loginSuccess.isSuperUser = this.isSuperUser = false;
      this.loginSuccess.imagePath = '';
      this.loginSuccess.iv = '';
      this.loginSuccess.message = '';
      this.loginSuccess.profileName = '';
      this.loginSuccess.id = 0;
      this.loginSuccess.likedArr = []; // who liked me
      this.loginSuccess.myBlockedArr = []; // whom I blocked
      this.loginSuccess.myBuddyArr = [];
      this.loginSuccess.myTrackedArr = []; // whom I tracked
      this.loginSuccess.myUnlockedArr = []; // from whom I unlocked
      this.loginSuccess.signedInUserId = 0;
      this.loginSuccess.signedInUserKey = '';
      this.loginSuccess.trackedArr = []; // who tracked me
      this.loginSuccess.unlockedArr = []; // who unlocked for me
      this.signedInUserId = 0;
    }
    this.ngAfterViewInit();
    return true;
  }
  // --------------------------------------------------------------
  // --------------------------------------------------------------
  public setRoute ( route : string, id : number ) : any
  {
     debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    // let sitUser : any;
    
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( route ) )
    {
      // start spinner:
      EmitterSubjectService.emitShowSpinner('Loading....');

      this.mcLoader.sitUserId = this.loginSuccess.signedInUserId;
      this.mcLoader.id = this.loginSuccess.signedInUserId;
      this.mcLoader.componentName = route;
      this.signedInUserId = this.loginSuccess.signedInUserId;

      // Note: use the copyLoginSuccessToSitUser only in case of the  MyStuff/MyProfile == signedInUserId. !important
      this.signedInUser = EmitterSubjectService.getSignedInUser();
      switch ( route.toLowerCase() )
      {
        // case 'buddies':
        // case 'iblocked':
        // case 'myfriends':
        // case 'iliked':
        // case 'itracked':
        // case 'utracked':
        // case 'iwinked':
        // case 'uwinked':
        //  // debugger;
        //  this.mcLoader.moduleName = 'activitym';
        //  break;
        case 'membersIndividually': 
          this.mcLoader.moduleName = 'memberm';
          break;
        case 'memberscroll':
          this.mcLoader.moduleName = 'memberscrollm';
          break;
        case 'memberview':
          this.loginSuccess.isEditProfile = false;
          EmitterSubjectService.emitIsEditProfile(false);
          this.isPhotoMgmt = false;
          EmitterSubjectService.emitIsPhotoMgmt(this.isPhotoMgmt);
          this.isViewMember = true;
          EmitterSubjectService.emitIsViewMember(this.isViewMember);          

          this.mcLoader.moduleName = 'memberm';
          break;
        case 'memberedit':
          this.loginSuccess.isEditProfile = true;
          this.loginSuccess.isProfileInfoComplete = false;
          this.loginSuccess.isProfileContentComplete = false;
          this.loginSuccess.isPreferenceComplete = false;
          // EmitterSubjectService.setLoginSuccess( this.loginSuccess );
          EmitterSubjectService.emitIsEditProfile( true );
          this.mcLoader.moduleName = 'profilem';
          break;
        case 'myphotos':
          EmitterSubjectService.emitIsPhotoMgmt( true );
          EmitterSubjectService.emitIsViewMember( true );
          this.mcLoader.moduleName = 'memberm';
          break;
        case 'mychats':
          this.mcLoader.moduleName = 'chatm';
          break;
        case 'myemails':
        case 'myenotes':
        case 'viewemail':
        case 'viewenote':
        case 'composeemail':
        case 'composeenote':
          this.mcLoader.moduleName = 'communicationm';
          break;
        case 'about':
        case 'contactus':
        case 'notfound':
        case 'privacy':
        case 'terms':
          this.mcLoader.moduleName = 'publicm';
          break;
        case 'login':
          this.mcLoader.moduleName = 'loginm';
          // alert('in navbarComponent->setRoute()-> emitting loadModule for: ' + this.mcLoader.componentName);

          break;
        case 'logout':
          this.resetLoginSuccess();
          this.signedInUserId = 0;
          this.isLoggedIn = false;
          this.mcLoader.moduleName = 'loginm';
          break;
        case 'register':
        case 'prescreen':
          this.mcLoader.moduleName = 'registerm';
          // alert('in navbarComponent->setRoute()-> emitting loadModule for: ' + this.mcLoader.componentName);

          break;
        case 'animation':
        case 'boxnoncetest':
        case 'errorlog':
        case 'gesturetest':
        case 'imageresizer':
          this.mcLoader.moduleName = 'staffm';
          break;
        default:
          // debugger;
          this.mcLoader.componentName = 'index';
          this.mcLoader.moduleName = 'app';
          break;
      }
    }
    else
    {
      this.mcLoader.componentName = 'index';
      this.mcLoader.moduleName = 'app';
    }
    let returnUrl = window.location.href;
    EmitterSubjectService.setReturnUrl(returnUrl);
    let pagerBrdCrm : any;
    // debugger;
    this.mcLoader.router = this.router;
    this.mcLoader = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(this.mcLoader);
    this.redirectionService.fireMcLoaderRoute(this.mcLoader).subscribe(data => {
      pagerBrdCrm = data;
    });
    
    //debugger;
    return true;
  }
  // ---------------------------------------------------------------------------------
}
