'use-strict'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { Bool } from '../../../models/common/bool.model';
import { KeyValueResult } from '../../../models/keyValue/keyValueResult.model';
import { KvAny } from '../../../models/keyValue/kvAny.model';
import { Preference } from '../../../models/profile/preference.model';
import { GlyphiconMenuService } from '../../../services/GlyphiconService/glypgiconMenuService.service';
import { GlyphiconServiceService } from '../../../services/GlyphiconService/glyphiconServiceService.service';
import { PreferenceService } from '../../../services/profileServiceService/preferenceService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';

@Component({
  selector: 'app-preference-key-value-arr',
  templateUrl: './preferenceKeyValueArr.component.html',
  styleUrls: [ './preferenceKeyValueArr.component.scss' ],
  
  animations: [
    Animations, slideInOutAnimation
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreferenceKeyValueArrComponent implements OnInit, OnDestroy, AfterViewInit {
  public glyphiconMenuService! : GlyphiconMenuService;
  @Input() preferenceKvAnyArr: KvAny[] =[];
  @Input() kvAnyLegend = '';
  @Input() kvAnyName = 'Preferences';
  @Input() isFormSubmitted = false;
  @Input() isRequired = false;
  public kvAnyResult: KeyValueResult = new KeyValueResult();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isMobilevar = false;
  public isClosed = true;
  public IsOpen = new Bool();
  public isPreferenceClosedBool : Bool = new Bool();
  public loginSuccess! : LoginSuccess;
  public preference : Preference = new Preference();
  public selectedKvAny!: FormControl;
  public selectedKvAnyIndex = -1;
  @Input() sitUserId:number = 0;
  // ---------------------------------------------------------------
  constructor (
    public cdr : ChangeDetectorRef,
    private preferenceService : PreferenceService,
    private glyphiconServiceService : GlyphiconServiceService,
    private router : Router,  ) {
    this.kvAnyResult.kvName = this.kvAnyName;
    this.glyphiconMenuService = this.glyphiconServiceService.getGlyphiconMenuService();
    // debugger;
  }
  ngOnInit() {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    if (this.sitUserId > 0) {
      this.getPreferenceData(this.sitUserId);
    }
    // --------------------------------------------------------------
    EmitterSubjectService.preferenceKvAnyArrEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result) && result.length > 0) {
          this.preferenceKvAnyArr = result as KvAny[];
          // debugger;
          this.ngAfterViewInit();
        }        
      });
  }
  // -------------------------------------------------------------
  public ngAfterViewInit () : void { 
    // debugger;
    this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.preferenceKvAnyArr = [];
  }
  
  // ---------------------------------------------------------------
  // ref:https:// www.positronx.io/angular-checkbox-tutorial/
  // ---------------------------------------------------------------
  onKeyValueChange (e : any): void {
    if (e.target.checked) {
      
       // this.selectedRadiobox = this.radioboxForm.get('radioSelection') as FormControl;
       // this.selectedRadiobox.patchValue('');
       // this.selectedRadiobox.patchValue(e.target.value);
       

      this.kvAnyResult.kvName = this.kvAnyName;
      this.kvAnyResult.kvSelection = e.target.value;
      this.kvAnyResult.isRequiredPassed = this.isRequired === true ? this.isRequiredPassed() : false;
      EmitterSubjectService.emitKeyValueResult(this.kvAnyResult);
    }
  }
  // ---------------------------------------------------------------------------------
  //  Note: isOpen is opposite of isClosed.
  // ---------------------------------------------------------------------------------
  isOpen (isClosed : any )
  {
    // debugger;
    this.isClosed = !isClosed;
    this.IsOpen.isClosed = this.isClosed;
    this.IsOpen.name = 'preferenceKeyValueArrComponentId';
    //if (!this.IsOpen.isClosed) {
    //  // debugger;
    //  this.getPreferenceData(this.sitUserId);
    //}
    // debugger;
    EmitterSubjectService.emitIsViewMember(true);  // TODO: may need to set instead of emitting
    
  }
  // ---------------------------------------------------------------
  isRequiredPassed(): any {
    return this.kvAnyResult.kvSelection.length > 0;
  }
  // ----------------------------------------------------------------
  getPreferenceData (sitId : number): void {
    if (sitId > 0) {
      this.preferenceKvAnyArr = this.preferenceService.getPreferenceFromDictionary(sitId);
      // debugger;
      this.ngAfterViewInit();
      // debugger;
      this.preferenceService.getPreference(sitId).subscribe(result => {
        // debugger;
        this.preference = result;   
        this.ngAfterViewInit();
      })
    }
  }
  // ---------------------------------------------------------------
}
