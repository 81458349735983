<div>
  <span class="whiteBlueInvxxxSB"> Feature-name :</span> 
  <span class="gryxxxS">
    {{appFeature.featureName}}
  </span>
</div>
<div>
  <span class="whiteBlueInvxxxSB">Premium (max):</span>
  <span class="gryxxxS">
    <span *ngIf="appFeature.isPrimary" class="glyphicon glyphicon-ok-circle" style="font-size: x-large;color: forestgreen;" aria-hidden="true"></span>
    <span *ngIf="appFeature.maxPrimaryCount.length > 0">{{appFeature.maxPrimaryCount}}</span>
  </span>
</div>
<div>
  <span class="whiteBlueInvxxxSB">Valued (max):</span>
  <span class="gryxxxS">
    <span *ngIf="appFeature.maxValuedCount.length === 0" class="glyphicon glyphicon-remove-circle" style="font-size: x-large;color: darkred;" aria-hidden="true"></span>
    <span *ngIf="appFeature.maxValuedCount.length > 0">{{appFeature.maxValuedCount}}</span>
  </span>
</div>
<br />
<div>
  <span class="whiteBlueInvxxxSB">Description</span>
  <span class="gryxxxS wordwrap">{{ appFeature.description}}</span>
</div>
<hr class="hrGray" style="display:inline-block;width:98%;padding:0;margin:0 2.5rem 0 0rem;" />
<!------------------------------------------------------------------------------------------------------------------>

