
<div id="navSidebarComponentId" style="z-index:14;width:220px;height:0.5rem;margin-left:-220px;border:1.5px solid green;"
     (swipe)="onSwipe($event)"
     (swipeleft)="onSwipeLeft($event)"
     (swiperight)="onSwipeRight($event)"
     (swipeup)="onSwipeUp($event)"
     (swipedown)="onSwipeDown($event)">
  <nav class="navbar navbar-inverse navbar-fixed-top" id="sidebar-wrapper" role="navigation" style="opacity: 1; z-index: 14;">
    <ul class="sidebar-nav">
      <!--Brand/Logo-->
      <li class="sidebar-brand appName">
        <a routerLink="" routerLinkActive="active" (click)="setRoute('index', 0)" class="hvr-underline-from-center sidebar-nav" aria-expanded="true">
          <div class="increase text-center">
            <span class="hvr-icon appName" style="z-index:99">Slakez</span>
          </div>
        </a>
      </li>
      <!--=====================================================================================================-->
      <!--NOTE:
  !!!DO NOT Use routerLink="/memberm/myPhotos/{{this.loginSuccess.signedInUserId}}" routerLinkActive="active"
      or [routerLink]="['/profilem/memberEdit', loginSuccess.signedInUserId]"
  pattern for routing because it will bypass the internal left-arrow and right-arrow navigation system,
  instead use (click)="setRouteBreadCrum('myEmails', loginSuccess.signedInUserId);" type of programmatic routing!!!
  -->

      <li *ngIf="signedInUserId > 0">
        <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('myChats', signedInUserId);">
          <div class="hvr-underline-from-center text-center increase" align="center" style="margin-left: -5px; display:inline-flex !important;">
            <img class="img img-circle coin hvr-icon text-center drkRedGrnSB"
                 src="assets/photos/chat-emoji.png"
                 style="background: orange; vertical-align: middle; margin: 0.15rem 0.01rem 0.02rem 0.01rem; z-index: 14; height: 20px; width: 20px;" aria-hidden="true" />
            <span class="hvr-icon" style="font-size: 14px;margin:7px 0 0 0" aria-hidden="true"> Chats</span>
          </div>
        </a>
      </li>
      <li *ngIf="signedInUserId > 0">
        <a routerLink="" routerLinkActive="active" (click)="setRoute('myEmails', signedInUserId);">
          <div align="center" class="hvr-underline-from-center increase" style="line-height:0.75rem;padding:0;">
            <span class="glyphicon glyphicon-envelope hvr-icon" style="font-size: large; color: orange;" aria-hidden="true"></span>
            <span class="hvr-icon" style="font-size: 14px;margin:0px 0 0px 1.5px;vertical-align:baseline;" aria-hidden="true">Emails</span>
          </div>
        </a>
      </li>
      <li *ngIf="signedInUserId > 0">
        <a routerLink="" routerLinkActive="active" (click)="setRoute('myEnotes', signedInUserId);">
          <div align="center" class="hvr-underline-from-center increase" style="line-height:0.75rem;">
            <span class="glyphicon glyphicon-bell hvr-icon" style="font-size: large; color: orange;" aria-hidden="true"></span>
            <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true">Notifications</span>
          </div>
        </a>
      </li>
      <!--Sandbox:Begin: DistanceComponent: link-->
      <!--<li *ngIf="signedInUserId === 0">
    <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('distance', 0);">
      <div class="hvr-underline-from-center increase" align="center" style="line-height:0.75rem;">
        <span class="glyphicon glyphicon-leaf hvr-icon" style="font-size: large; color: orange;" aria-hidden="true"></span>
        <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px; " aria-hidden="true"> Distance</span>
      </div>
    </a>
  </li>
      <li>
        <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('csJsTime', 0);">
          <div class="hvr-underline-from-center increase" align="center" style="line-height:0.75rem;">
            <span class="glyphicon glyphicon-calendar hvr-icon" style="font-size: large; color: orange;" aria-hidden="true"></span>
            <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px; " aria-hidden="true"> Cs/JsTime</span>
          </div>
        </a>
      </li>-->
      <!--Sandbox:End: DistanceComponent: link-->

      <li *ngIf="signedInUserId === 0">
        <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('login', 0);">
          <div class="hvr-underline-from-center increase" align="center" style="line-height:0.75rem;">
            <span class="glyphicon glyphicon-log-in hvr-icon" style="font-size: large; color: orange;" aria-hidden="true"></span>
            <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px; " aria-hidden="true"> Login</span>
          </div>
        </a>
      </li>

      <li *ngIf="signedInUserId > 0">
        <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('logout', 0);">
          <div class="hvr-underline-from-center increase" style="line-height: 0.95rem;" align="center">
            <span class="glyphicon glyphicon-log-out hvr-icon" style=" font-size: large; color: orange;" aria-hidden="true"></span>
            <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px; " aria-hidden="true"> Logout</span>
          </div>
        </a>
      </li>
      <li *ngIf="signedInUserId > 0">
        <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('profileTileScroll', 0);">
          <div class="hvr-underline-from-center text-center increase" align="center" style="margin-left: -5px; display:inline-flex !important;">
            <img class="img img-circle coin hvr-icon text-center drkRedGrnSB"
                 src="assets/photos/glyph-members-transparent.png"
                 style="background: orange; vertical-align: middle; margin: 0.15rem 0.01rem 0.02rem 0.01rem; z-index: 11; height: 20px; width: 20px;" aria-hidden="true" />
            <span class="hvr-icon" style="font-size: 14px;margin:7px 0 0 0" aria-hidden="true"> Members</span>
          </div>
        </a>
      </li>
      <li *ngIf="signedInUserId === 0">
        <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('signup', 0);">
          <div class="hvr-underline-from-center increase" align="center" style="line-height:0.65rem;">
            <span class="glyphicon glyphicon-pencil hvr-icon" style="font-size: large; color: orange;" aria-hidden="true"></span>
            <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true"> Signup Free</span>
          </div>
        </a>
      </li>
      <li><hr class="hr" style="color:white;height:1.15px;width:190px;" /></li>
      <!--====================================================================================================-->
      <!--====================================================================================================-->
      <!--Begin of MyStuff-->
      <li *ngIf="isLoggedIn" appExpandMenu>
        <a>
          <div align="center" class="hvr-underline-from-center increase" style="line-height:0.7rem;">
            <span class="glyphicon glyphicon-user hvr-icon" style="color:orange;font-size:large;" aria-hidden="true"></span>
            <span style="color: white; font-size: 14px; margin: 0px 0 0px 1.5px;"> MyStuff <i class="caret hvr-icon"></i></span>
          </div>
        </a>
      </li>
      <ul *ngIf="isLoggedIn" class="nav submenu" style="margin:-0.5rem 0 -0.25rem 0">
        <li class="dropdown-header text-center" style="color:white;margin:0.25rem 0 -0.2rem 0">(Profile/Photo) Mgmt</li>

        <li>
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('myPhotoMgmt', signedInUserId);">
            <div class="hvr-underline-from-center increase" align="center" style="line-height:0.7rem;">
              <span class="glyphicon glyphicon-camera hvr-icon" style="font-size:x-large;color:orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 2px 1.5px;" aria-hidden="true"> myPhotos</span>
            </div>
          </a>
        </li>

        <li>
          <a [routerLink]="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('memberEdit', signedInUserId);">
            <div class="hvr-underline-from-center increase" align="center" style="margin-left: -3px; display: inline-flex !important; line-height: 0.70rem;">
              <span class="glyphicon glyphicon-pencil hvr-icon" style="font-size: large;color: orange;" aria-hidden="true"> </span>
              <span class="hvr-icon" style="font-size: 14px; margin: 5px 0 0px 1.5px;" aria-hidden="true"> editMyProfile </span>
            </div>
          </a>
        </li>

        <li><hr class="hr" style="color:white;height:1.15px;width:190px;" /></li>
      </ul>
      <!--End of MyStuff-->
      <!--===============================================================================================-->
      <!--====================================================================================================-->
      <!--Begin of Admin-->

      <li *ngIf="isLoggedIn && (isEmployee || isSuperUser)" appExpandMenu>
        <a>
          <div align="center" class="hvr-underline-from-center increase" style="line-height:0.7rem;">
            <span class="glyphicon glyphicon-user hvr-icon" style="color:orange;font-size:large;" aria-hidden="true"></span>
            <span style="color: white; font-size: 14px; margin: 0px 0 0px 1.5px;"> Admin <i class="caret hvr-icon"></i></span>
          </div>
        </a>

      </li>
      <ul *ngIf="isLoggedIn && (isEmployee || isSuperUser)" class="nav submenu" style="margin:-0.5rem 0 -0.25rem 0">
        <li class="dropdown-header text-center" style="color:white;margin:0.25rem 0 -0.2rem 0">Admin/Mgmt</li>

        <li>
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('memexpdate', signedInUserId);">
            <div class="hvr-underline-from-center increase" align="center" style="line-height:0.7rem;">
              <span class="glyphicon glyphicon-star-empty hvr-icon" style="font-size:x-large;color:orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 2px 1.5px;" aria-hidden="true"> memExpDate</span>
            </div>
          </a>
        </li>
        <li>
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('paymenttest', signedInUserId);">
            <div class="hvr-underline-from-center increase" align="center" style="line-height:0.7rem;">
              <span class="glyphicon glyphicon-star hvr-icon" style="font-size:x-large;color:orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 2px 1.5px;" aria-hidden="true"> upgradeTest</span>
            </div>
          </a>
        </li>

        <li><hr class="hr" style="color:white;height:1.15px;width:190px;" /></li>
      </ul>
      <!--End of Admin-->
      <!--===============================================================================================-->
      <!--Begin of Activities-->
      <li *ngIf="signedInUserId > 0 && isPremium" appExpandMenu>
        <a>
          <div align="center" class="hvr-underline-from-center increase" style="line-height:0.75rem;">
            <span class="glyphicon glyphicon-list-alt hvr-icon" style="font-size: large;color:orange;" aria-hidden="true"></span>
            <span style="color: white; font-size: 14px; margin: 0px 0 0px 1.5px;"> myActivities <i class="caret hvr-icon"></i></span>
          </div>
        </a>

      </li>

      <ul *ngIf="signedInUserId > 0 && isPremium" class="nav submenu" style="margin:-1.1rem 0 -0.25rem 0;">
        <li class="dropdown-header text-center wordwrap" style="height:0;margin:0;"></li>
        <li *ngIf="buddyCount > 0">
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('buddies', signedInUserId);">
            <div class="hvr-underline-from-center increase" align="center" style="line-height:0.75rem;">
              <span class="glyphicon glyphicon-star-empty hvr-icon" style="font-size: x-large;color: orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true"> buddies</span>
            </div>
          </a>
        </li>


        <li>
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('iStarred', signedInUserId);">
            <div class="hvr-underline-from-center increase" align="center" style="line-height:0.75rem;">
              <span class="glyphicon glyphicon-star hvr-icon coin" style="font-size: large;color: orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true"> iStarred</span>
            </div>
          </a>
        </li>

        <li *ngIf="signedInUserId > 0">
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('iFriended', signedInUserId);">
            <div class="hvr-underline-from-center text-center increase" align="center" style="margin-left: -5px; display:inline-flex !important;">
              <!--<img class="img img-circle coin hvr-icon text-center drkRedGrnSB"
          src="assets/photos/glyph-members-transparent.png"
          style="background: transparent; border: 1.25px solid orange; vertical-align: middle; margin: 0.15rem 0.01rem 0.02rem 0.01rem; z-index: 11; height: 20px; width: 20px; " aria-hidden="true" />-->
              <span class="glyphicon glyphicon-heart hvr-icon" style="font-size: x-large;color:orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px;margin:7px 0 0 0" aria-hidden="true"> iFriended</span>
            </div>
          </a>
        </li>

        <li>
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('iLiked', signedInUserId);">
            <div class="hvr-underline-from-center increase" align="center" style="line-height:0.75rem;">
              <span class="glyphicon glyphicon-thumbs-up hvr-icon" style="font-size: x-large;color: orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true"> iLiked</span>
            </div>
          </a>
        </li>

        <li>
          <!--/activitym/iTracked-->
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('iTracked', signedInUserId);">
            <div class="hvr-underline-from-center increase" align="center" style="line-height:0.75rem;">
              <span class="glyphicon glyphicon-eye-open hvr-icon" style="font-size: large;color: orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true"> iTracked</span>
            </div>
          </a>
        </li>

        <!--/activitym/iUnlocked-->
        <li *ngIf="signedInUserId > 0">
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('iUnlocked', signedInUserId);">
            <div class="hvr-underline-from-center text-center increase" align="center" style="margin-left: -5px; display:inline-flex !important;">
              <img class="img img-circle coin hvr-icon text-center drkRedGrnSB"
                   src="assets/photos/unlock.png"
                   style="background: transparent; vertical-align: middle; margin: 0.15rem 0.01rem 0.02rem 0.01rem; z-index: 14; height: 20px; width: 20px;" aria-hidden="true" />
              <span class="hvr-icon" style="font-size: 14px;margin:7px 0 0 0" aria-hidden="true"> iUnlocked</span>
            </div>
          </a>
        </li>
        <!--wink has expiry date /activitym/iWinked-->
        <li *ngIf="signedInUserId > 0">
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('iWinked', signedInUserId);">
            <div class="hvr-underline-from-center text-center increase" align="center" style="margin-left: -5px; display:inline-flex !important;">
              <img class="img img-circle coin hvr-icon text-center drkRedGrnSB"
                   src="assets/photos/wink.png"
                   style="background: orange; vertical-align: middle; margin: 0.15rem 0.01rem 0.02rem 0.01rem; z-index: 14; height: 20px; width: 20px;" aria-hidden="true" />
              <span class="hvr-icon" style="font-size: 14px;margin:7px 0 0 0" aria-hidden="true"> iWinked</span>
            </div>
          </a>
        </li>

        <!--block does not expire; hax max=15 for valued customer-->
        <li>
          <!-- (click)="setRouteBreadCrum('iBlocked', loginSuccess.signedInUserId);"-->
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('iBlocked', signedInUserId);">
            <div align="center" class="hvr-underline-from-center increase" style="line-height:0.75rem;">
              <span class="glyphicon glyphicon-ban-circle hvr-icon coin" style="font-size: large;color: orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true"> iBlocked </span>
            </div>
          </a>
        </li>

        <li><hr class="hr" style="color:white;height:1.15px;width:190px;" /></li>
      </ul>
      <!--End of Activities-->
      <!--===============================================================================================-->
      <!--Begin of HomeStuff-->

      <li appExpandMenuHome>
        <a>
          <div align="center" class="hvr-underline-from-center increase" style="line-height:0.65rem;">
            <span class="glyphicon glyphicon-home hvr-icon" style="font-size:large;color:orange;" aria-hidden="true"></span>
            <span style="color: white; font-size: 14px; margin: 0px 0 0px 1.5px;"> HomeStuff <i class="caret hvr-icon"></i></span>
          </div>
        </a>

      </li>

      <ul class="nav submenu" style="margin:-1.1rem 0 -0.25rem 0;padding:0; ">
        <li class="dropdown-header text-center wordwrap" style="height:0;margin:0;padding:0">
        </li>
        <li>
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('index',  0);">
            <div align="center" class="hvr-underline-from-center increase" style="line-height:0.75rem;">
              <span class="glyphicon glyphicon-home hvr-icon" style="font-size: large;color: orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true"> Home </span>
            </div>
          </a>
        </li>

        <li>
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('about',  0);">
            <div class="hvr-underline-from-center increase" align="center" style="line-height:0.75rem;">
              <span class="glyphicon glyphicon-info-sign hvr-icon" style="font-size: large;color: orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true"> About Us</span>
            </div>
          </a>
        </li>
        <li>
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('features',  0);">
            <div class="hvr-underline-from-center increase" align="center" style="line-height:0.75rem;">
              <span class="glyphicon glyphicon-info-sign hvr-icon" style="font-size: large;color: orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true"> App Features</span>
            </div>
          </a>
        </li>
        <li>
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('contactUs', 0);">
            <div class="hvr-underline-from-center increase" align="center" style="line-height:0.75rem;">
              <span class="glyphicon glyphicon-send hvr-icon" style="font-size: large;color: orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true"> Contact Us</span>
            </div>
          </a>
        </li>
        <li>
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('terms',  0);">
            <div class="hvr-underline-from-center increase" align="center" style="line-height:0.75rem;">
              <span class="glyphicon glyphicon-hand-up hvr-icon" style="font-size: large;color: orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true"> TermsOfUse</span>
            </div>
          </a>
        </li>
        <li>
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('privacy',  0);">
            <div class="hvr-underline-from-center increase" align="center" style="line-height:0.75rem;">
              <span class="glyphicon glyphicon-qrcode hvr-icon" style="font-size: large;color: orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true"> PrivacPolicy</span>
            </div>
          </a>
        </li>
        <li *ngIf="signedInUserId > 0 && isAuthorized">
          <a routerLink="" routerLinkActive="active" aria-expanded="true" (click)="setRoute('upgradedMembers', 0);">
            <div class="hvr-underline-from-center increase" align="center" style="line-height:0.75rem;">
              <span class="glyphicon glyphicon-list-alt hvr-icon" style="font-size: large;color: orange;" aria-hidden="true"></span>
              <span class="hvr-icon" style="font-size: 14px; margin: 0px 0 0px 1.5px;" aria-hidden="true"> UpgradedMembers</span>
            </div>
          </a>
        </li>
        <li><hr class="hr" style="color:white;height:1.15px;width:190px;" /></li>
      </ul>
      <!--End of HomeStuff-->
      <!--===============================================================================================-->
      <li><hr class="hr" style="color:white;height:1.15px;width:190px;" /></li>

      <li *ngIf="!isPremium && signedInUserId > 0" class="text-center">
        <div class="whiteDrkRedxS" align="center" style="margin-top: 0px;">
          <div style="height: auto; font-size: 15px;" aria-hidden="true">
            Thank you for being a ValuedMember
            <div style="line-height: 0.75rem;">at </div>
            Slakez.com
            <div class="footerNote whiteBlueInvxS" style="font-size: 13px;">
              Premium Membership gives you unlimited features and freedom for a small monthly fee
              <!--<a routerLink="publicm/compareMemberships" routerLinkActive="active"  type="button" class="whiteBlueInvxxxS" (click)="setRouteBreadCrum('compareMemberships', 0);" style="background:transparent;border:none;"><div class="whiteBlueInvxxxS increase"> <span> *Compare</span> <div>Valued vs Premium</div> Membership</div></a>-->
            </div>
            <!--<div class="glyphicon glyphicon-heart-empty" style="font-size: x-large;color: orange;" aria-hidden="true"></div>-->
          </div>
        </div>

        <hr class="hr" style="color:white;height:1.15px;width:140px;margin-left:30px" />

        <a routerLink="" routerLinkActive="active" (click)="setRoute('payment', signedInUserId);" class="hvr-underline-from-center increase bttn-sm-transparent" align="center" aria-expanded="true" style="margin: 0.25rem; padding:0.15rem;">
          <span [ngClass]="{'wordwrap hvr-icon increase':true, 'whiteBlueInvxS': !isMobileVar, 'xsMobile':isMobileVar}" style="height: auto;padding: 0.15rem; height: auto; width: auto; font-size:15px; " aria-hidden="true">
            <br />
            Upgrade to <br />
            Premium Membership<br />
            <span class="glyphicon glyphicon-certificate hvr-icon coin" style="font-size: x-large;color: orange;" aria-hidden="true"></span>
            <br />
          </span>
        </a>
        <a routerLink="" routerLinkActive="active" type="button" (click)="setRoute('compareMemberships', 0);" style="background:transparent;border:none;">
          <div class="bttn-sm-transparent hvr-icon text-center">
            <!--whiteBlueInvxxxS -->
            <div [ngClass]="{'wordwrap':true, 'grnDrkRedxS': !isMobileVar, 'xsMobile':isMobileVar}" style="font-size:15px;">Compare Valued vs Premium Membership</div>
            <div class="glyphicon glyphicon-heart-empty smallerCircle coinV increase" style="font-size: x-large;color: orange;" aria-hidden="true"></div>
          </div>
        </a>

      </li>
      <li *ngIf="isPremium && signedInUserId > 0">
        <div class="hvr-underline-from-center increase" align="center" style="height:auto;">
          <span class="hvr-icon whiteDrkRedxS increase" style="height: auto; font-size: 15px; margin-bottom: 5px;" aria-hidden="true">
            <!--You tube video here: below is a temporaru placeholder-->
            <br />
            Thank you for becoming a <br />
            Premium Member <span class="glyphicon glyphicon-certificate smaller increase coin" style="font-size: x-large;color: orange;" aria-hidden="true"></span><br />
            at<br />
            Slakez.com <br />
            We appreciate your support.
            <br />
          </span>
        </div>
      </li>
      <li *ngIf="signedInUserId === 0">
        <div class="hvr-underline-from-center increase" align="center">
          <span class="glyphicon glyphicon-info-sign hvr-icon" style="font-size: x-large;color: orange;" aria-hidden="true"></span>
          <br />
          <span class="hvr-icon whiteDrkRedxS" style="height: auto; font-size: 15px;" aria-hidden="true">
            Signup now for a FREE<br />
            Valued Membership <br />
            <span class="footerNote" style="font-size: 13px;">With no monthly fee and limited features</span>
          </span>
        </div>

      </li>

      <li><hr class="hr" style="color:white;height:1.15px;width:190px;" /></li>
      <!--===============================================================================================-->
    </ul>
</nav>
</div>
