import { CommunicationActivity } from '../communication/communicationActivity.model';
import { Communicator } from '../communication/communicator.model';

export class Chat {
  public id? = 0;
  public baseFileName = ''; // TODO: make sure that the image-file-name generated is stored here
  public chatActivityId? = 0;
  public chatMessageId? = 0;
  public communicationActivityId? = 0;
  public communicationMessageId? = 0;
  public communicationType = '';
  public commTypeEnum = 0;
  public contentType = '';
  public date = '';
  public dateFormatted = '';
  public description = '';
  public file: any;
  public files: any[] = [];
  public fileNames: string[] = [];
  public fileBytesArr: any[] = [];
  public fileImageString = '';
  public fileImageArrBox !: any[];
  public fileImageArrNonce !: any[];
  public fileImageStringArr: string[] = [];
  public fromUser = '';
  public groupId? = 0;
  public groupName = '';
  public groupKey = '';
  public isAdministrative? = false;
  public isPic = false;
  public isReceiver = false;
  public isSender = false;
  public latitude? = 0;
  public longitude? = 0;
  public messageBody = '';
  public messageBodyShort = '';
  public receiver: Communicator = new Communicator();
  public receiverCryptUserKey = '';
  public receiverAction = '';
  public receiverEmail = '';
  public receiverPic = '';
  public receiverModelString = '';
  public receiverProfileName = '';
  public receiverSITUserId? = 0; // holds server-value
  public receiverSitUserId? = 0; // holds client-value
  public receiverSITUserKey = ''; // holds server-value
  public receiverSitUserKey = ''; // holds client-value
  public receiverSitUserKeyBackup = '';
  public repliedToId? = 0;
  public sender: Communicator = new Communicator();
  public senderCryptUserKey = '';
  public senderAction = '';
  public senderEmail = '';
  public senderPic = '';
  public senderModelString = '';
  public senderProfileName = '';
  public senderSITUserId? = 0; // holds server-value
  public senderSitUserId? = 0; // holds client-value
  public senderSITUserKey = ''; //  holds server-value
  public senderSitUserKey = ''; // holds client-value
  public senderSitUserKeyBackup = '';
  public sentPic = '';
  public signedInUserId? = 0;
  public signedInUserKey = '';
  public sitUserId? = 0;
  public subject = '';
  public subjectShort = '';
  public timeLapsed = '';
  public timeLapsedPlaceholder = '';
  public toUser = '';
  constructor () {
    this.harmonize(this);
  }
  harmonize (chat: Chat) : void {
    chat.receiverSITUserId == chat.receiverSitUserId;
    chat.receiverSitUserKey == chat.receiverSITUserKey;
    chat.senderSITUserId == chat.senderSitUserId;
    chat.senderSITUserKey == chat.senderSitUserKey;
	}
  copy2CommunicationActivity () {
    let commAct = new CommunicationActivity();
    commAct.id = this.id.toString();
    commAct.baseFileName = this.baseFileName;
    commAct.chatActivityId = this.chatActivityId;
    commAct.communicationActivityId = this.communicationActivityId;
    commAct.communicationMessageId = this.communicationMessageId;
    commAct.chatMessageId = this.chatMessageId;
    commAct.communicationType = "Chat";
    commAct.commTypeEnum = this.commTypeEnum;
    // commAct.contentType = this.contentType;
    commAct.dateFormatted = this.dateFormatted;
    commAct.date = this.date;
    commAct.fromUser = this.fromUser;
    commAct.isPic = this.isPic;
    commAct.latitude = this.latitude;
    commAct.longitude = this.longitude;
    commAct.messageBody = this.messageBody;
    commAct.messageBodyShort = this.messageBodyShort;

    commAct.receiver = this.receiver;
    commAct.receiverAction = this.receiverAction;
    commAct.receiverModelString = this.receiverModelString;
    commAct.receiverProfileName = this.receiverProfileName;
    commAct.receiverSITUserId = this.receiverSITUserId;
    commAct.receiverSitUserId = this.receiverSitUserId;
    commAct.receiverSITUserKey = this.receiverSITUserKey;
    commAct.receiverSitUserKey = this.receiverSitUserKey;

    commAct.sender = this.sender;
    commAct.senderAction = this.senderAction;
    commAct.senderModelString = this.senderModelString;
    commAct.senderProfileName = this.senderProfileName;
    commAct.senderSITUserId = this.senderSITUserId;
    commAct.senderSitUserId = this.senderSitUserId;
    commAct.senderSITUserKey = this.senderSITUserKey;
    commAct.senderSitUserKey = this.senderSitUserKey;

    commAct.sentPic = this.sentPic;

    commAct.signedInUserId = this.signedInUserId;
    commAct.signedInUserKey = this.signedInUserKey;


    commAct.subject = this.subject;
    commAct.subjectShort = this.subjectShort;
    commAct.timeLapsed = this.timeLapsed;

    commAct.toUser = this.toUser;

    // to harmonize these values:
    this.harmonize(this);
	}
}
