<div id="radioBoxComponentId">
  <!--Ref:https:// www.sitepoint.com/replacing-radio-buttons-without-replacing-radio-buttons/ -->
  <fieldset class="text-left" style="font-size: 0.35rem;">
    <legend *ngIf="radioboxLegend.length > 0" class="nvyB">{{radioboxLegend}} <span *ngIf="radioboxLegend.length > 0 && isRequired" class="errSub" style="margin-left:-2px;">*</span></legend>
    <label *ngFor="let rad of radioboxArr let i=index" for="{{rad}}_{{i}}" [ngClass]="{'increase text-left':true}" style="opacity:1;font-size:1rem;padding:0.02rem 0.5rem;">
      <input type="radio"
             value="{{rad}}"
             id="{{rad}}_{{i}}"
             style="width:auto; font-size:0.3rem;"
             name="{{radioboxName}}"
             (change)="onRadioboxChange($event, i);"
             [checked]="selectedRadioIndex === i" />
      <em class="text-left">
        <span *ngIf="rad.toLowerCase().indexOf('askme') !== -1" style="width: auto; text-transform: capitalize; font-size: 11px;">{{askMe}}</span>
        <span *ngIf="rad.toLowerCase().indexOf('askme') === -1" style="width: auto; text-transform: capitalize; font-size: 11px;" class="wordwrap">{{rad}}</span>
      </em>
    </label>
  </fieldset>
  <div *ngIf="isRequired && ! isRequiredPassed() && isFormSubmitted">
    <span class="flex-text errS" style="width: auto; font-size: 11px; text-transform: capitalize; color: orangered;"><em>{{radioboxName}}</em> is required, please select at leaset one value.</span>
  </div>
</div>
<!--==========================================================================================================-->
