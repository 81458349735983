<div id="checkButtonComponentId">
  <fieldset class="text-left" style="margin-left:0.5rem;padding:0.15rem;">
    <legend *ngIf="checkboxLegend.length > 0" class="nvyB">{{checkboxLegend}} <span *ngIf="checkboxLegend.length > 0 && minRequired > 0" class="errSub" style="margin-left:0px;">*</span></legend>
    <div *ngFor="let chk of checkboxArr let i=index" [ngClass]="{'increase':true}" style="margin:0.15rem;padding:0.15rem;height:auto;">
      <input type="checkbox"
             value="{{chk}}"
             name="{{checkboxName}}"
             id="{{checkboxName}}_{{i}}"
             (change)="onCheckboxChange($event, i);"
             [checked]="selectedChkBoxArr.indexOf(chk) > -1" />
      <label for="{{checkboxName}}_{{i}}">
        <span class="text-left"></span>
        <b class="text-left">
          <span *ngIf="chk.toLowerCase().indexOf('askme') !== -1" style="text-transform: capitalize;font-size:11px;">AskMe</span>
          <span *ngIf="chk.toLowerCase().indexOf('askme') === -1" style="text-transform: capitalize; font-size: 11px;">{{chk}}</span>
        </b>
      </label>
    </div>
  </fieldset>
  <div *ngIf="isFormSubmitted && !isRequiredPassed()">
    <span class="flex-text errxS" style="text-transform: capitalize; color: orangered; font-size: 11px;">
      <em>{{checkboxName}}</em> is required, please select at leaset one value.
    </span>
  </div>
</div>

<!--Ref:https://www.sitepoint.com/replacing-radio-buttons-without-replacing-radio-buttons/ -->

