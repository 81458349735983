'use-strict'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { MessageModel } from '../../../models/common/messageModel.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { UserAndPageTitle } from '../../../models/common/userAndPageTitle.model';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { PagerPhotoMgmtService } from '../../../services/pagerServiceService/pagerPhotoMgmtService.service';
import { PagerServiceService } from '../../../services/pagerServiceService/pagerServiceService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: [ './logo.component.scss' ],
  animations: [ Animations, slideInOutAnimation ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnInit, OnDestroy, AfterViewInit
{
  public redirectionService! : RedirectionService;
  public pagerPhotoMgmtService! : PagerPhotoMgmtService;
  public deviceInfo : any;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public isMobilevar = false;
  public isMouseIn = false;
  public isOnScroll = false;
  public isPhotoMgmt = false;
  public isSpinner = false;
  public isSuccess = true;
  public isViewMember = false;
  public index = -1;
  @Input() loginSuccess : LoginSuccess = new LoginSuccess();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public message : string = '';
  public messageModel : MessageModel = new MessageModel();
  public pageTitle = '';
  public pagerBrdCrm : PagerBreadcrum = new PagerBreadcrum();
  public sitUser : SitUser = new SitUser();
  public signedInUserId = 0;
  public tempElem : any;
  public timer : any;
  public timerArray : any[] = [];
  public userAndPageTitle : UserAndPageTitle = new UserAndPageTitle();
  constructor (private cdr : ChangeDetectorRef,
    private commonServiceServie: CommonServiceService,
    private logoElementRef : ElementRef,
    private pagerServiceService : PagerServiceService,
    private router : Router,)
  {
    if (this.pagerServiceService) this.pagerPhotoMgmtService = this.pagerServiceService.getPagerPhotoMgmtService();

    if (this.commonServiceServie) {
      this.redirectionService = this.commonServiceServie.getRedirectionService();
    }
  }

   //  -------------------------------------------------------------------------
  ngOnInit () : void
  {
    this.isMobilevar = EmitterSubjectService.getIsMobile();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
      this.signedInUserId = this.loginSuccess.signedInUserId;
    }
    
    //  -------------------------------------------------------------------------
    EmitterSubjectService.clearPageTitleEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.clearPageTitle();
      });
    
    //  -------------------------------------------------------------------------
    // debugger;
     EmitterSubjectService.loginSuccessEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        this.loginSuccess = result as LoginSuccess; //  JSON.parse(JSON.stringify(result));
        this.executeLoginSuccessTasks();
      });

    //  -------------------------------------------------------------------------
    EmitterSubjectService.messageEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result : any) => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
          if (result instanceof MessageModel) {
            this.messageModel = result;
            this.message = this.messageModel.message;
          }
          else {
            this.messageModel.message = result as string;
            this.message = this.messageModel.message;
          }
        }
        this.ngAfterViewInit();
      });
    //  -----------------------------------------------------------------
    EmitterSubjectService.pagerBreadcrumEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.pagerBrdCrm = result as PagerBreadcrum;
        this.ngAfterViewInit();
      })
    //  -------------------------------------------------------------
    //EmitterSubjectService.userAndPageTitleEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe((result) => {
    //    debugger;
    //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
    //      this.userAndPageTitle = result as UserAndPageTitle;

    //      this.sitUser.sitUserId = this.userAndPageTitle.sitUserId;
    //      this.pageTitle = this.userAndPageTitle.pageTitle;
    //      // debugger;
    //      if (!this.userAndPageTitle.isPrepared && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.userAndPageTitle.pageTitle)) {
    //        this.pageTitle = StringServiceStatic.preparePageTitle(this.userAndPageTitle.pageTitle);
    //        // debugger;
    //      }
    //      // debugger;
    //    }
    //    this.ngAfterViewInit();
    //    // EmitterSubjectService.emitRunNgAfterViewInit(true);

    //  });

    //  -----------------------------------------------------------------
  }
  //  -------------------------------------------------------------------
  ngAfterViewInit () : any
  {
    this.cdr.detectChanges();
    return true;
  }
  //  ---------------------------------------------------------------
  ngOnDestroy (): void
  {
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    this.timerArray.forEach( ( timer ) => clearInterval( timer ) );
  }
  // ---------------------------------------------------------------
  clearPageTitle () : any {
    this.userAndPageTitle = new UserAndPageTitle();
    this.pageTitle = '';
    // this.ngAfterViewInit();
    // debugger;
    return true;

  }
  // ---------------------------------------------------------------------------------
  //clearVerticalMenues () {
  //  // debugger;
  //  //EmitterSubjectService.emitIsViewMember(false);
  //  //EmitterSubjectService.emitIsPhotoMgmt(false);
  //}
  // ---------------------------------------------------------------------------------
  public executeLoginSuccessTasks () : any
  {
    if ( this.loginSuccess.signedInUserId > 0 )
    {
      this.isSuccess = this.loginSuccess.isSuccess;
      this.signedInUserId = this.loginSuccess.signedInUserId;
      // debugger;
    }
    return this.loginSuccess;
  }
  // ---------------------------------------------------------------
  getOnScroll () {
    // debugger;
    const pageContentWrapper = this.getNavHeaderElementById('page-content-wrapper');

    pageContentWrapper.onscroll = this.logScroll;

  }
  // ---------------------------------------------------------------
  logScroll (e: any) {
    // debugger;
    this.getOnScroll();
    if (e && e.type) {
      this.isOnScroll = true;
      // debugger;
    }
    else {
      this.isOnScroll = false;
      // debugger;
    }
  }
  // ---------------------------------------------------------------
  mouseOver (e : Event) {
    if (e && e.type) {
      this.isMouseIn = true;
    }
    else {
      this.isMouseIn = false;
    }
  }
  // ----------------------------------------------------------------
  getNavHeaderElementById (id : string) : any {
    this.tempElem = document.getElementById(id);
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.tempElem)) {

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.logoElementRef) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.logoElementRef.nativeElement)) {
        this.tempElem = this.logoElementRef.nativeElement.querySelector(id);
      }
    }
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.tempElem)) {
      this.tempElem = this.logoElementRef.nativeElement.querySelector('#' + id);
    }
    // debugger;
    return this.tempElem;
  }
  // ---------------------------------------------------------------------------------
  // ----------------------------------------------------- 
  //  Login-Register-Nav-Header-Buttons-Management-System:
  // -----------------------------------------------------
  resetLogRegBttns () {
    // debugger;
    EmitterSubjectService.emitSetSignedInUserBackgroundImage(true);

    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.mcLoader.moduleName = 'app';
    this.mcLoader.componentName = 'index';
    if (this.loginSuccess.signedInUserId > 0) {

      this.mcLoader.sitUserId = this.loginSuccess.signedInUserId;
    }
    else {

      this.mcLoader.sitUserId = 0;
    }

    this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.mcLoader.sitUserId).subscribe(data => {
      this.pagerBrdCrm = data;
    });
  }
  // ---------------------------------------------------------------------------------
}
