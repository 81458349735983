
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Chat } from '../../models/chat/chat.model';
import { CommunicationActivity } from '../../models/communication/communicationActivity.model';
import { KV } from '../../models/keyValue/kv.model';
import { MemberActivity } from '../../models/member/memberActivity.model';
import { BinarySearchService } from '../searchSortServiceService/binarySearchService.service';
import { FrequentlyUsedFunctionsServiceStatic } from './frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from './stringServiceStatic.service';
import { KvMemberActivityGlyph } from '../../models/keyValue/kvMemberActivityGlyph.model';

// =====================================================================
//  Use Case:
// ----------
//  In a Component:
//      import { Subject } from 'rxjs;
//      import { takeUntil } from 'rxjs/operators';
//        ...
//      private onDestroy$: Subject<void> = new Subject<void>();
//        ...
//      this.RandomNumberGeneratorObservableServiceStatic.getRandomNumber()
//      .pipe( takeUntil( this.onDestroy$ ) )
//      .subscribe( ( luckyNumber : number ) =>
//      {
//        this.number1 = luckyNumber;
//        ...
//      public ngOnDestroy(): void {
//        this.onDestroy$.next();
//      }
// =====================================================================

@Injectable({ providedIn: 'root'})
export abstract class ArraySupportServiceStatic
{
  public static binarySearchService : BinarySearchService;
  public static rootScopeTs : Set<KV>;
  public static distUnit = '';
  public static isMobilevar = false;
  public static height : any;
  public static width : any;
  public static heightUnit : any;
  public static weightUnit : any;
  public static kv : KV;

  constructor (public _binarySearchService : BinarySearchService,
  ) {
    ArraySupportServiceStatic.binarySearchService = _binarySearchService;
    ArraySupportServiceStatic.rootScopeTs = new Set<KV>();
    
  }
  // ------------------------------------------------------------------
  // Tested, works!
  //  On 20220411
  // ------------------------------------------------------------------
  static createArrayFromMap (inmap : Map<any, any>) : any {
    const arr : any[] = [];

    if (inmap && inmap.size > 0) {
      arr.push([ ...inmap.values() ])
    }
    // debugger;
    return arr;
  }
  // ------------------------------------------------------------------
  //  Tested, works!
  // ------------------------------------------------------------------
  static createMapFromArray (arr : any) : Map<any, any> {
    const map = new Map<any, any>();

    if (arr && arr.length > 0) {
      arr.forEach(function callback (key : any, value : any) {
        map.set(value, key);
      });
    }
    return map;
  }
  // --------------------------------------------------------------
  static deepCopyArray (arr : any) : any {
    let tMap = this.createMapFromArray(arr);
    return this.createArrayFromMap(tMap);
  }
  // --------------------------------------------------------------
  // ref: https://stackoverflow.com/questions/4775722/how-can-i-check-if-an-object-is-an-array
  // Only implement if no native implementation is available
  // --------------------------------------------------------------
  static isTypeOfArray (obj : any) : boolean {
    let isArray : boolean = false;
    if (typeof Array.isArray === 'undefined') {
      (obj : any) => {
        isArray = Object.prototype.toString.call(obj) === '[object Array]';
      }
    };
    // debugger;
    return isArray;
  }
  // --------------------------------------------------------------
  static QueuePop (inArray : any[]) : any {
    for (let i = 0; i < inArray.length; i++) {
      // this.unloadComponent(this.activeComponentIds[i]); //Do something;
      inArray.splice(i--, 1);
    }
  }
  // --------------------------------------------------------------
  static RemoveFromBottom (inArray : any[]) : any {
    // bottom=0th-index
    for (let i = 0; i < inArray.length; i++) {
      // this.unloadComponent(this.activeComponentIds[i]); //do something;
      inArray.splice(i--, 1);
    }
  }
  // --------------------------------------------------------------
  static StackPop (inArray : any[]) : any {
    for (let i = inArray.length - 1; i >= 0; i--) {
      // this.unloadComponent(this.activeComponentIds[i]); //do something
      inArray.splice(i, 1);
      // debugger;
    }
  }
  // --------------------------------------------------------------
  static RemoveFromTop (inArray : any[]) : any {
    // top=last-index
    for (let i = inArray.length - 1; i >= 0; i--) {
      // this.unloadComponent(this.activeComponentIds[i]); // do something
      inArray.splice(i, 1);
      // debugger;
    }
  }
  // --------------------------------------------------------------
  static RemoveFromArray (inArray : any[], index : number) : any {
    // top=last-index
    inArray.splice(index, 1);
  }
  /*
  * --------------------------------------------
  * Note: Tested on 2020/12/20. Works!
  * --------------------------------------------
  */
  // -------------------------------------------
  //  a[]: any[] =[];
  //  revA = a.reverse();
  //  1) .reverse() method operates on the original array, thus you don't need to reassign a.
  //     ref: https://stackoverflow.com/questions/10168034/how-can-i-reverse-an-array-in-javascript-without-using-libraries
  //
  // -------------------------------------------
  //     ref: https://stackoverflow.com/questions/10168034/how-can-i-reverse-an-array-in-javascript-without-using-libraries
  // O(n/2)
  // -------------------------------------------
  static reverseOnItself = (s : any) => {
    let start = 0, end = s.length - 1;
    while (start < end) {
      [ s[ start ], s[ end ] ] = [ s[ end ], s[ start ] ]; // swap
      start++, end--;
    }
    return s;
  };
  // -------------------------------------------
  // O(n/2)
  // -------------------------------------------
  static reverseArrayOnItself (inArr : any[]) : any[] {
    const len = inArr.length;
    let middleIndex = len / 2;
    let temp : any;

    if (len % 2 === 0) {
      // even-case
      for (let i = 0; i <= middleIndex; i++) {
        temp = inArr[ i ];
        inArr[ i ] = inArr[ len - i - 1 ];
        inArr[ len - i - 1 ] = temp;
      }
    } else if (len % 2 === 1) {
      // odd-case
      const middleV = inArr[ len / 2 ];

      middleIndex = len / 2;
      for (let i = 0; i <= middleIndex || inArr[ i ] === middleV; i++) {
        // swap up to middle or  middleValue
        temp = inArr[ i ];
        inArr[ i ] = inArr[ len - i - 1 ];
        inArr[ len - i - 1 ] = temp;
      }
    }
    return inArr;
  }
  // ---------------------------------------------------------------
  // testing/usage:
  // console.time ('reverseOnItself');
  // for (var x = 0; x < 1000000; x++) {
  //    reverseOnItself();
  // }
  // console.timeEnd('reverse1');
  // ---------------------------------------------------------------
  static communicationActivityComparatorBySubType (dCa : CommunicationActivity, sCa : CommunicationActivity) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dCa) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCa)) {
      if (dCa.communicationSubType.indexOf(sCa.communicationSubType) !== -1) {
        return sCa.date > dCa.date;
      }
    }
  }
  // ---------------------------------------------------------------
  static communicationActivityComparator (dCa : CommunicationActivity, sCa : CommunicationActivity) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dCa) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCa)) {     
      return dCa.communicationActivityId - sCa.communicationActivityId;
    }
  }
  // ---------------------------------------------------------------
  static memberActivityComparator (dMa : MemberActivity, sMa : MemberActivity) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dMa) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sMa)) {
      return dMa.action.indexOf(sMa.action) !== -1;
    }
  }
  
  // ---------------------------------------------------------------
  static mergeMapsViaSpread (first : Map<any, any>, second : Map<any, any>) : Map<any, any> {
    let merged = new Map<any, any>([ ...first, ...second ]) as Map<any, any>;
    return merged;
  }
  // ---------------------------------------------------------------
  // static sortMap (first : Map<any, any>) : Map<any, any> {
  //  let m = this.sortMap(first);
  //  return m;
  // }
  // ---------------------------------------------------------------
  // Note: there is a similar function called createMapFromArray()
  //       and its counterpart createArrayFromMap()
  // ---------------------------------------------------------------
  static mapFromArray (list : any[]) : any {
    const mapped = list.map((el, i) => ({ index: i, value: el }));

    return mapped;
  }
  // ---------------------------------------------------------------
  static arrayFromMap (list : Map<any, any>) : any {
    return this.createArrayFromMap(list);
  }
  // ---------------------------------------------------------------
  // TODO: Tested on 20220616.
  // Note: This does not work.
  // Produces a map or an array within array without the values of array
  // ---------------------------------------------------------------
  static mergeArrays (dArr : any[], sArr : any[]) : any[] {
    let index = -1;
    let dMap = new Map<any, any>();
    let sMap = new Map<any, any>();
    let merged = new Map<any, any>();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dArr)
      || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sArr)) {
      if (dArr.length > 0) {
        dMap = new Map<any, any>(dArr);
      }
      if (sArr.length > 0) {
        sMap = new Map<any, any>(sArr);
      }
    }
    merged = new Map([ ...dMap, ...sMap ]);
    return this.createArrayFromMap(merged);
  }
  // --------------------------------------------------------------
  //  Note: makeUniq(arr[]) method makes an array
  //        unique-valued, ==> there is no duplicate element in it.
  //  
  //        Using the Set constructor and the spread syntax:
  // --------------------------------------------------------------
  public static makeArrayOfUniqElements (arr : any[]) : any[] {
    let uniq : any[];
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr) && arr.length > 0) {
      uniq = [ ...new Set(arr) ];

      return uniq;
    }
    else return arr;
  }
  // --------------------------------------------------------------
  //  Note: Tested on 20220411. Works!
  // --------------------------------------------------------------
  public static mergeArraysUniq (dArr : any[], sArr : any[]) : any[] {
    // merge
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sArr) && sArr.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dArr)) {
      dArr.push(...sArr);
    }
    // debugger;
    //uniq
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dArr) && dArr.length > 0) {
      dArr = [ ...new Set(dArr) ];
      // debugger;
    }
    // debugger;
    return dArr;
  }
  // ---------------------------------------------------------------
  static mergeMaps (dMap : Map<any, any>, sMap : Map<any, any>) : Map<any, any> {
    let merged = new Map<any, any>();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dMap) || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sMap)) {
      merged = new Map([ ...dMap, ...sMap ]);
    }
    return merged;
  }
  // ---------------------------------------------------------------
  static uniquelyMergeChatArrays (destArr : Chat[], srcArr : Chat[]) : Chat[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destArr) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(srcArr)) {
      if (srcArr.length > 0) {
        srcArr.map(e => {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e)) {
            var index = new BinarySearchService().isFound(destArr, e, this.communicationActivityComparator);
            if (index === -1) {
              destArr.push(e);
            }
          }
        })
      }
    }
    return destArr;
  }
  // ---------------------------------------------------------------
  static uniquelyMergeMemberActivityArrays (destArr : MemberActivity[], srcArr : MemberActivity[]) : MemberActivity[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destArr) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(srcArr)) {
      if (srcArr.length > 0) {
        srcArr.map(e => {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e)) {
            var index = new BinarySearchService().isFound(destArr, e, this.memberActivityComparator);
            if (index === -1) {
              destArr.push(e);
            }
          }
        })
      }
    }
    debugger;
    return destArr;
  }
  // ----------------------------------------------------------------------
  static getIndexOfStringInArray (arr : string[], input : string) : number {
    var index = -1;
    var i = 0;
    // debugger;
    if (arr.length > 0) {
      // debugger;
      for (var i = 0; i < arr.length; i++) {
        // debugger;
        if (arr[ i ] === input) {
          index = i;
          // debugger;
          break;
        }
      }      
    }
    return index;
  }
  // ----------------------------------------------------------------------
  static uniquelyUpdateStringArray (arr : string[], input : string) : string[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(input)) {
      var index = -1;
      if (arr.length > 0) {
        index = this.getIndexOfStringInArray(arr, input);   
        // debugger;
        if (index < 0) {
          arr.push(input);
          // debugger;
        }
      }
      else {
        arr.push(input);
      }
    }
    return arr;
  }
  // ----------------------------------------------------------------------
  static getIndexOfKvGlyphInArray (arr : KvMemberActivityGlyph[], input : CommunicationActivity) : number {
    var index = -1;
    var i = 0;
     debugger;
    if (arr.length > 0) {
      // debugger;
      for (var i = 0; i < arr.length; i++) {
        // debugger;
        if (arr[ i ].action.toLowerCase().indexOf(input.memberActivity.toLowerCase()) !== -1
          || arr[ i ].key.toLowerCase().indexOf(input.memberActivity.toLowerCase()) !== -1) {
          index = i;
           debugger;
          break;
        }
      }
    }
    return index;
  }
  // ------------------------------------------------------------------------
}
