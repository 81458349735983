
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { DateAssay } from '../../../models/common/dateAssay.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from './../stringServiceStatic.service';

/*
 * //ref:https:// attacomsian.com/blog/unix-timestamp-javacript
 *const timestamp = date.getTime()();
 *This method returns the current UTC timestamp in milliseconds.
 *date.getTime()() works in almost all modern browsers except IE8 and earlier versions.
 *But you can easily fix this by writing a small polyfill:
 *
 *if(!date.getTime()) {
 *  date.getTime() = () => new Date().getTime();
 *}
 *
 *The UNIX timestamp is an integer value that represents the number of seconds elapsed since
 *UNIX Epoch on January 1st, 1970 at 00:00:00 UTC.
 *
 *To convert the timestamp to seconds (UNIX time), you can do the following:
 *
 *const unixTime = Math.floor(date.getTime()() / 1000);
 *
 *Unix epoch in ISO 8601 date and time format is: '1970-01-01T00:00:00Z'
 * //ref:https:// en.wikipedia.org/wiki/Unix_time
 */

/*
 * ========================================================================================================================
 * VERY IMPORTANT NOTE ABOUT DateTime, both in JavScript and C#/.NET, and it's relationship between JS and C#/.NET:
 *
 * 1. Client's datetime is captured in JS via and stored as C#/.Net-Ticks in the backend-db.
 * 2. Client's date is caputred via the following method: new Date().getTime();
 * 3. The getDateAssay() method will do all the conversion necessary and store the values in the DateAssay.model.ts model
 * 3.1    There are two other important methods for the conversion between JS and C# which are:
 * 3.1.a.    getDateAssayOfCsTicks(csTicks)
 * 3.1.b.    getDateAssayOfJsTicks(jsTicks)
 * 4. The DateAssay.model will contain the following important data:
 * 4.1.   DateAssay.jsTicks (Client's datetime in JavaScript Ticks in string);
 * 4.2.   DateAssay.jsTicksNum (Client's datetime in JavaScript Ticks in number/interger);
 * 4.3.   DateAssay.csTicks (Client's datetime in C#/.NET Ticks in string);
 * 4.4.   DateAssay.csTicksNum (Client's datetime in C#/.NET Ticks in number/interger);
 * 4.5.   DataAssay.csTicks2JsDate (Client's datetime from the backend-db converted to JavaScript DateTime)
 * 4.6.   DataAssay.csTicks2JsDateFormatted (Client's datetime from the backend-db converted to JavaScript DateTime in localeString format)
 * ========================================================================================================================
 */

/*
 * Unix timestamp in milliseconds
 * ref:https:// stackoverflow.com/questions/9575790/how-to-get-time-in-milliseconds-since-the-unix-epoch-in-javascript
 */
const unixTimeStampMillSec = new Date().getTime();

@Injectable({ providedIn: 'root' })
export abstract class DateStringServiceStatic {
  // ------------------------------------------------------------------------
  constructor ( /*private copyService: CopyService*/) {
    // if (!date.getTime()) {
    //  date.getTime() = () => (this.now = new Date().getTime()); // in milliseconds
    // }
    // this.unixTimeStampSec = Math.floor(date.getTime()() / 1000); // in seconds
  }
  // private stringService! : StringService;
  /*
   * ------------------------------------------------------------------------
   * Ref:https:// stackoverflow.com/questions/6939685/get-client-time-zone-from-browser
   */
  // public ClientTZ = {
  //  UTCoffset: 0, // Browser time offset from UTC in minutes
  //  UTCoffsetDST: '',
  //  UTCoffsetT: '+0000S', // Browser time offset from UTC in '±hhmmD' form
  //  hasDST: false, // Browser time observes DST
  // };
  // ------------------------------------------------------------------------
  public static Date : Date = new Date();
  private static loginSuccess : LoginSuccess = new LoginSuccess();
  // public unixTimeStampSec = -1;
  public now : any;
  // ------------------------------------------------------------------------
  public static now () : number {
    return new Date().getTime(); // in milliseconds
  }
  // ------------------------------------------------------------------------
  public static unixTimeStampSec () : number {
    return Math.floor(new Date().getTime() / 1000); // in seconds
  }
  // ------------------------------------------------------------------------
  public static jsNowForOldBrowsers () : number {
    let date = new Date();
    let now : any = new Date().getTime(); // in milliseconds    
    return now;
  }
  // ------------------------------------------------------------------------
  public static jsNow () : number {
    return this.jsNowForOldBrowsers();
  }

  // ------------------------------------------------------------------------
  static compareDate (thisD : string, thatD : string) : number {
    const thisDateTicks = new Date(thisD).getTime();
    const thatDateTicks = new Date(thatD).getTime();
    // debugger;
    return thisDateTicks - thatDateTicks;
  }
  /*
   * ------------------------------------------------------------------------
   * Ref:https:// stackoverflow.com/questions/41948/how-do-i-get-the-difference-between-two-dates-in-javascript
   * ------------------------------------------------------------------------
   */
  static dateDiff (later : any, earlier : any, unit : string) : any {
    if (later && earlier) {
      const date2 = later;
      const date1 = earlier;
      const diff = date2 - date1;
      let diffSec = (date2 - date1) / 1000;

      diffSec = Math.abs(Math.floor(diffSec));
      // debugger;
      const years = Math.floor(diffSec / (365 * 24 * 60 * 60));
      let leftSec = diffSec - years * 365 * 24 * 60 * 60;

      const months = Math.floor(leftSec / ((365 / 12) * 24 * 60 * 60));

      leftSec = leftSec - months * (365 / 12) * 24 * 60 * 60;

      const days = Math.floor(leftSec / (24 * 60 * 60));

      leftSec = leftSec - days * 24 * 60 * 60;

      const hrs = Math.floor(leftSec / (60 * 60));

      leftSec = leftSec - hrs * 60 * 60;

      const min = Math.floor(leftSec / 60);

      leftSec = leftSec - min * 60;
      const msg = 'You have ' + days + ' days ' + hrs + ' hours ' + min + ' minutes and ' + leftSec + ' seconds between later and earlier.';

      if (unit) {
        switch (unit) {
          case 'year':
            return years;
          case 'month':
            return months;
          case 'day':
            return days;
          case 'hour':
            return hrs;
          case 'minute':
            return min;
          case 'second':
            return leftSec;
          case 'string':
          default:
            return msg;
        }
      } else {
        // debugger;
        return diff;
      }
    }
  }
  // ------------------------------------------------------------------------
  public static convertToLocalWithOffset (date : Date) : any {
    if (date) {
      return formatDate(date, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    }
    return date;
  }

  /*
   * ------------------------------------------------------------------------
   * output format:'dd-MM-yyyy hh:mm:ss AM/PM'
   * ------------------------------------------------------------------------
   */
  public static convertToLocal (date : Date) : any {
    if (date) {
      return formatDate(date, 'dd-MM-yyyy hh:mm:ss a', 'en-US');
    }
    return date;
  }
  // ------------------------------------------------------------------------
  public static convertToDDMMYYYY (date : Date) : any {
    if (date) {
      return formatDate(date, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    }
    return date;
  }
  // ------------------------------------------------------------------------
  public static convertToYYYYMMDD (date : Date) : any {
    if (date) {
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss a', 'en-US', '+0530');
    }
    return date;
  }
  // ------------------------------------------------------------------------
  public static convertToYYYYMMDDFromJsTicks (ticks : any) : string {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(ticks)) {
      let ts;

      ts = Number(ticks); // cast it to a Number
      let date = new Date(ts); // works
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(date)) {
        date = new Date(ticks);
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(date)) {
        return formatDate(date, 'yyyy-MM-dd hh:mm:ss a', 'en-US', '+0530');
      }
      else return '';
    }
    else return '';
  }
  // ------------------------------------------------------------------------
  // Note: use thatTick for now/later, and thisTick for focused/target
  // ------------------------------------------------------------------------
  public static compareTicks (thisTicks : any, thatTicks : any) : number {
    if (thisTicks && thatTicks) {
      const thisTn = parseInt(thisTicks, 10);
      const thatTn = parseInt(thatTicks, 10);

      if (thisTn > 0 && thatTn > 0) {
        return thisTn === thatTn ? 0 : thisTn < thatTn ? -1 : 1;
      }
    }
    return -99;
  }
  // ------------------------------------------------------------------------
  public static isOnlineFromDateTimeTicks (heartbeatTime : any) : boolean {
    let isOnline = false;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(heartbeatTime)) {
      const nowTicks = new Date().getTime();
      let minutes = DateStringServiceStatic.dateDiff(nowTicks, heartbeatTime, 'minute')

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(minutes)) {
        if (minutes > 0 && minutes < 21) {
          isOnline = true;
          // debugger;
        }
      }
    }
    return isOnline;
  }
  // ------------------------------------------------------------------------
  public static convertGroupsToLocalTime (groups : any) : any {
    if (groups && groups.length > 0) {
      groups.map((e : any) => {
        e.displayDate = this.convertToLocal(e.date);
      });
    }
    return groups;
  }
  // ------------------------------------------------------------------------
  static getDateOnly (date : string) : any {
    if (date) {
      const dateParts = date.split('T');

      if (dateParts.length > 0) {
        return dateParts[ 0 ].toString();
      }
    }
    return date;
  }
  // ------------------------------------------------------------------------
  static getMDYDate (date : string) : any {
    if (date) {
      const parts = date.split('/'); // date=mm/dd/yyyy

      if (parts.length > 2) {
        return this.GetMonthName(parseInt(parts[ 0 ], 10)) + ' ' + parts[ 1 ] + ', ' + parts[ 2 ];
      }
    }
    return date;
  }
  // ------------------------------------------------------------------------
  static GetMonthName (month : any) : any {
    if (month > 0) {
      switch (month) {
        case 1:
          return 'January';
        case 2:
          return 'February';
        case 3:
          return 'March';
        case 4:
          return 'April';
        case 5:
          return 'May';
        case 6:
          return 'June';
        case 7:
          return 'July';
        case 8:
          return 'August';
        case 9:
          return 'September';
        case 10:
          return 'October';
        case 11:
          return 'November';
        case 12:
          return 'December';
        default:
          return '';
      }
    }
    return '';
  }
  /*
   * ------------------------------------------------------------------------
   * Note: Tested work!
   * ------------------------------------------------------------------------
   */
  static getTicks (date : Date) : any {
    if (date) {
      return new Date(date).getTime();
    }
    return new Date().getTime();
  }
  // ------------------------------------------------------------------------
  public static getUnixTimeStampInSecond () : number {
    return this.unixTimeStampSec();
  }
  // ------------------------------------------------------------------------
  public static isCsTime (t : any) : boolean {
    var isCs = false;
    if (!StringServiceStatic.isNullOrEmpty(t)) {
      var tStr : string = JSON.stringify(t);
      if (tStr[ 0 ] == '6' && tStr.length >= 18) {
        isCs = true;
      }
    }
    return isCs;
  }
  // ------------------------------------------------------------------------
  public static isJsTime (t: any) : boolean {
    var isJs = false;
    if (!StringServiceStatic.isNullOrEmpty(t)) {
      var tStr : string = JSON.stringify(t);
      if (tStr[ 0 ] !== '6' && tStr.length <= 13) {
        isJs = true;
      }
    }
    return isJs;
  }
  // ------------------------------------------------------------------------
  public static isTimerFound (timerArr : any[], timer : number) : boolean {
    let isFound = false;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(timerArr) && timerArr.length > 0 && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(timer)) {
      for (const e of timerArr) {
        isFound = e === timer;
        if (isFound === true) {
          return isFound;
        }
      }
    }
    return false;
  }
  // ------------------------------------------------------------------------
  // Note:  When Date(jsTicks) is neded, use this method.
  // ------------------------------------------------------------------------
  public static timeStampToDate (inTs : any) : any {
    let ts;

    ts = Number(inTs); // cast it to a Number
    const date = new Date(ts); // works
    // debugger;

    return this.convertToLocal(date);
  }
  /*
   * ========================================================================
   * ------------------------------------------------------------------------
   * NOTE: BEGIN of AGE-CALCULATION-SYSTEM-JS
   * ------------------------------------------------------------------------
   * Note: dob is sent in yyyy/mm/dd format
   */
  static ageFromDobString (dob : string) : number {
    let age = 0;
    let dobYYYY = 0;
    let dobMM = 0;
    let dobDD = 0;
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dob)) {
      const now = new Date();
      const nowYear = now.getFullYear();
      const nowMonth = now.getMonth() + 1;
      const nowDay = now.getDate();
      var dobParts = dob.split('/');
      if (dobParts.length < 2) {
        dobParts = dob.split('-');
      }

      if (dobParts.length > 2) {
        dobYYYY = parseInt(dobParts[ 0 ], 10);
        dobMM = parseInt(dobParts[ 1 ], 10);
        dobDD = parseInt(dobParts[ 2 ], 10);
        // debugger;
        age = nowYear - dobYYYY;
        if (nowMonth < dobMM && age > 0) {
          age--;
        }
        if (nowMonth === dobMM && nowDay < dobDD && age > 0) {
          age--;
        }
      }
      else {
        //debugger;
        age = this.ageFromDobTicks(parseInt(dob, 10));
      }
    }
    debugger;
    return age;
  }
  // ------------------------------------------------------------------------
  static ageFromDobTicks (ticks : number) : number {
    let age = 0;
    let dobYYYY = 0;
    let dobMM = 0;
    let dobDD = 0;
    // debugger;
    const now = new Date();
    const nowYear = now.getFullYear();
    const nowMonth = now.getMonth() + 1;
    const nowDay = now.getDate();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(ticks) && ticks > 0) {
      var dateAssay = new Date(ticks); // this.jsTicksInYYMMSSmmhhssOld(ticks);
      // debugger;
      dobYYYY = dateAssay.getFullYear(); //yyyy
      dobMM = dateAssay.getMonth(); // months;
      dobDD = dateAssay.getDay();
      age = nowYear - dobYYYY;
      // debugger;
      if (nowMonth < dobMM && age > 0) {
        age--;
      }
      if (nowMonth === dobMM && nowDay < dobDD && age > 0) {
        age--;
      }
    }
    return age;
  }
  /*
   * ------------------------------------------------------------------------
   * TODO: flesh out format1, and this method
   */
  static ageFromDobFormat1 (dobInFormat1 : number) : number {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dobInFormat1) && dobInFormat1 > 0) {
      return this.ageFromDobString(this.dateFromTicks(dobInFormat1, false));
    }
    return 0;
  }
  /*
   * ------------------------------------------------------------------------
   * TODO: flesh out format2, and this method
   */
  static ageFromDobFormat2 (dobInFormat2 : number) : number {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dobInFormat2) && dobInFormat2 > 0) {
      return this.ageFromDobString(this.dateFromTicks(dobInFormat2, false));
    }
    return 0;
  }
  /*
   * ------------------------------------------------------------------------
   * TODO: flesh out format3, and this method
   */
  static ageFromDobFormat3 (dobInFormat3 : number) : number {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dobInFormat3) && dobInFormat3 > 0) {
      return this.ageFromDobString(this.dateFromTicks(dobInFormat3, false));
    }
    return 0;
  }
  /*
   * ------------------------------------------------------------------------
   * END of AGE-CALCULATION-SYSTEM-JS
   * ------------------------------------------------------------------------
   * ========================================================================
   */


  // ========================================================================
  // ------------------------------------------------------------------------
  // NOTE: BEGIN of DateAssay-SYSTEM-JS
  // ------------------------------------------------------------------------

  static dateInYYYYMMDDhhmmss (inDate : any) : any {
    const dA = new DateAssay();
    let date = 0;
    if (typeof inDate === 'string') {
      date = parseInt(inDate, 10);
    }
    else date = inDate;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(date) && date > 0) {
      const tDate = new Date(date);
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tDate) && tDate instanceof Date) {
        // debugger;
        dA.yyyy = tDate.getFullYear();
        // Note: months in JS seems to start from 0, but on a calender it starts at 1
        dA.months = tDate.getMonth();
        dA.dd = tDate.getDay();
        dA.hh = tDate.getHours();
        dA.mm = tDate.getMinutes();
        dA.ss = tDate.getSeconds();
        dA.millss = tDate.getMilliseconds();
        dA.date = tDate;
        dA.dateFormatted = tDate.toLocaleString();
      }
    }
    // debugger;
    return dA;
  }
  // ------------------------------------------------------------------------
  // Note:  HeartbeatTime number sometimes has a fractional part,
  //        but we need only the whole number without the fractional part.
  // ------------------------------------------------------------------------
  static getHeartbeatTimeWholeNumber (hbt : any) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hbt)) {
      if (hbt.toString().indexOf('.') !== -1) {
        let parts = hbt.toString().split('.');
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(parts)) {
          if (parts.length === 2) {
            return parts[ 0 ];
          }
          else return parts;
        }
        else return hbt;
      }
      else return hbt;
    }
    else return hbt;
  }
  // ------------------------------------------------------------------------
  static getDateAssayOfCsTicks (csTicks : any) : any {
    let csTicksStr = '';
    let date : any;
    let dateA : DateAssay = new DateAssay();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(csTicks)) {

      // debugger;
      if (typeof csTicks === 'number') {
        csTicksStr = csTicks.toString();
      } else {
        csTicksStr = csTicks;
      }
      date = this.convertNetTicsToJsDate(csTicksStr);

      let tDateA = this.getDateAssay(date.getTime()) as DateAssay;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tDateA)) {
        dateA = tDateA;
      }
      else dateA = date;
    }
    // debugger;
    if (dateA.yyyy > 0) {
      // debugger;
      return dateA;
    }
    else return null;
  }
  // ------------------------------------------------------------------------
  static getDateAssayOfJsTicks (date : number) : DateAssay {
    return this.getDateAssay(date);
  }
  // ------------------------------------------------------------------------
  static getDateAssay (inDate : number) : any {
    let dAssay : DateAssay = new DateAssay();
    let date = 0;
    let inTicks = 0;

    if (typeof inDate === 'string') {
      date = parseInt(inDate, 10);
    }
    else date = inDate;

    if (date > 0) {
      dAssay = this.dateInYYYYMMDDhhmmss(date);
      inTicks = date / 10;

      dAssay.jsTicks = inTicks.toString();
      dAssay.jsTicksNum = Number(inTicks);
      dAssay.csTicksNum = this.getJsDateToNetTicks(dAssay.date);
      dAssay.csTicks = dAssay.csTicksNum.toString();
      dAssay.csTicks2JsDate = this.convertNetTicsToJsDate(dAssay.csTicks);
      dAssay.csTicks2JsDateFormatted = this.addDate(this.convertNetTicsToJsDate(dAssay.csTicks), 'minute', -Number(dAssay.UTCoffset)).toLocaleString();
    }
    if (dAssay.yyyy > 0) {
      // debugger;
      return dAssay;
    }
    else return null;
  }
  // ------------------------------------------------------------------------
  static getDateAssayFromTicks (inTicks : any) : DateAssay {
    const dAssay = this.getDateAssay(inTicks * 10);

    return dAssay;
  }
  // ------------------------------------------------------------------------
  static getDateAssayOfNow () : DateAssay {
    return this.getDateAssay(Number(new Date().getTime()));
  }
  /*
   * ------------------------------------------------------------------------
   * NOTE: END of DateAssay-SYSTEM-JS
   * ------------------------------------------------------------------------
   * =======================================================================
   */

  /*
   * ========================================================================
   * Begin of  The Adder-System
   * ------------------------------------------------------------------------
   * ------------------------------------------------------------------------
   * Ref:https:// stackoverflow.com/questions/1197928/how-to-add-30-minutes-to-a-javascript-date-object
   */
  /**
   * Adds time to a date. Modelled after MySQL DATE_ADD function.
   * Example: addDate(new Date(), 'minute', 30)  // returns 30 minutes from now.
   * https:// stackoverflow.com/a/1214753/18511
   *
   * @param date  Date to start with
   * @param interval  One of: year, quarter, month, week, day, hour, minute, second
   * @param units  Number of units of the given interval to add.
   */
  // ------------------------------------------------------------------------
  static addDate (date : Date, interval : string, units : number) : any {
    if (!(date instanceof Date)) {
      return undefined;
    }
    let ret = new Date(date); // don't change original date
    const checkRollover = () => {
      if (ret.getDate() !== date.getDate()) {
        ret.setDate(0);
      }
    };

    switch (String(interval).toLowerCase()) {
      case 'year':
        ret.setFullYear(ret.getFullYear() + units);
        checkRollover();
        break;
      case 'quarter':
        ret.setMonth(ret.getMonth() + 3 * units);
        checkRollover();
        break;
      case 'month':
        ret.setMonth(ret.getMonth() + units);
        checkRollover();
        break;
      case 'week':
        ret.setDate(ret.getDate() + 7 * units);
        break;
      case 'day':
        ret.setDate(ret.getDate() + units);
        break;
      case 'hour':
        ret.setTime(ret.getTime() + units * 3600000);
        break;
      case 'minute':
        ret.setTime(ret.getTime() + units * 60000);
        break;
      case 'second':
        ret.setTime(ret.getTime() + units * 1000);
        break;
      default:
        // ret = undefined;
        break;
    }
    return ret;
  }
  /*
   * Ref: https:// stackoverflow.com/questions/1197928/how-to-add-30-minutes-to-a-javascript-date-object
   * Tested: works!
   * ------------------------------------------------------------------------
   */
  static addSeconds (date : Date, seconds : number) : any {
    if (date) {
      this.Date = new Date(date);
      this.Date.setSeconds(this.Date.getSeconds() + seconds);
      return this;
    }
  }

  static addMinutes (date : Date, minutes : number) : any {

    if (date) {
      this.Date = new Date(date);
      this.Date.setMinutes(this.Date.getMinutes() + minutes);
      return this;
    }
  }

  static addHours (date : Date, hours : number) : any {
    if (date) {
      this.Date = new Date(date);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.Date)) {
        this.Date.setHours(this.Date.getHours() + hours);
        return this;
      }
    }
  }

  static addDays (date : Date, days : number) : any {
    if (date) {
      this.Date = new Date(date);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.Date)) {
        this.Date.setDate(this.Date.getDate() + days);
        return this;
      }
    }
  }

  static addWeeks (date : Date, weeks : number) : any {
    if (date) {
      this.Date = new Date(date);
      this.addDays(date, weeks * 7);
      return this;
    }
  }

  static addMonths (date : Date, months : number) : any {
    if (date) {
      this.Date = new Date(date);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.Date)) {
        const dt = this.Date.getDate();

        this.Date.setMonth(this.Date.getMonth() + months);
        const currDt = this.Date.getDate();

        if (dt !== currDt) {
          this.addDays(date, -currDt);
        }

        return this;
      }
    }
  }

  static addYears (date : Date, years : number) : any {
    if (date) {
      this.Date = new Date(date);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.Date)) {
        const dt = this.Date.getDate();

        this.Date.setFullYear(this.Date.getFullYear() + years);

        const currDt = this.Date.getDate();

        if (dt !== currDt) {
          this.addDays(date, -currDt);
        }

        return this;
      }
    }
  }
  /*
   * ========================================================================
   * End of  The Adder-System
   * ------------------------------------------------------------------------
   * ========================================================================
   */

  /*
   * ------------------------------------------------------------------------
   * pad(n, width) {
   *  n = n + '';
   * return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
   * }
   */

  /*
   * ========================================================================
   * ------------------------------------------------------------------------
   * BEGIN of JS-Ticks-To-.Net/C#Ticks-To-JS-Date-System
   * ------------------------------------------------------------------------
   * You can translate a JavaScript Date object to .NET ticks as follows:
   * Ref;https:// stackoverflow.com/questions/7966559/how-to-convert-javascript-date-object-to-ticks
   * ------------------------------------------------------------------------
   */
  static getJsDateToNetTicks (date : Date) : any {
    let yourDate; // for example

    if (date) {
      yourDate = new Date(date);
    } else {
      yourDate = new Date();
    }

    // the number of .net ticks at the unix epoch
    const epochTicks = 621355968000000000;

    // there are 10000 .net ticks per millisecond
    const ticksPerMillisecond = 10000;

    // calculate the total number of .net ticks for your date
    const yourTicks = epochTicks + yourDate.getTime() * ticksPerMillisecond;

    return yourTicks;

    /*
     * OR
     * Note: Date in JavaScript also contains offset.If you need to get rid of it use following:
     * return ((date.getTime() * 10000) + 621355968000000000) - (date.getTimezoneOffset() * 600000000);
     */
  }
  /*
   * ------------------------------------------------------------------------
   * You can translate a JavaScript Date object to .NET ticks as follows:
   * Ref:https:// stackoverflow.com/questions/7966559/how-to-convert-javascript-date-object-to-ticks
   * ------------------------------------------------------------------------
   */
  static getJsDateMinusJsOffsetToNetTicks (date : any) : any {
    // Note: Date in JavaScript also contains offset.If you need to get rid of it use following:
    if (date) {
      const dt = new Date(date);

      return dt.getTime() * 10000 + 621355968000000000 - dt.getTimezoneOffset() * 600000000;
    }
    else { 
      const dt = new Date();

      return dt.getTime() * 10000 + 621355968000000000 - dt.getTimezoneOffset() * 600000000;
    }
  }

  /*
   * ------------------------------------------------------------------------
   * Note: Tested and works! August 14, 2020.
   *      But this method does not consider 'TimezoneOffset'
   * ------------------------------------------------------------------------
   */

  // ------------------------------------------------------------------------
  static convertNetTicsToJsDateTicks (netTicks : string) : any {
    if (netTicks) {
      // the number of .net ticks at the unix epoch
      const epochTicks = 621355968000000000;

      // there are 10000 .net ticks per millisecond
      const ticksPerMillisecond = 10000;

      let jsDateTicks;

      /*
       * calculate the total number of .net ticks for your date
       * var yourTicks = epochTicks + (jsDateTicks * ticksPerMillisecond);
       */

      jsDateTicks = (Number(netTicks) - epochTicks) / ticksPerMillisecond;
      // var localDate = this.convertToLocal(date);
      // debugger;
      return jsDateTicks;
    }
    return null;
  }
  // ------------------------------------------------------------------------
  static convertNetTicsToJsDate (netTicks : string) : any {
    if (netTicks) {
      var jsDateTicks = this.convertNetTicsToJsDateTicks(netTicks);
      // debugger;
      const date = new Date(jsDateTicks); // works
      return date;
    }
    return null;
  }
  // ------------------------------------------------------------------------
  static convertNetTicsToJsDateString (netTicks : string) : string {
    if (netTicks) {
      const date = this.convertNetTicsToJsDate(netTicks);
      const localDate = this.convertToLocalWithOffset(date);
      // debugger;

      return localDate;
    }
    return '';
  }
  // ------------------------------------------------------------------------
  // date.toJSON(); // this is the JavaScript date as a c# DateTime  !!
  // Note: The result will be in UTC time
  // ref: https://stackoverflow.com/questions/6702705/how-to-convert-javascript-datetime-to-c-sharp-datetime
  // ------------------------------------------------------------------------
  /*
   * ------------------------------------------------------------------------
   * END of JS-Ticks-To-.Net/C#Ticks-To-JS-Date-System
   * ------------------------------------------------------------------------
   * ========================================================================
   */

  /*
   * ========================================================================
   * ------------------------------------------------------------------------
   * BEGIN of SitUserKey-Comparison-System
   * ------------------------------------------------------------------------
   * NOTE: This method should be  used whenever we need to compare sitUserKeys!!!!
   * Note: This method can compare the SITUserKeys of the following tow forms:
   *       1. dateTime-in-tics&user@email.com
   *       2.dateTime-in-format&user@email.com
   * Note: compared return values: 0 == this equals that, 1 == this > that, -1 == this < that
   * ------------------------------------------------------------------------
   */
  static compareOldSitUserKeys (thisSitUserKey : string, thatSitUserKey : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(thisSitUserKey) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(thatSitUserKey)) {
      const thisSitUserKeyParts = this.getSitUserKeyPartsArr(thisSitUserKey);
      const thatSitUserKeyParts = this.getSitUserKeyPartsArr(thatSitUserKey);
      const thisSitUserKeyDate = thisSitUserKeyParts[ 0 ];
      const thatSitUserKeyDate = thatSitUserKeyParts[ 0 ];
      let thisSitUserKeyTicks = 0;
      let thatSitUserKeyTicks = 0;
      /*
       * if the emails are equal
       */
      // debugger;
      if (thisSitUserKeyParts[ 1 ].toLocaleLowerCase().indexOf(thatSitUserKeyParts[ 1 ].toLocaleLowerCase()) !== -1) {
        // debugger;
        if (thisSitUserKeyParts[ 0 ].toLocaleLowerCase().indexOf(thatSitUserKeyParts[ 0 ].toLocaleLowerCase()) !== -1) {
          // debugger;
          return 0;
        }
        // compare the dateTime-ticks
        if (thisSitUserKeyParts[ 0 ].indexOf('/') === -1 && thisSitUserKeyParts[ 0 ].indexOf('-') === -1 && thisSitUserKeyParts[ 0 ].indexOf(':') === -1) {
          thisSitUserKeyTicks = Number(thisSitUserKeyParts[ 0 ]);
        }
        if (thatSitUserKeyParts[ 0 ].indexOf('/') === -1 && thatSitUserKeyParts[ 0 ].indexOf('-') === -1 && thisSitUserKeyParts[ 0 ].indexOf(':') === -1) {
          thatSitUserKeyTicks = Number(thatSitUserKeyParts[ 0 ]);
        }
        /*
         * if both dateTimes are in ticks, then compare ticks
         */
        if (thisSitUserKeyTicks > 0 && thatSitUserKeyTicks > 0) {
          // debugger;
          return this.compareTicks(thisSitUserKeyTicks, thatSitUserKeyTicks);
        }
        /*
         * compare the dateTimes (possibly in different format such as
         * DateTime format = yy-mm-dd hh:mm:ss AMPM or mm/dd/yyy hh:mm:ss AMPM)
         */
        // debugger;
        const thisDate = new Date(thisSitUserKeyParts[ 0 ]);
        const thatDate = new Date(thatSitUserKeyParts[ 0 ]);
        const diff = thisDate.getTime() - thatDate.getTime();
        // debugger;

        return diff;
      }
      return -1;
    }
  }
  // ------------------------------------------------------------------------
  static compareSitUserKeys (thisSitUserKey : string, thatSitUserKey : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(thisSitUserKey) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(thatSitUserKey)) {
      const thisSitUserKeyParts = this.getSitUserKeyPartsArr(thisSitUserKey);
      const thatSitUserKeyParts = this.getSitUserKeyPartsArr(thatSitUserKey);
      const thisSitUserKeyTicks = Number(thisSitUserKeyParts[ 0 ]);
      const thatSitUserKeyTicks = Number(thatSitUserKeyParts[ 0 ]);
      /*
       * if the emails are equal
       */
      // debugger;
      if (thisSitUserKeyParts[ 1 ].toLocaleLowerCase().indexOf(thatSitUserKeyParts[ 1 ].toLocaleLowerCase()) !== -1) {
        
         /*
         * if both dateTimes are in ticks, then compare ticks
         */
        if (thisSitUserKeyTicks > 0 && thatSitUserKeyTicks > 0) {
          // debugger;
          return this.compareTicks(thisSitUserKeyTicks, thatSitUserKeyTicks);
        } 
        else return -1;
      }
      else return -1;
    }
    else return -1;
  }
  // ------------------------------------------------------------------------
  static getSitUserKeyPartsArr (sITUserKey : string) : string[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sITUserKey)) {
      const parts = sITUserKey.split('&');

      if (parts != null && parts.length > 1) {
        return parts;
      }
    }
    return null as any;
  }
  // ------------------------------------------------------------------------
  static getEmailFromSitUserKey (sitUserKey : string) : string {
    const parts = this.getSitUserKeyPartsArr(sitUserKey);

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(parts) && parts.length > 1) {
      return parts[ 0 ];
    }
    return '';
  }
  // ------------------------------------------------------------------------
  static getProfileNameFromEmail (email : any) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email)) {
      let parts = email.split('@');
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(parts) && parts.length > 1) {
        return parts[ 0 ];
      }
      return '';
    }
  }
  // ------------------------------------------------------------------------
  static getProfileNameFromSitUserKey (sitUserKey : string) : string {
    let email = this.getEmailFromSitUserKey(sitUserKey);
    return this.getProfileNameFromEmail(email);
  }
  /*
   * ------------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/14350148/convert-ticks-to-time-format-hhmmss
   */
  static getDateFromTicks (inTicks : any) : any {
    if (inTicks) {
      const ticksInSecs = inTicks / 1000;
      const ticks = ticksInSecs;
      const hh = Math.floor(ticks / 3600);
      const mm = Math.floor((ticks % 3600) / 60);
      const ss = ticks % 60;

      return this.pad(hh, 2) + ':' + this.pad(mm, 2) + ':' + this.pad(ss, 2);
    }
    return null as any;
  }
  /*
   * ------------------------------------------------------------------------
   * END of SitUserKey-Comparison-System
   * ------------------------------------------------------------------------
   * ========================================================================
   * ------------------------------------------------------------------------
   * NOTE: BEGIN of BROWSER-TIMEZONE-SYSTEM-JS
   *
   *       This system/method is not needed since
   *       Date.getTimezoneOffset() does pretty much the same things.
   * ------------------------------------------------------------------------
   */
  static getBrowserTZ (isT : boolean) : any {
    const dAssay = this.getBrowserTZInDateAssay();

    if (isT) {
      return dAssay.UTCoffsetT;
    }
    return dAssay.UTCoffset;
  }
  /*
   * ------------------------------------------------------------------------
   * Ref:https:// stackoverflow.com/questions/6939685/get-client-time-zone-from-browser
   * Determine browser's timezone and DST
   * ------------------------------------------------------------------------
   */
  static getBrowserTZInDateAssay () : DateAssay {
    let ClientTZ = {
      UTCoffset: 0, // Browser time offset from UTC in minutes
      UTCoffsetDST: '',
      UTCoffsetT: '+0000S', // Browser time offset from UTC in '±hhmmD' form
      hasDST: false, // Browser time observes DST
    };
    const self = ClientTZ;

    // Determine UTC time offset
    const now = new Date();
    const date1 = new Date(now.getFullYear(), 1 - 1, 1, 0, 0, 0, 0); // Jan
    const diff1 = -date1.getTimezoneOffset();

    self.UTCoffset = diff1; // West of GMT ?

    // Determine DST use
    const date2 = new Date(now.getFullYear(), 6 - 1, 1, 0, 0, 0, 0); // Jun
    let diff2 = -date2.getTimezoneOffset();

    if (diff1 !== diff2) {
      self.hasDST = true;
      if (diff1 - diff2 >= 0) {
        self.UTCoffset = diff2; // East of GMT
        // else self.UTCoffset = diff1;  //West of GMT ?; (I added this line.)
      }
    }

    // Convert UTC offset to ±hhmmD form
    diff2 = (diff1 < 0 ? -diff1 : diff1) / 60;
    const hr = Math.floor(diff2);
    const min = diff2 - hr;

    diff2 = hr * 100 + min * 60;
    self.UTCoffsetT = (diff1 < 0 ? '-' : '+') + (hr < 10 ? '0' : '') + diff2.toString() + (self.hasDST ? 'D' : 'S');

    /*
     * if (isT) return self.UTCoffsetT;
     * else return self.UTCoffset;
     */

    const dateAssay = new DateAssay();

    dateAssay.UTCoffsetT = self.UTCoffsetT;
    dateAssay.UTCoffset = self.UTCoffset.toString();
    dateAssay.hasDST = self.hasDST;

    return dateAssay;
  }
  /*
   * ------------------------------------------------------------------------
   * NOTE: END of BROWSER-TIMEZONE-SYSTEM-JS
   * ------------------------------------------------------------------------
   * ------------------------------------------------------------------------
   * Note: This does not work!
   * ------------------------------------------------------------------------
   */
  static jsTicksInYYMMSSmmhhssOld (millsecs : number) : DateAssay {
    const secs = millsecs / 1000;
    const years = Math.floor(secs / (365 * 24 * 60 * 60));
    let leftSec = secs - years * 365 * 24 * 60 * 60;

    const months = Math.floor(leftSec / ((365 / 12) * 24 * 60 * 60));

    leftSec = leftSec - months * (365 / 12) * 24 * 60 * 60;

    const days = Math.floor(leftSec / (24 * 60 * 60));

    leftSec = leftSec - days * 24 * 60 * 60;

    const hrs = Math.floor(leftSec / (60 * 60));

    leftSec = leftSec - hrs * 60 * 60;

    const min = Math.floor(leftSec / 60);

    leftSec = leftSec - min * 60;
    const msg = 'Your date has: ' + days + ' days, ' + hrs + ' hours, ' + min + ' minutes, and ' + leftSec + ' seconds.';

    const dA : DateAssay = new DateAssay();

    dA.ss = leftSec;
    dA.mm = min;
    dA.hh = hrs;
    dA.dd = days;
    dA.months = months;
    dA.yyyy = years;
    dA.date = new Date(secs);
    debugger;
    dA.dateFormatted = years + '/' + months + '/' + days + ' T ' + hrs + ':' + min + ':' + leftSec;
    return dA;
    /*
     * if (unit) {
     * switch (unit) {
     *   case 'year':
     *     return years;
     *   case 'month':
     *     return months;
     *   case 'day':
     *     return days;
     *   case 'hour':
     *     return hrs;
     *   case 'minute':
     *     return min
     *   case 'second':
     *     return leftSec;
     *   case 'string': default:
     *     return msg;
     * }
     * }
     */
  }
  /*
   * ------------------------------------------------------------------------
   * NOTE: This does not work!
   * ------------------------------------------------------------------------
   */
  static dateFromTicks (inTicks : any, isBool : boolean) : any {
    const ticks = parseInt(inTicks, 10);
    // original code
    const ss = ticks % 60;
    const mm = Math.floor((ticks % 3600) / 60);
    const hh = Math.floor(ticks / 3600);
    const dd = Math.floor(hh / 24);
    const months = Math.floor(dd / 30);
    const yyyy = Math.floor(months / 12);

    // Note: 10*tick = millisec
    const millss = ticks * 10;

    const dateAssay = new DateAssay();

    dateAssay.millss = millss;
    dateAssay.ss = ss;
    dateAssay.mm = mm;
    dateAssay.hh = hh;
    dateAssay.dd = dd;
    dateAssay.yyyy = yyyy;
    dateAssay.months = months;
    dateAssay.jsTicksNum = ticks;
    dateAssay.jsTicks = ticks.toString();
    dateAssay.date = new Date(millss);

    /*
     * let ss = Math.floor((ticks / 10 / 1000) % 60);
     * let mm = Math.floor(((ticks / 10 / 100) % 3600) / 60);
     * let hh = Math.floor((ticks / 10 / 1000) / 3600);
     * -----------------------------------------------------
     * ss = Math.floor((millss / 1000) % 60);
     * mm = Math.floor(((millss / 1000) % 3600) / 60);
     * hh = Math.floor((millss / 1000) / 3600);
     * -----------------------------------------------------
     * let dd = Math.floor(hh / 24);
     * let months = Math.floor(dd / 30);
     * let yyyy = Math.floor(months / 12);
     * -----------------------------------------------------
     * + 'T' + this.pad(hh, 2) + ':' + this.pad(mm, 2) + ':' + this.pad(ss, 2);
     */
    const result = this.padYear(yyyy) + '/' + this.padMMDD(months) + '/' + this.padMMDD(dd);
    /*
     * alert ('age: ' + this.ageFromDobString(result) + '=>' + this.pad(yyyy, 2) + '/' + this.pad(months, 2) +;
     * '/' + this.pad(dd, 2) + this.pad(hh, 2) + ':' + this.pad(mm, 2) + ':' + this.pad(ss, 2));
     * debugger;
     */

    dateAssay.dateFormatted = this.convertToLocal(new Date(inTicks));
    if (isBool) {
      return dateAssay;
    }
    return result;
  }
  // ------------------------------------------------------------------------
  static pad (n : any, width : any) : any {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }
  static padMMDD (num : any) : any {
    num = '0' + num;
    return num.substr(num.length - 2);
  }
  static padYear (num : any) : any {
    num = '0' + num;
    return num.substr(num.length - 4);
  }
  // ------------------------------------------------------------------------
  // Ref:https:// docs.microsoft.com/en-us/dotnet/api/system.datetime.ticks?view=netframework-4.8
  // Tested on: 202301091521
  // ------------------------------------------------------------------------
  static timeAgoSince (date : Date) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(date)) {
      var diff : any;
      diff = Date.now() - date.getTime();

      var diffDate = this.getDateAssayFromTicks(diff);

      var ago = '';
      var totalMilliseconds = 0;
      var totalSeconds = 0;
      var totalMinutes = 0;
      var totalHours = 0;
      var totalDays = 0;
      var totalMonths = 0;
      var totalYears = 0;
      // debugger;
      if (diff > 1000) {
        totalSeconds = Math.floor(diff / 1000);
        totalMilliseconds = Math.ceil(diff - totalSeconds * 1000);
        // debugger;
        if (totalSeconds > 60) {
          totalMinutes = Math.floor(totalSeconds / 60);
          totalSeconds = Math.ceil(totalSeconds - totalMinutes * 60);
          // debugger;
          if (totalMinutes > 60) {
            totalHours = Math.floor(totalMinutes / 60);
            totalMinutes = Math.ceil(totalMinutes - totalHours * 60);
            // debugger;
            if (totalHours > 24) {
              totalDays = Math.floor(totalHours / 24);
              totalHours = Math.ceil(totalHours - totalDays * 24);
              // debugger;
              if (totalDays > 30.41667) {
                totalMonths = Math.floor(totalDays / 30.41667);
                totalDays = Math.ceil(totalDays - totalMonths * 30.41667);
                // debugger;
                if (totalMonths > 12) {
                  totalYears = Math.floor(totalMonths / 12);
                  totalMonths = Math.ceil(totalMonths - totalYears * 12);
                }
              }
            }
          }
        }
      }
      else totalMilliseconds = diff;

      // debugger;
      var years = totalYears;
      var months = totalMonths;
      var days = totalDays;
      var hours = totalHours;
      var minutes = totalMinutes;
      var seconds = totalSeconds;;
      var milliseconds = totalMilliseconds;

      years > 0 ? ago = years + " years " + months + " months " + days + " days " + hours + " hours " + minutes + " minutes " + seconds + " seconds " + milliseconds + " milliseconds ago."
        :
        months < 12 && months > 0 ? ago = months + " months " + days + " days " + hours + " hours " + minutes + " minutes " + seconds + " seconds " + milliseconds + " milliseconds ago."
          :
          days < 30 && days > 0 ? ago = days + " days " + hours + " hours " + minutes + " minutes " + seconds + " seconds " + milliseconds + " milliseconds ago."
            :
            hours < 24 && hours > 0 ? ago = hours + " hours " + minutes + " minutes " + seconds + " seconds " + milliseconds + " milliseconds ago."
              :
              minutes < 60 && minutes > 0 ? ago = minutes + " minutes " + seconds + " seconds " + milliseconds + " milliseconds ago."
                :
                seconds < 60 && seconds > 0 ? ago = seconds + " seconds " + milliseconds + " milliseconds ago."
                  :
                  milliseconds < 1000 && milliseconds > 0 ? ago = milliseconds + " milliseconds ago."
                    :
                    milliseconds > 1 ? ago = milliseconds + " milliseconds ago."
                      : ago = "couldn't determine time ago. :(";
      // debugger;
      return ago;
    }
  }
  // ------------------------------------------------------------------------
}
