<!--=================================================Begin of MessageBoard=============================================-->
<!--This section is situated just below the logo at the center of the heading section-->
<nav  *ngIf="message?.length > 0"
     class="flex-container" 
     style="top:1.5rem;
              left:0;
              position: fixed;
              display: inline-block;
              width: auto;
              height: auto;
              max-height: 7rem;
              min-height: 3rem;
              margin: 0;
              padding: 0;
              z-index: 9;
              opacity:1;
              will-change:transform;
              justify-content: center;
              background-color: transparent;">
  
    <!--Main-Message-Board -->
    <div
         [@rotateInSlideOutVanish]
         class="boxShadowInsetDeepSkyBlue wordwrap flex-button text-center"
         style="width: auto;
                min-width: 3rem;
                max-width: 80%;
                height: auto;
                max-height: 6rem;
                min-height: 1rem;
                margin: 0 10% 0 10%;
                padding: 0.5rem;
                z-index: 9;
                align-items: center;
                justify-content: center;
                background-color: transparent;"
         [ngClass]="{'errS':isMobilevar, 'errxS':!isMobilevar}"
         [ngStyle]="{'color': isSuccess ? 'forestgreen' : '#e80c4d'}">
      <span *ngIf="!isSuccess" class="glyphicon glyphicon-exclamation-sign" aria-hidden="true" style="color: #e80c4d"> </span>
      <span *ngIf="isSuccess" class="glyphicon glyphicon-exclamation-sign" aria-hidden="true" style="color:forestgreen"> </span>
      <span class="text-center" [innerHTML]="message | safe: 'html'"></span>
    </div>
</nav>
