
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { NaclPairClient } from '../../models/crypto/naclPairClient.model';
import { Salt } from '../../models/crypto/salt.model';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';

/*
 * ref:https:// www.techiediaries.com/angular-local-json-files/
 * import * as Enums from '../../assets/json/enums';
 */

// define this namespace somewhere
/*
 * export namespace ResponseTypeOld {
 * export const JSON = 'json' as const;
 * export const  ArrayBuffer = 'arraybuffer' as const;
 * export const  Blob = 'blob' as const;
 * export const  Text = 'text' as const;
 * export const  Image = 'image' as const;
 * export const  CSS = 'css' as const;
 * }
 */

/*
 * export namespace HeaderTypeOld
 * {
 * export const JSON = { 'Content-Type': 'application/json' };//;'application/json'; //
 * export const ArrayBuffer = { 'Content-Type': 'application/octet-stream' };
 * export const Blob = { 'Content-Type': 'blob' };
 * export const Text = { 'Content-Type': 'application/text' };
 * export const CSS = { 'Content-Type': 'text/css' };
 * export const Image = { 'Content-Type': 'multipart/form-data' };
 * }
 */

/*
 * export namespace HeaderType
 * {
 * export const Http = HttpHeaders;
 * export const kvHeader = { header: '' };
 * export const StrHeader = '';
 * export const arrHeader = [];
 * }
 */

// var params = new URLSearchParams()
/*
 * export namespace ParamsType
 * {
 * export const httpParams = HttpParams;
 * export const kvParams = { param: '' };
 * export const StrParam = '';
 * export const arrParam = [];
 * }
 */
/*
 * ------------------------------------------------------------------------------------
 * for form:
 * var params = new URLSearchParams()
 * params.set( 'firstName', 'Ali' )
 * let headers = new Headers( { 'Content-Type': 'application/x-www-form-urlencoded' } )
 */

/*
 * this.http.post( this.url, params.toString(), { headers: headers } )
 * ------------------------------------------------------------------------------------
 * ref:https:// docs.w3cub.com/angular~10/guide/http.html
 * ref: HTTP header options:
 * options: {
 * headers ?: HttpHeaders | { [ header: string]: string | string[] },
 * observe ?: 'body' | 'events' | 'response',
 * params ?: HttpParams | { [ param: string]: string | string[] },
 * reportProgress ?: boolean,
 * responseType ?: 'arraybuffer' | 'blob' | 'json' | 'text',
 * withCredentials ?: boolean,
 * }
 * ------------------------------------------------------------------------------------
 */

@Injectable({
  providedIn: 'root',
})
export class HttpService implements OnDestroy {
  // ResponseTypes:
  public  respJSON = [ 'application/json; charset=utf-8; text/plain;'] as const;
  public  respArrayBuffer = 'application/octet-stream' as const;
  public  respBlob = 'blob' as const;
  public  respText = 'application/text' as const;
  public  respImage = 'multipart/form-data' as const;
  public  respCSS = 'text/css' as const;

  // ContentTypes:
  public  contJSON = { 'Content-Type': [ 'application/json; charset=utf-8' ] }; // [ 'application/json; charset=utf-8; text/plain' ]
  public  contArrayBuffer = { 'Content-Type': 'application/octet-stream'}; // ;'application/x-www-form-urlencoded'
  public  contBlob = { 'Content-Type': 'blob'};
  public  contText = { 'Content-Type': 'text/plain; application/text'};
  public  contCSS = { 'Content-Type': 'text/css'};
  public  contImage = { 'Content-Type': 'multipart/form-data'};

  // HeaderType: //may be redundent
  public  hdrJSON = { 'Content-Type': [ 'application/json; charset=utf-8' ]}; // ;'application/json'; //
  public  hdrArrayBuffer = { 'Content-Type': 'application/octet-stream'};
  public  hdrBlob = { 'Content-Type': 'blob'};
  public  hdrText = { 'Content-Type': 'application/text'};
  public  hdrCSS = { 'Content-Type': 'text/css'};
  public  hdrImage = { 'Content-Type': 'multipart/form-data'};

  // HeadersTypes:
  public  hdrHttpHeaders = HttpHeaders;
  public  hdrKvHeaders = { header: ''};
  public  hdrStrHeaders = '';
  public  hdrArrHeaders = [];

  // ParamsTypes:
  public  httpParams = HttpParams;
  public  kvParams = { param: ''};
  public  strParams = '';
  public  arrParams = [];
  // ----------------------------------------
  public  boxNonce: BoxNonceEntity = new BoxNonceEntity();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public  environment = '';
  public  enums: any;
  public  globalVars = [];
  public  headers = new HttpHeaders();
  public  isDevelopmentEnvironment = true;
  public  isMobilevar = false;
  public  loginSuccess: LoginSuccess = new LoginSuccess();
  public  message = '';
  public  naclPairClient: NaclPairClient = new NaclPairClient();
  public  params = new HttpParams();
  public  reqOpts = {
    headers: { 'Content-Type': 'application/octet-stream'},
    transformRequest: [],
    responseType: 'arraybuffer',
  };
  public  returnUrl = '';
  public  salt : Salt = new Salt();
  public  signedInUserId = 0;
  public  sitUser: SitUser = new SitUser();
  public tempElem : any;
  

  constructor(
    public httpClient: HttpClient,
    public router: Router,
  ) {
    /*
     * this.enums = ( Enums as any ).default; //ref:https:// www.techiediaries.com/angular-local-json-files/
     * debugger;
     */
    this.getEnumsFromServer();
    this.enums = EmitterSubjectService.getEnums();

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.enums)) {
      this.salt.publicKey = this.enums.talaChabi.chabi;
      this.salt.secretKey = this.enums.talaChabi.chabiGopon;
      this.salt.date = DateStringServiceStatic.getTicks( new Date() ).toString();
      EmitterSubjectService.setSalty( this.salt );
      // debugger;
      // Update the application's default-keys:
      EmitterSubjectService.emitResetSaltShaker(this.salt);
    }
    // debugger
    // this.getAccountMessages();
    // this.getKeys( 'pair' );


    EmitterSubjectService.loginSuccessEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
    {
      this.loginSuccess = result;
    } );

    EmitterSubjectService.returnUrlEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
    {
        this.returnUrl = result;
        this.emitterDestroyed$.next( true );
        this.emitterDestroyed$.complete();
        this.emitterDestroyed$.unsubscribe();
    } );


    this.isMobilevar = EmitterSubjectService.getIsMobile();


    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    EmitterSubjectService.loginSuccessEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
    {
        this.loginSuccess = result; // JSON.parse(JSON.stringify(result));
        this.emitterDestroyed$.next( true );
        this.emitterDestroyed$.complete();
        this.emitterDestroyed$.unsubscribe();
    } );
  /*
    * ---------------------------------------------------
    * Note: only SlakezSaltService will emit this value,
    *       and there are no other listerens for this.
    *       It's objective is to get the Nacl-Key-Pairs
    *       and set it to emitterService so that
    *       the respective caller can retrieve it from
    *       emitterService.
    * ---------------------------------------------------
    */
    EmitterSubjectService.getKeysEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( ktype ) =>
    {
      // SlakezSaltServiceStatic.getSaltyPair( ktype ); // JSON.parse(JSON.stringify(result));
        EmitterSubjectService.getSaltyPair();
        this.emitterDestroyed$.next( true );
        this.emitterDestroyed$.complete();
        this.emitterDestroyed$.unsubscribe();
    } );
    // this.globalVars = this.getCssRootVariables();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
  }
  /*
   * ---------------------------------------------------------------
   * BEGIN of ResponseType getters:
   */
  public  getResponseTypeJson(): any {
    return this.respJSON;
  }
  public  getResponseTypeArrayBuffer(): any {
    return this.respArrayBuffer;
  }
  public  getResponseTypeBlob(): any {
    return this.respBlob;
  }
  public  getResponseTypeText(): any {
    return this.respText;
  }
  public  getResponseTypeCSS(): any {
    return this.respCSS;
  }
  public  getResponseTypeImage(): any {
    return this.respImage;
  }
  /*
   * END of ResponseType getters:
   * ---------------------------------------------------------------
   * BEGIN of ContentType getters:
   */
  public  getContentTypeJson(): any {
    return this.contJSON;
  }
  public  getContentTypeArrayBuffer(): any {
    return this.contArrayBuffer;
  }
  public  getContentTypeBlob(): any {
    return this.contBlob;
  }
  public  getContentTypeText(): any {
    return this.contText;
  }
  public  getContentTypeCSS(): any {
    return this.contCSS;
  }
  public  getContentTypeImage(): any {
    return this.contImage;
  }
  /*
   * END of ContentType getters:
   * ---------------------------------------------------------------
   * BEGIN of HeaderType getters:
   */
  public  getHeadersTypeHttp(): any {
    return this.hdrHttpHeaders;
  }
  public  getHeadersTypeKvHeader(): any {
    return this.hdrKvHeaders;
  }
  public  getHeadersTypeString(): any {
    return this.hdrStrHeaders;
  }
  public  getHeadersTypeArray(): any {
    return this.hdrArrHeaders;
  }
  /*
   * END of ResponseType getters:
   * ---------------------------------------------------------------
   * BEGIN of ParamsType getters:
   */
  public  getParamsTypeHttp(): any {
    return this.httpParams;
  }
  public  getParamsTypeKvHeader(): any {
    return this.kvParams;
  }
  public  getParamsTypeString(): any {
    return this.strParams;
  }
  public  getParamsTypeArray(): any {
    return this.arrParams;
  }
  /*
   * END of ResponseType getters:
   * ---------------------------------------------------------------
   */
   setSaltShaker(salt: Salt): any {
    this.naclPairClient.publicKey = salt.publicKey; // naclUtil.decodeBase64(this.salt.pk);
     this.naclPairClient.secretKey = salt.secretKey; // naclUtil.decodeBase64(this.salt.sk);
    const localSaltyPair = new NaclPairClient();

    localSaltyPair.publicKey = this.naclPairClient.publicKey;
    localSaltyPair.secretKey = this.naclPairClient.secretKey;
    localSaltyPair.id = this.signedInUserId;
    localSaltyPair.modelName = 'saltyPair'; // key
    localSaltyPair.date = DateStringServiceStatic.getTicks(new Date()).toString();

    const message = 'the size of SaltyPair data is: ' + localSaltyPair.toString().length;

    EmitterSubjectService.setSaltyPair(localSaltyPair); // set it on emitterService
    // this.localStorageService.setSaltyPair(localSaltyPair); // save to localStorage

    return localSaltyPair;
  }
  // ---------------------------------------------------------------
  public  setHeaderType(hType: string) {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hType)) {
      switch (hType.toLowerCase()) {
        case 'json':
          this.reqOpts.headers = this.getContentTypeJson();
          this.reqOpts.responseType = this.getResponseTypeJson();
          break;
        case 'json2text':
          this.reqOpts.headers = this.getContentTypeJson();
          this.reqOpts.responseType = this.getResponseTypeText(); // this.getResponseTypeJson();         
          break;
        case 'blob':
          this.reqOpts.headers = this.getContentTypeBlob();
          this.reqOpts.responseType = this.getResponseTypeBlob();
          break;
        case 'text':
          this.reqOpts.headers = this.getContentTypeText();
          this.reqOpts.responseType = this.getContentTypeText();
          break;
        case 'arraybuffer':
          this.reqOpts.headers = this.getContentTypeArrayBuffer();
          this.reqOpts.responseType = this.getResponseTypeArrayBuffer();
          break;
        case 'image':
          this.reqOpts.headers = this.getContentTypeImage();
          this.reqOpts.responseType = this.getResponseTypeImage();
          break;
        case 'css':
          this.reqOpts.headers = this.getContentTypeCSS();
          this.reqOpts.responseType = this.getResponseTypeCSS();
          break;
        default:
          this.reqOpts.headers = this.getContentTypeJson();
          this.reqOpts.responseType = this.getResponseTypeJson();
      }
    }
  }
  // ---------------------------------------------------------------
   getContentType(cType: string): any {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cType)) {
      switch (cType.toLowerCase()) {
        case 'json':
          return this.getContentTypeJson();
        case 'blob':
          return this.getContentTypeBlob();
        case 'text':
          return this.getContentTypeText();
        case 'arraybuffer':
          return this.getContentTypeArrayBuffer();
        case 'image':
          return this.getContentTypeImage();
        case 'css':
          return this.getContentTypeCSS();
        default:
          return this.getContentTypeJson();
      }
    }
  }
  // ---------------------------------------------------------------
   getResponseType(cType: string): any {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cType)) {
      switch (cType.toLowerCase()) {
        case 'json':
          return this.getResponseTypeJson();
        case 'blob':
          return this.getResponseTypeBlob();
        case 'text':
          return this.getResponseTypeText();
        case 'arraybuffer':
          return this.getResponseTypeArrayBuffer();
        case 'image':
          return this.getResponseTypeImage();
        case 'css':
          return this.getResponseTypeCSS();
        default:
          return this.getResponseTypeJson();
      }
    }
  }
  // ---------------------------------------------------------------
   getParamsType(cType: string): any {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cType)) {
      switch (cType.toLowerCase()) {
        case 'http':
          return this.getParamsTypeHttp();
        case 'kv':
          return this.getParamsTypeKvHeader();
        case 'str':
          return this.getParamsTypeString();
        case 'array':
          return this.getParamsTypeArray();
        default:
          return this.getParamsTypeHttp();
      }
    }
  }
  // ------------------------------------------------------------------------------------------------
   createHeaders(cType: string, rType: string, cryptUserKey: string, parms: []): any {
    const contentType = this.getContentType(cType);
    const resType = this.getResponseType(rType);
    const httpOptions = {
      headers: new HttpHeaders({
        contentType,
        responseType: resType,
        observe: 'response',
        reportProgress: 'false',
        withCredentials: 'false',
        params: parms,
        Authorization: cryptUserKey,
      }),
    };

    return httpOptions;
  }
  // ------------------------------------------------------------------------------------------------
  getPromise (url : any, hType : any): Promise<any> {
    this.setHeaderType(hType);
    const value = this.httpClient
      .get<any>( url, { headers: this.reqOpts.headers})
      .toPromise();

    return value;
  }
  /*
   * ------------------------------------------------------------------------------------------------
   * Note: This is not used, but do not delete it
   * ------------------------------------------------------------------------------------------------
   */
   normalizeApiUrl(url: string): string {
    const oldUrl = url;

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)) {
      // alert ('prefixing: slakez to api');
      if (url.toLowerCase().indexOf('slakez') === -1) {
        if (url.toLowerCase().indexOf('/api/') !== -1) {
          url = '/slakez' + url;
        } else if (url.toLowerCase().indexOf('api/') !== -1) {
          url = '/slakez/' + url;
        } else if (url.toLowerCase().indexOf('/api') !== -1) {
          url = '/slakez' + url;
        } else if (url.toLowerCase().indexOf('api') === -1) {
          url = '/slakez/api/' + url;
        }
      }
    }
    alert('original-url: ' + oldUrl + '\n modified url:' + url);
    // debugger;
    return url;
  }
  // ------------------------------------------------------------------------------------------------
   normalizeApi(url: string, isAbsolute: boolean): string {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url) && url.indexOf('api') !== -1) {
      if (isAbsolute) {
        if (url.indexOf('/api') !== -1) {
          url = url.replace('/api', 'api');
        }
      } else {
        if (url[0] !== '/') {
          url = '/' + url;
        }
      }
    }
    return url;
  }
  // ------------------------------------------------------------------------------------------------
  prefixUrlWithApi (url : any) {
    var out : string = '';
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)) {
      if (url.indexOf('api') !== -1) {
        out = url;
      }
      else {
        out = "/api/" + url;
      }
      out = out.replace("//", "/");
      // debugger;
    }
    return out;
  }
  // ------------------------------------------------------------------------------------------------
   stripSlakez(url: string): string {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)) {
      url = url.replace(/slakez\//, '');
    }
    return url;
  }

  // ------------------------------------------------------------------------------------------------
  isJsonFile (url : string) : boolean {
    var isJson = false;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)) {
      if (url.indexOf('.json') !== -1) {
        isJson = true;
      }
    }
    return isJson;
   }
  // ------------------------------------------------------------------------------------------------
   trimFileExtensionFromUrl(url: string): string | any {
     if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)) {
       let lastIndex = url.lastIndexOf('.');
       let ext = url.slice(lastIndex, url.length - 1);
       let urlWithoutFileExt = url.slice(0, lastIndex);
       return urlWithoutFileExt;
     }
     else return null;
   }
  // ------------------------------------------------------------------------------------------------
   https2http(url: string): string {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)) {
      url = url.replace('https:// ', 'http:// ');
      // url = this.stripSlakez(url);
    }
    return url;
  }
  // ------------------------------------------------------------------------------------------------
   http2https(url: string): string {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)) {
      url = url.replace('http:// ', 'https:// ');
      // url = this.stripSlakez(url);
    }
    return url;
  }
  // ------------------------------------------------------------------------------------------------
  get<T> (url : any): Observable<any> {
    /*
     * a special case:!!!
     * ------------------
     */
    if (url.toLowerCase().indexOf('memberscroll') !== -1) {
      if (url.toLowerCase().indexOf('api') === -1) {
        url.replace('memberscroll', '/api/memberscroll');
      }
    } else if (url.toLowerCase().indexOf('myphotos') !== -1) {
      if (url.toLowerCase().indexOf('api') === -1) {
        url.replace('myPhotos', '/api/myPhotos');
      }
    }

    // url = this.normalizeApiUrl( url );

    const conType = this.getContentType('json'); // current-version
    const resType = this.getResponseType('json');
    const options = {
      headers: new HttpHeaders({
        contentType: 'application/json; charset=utf-8;', // conType,
        responseType: [ 'application/json; charset=utf-8;' ], // resType,
        Accept: '*/*',
        observe: 'response', // {'event' | 'body'}
      }),
    };
    /*
     * debugger;
     * url = this.https2http( url );
     */

    // url = this.stripOut(url);
    url = this.http2https(url);
    // alert('get<T>==>' + url);
    if (!this.isJsonFile(url)) {
      url = this.prefixUrlWithApi(url);
    }
    // debugger;
     return this.httpClient.get<T>( url, options );

     //if ( window.navigator.onLine )
     //{
     //  return this.httpClient.get<T>( url, options );
     //}
    // var value = this.commonHttp.get<any>( url ) //previous-version

    // return null;
  }
  // ------------------------------------------------------------------------------------------------
  getObservable (url : any, hType : any): Observable<any> {
    this.setHeaderType(hType);
    if (!this.isJsonFile(url)) {
      url = this.prefixUrlWithApi(url);
    }
    // debugger;
    const value = this.httpClient.get<any>(url, { headers: this.reqOpts.headers});
    // debugger;
    return value;
  }
  // ------------------------------------------------------------------------------------------------
  async getPromiseAsync<T> (url : string, hType? : string) : Promise<any> {
    // debugger;
    const options = {
      headers: new HttpHeaders({
        contentType: 'application/json; charset=utf-8;', // conType,
        responseType: [ 'application/json; charset=utf-8;' ], // resType,
        Accept: 'application/json; charset=utf-8;', // https://denisjakus.com/dotnet-core-415-unsupported-media-type-error-easy-fix/
        observe: [ 'response', 'event', 'body' ], // 'response', //
      }),
    };
    url = this.http2https(url);
    if (!this.isJsonFile(url)) {
      url = this.prefixUrlWithApi(url);
    }
    let response = await this.httpClient.get<T>(url, options)?.toPromise();
    // debugger;
    return response;
  }
  // ================================================================================================
  // ------------------------------------------------------------------------------------------------
  // const reqHeader = new HttpHeaders( { 'Content-Type': 'application/json', 'No-Auth': 'True' } );
  // ------------------------------------------------------------------------------------------------
  post<T>(url: string, data: any, hType: string): Observable<any> {
    /*
     * var value = this.commonHttp.post<T>(url, data, { 'headers': this.reqOpts.headers }); //previous-version
     */
    // debugger;
    // const conType = this.getContentType(hType); // current-version
    // const resType = this.getResponseType(hType);
    const options = {
      headers: new HttpHeaders({
        contentType: 'application/json; charset=utf-8; application/octet-stream;', // conType,
        responseType: [ 'text/plain; charset=utf-8;  arraybuffer; application/json; ' ], // resType,
        Accept: 'application/json; charset=utf-8; arraybuffer; text/plain;', // https://denisjakus.com/dotnet-core-415-unsupported-media-type-error-easy-fix/
        observe: [ 'response', 'body', 'event' ], // 'response', //
      }),
    };

    /*
     * url = this.normalizeApiUrl( url )
     * url = this.normalizeApi( url, false );
     * url = this.https2http( url );
     */
    // url = this.stripOut(url);
    url = this.http2https(url);
    // alert( 'post<T>==>' + url );
    if (!this.isJsonFile(url)) {
      url = this.prefixUrlWithApi(url);
    }
    // debugger;

    let subscriber = this.httpClient.post<T>(url, data, options);
    return subscriber;

     //if ( window.navigator.onLine )
     //{
     //  return this.httpClient.post<T>( url, data, options );
     //}
     //else
     //{
     //  this.startSpinner();
     //  let timer = setTimeout( () =>
     //  {
     //    EmitterSubjectService.emitStopSpinner( true );
     //    clearTimeout( timer );
     //  }, 5000 );
     //  return null;
     // }
     // debugger;   
  }
  // ------------------------------------------------------------------------------------------------
  //  Does not work! Difficult to implement on the calling method that actually works. Date: 20230115
  // ------------------------------------------------------------------------------------------------
  async postAsync<T> (url : string, data : any, hType : string) : Promise<any> {
    const options = {
      headers: new HttpHeaders({
        contentType: 'application/json; charset=utf-8; application/octet-stream;', // conType,
        responseType: [ 'application/json; charset=utf-8; arraybuffer; text/plain;' ], // resType,
        Accept: 'application/json; charset=utf-8; arraybuffer; text/plain;', // https://denisjakus.com/dotnet-core-415-unsupported-media-type-error-easy-fix/
        observe: [ 'response', 'body', 'event' ], // 'response', //
      }),
    };
    let subscriber : any;
    url = this.http2https(url);
    if (!this.isJsonFile(url)) {
      url = this.prefixUrlWithApi(url);
    }
    // debugger;
    
    try {
      await this.httpClient.post<T>(url, data, options).subscribe(data => {
        return new Promise((resolve, reject) => {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
            resolve(data);
          }
          else {
            reject(null);
          }
        }).catch((error) => {
            this.message = StringServiceStatic.stringBuilder("httpService.postAsync promise's error-message: " + error.message);
            console.log(this.message);
            return null;
          });
      })
    }
    catch (error) {
      this.message = StringServiceStatic.stringBuilder("httpService.postAsync try error-message: " + error.message);
      console.log(this.message);
      return null;
		}    
  }
  // ------------------------------------------------------------------------------------------------
  async postAsync1<T> (url : string, data : any, hType : string) : Promise<any> {
    /*
     * var value = this.commonHttp.post<T>(url, data, { 'headers': this.reqOpts.headers }); //previous-version
     */
    // debugger;
    // const conType = this.getContentType(hType); // current-version
    // const resType = this.getResponseType(hType);
    const options = {
      headers: new HttpHeaders({
        contentType: 'application/json; charset=utf-8; application/octet-stream;', // conType,
        responseType: [ 'application/json; charset=utf-8; arraybuffer; text/plain;' ], // resType,
        Accept: 'application/json; charset=utf-8; arraybuffer; text/plain;', // https://denisjakus.com/dotnet-core-415-unsupported-media-type-error-easy-fix/
        observe: [ 'response', 'body', 'event' ], // 'response', //
        CacheControl: 'must-revalidate',
      }),
    };

    /*
     * url = this.normalizeApiUrl( url )
     * url = this.normalizeApi( url, false );
     * url = this.https2http( url );
     */
    // url = this.stripOut(url);
    url = this.http2https(url);
    // alert( 'post<T>==>' + url );
    if (!this.isJsonFile(url)) {
      url = this.prefixUrlWithApi(url);
    }
    // debugger;

    let subscriber = await this.httpClient.post<T>(url, data, options);
    return subscriber.toPromise().catch((error) => {
      this.message = StringServiceStatic.stringBuilder("httpService.postAsync error-message: " + error.message);
      console.log(this.message);
    });

    //if ( window.navigator.onLine )
    //{
    //  return this.httpClient.post<T>( url, data, options );
    //}
    //else
    //{
    //  this.startSpinner();
    //  let timer = setTimeout( () =>
    //  {
    //    EmitterSubjectService.emitStopSpinner( true );
    //    clearTimeout( timer );
    //  }, 5000 );
    //  return null;
    // }
    // debugger;   
  }
  // ------------------------------------------------------------------------------------------------
  // Note:  async operation is not compatible with Observers, because since observers are used for
  //        streaming data, the operation cannot be halted while sending data, hence, going against
  //        the principal of async operation.
  // ------------------------------------------------------------------------------------------------
  postObservable<T> (url : string, data : any, hType : string) : Observable<any> {
    // debugger;
    this.setHeaderType(hType);
    if (!this.isJsonFile(url)) {
      url = this.prefixUrlWithApi(url);
    }
    // debugger;
    const subscriber = this.httpClient.post<T>(url, data, { headers: this.reqOpts.headers });
    // debugger;
    return subscriber;
  }
  // ------------------------------------------------------------------------------------------------
  // Note:  This method uses a HttpRequest as follows:
  // const req = new HttpRequest('POST', `/api/FileUpload/ImageUpload`, formData, {
  //   reportProgress: true,
  //   responseType: 'json'
  // });
  // ------------------------------------------------------------------------------------------------
  postReqObservable<T> (req : any) : Observable<any> {
    // debugger;
    const subscriber = this.httpClient.request(req);
    // debugger;
    return subscriber;
  }
  // ================================================================================================
  // ------------------------------------------------------------------------------------------------
  async postAsync2<T> (url : string, data : any, hType : string) : Promise<any> {
    // debugger;
    const options = {
      headers: new HttpHeaders({
        contentType: 'application/json; charset=utf-8;', // conType,
        responseType: [ 'application/json; charset=utf-8;' ], // resType,
        Accept: 'application/json; charset=utf-8;', // https://denisjakus.com/dotnet-core-415-unsupported-media-type-error-easy-fix/
        observe: [ 'response', 'event', 'body' ], // 'response', //
      }),
    };
    url = this.http2https(url);
    if (!this.isJsonFile(url)) {
      url = this.prefixUrlWithApi(url);
    }
    let promise = await this.httpClient.post<T>(url, data, options).toPromise();
    // debugger;
    return promise;

    //.catch((e) => {
    //  console.log('error occured while returning promise from observavle-data in postAsync(). Error-message: ' + e);
    // });

    // debugger;
    // let returnResult : any;
    // response.toPromise().finally();
    // return new Promise<any>((resolve, reject) => {
    //  response?.subscribe(result => {
    //    returnResult = result;
    //    if (returnResult) {
    //      debugger;
    //      resolve(returnResult);
    //    }
    //    else {
    //      debugger;
    //      reject('postAsyn() returned a null result');
    //    }
    //  }).unsubscribe()
    // }).catch((e) => {
    //  console.log('error occured while returning promise from observavle-data in postAsync(). Error-message: ' + e);
    // });
  }
  // ------------------------------------------------------------------------------------------------
  postPromise<T> (url : string, data : any, hType : string) : Promise<any> {
    // debugger;
    this.setHeaderType(hType);
    if (!this.isJsonFile(url)) {
      url = this.prefixUrlWithApi(url);
    }
    const value = this.httpClient
      .post<T>(url, data, { headers: this.reqOpts.headers })
      .toPromise();
    // .catch ((e) => {
    //    console.log('error occured while returning promise from observavle-data in postPromise(). Error-message: ' + e);
    //  });

    return value;
  }
  // ------------------------------------------------------------------------------------------------
   async postPromiseAsync<T>(url: string, data: any, hType: string): Promise<any> {
     debugger;
     if (!this.isJsonFile(url)) {
       url = this.prefixUrlWithApi(url);
     }
     this.setHeaderType(hType);
     let message = '';
     return new Promise<any>((resolve, reject) => {
       const value = this.httpClient
         .post<T>(url, data, { headers: this.reqOpts.headers })
         .subscribe(result => {
           resolve(result);
           message = 'postPromiseAsync->subscribe(result) failed: ' + result;
         });

       reject(message);
     });
  }
  
  // -------------------------------------------------------------------------
  sendFile ( url : string, formData : FormData ) : Observable<HttpEvent<any>>
  {
    if (!this.isJsonFile(url)) {
      url = this.prefixUrlWithApi(url);
    }
    const req = new HttpRequest( 'POST', `${ url }`, formData, {
      reportProgress: true,
      responseType: 'json',
    } );

    return this.httpClient.request( req );
  }
  // -------------------------------------------------------------------------
  
      
  // ------------------------------------------------------------------------------------------------
  // startSpinner() : void
  // {
  // // let isBusy = new IsBusy();
  // // isBusy.message = '<span class=\'grnDrkRedxSB\'>Offline...</span>';
  // // isBusy.isSpinner = isBusy.isBusy = true; // == isOpen
  // // isBusy.callerName = 'HttpService';
  // // EmitterSubjectService.emitShowSpinner(isBusy);
  //}
   // ------------------------------------------------------------------------------------------------
  // getAuthorization(): any {
  //  // debugger
  //  let url = '/api/Home/GetAuthorizationJson';

  //  url = '/api/Home/GetAuthorizationJson';
  //  /*
  //   * url = 'http:// slakez.com/slakez/api/home/getAauthorizationJson'
  //   * url = 'http:// slakez.com/slakez/out/api/home/getAauthorizationJson';
  //   * url = 'http:// slakez.com/slakez/out/api/SlakezSalt/GetSaltyPair';
  //   * url = 'http:// slakez.com/slakez/api/SlakezSalt/GetSaltyPair'; // ERR_CONNECTION_RESET
  //   * url = 'http:// slakez.com/api/SlakezSalt/GetSaltyPair' // 404 (Not Found)
  //   * url = 'http:// slakez.com/wwwroot/api/SlakezSalt/GetSaltyPair';
  //   */

  //  // alert ( 'Sending-->/home/getAuthorizationJson' );

  //  // debugger;
  //  this.get(url).subscribe((data: any) => {
  //    /*
  //     * alert( 'getAuthorization()=>data: \n' + data );
  //     * debugger;
  //     */
  //    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
  //      /*
  //       * this.salt = data as Salt //Note: server-send variables are all lower-case
  //       * debugger;
  //       */
  //      return data;
  //    }
  //  });
  //  return true;
  // }
  // -------------------------------------------------------------------------
  // getAccountMessages(): any {
  //  // debugger
  //  let url = '/api/home/getAccountMessagesJson';

  //  url = '/api/Home/GetAccountMessagesJson';
  //  /*
  //   * url = 'http:// slakez.com/slakez/api/home/getAccountMessagesJson'
  //   * url = 'http:// slakez.com/slakez/out/api/home/getAccountMessagesJson';
  //   * url = 'http:// slakez.com/slakez/out/api/SlakezSalt/GetSaltyPair';
  //   * url = 'http:// slakez.com/slakez/api/SlakezSalt/GetSaltyPair'; // ERR_CONNECTION_RESET
  //   * url = 'http:// slakez.com/api/SlakezSalt/GetSaltyPair' // 404 (Not Found)
  //   * url = 'http:// slakez.com/wwwroot/api/SlakezSalt/GetSaltyPair';
  //   */

  //  // alert ( 'Sending-->/home/getAccountMessagesJson' );

  //  // debugger;
  //  this.get(url).subscribe((data: any) => {
  //    /*
  //     * alert( '(getAccountMessagesJson())=>data: \n' + data );
  //     * debugger;
  //     */
  //    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
  //      /*
  //       * this.salt = data as Salt //Note: server-send variables are all lower-case
  //       * debugger;
  //       */
  //      return data;
  //    }
  //  });
  //  // return this.getAuthorization();
  // }
  /*
   * ---------------------------------------------------------------
   * Tested, works! 2020/12/26
   * ---------------------------------------------------------------
   */
  public getEnumsFromServer () : any
  {
    // debugger;
    this.get( 'assets/json/enums.json' ).subscribe(
      ( result ) =>
      {
        // debugger;
        if ( result )
        {
          /*
            * debugger;
            * this.enumsCallCounter = 0;
            */
          this.enums = result;
          EmitterSubjectService.setEnums( this.enums );
          let salt = new Salt();
          salt.publicKey = this.enums.talaChabi.chabi;
          salt.secretKey = this.enums.talaChabi.chabiGopon;
          EmitterSubjectService.emitResetSaltShaker( salt );
          EmitterSubjectService.setEnums( this.enums );
          return this.enums;
          // var unsalted = ( this.slakezSaltService as SlakezSaltService ).boxUnsalt(result);
          // //debugger;
          // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
          // var tEnums = JSON.parse(unsalted);
          // tEnums = JSON.stringify(tEnums, null, '\n');
          // ref:https:// www.digitalocean.com/community/tutorials/js-json-parse-stringify
          // //debugger;

          // tEnums = JSON.parse(tEnums);
          // //debugger;
          // EmitterSubjectService.setEnums(tEnums);
          // this.enumsCallCounter = 0;
          // return this.enums = tEnums;
          // }
        }
      },
      ( error ) =>
      {
        // debugger;
        EmitterSubjectService.emitMyErrorLog( 'Error occured in GetEnums();\n Error-message: ' + error.message );
      },
    );
  }

  // ---------------------------------------------------------------
  getSandBoxFromServer () : Observable<any> {
    // debugger;
    let url = 'Home/GetSandBoxJson';
    //this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(photoBn);

    //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
    //}
    return this.getObservable(url, 'json');
  }
  // -------------------------------------------------------------------------
   getSaltyPair(ktype: string): any {
    // debugger;
     let value : any;
    // this.enums = ( Enums as any ).default; //ref:https:// www.techiediaries.com/angular-local-json-files/

    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.enums)) {
      this.enums = EmitterSubjectService.getEnums();
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(ktype)) {
      this.salt.ktype = ktype;
      let url = 'SlakezSalt/GetSaltyPair';

      url = 'api/SlakezSalt/GetSaltyPair';
      /*
       * url = this.enums.environmentVariables.currentDirectory + '/Controllers/SlakezSalt/GetKeys';
       * url = './api/SalezSalt/' + this.enums.environmentVariables.currentDirectory + '/GetKeys';
       * url = 'http:// slakez.com/slakez/out/api/SlakezSalt/GetSaltyPair';
       * url = 'http:// slakez.com/slakez/api/SlakezSalt/GetSaltyPair'; // ERR_CONNECTION_RESET
       * url = 'http:// slakez.com/api/SlakezSalt/GetSaltyPair' // 404 (Not Found)
       * url = 'http:// slakez.com/wwwroot/api/SlakezSalt/GetSaltyPair';
       */

      // debugger;
      this.post(url, this.salt, 'json').subscribe((data: any) => {
        // debugger;

        // alert( '(getSaltyPair())===>data: \n' + data );
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
          this.salt = data as Salt; // Note: server-send variables are all lower-case
          // alert ('GetSaltyPair=>data:' + this.salt.ktype + '; sk:' + this.salt.sk + '; pk:' + this.salt.pk);
          if (this.salt.ktype.indexOf('pair') !== -1) {
            value = this.setSaltShaker(this.salt);
            // debugger;
          } else if (this.salt.ktype.indexOf('sign') !== -1) {
            /* TODO:capture sign-saltys*/ value = this.setSaltShaker(this.salt);
          } else if (this.salt.ktype.indexOf('shared') !== -1) {
            /* TODO:capture shared-saltys*/ value = this.setSaltShaker(this.salt);
          }
          return value;
        }
      });
    } else {
      return null as any;
    }
  }
  /*
   * ---------------------------------------------------------------
   * ================================================================================================
   * ------------------------------------------------------------------------------------------------
   * ref:https:// www.htmlgoodies.com/html5/tutorials/determine-an-images-type-using-the-javascript-filereader.html
   */
  fileChunk (File : any): any {
    if (!File.prototype.slice) {
      const newSlice = File.prototype.mozSlice || File.prototype.webkitSlice;

      if (newSlice) {
        File.prototype.slice = (() => (startingByte : any, length : any) => newSlice.call(this, startingByte, length + startingByte))();
      } else {
        throw Error('File.slice() not supported.');
      }
    }
  }
  /*
   * ---------------------------------------------------------------
   * ref:https:// github.com/mdn/js-examples/blob/master/promises-test/index.html
   */
  httpPromise (url : any, method: string): any {
    /*
     * Note:method={GET, POST, PUT, ...}
     * Create new promise with the Promise() constructor;
     * This has as its argument a function
     * with two parameters, resolve and reject
     */
    return new Promise((resolve, reject) => {
      // Standard XHR to load an image
      const request = new XMLHttpRequest();

      request.open(method, url);
      request.responseType = 'blob';
      // When the request loads, check whether it was successful
      request.onload = () => {
        if (request.status === 200) {
          // If successful, resolve the promise by passing back the request response
          resolve(request.response);
        } else {
          // If it fails, reject the promise with a error message
          reject(Error('Image didn\'t load successfully; error code:' + request.statusText));
        }
      };
      request.onerror = () => {
        /*
         * Also deal with the case when the entire request fails to begin with
         * This is probably a network error, so reject the promise with an appropriate message
         */
        reject(Error('There was a network error.'));
      };
      /*
       * Send the request
       * this.isBusy.isBusy = true;
       * this.isBusy.message = 'fetching from: ' + url;
       * EmitterSubjectService.emitIsBusy(this.isBusy);
       */

      const value = request.send();

      /*
       * this.isBusy.isBusy = false;
       * this.isBusy.message = '';
       * EmitterSubjectService.emitIsBusy(this.isBusy);
       */

      return value;
    });
  }
  /*
   * ---------------------------------------------------------------
   * This method will read all the vairables in the :root element of a css stylesheet
   * ref:https:// stackoverflow.com/questions/48760274/get-all-css-root-variables-in-array-using-javascript-and-change-the-values
   * ref:https:// github.com/Microsoft/TypeScript/issues/15319
   * ---------------------------------------------------------------
   */
   getCssVariables(): void {
    /*
     * const sheet = <CSSStyleSheet document.styleSheets[0];
     * const declaration = <CSSStyleRule>sheet.cssRules[0];
     */
    const sheet = document.styleSheets[0];
    const declaration = sheet.cssRules[0];
    const allVar = declaration.cssText.split(';');

    const result: any = { };

    allVar.map((e) => {
      const a = e.split(':');

      if (a[0] !== '') {
        result[a[0].trim()] = a[1].trim();
      }
    });

    console.log(result);
    const keys = Object.keys(result);

    console.log(keys);

    /*
     * Usage examples:
     * we change the first variable
     * document.documentElement.style.setProperty(keys[0], 'green');
     */

    /*
     * we change the variable  --secondary-color
     * document.documentElement.style.setProperty(keys[keys.indexOf('--secondary-color')], 'red');
     */
  }
  // ---------------------------------------------------------------
   getCssRootVariables(url = './styles.css'): any {
    // an associative array that will hold our values
    const cssVars: any = { };

    /*
     * var request = new XMLHttpRequest();
     * request.open('GET', url, true);
     */

    /*
     * request.onload = function () {
     * if (request.status >= 200 && request.status < 400) {
     * Get all CSS Variables in the document
     * var matches = request.responseText.match(/(--)\w.+;/gi);
     */

    return this.get(url).subscribe(
      (result: any) => {
        // Get all CSS Variables in the document
        const matches = result.match(/(--)\w.+;/gi);

        /*
         * var matches = styles.match(/(--)\w.+;/gi);
         * Get all CSS Variables in the document
         */

        matches.map((e : any) => {
          const property = e;
          // split the Variable name from its value
          const splitprop = property.split(':');

          // turn the value into a string
          const value = splitprop[1].toString();

          cssVars[splitprop[0]] = value.slice(0, -1); // remove ;
        });

        return cssVars;

        /*
         * Usage examples:
         * console.log(cssVars);
         * > Object {--primaryColor: 'aliceblue', --secondaryColor: 'blue', --errorColor: '#cc2511'}
         */

        /*
         * console.log(Object.keys(cssVars));
         * > ['--primaryColor', '--secondaryColor', '--errorColor' ]
         */

        // this.setTheme(cssVars)
      },
      (onerror = () => {
        console.log('There was a connection error');
      })
    );
  }
  // ---------------------------------------------------------------
  setTheme (theme : any): void {
    const keys = Object.keys(theme);

    keys.map((e) => {
      const prop = e;
      const color = theme[e];

      console.log(prop, color);
      // --primaryColor aliceblue etc...;
    });
  }
  // ---------------------------------------------------------------
   getGlobalVars(): any {
    return this.globalVars;
  }
  /*
   * ---------------------------------------------------------------
   * resetPasswordPreAuthentication(returnUrl: string) {
   * debugger;
   * if (returnUrl) {
   *   this.post('/api/Account/ResetPasswordPreAuthentication', JSON.stringify(returnUrl), 'json').subscribe(result => {
   *     debugger;
   *     if (!(StringServiceStatic as StringService).isNullOrEmpty(result)) {
   *       var loginResult = JSON.parse((this.slakezSaltService as SlakezSaltService).boxUnsalt(result as BoxNonce)) as LoginSuccess;
   *       debugger;
   *       this.loginSuccess = loginResult as LoginSuccess;
   *       this.router.navigate(['/resetPassword']);
   *     }
   *     else {
   *       if (loginResult) {
   *         this.emitterService
   *           .emitMyErrorLog({ feedbackMessage: 'Error occured in password recovery process;\n Error-mag: ' + loginResult.message });
   *       }
   *       else {
   *         this.emitterService
   *           .emitMyErrorLog({ feedbackMessage: 'Error occured in password recovery process;\n Error-mag: ' });
   *       }
   *     }
   *   },
   *     error => {
   *       //alert ('Error occured in GetArticleContent(' + idArr[i] + ');\n Error-mag: ' + error);
   *       this.emitterService
   *         .emitMyErrorLog({ feedbackMessage: 'Error occured in password recovery process;\n Error-mag: ' + error.message });
   *     });
   * }
   * }
   * ---------------------------------------------------------------
   * ---------------------------------------------------------------
   */
   navigateTo(path: string): void {
    if (path && path.length > 0) {
      // this.router.navigate([path]); //TODO: send to redirectionService.fire....
    }
  }
  /*
   * ---------------------------------------------------------------------------------
   * TODO: Test
   */
   whenScrollEnds(): boolean {
    const threshold = 100;

    this.tempElem = document.getElementById('whenScrollEndsId') as HTMLElement;
    // debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.tempElem) && this.tempElem[0] && this.tempElem[0].scrollHeight - (this.tempElem[0].scrollTop + this.tempElem[0].clientHeight) <= threshold) {
      /*
       * debugger;
       * Scroll is almost at the bottom. Loading more rows
       */
      this.tempElem.remove();
      EmitterSubjectService.emitWhenScrollEnds(true);
      return true;
    }
    return false;
  }
  // ---------------------------------------------------------------
}
