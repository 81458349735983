<div id="profileContentComponentId" [@rotateInSlideOutVanish] [ngClass]="{'login':true, 'row':true}" style="width: 90%;margin:0.5rem 3.5% 0.5rem 6.5%;height:auto;min-height: 25rem;padding:0.15rem;opacity:1;border-radius:7px;">
  <div style="height: 12rem;
              width:100%;
              background-image: url('/assets/photos/tomFinland-1.png');
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 110% 110%;
              border-radius: 5px;" alt="tomFinland-1 image">
  </div>

  <div [ngClass]="{'col-lg-12':true, 'flex-conatinar':true}"
       style="margin-top: 0.15rem;
              height: auto;
              min-height: 22rem;">
    <a routerLink="/index" style="float:right;text-align:right;margin-top:0rem;"><span class="glyphicon glyphicon-remove flex-button-primary increase" style="font-size:large;padding:0.5rem 1.25rem 0 0;" aria-hidden="true"></span></a>
    <div [ngClass]="{'flex-text':true}" style="width:95%">
      <div *ngIf="profileContent && profileContent.message && profileContent.message.length > 0" class="flex-container">
        <div class="flex-text" [ngClass]="{'errxS':!profileContent.isSuccess, 'grnxS':profileContent.isSuccess}">
          <span class="glyphicon glyphicon-alert" aria-hidden="true"></span>
          <span> {{profileContent.message}}</span>
        </div>
      </div>
    </div>

    <div class="flex-text-center nvyxSB" style="color:darkred;margin-top:0;clear:both;">
      <div>Create/Edit Profile: Step 4 of 4: </div>
    </div>

    <div class="flex-text-center drkRedGrnSB increase" style="margin: 1.15rem 0 1.5rem 1rem; clear: both;">
      <div>More about me & highlights</div>
    </div>

    <form #profileContentForm="ngForm" class="flex-container increase" novalidate>
      <!--Heading-->
      <div class="flex-item-plain" style="margin:-0.25rem 0 0 0;width:100%;">
        <div class="form-group flex-item-main" [ngClass]="{
          'has-danger': heading.invalid && heading?.dirty,
          'has-success': heading.valid && heading?.dirty
        }">
          <div>
            <span class="nvyxSB">Profile Name<span class="errSub" style="margin-left:0px;margin-top:-5px;color: #e80c4d;">*</span> :</span>
          </div>
          <div align="center" >
            <input [(ngModel)]="profileContent.heading" class="form-control" name="heading" type="text" #heading="ngModel" style="width:13rem;height:auto;margin-left:1rem;" required autofocus />
          </div>
        </div>
      </div>
      <div *ngIf="heading.errors && (heading.dirty || heading.touched)" class="flex-container" style="margin:-0.75rem 0 0 0;">
        <div class="flex-text">
          <span *ngIf="heading.errors.required" class="errxxS"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> Your profile name is required</span>
        </div>
      </div>

      <!--Highlights-->
      <div class="flex-item-plain" style="margin:0;width:100%;">
        <div class="form-group flex-item-main" [ngClass]="{
          'has-danger': aBstract.invalid && aBstract.dirty,
          'has-success': aBstract.valid && aBstract.dirty
        }">
          <div>
            <span class="nvyxSB">Profile Highlights<span class="errSub" style="margin-left:0px;margin-top:-5px;color: #e80c4d;">*</span> :</span>
          </div>
          <div align="center" >
            <textarea [(ngModel)]="profileContent.aBstract" class="form-control" align="center" name="aBstract" type="text" #aBstract="ngModel" 
                      style="width: 13rem;
                      height: 6rem;
                      margin-left: 1rem;" required>
            </textarea>
          </div>
        </div>
      </div>
      <div *ngIf="profileContent.aBstract.length === 0 && isFormSubmitted" class="flex-container" style="margin:-0.75rem 0 0 0;width:100%;">
        <div class="flex-text">
          <span class="errxxS"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> Profile highlights is required</span>
        </div>
      </div>
      <!--Contents-->
      <div class="flex-item-plain" style="margin:0;width:100%;">
        <div class="form-group flex-item-main" [ngClass]="{
          'has-danger': contents.invalid && contents?.dirty,
          'has-success': contents.valid && contents?.dirty
        }">
          <div>
            <span class="nvyxSB">Profile Description<span class="errSub" style="margin-left:0px;margin-top:-5px;color: #e80c4d;">*</span> :</span>
          </div>
          <div align="center" >
            <textarea [(ngModel)]="profileContent.contents" class="form-control" align="center" name="contents" type="text" #contents="ngModel"
                      style="width: 13rem;
                      height: 6rem;
                      margin-left: 1rem;" required>
            </textarea>
          </div>
        </div>
      </div>
      <div *ngIf="profileContent.contents?.length === 0 && isFormSubmitted" class="flex-container" style="margin:-0.75rem 0 0 0;width:100%;">
        <div class="flex-text">
          <span class="errxxS"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> Profile description is required</span>
        </div>
      </div>

      <div class="flex-container" style="margin:1rem 0 1rem 0;width:100%;padding:0.1rem 0.01rem 1.75rem 0.01rem;">
        <button type="button" (click)="submitProfileContentForm();" class="bttn-lg-info mBttnFontWeight flex-button" [disabled]="click || !profileContentForm.valid" style="padding: 5px 10px 5px 10px;">Submit</button>
        <button type="button" (click)="cancel()" class="bttn-lg-invDefault mBttnFontWeight flex-button" style="padding: 5px 10px 5px 10px;">Close</button>
      </div>
    </form>
  </div>
</div>
