<!--Reactve Form Validation: Ref:https:// www.codingame.com/playgrounds/8012/angular-form-validation-example-tutorial-->
<!--*ngIf="isProfileInfo"-->
<div id="profileComponentId" [@rotateInSlideOutVanish] [ngClass]="{'login row':true}" style="width:95%;margin:0.5rem 1.5% 0.5rem 3.5%;padding:0.2rem;opacity:1;border-radius:7px;">
  <div style="width: 100%; height: 16rem; background-image: url('/assets/photos/leatherMan-2.png'); background-repeat: no-repeat; background-size: cover; border-radius: 5px; background-position: 50% 50%;" alt="login image"></div>
  <div class="col-lg-12" style="margin-top:2rem;">
    <a routerLink="/index" style="float:right;text-align:right;margin-top:-2rem;"><span class="glyphicon glyphicon-remove flex-button-primary increase" style="font-size:x-large;padding:0.5rem 0.25rem 0 0;" aria-hidden="true"></span></a>
    <div>
      <div id="profileMsgId" *ngIf="profile && profile.message && profile.message.length > 0" class="flex-container">
        <div class="flex-table increase" style="margin-left: -0.25rem;">
          <span *ngIf="!profile.isSuccess" class="glyphicon glyphicon-alert align-center errS" aria-hidden="true"></span>
          <span *ngIf="profile.isSuccess" class="glyphicon glyphicon-ok-circle align-center grnS" aria-hidden="true"></span>
          <span [ngClass]="{'errS':!profile.isSuccess, 'grnS':profile.isSuccess}">{{profile.message}}</span>
        </div>
      </div>

      <div class="flex-container" style="margin:1rem 0 1rem 0;">
        <div class="flex-text nvyxSB"><span style="color:darkred;margin-top:0;">Create/Edit Profile: Step 2 of 4</span></div>
        <hr class="hr" style="width:90%;padding-right: 1rem;" />
      </div>

      <div class="flex-container" style="margin-top:0rem;">
        <div class="flex-text"><span class="gryxSB">Required (Choose one): </span><span class="errSub" style="margin:0.2rem;">* </span></div>
      </div>

      <form #profileForm="ngForm" novalidate>
        <!--====================================================================-->
        <!--HighestEducation-->
        <div style="margin:1rem 0 1rem 0;">
          <div class="flex-container" style="margin:0.15rem 0 0 0;">
            <div class="flex-text"><span class="nvyB" style="margin-top:0;">What is your highest level of education?: </span><span class="errSub" style="margin-left:-2px;">*</span></div>
          </div>
          <div class="flex-container" style="margin:0.15rem 0 0.15rem 0;">
            <div class="flex-text-left" style="padding:0.15rem 0 0.15rem 0;">
              <app-radio-box [radioboxArr]="highestEducationArr" [selectedRadioIndex]="selectedHiEduIndex" [isRequired]=true [radioboxName]="this.radioboxNames[0]" [isFormSubmitted]="isFormSubmitted"></app-radio-box>
            </div>
          </div>
        </div>
        <hr class="hr" style="width:90%;padding-right: 1rem;" />
        <!--====================================================================-->
        <!--Religion-->
        <div style="margin:1rem 0 1rem 0;">
          <div class="flex-container" style="margin:0.15rem 0 0 0;">
            <div class="flex-text"><span class="nvyB" style="margin-top:0;">What is your religion?: </span><span class="errSub" style="margin-left:-2px;">*</span></div>
          </div>
          <div class="flex-container" style="margin:0;">
            <div class="flex-text-left" style="padding:0.15rem 0 0.15rem 0;height:auto;">
              <app-radio-box [radioboxArr]="religionArr" [selectedRadioIndex]="selectedReligionIndex" [isRequired]=true [radioboxName]="this.radioboxNames[1]" [isFormSubmitted]="isFormSubmitted"></app-radio-box>
            </div>
          </div>
        </div>
        <hr class="hr" style="width:90%;padding-right: 1rem;" />
        <!--====================================================================-->
        <!--PlaySafe-->
        <div class="" style="margin:1rem 0 1rem 0;">
          <div class="flex-container increase">
            <div class="flex-text"><span class="nvyB" style="margin-top:0.15rem;">Do you play safe?:</span><span class="errSub" style="margin-left:-2px;">*</span></div>
          </div>

          <div class="flex-container">
            <div class="flex-text" style="min-height:9rem;width:30%;background-image:url('/assets/photos/playSafe-1.png');background-repeat:no-repeat;background-size:cover;border-radius:5px;background-position: 50% 50%;" alt="puppies"></div>
            <div class="flex-text increase" style="width:65%;height:auto;">
              <div class="flex-container" style="margin:0;">
                <div class="flex-text-left" style="padding:0.15rem 0 0.15rem 0;">
                  <app-radio-box [radioboxArr]="playSafeArr" [selectedRadioIndex]="selectedPlaySafeIndex" [isRequired]=true [radioboxName]="this.radioboxNames[2]" [isFormSubmitted]="isFormSubmitted"></app-radio-box>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="hr" style="width:90%;padding-right: 1rem;" />
        <!--====================================================================-->
        <!--Position-->
        <div style="margin:1rem 0 1rem 0;">
          <div class="flex-container" style="margin:0.15rem 0 0 0;">
            <div class="flex-text"><span class="nvyB" style="margin-top:0;">You consider your position to be: </span><span class="errSub" style="margin-left:-2px;">*</span></div>
          </div>
          <div class="flex-container" style="margin:0;">
            <div class="flex-text-left" style="padding:0.15rem 0 0.15rem 0;height:auto;">
              <app-radio-box [radioboxArr]="positionArr" [selectedRadioIndex]="selectedPositionIndex" [isRequired]=true [radioboxName]="this.radioboxNames[3]" [isFormSubmitted]="isFormSubmitted"></app-radio-box>
            </div>
          </div>
        </div>
        <hr class="hr" style="width:90%;padding-right: 1rem;" />
        <!--====================================================================-->
        <!--RelationshipStatus-->
        <div style="margin:1rem 0 1rem 0;" class="">
          <div class="flex-container increase">
            <div class="flex-text nvyB"><span style="margin-top:0.15rem;">What is your current relationship status?: <span class="errSub" style="margin-left:-2px;">*</span></span></div>
          </div>

          <div class="flex-container">
            <div class="flex-text" style="min-height:6rem;width:20%;background-image:url('/assets/photos/gay-couple-1.jpg');background-repeat:no-repeat;background-size:cover;border-radius:5px;background-position: 50% 50%;" alt="relationships"></div>
            <div class="flex-text-left increase" style="width:75%;height:auto;padding:0.15rem 0 0.15rem 1rem;">
              <app-radio-box [radioboxArr]="relationshipStatusArr" [selectedRadioIndex]="selectedRelStatusIndex" [isRequired]=true [radioboxName]='this.radioboxNames[4]'></app-radio-box>
            </div>
          </div>
        </div>
        <hr class="hr" style="width:90%;padding-right: 1rem;" />
        <!--====================================================================-->
        <!--Smoking-->
        <div (mouseout)="onMouseOut($event)" style="margin:1rem 0 1rem 0;" class="">
          <div class="flex-container increase">
            <div class="flex-text"><span class="nvyB" style="margin-top:0.15rem;">Do you vape or smoke cigarette?: </span><span class="errSub" style="margin-left:-2px;">*</span></div>
          </div>

          <div class="flex-container">
            <div class="flex-text" style="min-height:6rem;width:30%;background-image:url('/assets/photos/smoking-cartoon-1.png');background-repeat:no-repeat;background-size:cover;border-radius:5px;background-position: 50% 50%;" alt="puppies"></div>
            <div class="flex-text-left increase" style="width:65%;height:auto;padding:0.15rem 0 0.15rem 1.5rem;">
              <app-radio-box [radioboxArr]="smokingStatusArr" [selectedRadioIndex]="selectedSmkStatusIndex" [isRequired]=true [radioboxName]="this.radioboxNames[5]" [isFormSubmitted]="isFormSubmitted"></app-radio-box>
            </div>
          </div>
        </div>
        <hr class="hr" style="width:90%;padding-right: 1rem;" />
        <!--====================================================================-->
        <!--SubmitButton-->
        <div *ngIf="isSubmitBttnFocus" class="flex-container" style="margin:0.75rem 0.5rem 0.75rem 0.5rem;">
          <div class="col-md-12 flex-text errxS">
            Please enter all the required (*) fileds to enable the SUBMIT button. This Form is incomplete, therefore, the SUBMIT button is disabled! Please complete the form to enable the SUBMIT button.
          </div>
        </div>
        <div class="flex-container" style="margin:1.25rem 0 0 0;">
          <div class="flex-text">
            <button *ngIf="!isProfileFormValid" (mouseout)="onSubmitBttnMouseOut($event)" (click)="showInvalidFormMessage($event);" type="button" [ngClass]="{'bttn-md-info':!isMobilevar, 'bttn-lg-info mBttnFontWeight':isMobilevar, 'flex-button':true}" style="    margin: 5px;
    padding: 5px 10px 5px 10px;
">
              Submit
            </button>
            <button *ngIf="isProfileFormValid" type="button" (click)="submitProfileForm();" [ngClass]="{'bttn-md-info':!isMobilevar, 'bttn-lg-info mBttnFontWeight':isMobilevar, 'flex-button':true}" style="    margin: 5px;
    padding: 5px 10px 5px 10px;
">
              Submit
            </button>
            <button type="button" (click)="cancel();" [ngClass]="{'bttn-md-invDefault':!isMobilevar, 'bttn-lg-invDefault mBttnFontWeight':isMobilevar, 'flex-button':true}" style="    margin: 5px;
    padding: 5px 10px 5px 10px;
">
              cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
