
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { ProfileNameServiceInterface } from '../../interfaces/serviceInterfaces/profileServiceInterfaces/profileNameServiceInterface';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { ProfileInfo } from '../../models/profile/profileInfo.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { SitUserService } from '../commonServiceService/sitUserService.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
// =====================================================================
// 
// =====================================================================

@Injectable({ providedIn: 'root'})
export class ProfileNameService implements OnDestroy, ProfileNameServiceInterface
{  
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  // ----------------------------------------------------------
  public loginSuccess : LoginSuccess = new LoginSuccess();;
  public profileName = '';
  public profileNameMap : Map<any, any> = new Map();
  public profileTile : ProfileTile = new ProfileTile();
  public sitUserId : number = 0;
  public sitUser : SitUser = new SitUser();
  public signedInUser : SitUser = new SitUser();
  constructor (
    public httpService : HttpService,
    public sitUserService : SitUserService,
  ) {
    
    this.initialize();

    // -----------------------------------------------------------
    // this indexedDb-system works. Tested 2020/August/05,
    // -----------------------------------------------------------
  }
  // ------------------------------------------------------------
  public initialize () : void {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.sitUser = EmitterSubjectService.getSitUserModel();
  }
  // ---------------------------------------------------------------
  public ngOnDestroy () : any {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    
    // this.timerArray.forEach((timer) => clearInterval(timer));
    return true;
  }
  // ------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------
  public fetchProfileName (sitId : number): string {
    let pName = '';
    if (sitId > 0) {
      this.sitUserService.getProfileName(sitId).then(data => {
        pName = data;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileNameMap) && !this.profileNameMap.has(sitId)) {
          this.profileNameMap.set(sitId, pName);
        }
      })
    }
    return pName;
  }
  // ---------------------------------------------------------------
  public getProfileName (sitId : number) : string {
    if (sitId > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileNameMap) && this.profileNameMap.size > 0) {
      if (this.profileNameMap.has(sitId)) {
        return this.profileNameMap.get(sitId);
      }
      else return '';
    }
    else return '';
  }
  // ---------------------------------------------------------------
  public setProfileNameMap (pName : string, sitId: number) : void {
    this.profileName = pName;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileNameMap) && !this.profileNameMap.has(sitId)) {
      this.profileNameMap.set(sitId, pName);
    }
    // debugger;
  }
  // --------------------------------------------------------------
  public getProfileNameFromProfileTile (pTile : ProfileTile) : string {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.heading)) {
        this.profileName = pTile.heading;
      }
      else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.profileName)) {
         // this.profileName = this.profileName + pInfo.sitUserId; // Warning: accordionHeader.profileName gets the postfic sitUserId which is not the actual profileName
        this.profileName = pTile.profileName;
      }
      else {
        this.profileName = this.profileName;
      }
    }

    this.setProfileNameMap(this.profileName, pTile.sitUserId);
    // debugger;
    return this.profileName;
  }
  // --------------------------------------------------------------
  public getProfileNameFromProfileInfo (pInfo : ProfileInfo) : string {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo.heading)) {
        this.profileName = pInfo.heading;
      }
      else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo.profileName)) {
        this.profileName = pInfo.profileName;
      }
      else {
        // this.profileName = this.profileName + pInfo.sitUserId; // Warning: accordionHeader.profileName gets the postfic sitUserId which is not the actual profileName
        this.profileName = this.profileName;
      }
    }
    this.setProfileNameMap(this.profileName, pInfo.sitUserId);
    // debugger;
    return this.profileName;
  }
  // ---------------------------------------------------------------
  public updateProfileTileForSignedInUser (pTile : ProfileTile) : ProfileTile {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      if (pTile.sitUserId === this.loginSuccess.signedInUserId) {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.heading)) {
          // debugger;
          pTile.heading = this.loginSuccess.heading;
        }
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.profileName)) {
          pTile.profileName = this.loginSuccess.profileName;
        }

        this.setProfileNameMap(pTile.profileName, pTile.sitUserId);
      }
    }
    return pTile;
  }
  // ---------------------------------------------------------------
  public updateProfileTileForSitUser (pTile : ProfileTile) : ProfileTile {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)
      && (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.profileName) || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.heading))) {
      this.sitUser = EmitterSubjectService.getSitUserModel();
      if (pTile.sitUserId === this.sitUser.sitUserId && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser.profileName)) {
        // debugger;
        pTile.heading = pTile.profileName = this.sitUser.profileName;
        this.setProfileNameMap(pTile.profileName, pTile.sitUserId);
      }
    }
    return pTile;
  }
  // ----------------------------------------------------------------------

  // ------------------------------------------------------------------------
}
