
import { Injectable } from '@angular/core';
// import { SignalRService } from './signal-r.service';
import { EmailService } from './emailService';
import { EnoteService } from './enoteService';
import { ChatService } from './chatService';
import { SwBackgroundFetchService } from './swBackgroundFetchService.service';
import { CommunicatorService } from './communicatorService.service';
import { SignalRService } from './signal-r.service.service';
// import { SignalRNotificationService } from './signal-r-notificationService.service';
// import { SignalRChatService } from './signal-r-chatService.service';

@Injectable({
  providedIn: 'any'
})
export class CommunicationServiceService {

  constructor(
    private chatService : ChatService,
    private communicatorService : CommunicatorService,
    private emailService: EmailService,
    private enoteService : EnoteService,
    private swBackgroundFetchService:SwBackgroundFetchService,
    private signalRService : SignalRService,
    // private signalrChatService : SignalRChatService,
    // private signalrNoteService : SignalRNotificationService,
  ) { }
 // ------------------------------------------------------------------
  setChatService(cs: ChatService): void {
    this.chatService = cs;
  }
  getChatService(): ChatService {
    return this.chatService;
  }
  // ------------------------------------------------------------------
  setCommunicatorService (cos : CommunicatorService) : void {
    this.communicatorService = cos;
  }
  getCommunicatorService () : CommunicatorService {
    return this.communicatorService;
  }
 // ------------------------------------------------------------------
  // setChunkyStreamService(cks: ChunkyStreamService): void {
  //  this.chunkyStreamService = cks;
  //  return;
  // }
  // getChunkyStreamService(): ChunkyStreamService {
  //  return this.chunkyStreamService;
  // }
 // ------------------------------------------------------------------
  setEmailService(es: EmailService): void {
    this.emailService = es;
  }
  getEmailService(): EmailService {
    return this.emailService;
  }
 // ------------------------------------------------------------------
  setEnoteService(ens: EnoteService): void {
    this.enoteService = ens;
  }
  getEnoteService(): EnoteService {
    return this.enoteService;
  }
 // ------------------------------------------------------------------
  setSwBackgroundFetchService (swb : SwBackgroundFetchService): void {
    this.swBackgroundFetchService = swb;
   }
  getSwBackgroundFetchService () : SwBackgroundFetchService {
    return this.swBackgroundFetchService;
   }

  // ------------------------------------------------------------------
   setSignalRService ( src : SignalRService ) : void
   {
    this.signalRService = src;
   }
   getSignalRService () : SignalRService
   {
    return this.signalRService;
   }

  // ------------------------------------------------------------------
  // setSignalRNotificationService ( srn : SignalRNotificationService ) : void
  // {
  //  this.signalrNoteService = srn;
  // }
  // getSignalRNotificationService () : SignalRNotificationService
  // {
  //  return this.signalrNoteService;
  // }
 // ------------------------------------------------------------------
}
