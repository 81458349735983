
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Dictionary } from 'dictionaryjs';
import { AppFeature } from '../../models/common/appFeture.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { HttpService } from '../coreServiceService/httpService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
// import { } from 'zone.js';



@Injectable( {
  providedIn: 'root',
} )
export class AppFeatureService 
{
  public appFeatureDictionary = new Dictionary<string, AppFeature>(); //new Collections.Dictionary<string, AppFeature>();
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public tempElem : any;
  public rendererService : any;
  public appFeatureArr : AppFeature[] = [];
  public arrKvAny : KvAny[] = [];
  public kvAny : KvAny = new KvAny();
  public index = -1;
  public timer : any;
  public timerArray : any[] = [];
  //  ---------------------------------------------------------------
  constructor (
    private httpService : HttpService,
    private router : Router )
  {
    // debugger;
    this.getAppFeaturesFromServer().subscribe(data => {
      this.appFeatureArr = data;
    })
  }
  // ----------------------------------------------------------------
  // ngOnDestroy () : void
  // {
  //  this.emitterDestroyed$.next( true );
  //  this.emitterDestroyed$.complete();
  //  // RunOutsideAngularServiceStatic.timerArray.forEach( ( timer ) => clearInterval( timer ) );
  // }

  // ---------------------------------------------------------------------------------
  public getAppFeatureArr () : AppFeature[]
  {
    if ( this.appFeatureArr.length == 0 )
    {
      this.getAppFeaturesFromServer().subscribe(data => {
        this.appFeatureArr = data;
        return this.appFeatureArr;
      })
    }
    //  debugger;
    return this.appFeatureArr;
  }
  // ---------------------------------------------------------------------------------
  public setAppFeatureArr ( aFArr : AppFeature[] ) : void
  {
    // debugger;
    this.appFeatureArr = aFArr;
  }
  // ---------------------------------------------------------------------------------
  public getArrKvAny () : KvAny[]
  {
    // debugger;
    return this.arrKvAny;
  }
  // ---------------------------------------------------------------------------------
  public setArrKvAny ( arrKvAny : KvAny[] ) : void
  {
    // debugger;
    this.arrKvAny = arrKvAny;
  }
  // ---------------------------------------------------------------------------------
  // static getAppFeatureDictionary () : Collections.Dictionary<string, AppFeature>
  // {
  //  debugger;
  //  return AppFeatureServiceStatic.appFeatureDictionary;
  // }

  // ---------------------------------------------------------------------------------
  // static setAppFeature (dictionary : Collections.Dictionary<string, AppFeature>) : void
  // {Dictionary
  //  debugger;
  //  AppFeatureServiceStatic.appFeatureDictionary = dictionary;
  // }
  // ---------------------------------------------------------------
  // this.httpService.get('/Home/GetAppFeaturesJson').subscribe(
  public getAppFeaturesFromServer () : Observable<any>
  {
    // debugger;
    return new Observable<any>(subscriber => {
      
      
      this.httpService.get('/api/Home/GetAppFeaturesJson').subscribe(
        (result) => {
          // debugger;
          //if (result) {
          //  // debugger;
          //  this.appFeatureArr = result as AppFeature[];

          //  this.appFeatureArr.map(e => {
          //    this.appFeatureDictionary.set(e.featureName, e);

          //    this.kvAny = new KvAny();
          //    this.kvAny.key = (e as AppFeature).featureName;
          //    this.kvAny.value = e as AppFeature;
          //    this.arrKvAny.push(this.kvAny);
          //  });
          //}
          // debugger;
          //EmitterSubjectService.emitAppFeatureArr(this.appFeatureArr);
          //subscriber.next(this.appFeatureArr);
          EmitterSubjectService.emitAppFeatureArr(result);
          subscriber.next(result);
          subscriber.complete();
        },
        (error) => {
          EmitterSubjectService.emitMyErrorLog('Error occured in getAppFeaturesFromServer();\n Error-message: ' + error.message);
        },
      );      
    })
  }
  // ---------------------------------------------------------------------------------
}

