
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProfileContentServiceInterface } from '../../interfaces/serviceInterfaces/profileServiceInterfaces/profileContentServiceInterface';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Content } from '../../models/common/content.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { MemberViewMgmtModel } from '../../models/member/memberViewMgmtModel.model';
import { ProfileInfo } from '../../models/profile/profileInfo.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { KvArrayService } from '../coreServiceService/kvArrayService.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { Communication } from '../../models/communication/communication.model';

@Injectable({
  providedIn: 'root',
} )

// ------------------------------------------------------------------------------------------------
// Disclaimer:  this service accomplishes all activities regarding signedInUser's ProfilePics only,
//              i.e. creating the profilePics for the signedInUser (1st  person user), and all of
//              the activities regarding managing the profilePics such as making a photo private
//              if it is public and vice versa, and generating the PhotoMgmtNavigation buttons.
// ------------------------------------------------------------------------------------------------
export class ProfileContentService implements OnDestroy, ProfileContentServiceInterface {
  public boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  public content : Content = new Content();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isOnLine = false; 
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public memberViewMgmtModel : MemberViewMgmtModel = new MemberViewMgmtModel();
  public message = '';
  public profileContent : Content = new Content();
  public profileContentKvAnyArr : KvAny[] = [];
  public profileInfo : ProfileInfo = new ProfileInfo();
  // will contain member-selection of KvPhoto as final state of currentStateOfPhoto
  public sitUser : SitUser = new SitUser();
  public signedInUser : SitUser = new SitUser();

  constructor (
    public httpService : HttpService,
    public kvArrayService : KvArrayService,
  )
  {    
    // -----------------------------------------------------------
    this.initialize();
  }
  // ------------------------------------------------------------
  initialize(): void {
    // debugger;
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.memberViewMgmtModel = EmitterSubjectService.getMemberViewMgmtModel();
    this.signedInUser = EmitterSubjectService.getSignedInUser();
    this.sitUser = EmitterSubjectService.getSitUserModel();
  }
  // ---------------------------------------------------------------
  ngOnDestroy () : any {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    // this.preference = new Preference();
    return true;
  }
  // ---------------------------------------------------------------

  // ---------------------------------------------------------------
  // create profileContentArrKvAny for ContentKeyValueArrComponent:
  // ---------------------------------------------------------------
  public createProfileContentKvAnyArr (pInfo : ProfileInfo) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo) && pInfo.sitUserId > 0) {
      this.profileContent = CopyServiceStatic.copyFromProfileInfoToContent(pInfo);
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileContent)) {
        this.profileContentKvAnyArr = this.kvArrayService.getProfileContentToKvArrArr(this.profileContent);

        // debugger;
        this.memberViewMgmtModel.populateModelData(this.profileContent, null, pInfo, pInfo, null, null, null, this.profileContentKvAnyArr, null);
      }
    }
    EmitterSubjectService.emitProfileContentKvAnyArr(this.profileContentKvAnyArr);
    return this.profileContentKvAnyArr;
  }
  // ---------------------------------------------------------------
  public executeProfileContentTasks (pcontent : Content) : Promise<any> {
    let date = new Date();
    let pContent = pcontent;
    return new Promise<any>((resolve, reject) => {
      // debugger;
      this.memberViewMgmtModel = EmitterSubjectService.getMemberViewMgmtModel();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pContent) && pContent.sitUserId > 0) {
        this.profileContent = pContent;
        this.profileContentKvAnyArr = this.kvArrayService.getProfileContentToKvArrArr(this.profileContent); // TODO: restore
          // debugger;
        // this.memberViewMgmtModel.populateModelData(pContent, null, null, null, null, null, null, this.profileContentKvAnyArr, null);
          
        resolve(this.profileContentKvAnyArr);
      }
      else {
        // debugger;
        reject(date.getTime() + ': profileContentService.fetchAndExecuteProfileContentTasks() was rejected.-2');
      }
    }).catch(error => {
      // debugger;
      console.log(date.getTime() + ': profileContentService.promise returned a null result.-3');
    })
  }
  // ---------------------------------------------------------------
  public fetchAndExecuteProfileContentTasks (pinfo : ProfileInfo) : Promise<any> {
    let date = new Date();
    let pInfo = pinfo;
    return new Promise<any>((resolve, reject) => {
      // debugger;
      this.memberViewMgmtModel = EmitterSubjectService.getMemberViewMgmtModel();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo) && pInfo.sitUserId > 0) {
        this.getProfileContent(pInfo.sitUserId).subscribe(data => {
          // debugger;
          if (data) {            
            this.memberViewMgmtModel.populateModelData(data.profileContent, null, pInfo, pInfo, null, null, null, data.profileContentKvAnyArr, null);
          }
          resolve(this.memberViewMgmtModel);
        })
      }
      else {
        // debugger;
        reject(date.getTime() + ': profileContentService.fetchAndExecuteProfileContentTasks() was rejected.-2');
      }
    }).catch(error => {
      // debugger;
      console.log(date.getTime() + ': profileContentService.promise returned a null result.-3');
    })
  }
  // ---------------------------------------------------------------
  public executeProfileInfoContentTasks (pinfo : ProfileInfo) : Promise<any> {
    let pInfo = pinfo;
    return new Promise<any>((resolve, reject) => {
       if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo)) {

         // create profileContentArrKvAny for ContentKeyValueArrComponent:
         // --------------------------------------------------------------
         this.profileContent = CopyServiceStatic.copyFromProfileInfoToContent(this.profileInfo);
         // debugger;
         if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileContent)
           && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileContent.contents)
           && this.profileContent.contents.length > 0) {

           this.profileContentKvAnyArr = this.kvArrayService.getProfileContentToKvArrArr(this.profileContent); // TODO: restore

           // debugger;
           this.memberViewMgmtModel.populateModelData(this.profileContent, null, pInfo, pInfo, null, null, null, this.profileContentKvAnyArr, null);

           EmitterSubjectService.emitProfileContentKvAnyArr(this.profileContentKvAnyArr);

           resolve(this.profileContentKvAnyArr);
         }         
       }
    })
   }
  // ---------------------------------------------------------------
  //  Use this method only instead of fetchAndExecuteProfileContentTasks
  // ---------------------------------------------------------------
  public getProfileContent (sitid : number) : Observable<any> {
    let date = new Date();
    let sitUserId = sitid;

    return new Observable<any>(subscriber => {
      if (sitUserId > 0) {
        let bnComm : any;

        this.loginSuccess = EmitterSubjectService.getLoginSuccess();

        if (this.loginSuccess.signedInUserId > 0) {
          bnComm = new Communication();
          bnComm.sitUserId = sitUserId;
          bnComm.signedInUserId = this.loginSuccess.signedInUserId;
          this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce))
           // debugger;
          this.httpService.postObservable('/Member/GetProfileContent', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
          }, 'json2text').subscribe(result =>
          {
            if (result) {
              // debugger;
              this.boxNonceEntity = result as BoxNonceEntity;
              if (this.boxNonceEntity && this.boxNonceEntity.box.length > 0 && this.boxNonceEntity.nonce.length > 0) {
                var unsalted = SlakezSaltServiceStatic.boxUnsalt(this.boxNonceEntity);
                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
                  this.profileContent = JSON.parse(unsalted) as Content;
                }
                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileContent)) {
                  this.content.aBstract = this.profileContent.aBstract;
                  this.content.contentId = this.profileContent.contentId;
                  this.content.contents = this.profileContent.contents;
                  this.content.heading = this.profileContent.heading;
                  if (this.content.profileId !== this.profileContent.profileId
                    && this.content.profileId === 0 && this.profileContent.profileId > 0) {
                    this.content.profileId = this.profileContent.profileId;
                  }
                  if (this.content.sitUserId !== this.profileContent.sitUserId
                    && this.content.sitUserId === 0 && this.profileContent.sitUserId > 0) {
                    this.content.sitUserId = this.profileContent.sitUserId;
                  }
                  // debugger;
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileContent)) {
                    this.profileContentKvAnyArr = this.kvArrayService.getProfileContentToKvArrArr(this.profileContent);

                    // debugger;

                    EmitterSubjectService.emitProfileContentKvAnyArr(this.profileContentKvAnyArr);
                    subscriber.next(this.profileContent);
                  }
                }
              }
            }
          })
        }
      }
    })
  }
  // ==================================================================
}

