import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '../../../../services/coreServiceService/httpService.service';
import { EmitterSubjectService } from '../../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { LoginSuccess } from '../../../../models/account/loginSuccess.model';

@Injectable({
  providedIn: 'any'
})
export class ImageUploadService {
  private baseUrl = 'http://localhost:44334';
  private loginSuccess : LoginSuccess = new LoginSuccess();
  constructor (
    private http : HttpClient,
    public httpService: HttpService, ) { }

  upload (file : File) : Observable<HttpEvent<any>> {
    const formData : FormData = new FormData();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    formData.append('file', file);
    formData.append('signedInUserId', this.loginSuccess.signedInUserId.toString());
    formData.append('date', new Date().getTime().toString());
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position : any) => {
          if (position) {
            formData.append('latitude', position.coords.latitude);
            formData.append('longitude', position.coords.longitude)
          }
        })
    }
    // debugger;
    /*const req = new HttpRequest('POST', `${ this.baseUrl }/api/FileUpload/ImageUpload`, formData, {*/
    const req = new HttpRequest('POST', `/FileUpload/ImageUpload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    // debugger;
    return this.http.request(req);
  }
  // ------------------------------------------------
  // Not used:
  // ------------------------------------------------
  getFiles () : Observable<any> {
    debugger;
   // return this.http.get(`${ this.baseUrl }/files`);
    debugger;
     return this.http.get(`/FileUpload/ImageUpload`);
  }
}