
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Login } from '../../models/account/login.model';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { ClientApiModel } from '../../models/common/clientApiModel.model';
import { Heartbeat } from '../../models/common/heartbeat.model';
import { Unit } from '../../models/common/unit.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';
import { HttpService } from './httpService.service';

@Injectable({
  providedIn: 'any',
})
export class HeartbeatService implements OnDestroy {
  public boxNonce : BoxNonceEntity = new BoxNonceEntity();
  public clientApiModel : ClientApiModel = new ClientApiModel();
  public distanceUnit : Unit = new Unit();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public heartbeat: Heartbeat = new Heartbeat();
  public isMobilevar = false;
  public login: Login = new Login();
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public message = '';
  public profileTile: ProfileTile = new ProfileTile();
  public sitUser: SitUser = new SitUser();
  public timer: any;
  public timerMap : Map<any, any> = new Map();
  constructor (
    public dictionaryService : DictionaryService,
    public httpService : HttpService,
  ) {
    this.initialize();
  }
  // ---------------------------------------------------------------
  initialize() {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    EmitterSubjectService.loginSuccessEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
        this.loginSuccess = result as LoginSuccess;
    });

    EmitterSubjectService.isMobileEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
        this.isMobilevar = result as boolean;
    });
    this.isMobilevar = EmitterSubjectService.getIsMobile();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.timerMap.forEach((timer) => clearInterval(timer));
  }
  // ---------------------------------------------------------------------------------  
  //        This method should be called in @Input() distanceUnit is empty.
  //
  //        This method expects lat and long data either in sitUser or sitUser.heartbeat
  // ---------------------------------------------------------------------------------
  //public computeSitUserDistance (sitUsr : any) : Observable<any> {
  //  var sitUser = sitUsr;
  //  var tDistUnit = new Unit();
  //  return new Observable<any>(subscriber => {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser)) {
  //      /*this.setupSitUserHeartbeat(sitUser).subscribe(data => {*/
  //        // sitUser = data;
  //        debugger;
         
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser)
  //          && ((sitUser.longitude != 0 && sitUser.latitude != 0)
  //            || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser.heartbeat))) {
  //          this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //          let isKm = true;
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)
  //            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.heartbeat)) {
  //            if (this.loginSuccess.heartbeat.latitude != 0 && this.loginSuccess.heartbeat.longitude != 0) {
  //              if (sitUser.heartbeat.longitude != 0 && sitUser.heartbeat.latitude != 0) {
  //                tDistUnit = this.computeDistance(sitUser.heartbeat.latitude, sitUser.heartbeat.longitude, this.loginSuccess.heartbeat.latitude, this.loginSuccess.heartbeat.longitude, isKm);
  //                debugger;
  //              }
  //              else if ((this.sitUser.longitude != 0 && sitUser.latitude != 0)) {
  //                debugger;
  //                tDistUnit = this.computeDistance(sitUser.latitude, sitUser.longitude, this.loginSuccess.heartbeat.latitude, this.loginSuccess.heartbeat.longitude, isKm);
  //              }

  //              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tDistUnit) && tDistUnit.distance > 0) {
  //                this.distanceUnit = tDistUnit;
  //                debugger;
  //                subscriber.next(tDistUnit);
  //                subscriber.complete();
  //              }
  //              else {
  //                tDistUnit = this.defaultDistanceUnit();
  //                debugger;
  //                subscriber.next(tDistUnit);
  //                subscriber.complete();
  //              }
  //            }
  //            else {
  //              tDistUnit = this.defaultDistanceUnit();
  //              debugger;
  //              subscriber.next(tDistUnit);
  //              subscriber.complete();
  //            }
  //          }
  //          else {
  //            tDistUnit = this.defaultDistanceUnit();
  //            debugger;
  //            subscriber.next(tDistUnit);
  //            subscriber.complete();
  //          }
  //        }
  //        else {
  //          tDistUnit = this.defaultDistanceUnit();
  //          debugger;
  //          subscriber.next(tDistUnit);
  //          subscriber.complete();
  //        }
  //     // })
  //    }
  //    else {
  //      tDistUnit = this.defaultDistanceUnit();        
  //      debugger;
  //      subscriber.next(tDistUnit);
  //      subscriber.complete();
  //    }
  //  })
  //}
  // ---------------------------------------------------------------------------------
   copyFromHeartbeatResult(hb: any): Heartbeat {
    const hbeat = new Heartbeat();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.City)) {
        hbeat.city = hb.City;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.Country)) {
        hbeat.country = hb.Country;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.Date)) {
        hbeat.date = hb.Date;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.Distance)) {
        hbeat.distance = hb.Distance;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.Gender)) {
        hbeat.gender = hb.Gender;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.HeartbeatTime)) {
        hbeat.heartbeatTime = parseInt(hb.HeartbeatTime, 10);
      }
      // If (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.away)) hbeat.date = hb.isOnline;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.Latitude)) {
        hbeat.latitude = hb.Latitude;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.Longitude)) {
        hbeat.longitude = hb.Longitude;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.Neighborhood)) {
        hbeat.neighborhood = hb.Neighborhood;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.RegionCode)) {
        hbeat.regionCode = hb.RegionCode;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.SignOnTime)) {
        hbeat.prevHeartbeat = parseInt(hb.SignOnTime, 10);
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.SITUserId)) {
        hbeat.sitUserId = hb.SITUserId;
      }
      // debugger;
    }
    return hbeat;
  }

  // ---------------------------------------------------------------------------------
  computeDistanceAndUnit ( hb : Heartbeat ) : Unit {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    let distanceUnit : Unit = new Unit();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb) && hb.distance >= 0 && hb.sitUserId > 0) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.heartbeat)) {
        // debugger;
        distanceUnit = this.computeDistance(this.loginSuccess.latitude, this.loginSuccess.longitude, this.heartbeat.latitude, this.heartbeat.longitude, true); // (dest, origin, isKm)

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit)) {
          distanceUnit.distanceStr = distanceUnit.distance.toFixed( 2 ) + ' ' + distanceUnit.unit;
        }
      }
    }
    return distanceUnit;
  }
  // ---------------------------------------------------------------
  defaultDistanceUnit () : Unit {
    var tDistUnit = new Unit();
    tDistUnit.distance = -999;
    tDistUnit.unit = tDistUnit.distance > 0 ? 'km' : 'distance unavailable';
    return tDistUnit;
  }
  // ---------------------------------------------------------------
  // API:
  // -----------------------------------------------------------
  getDistanceFromHeartBeatDictionary (sitId : number) : any {  
    if (sitId > 0) {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      this.sitUser.sitUserId = sitId;
      let hb = this.dictionaryService.heartbeatDictionary.get(sitId) as Heartbeat;

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( hb ) )
      {
        return this.computeDistanceAndUnit( hb );
      }
      else
      {
        return null;
      }
    }
    return null;
  }
  // ---------------------------------------------------------------
   getHeartbeat(): Heartbeat {
    return this.heartbeat;
  }
  // ---------------------------------------------------------------
  // API:
  // -----------------------------------------------------------
  //getHeartbeatBySitUserId (sitId: number) : Observable<any> {
  //  // Fetch a heartbeat of sitUserId:
  //  // -------------------------------
  //  var sitUserId = sitId;
  //  return new Observable<any>(subscriber => {
  //    if (sitUserId > -1) {
  //      this.getHeartbeatObservable(sitUserId, 'AppComponent.heartbeat').subscribe(hb => {
  //      // debugger;
  //      subscriber.next(hb);
  //      subscriber.complete();
  //    });
  //    }  
  //  })
  //}
  // -----------------------------------------------------------
  // API:
  // -----------------------------------------------------------
  getHeartbeatFromDictionaryOrServer (sitId : number, cName: string): Observable<any> {
    var sitUserId = sitId;
    // debugger;
    var componentName = 'DistanceComponent';
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cName)) {
      componentName = cName;
    }
    return new Observable<any>(subscriber => {
      let hb = this.dictionaryService.heartbeatDictionary.get(sitUserId) as Heartbeat;
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb) && hb.heartbeatTime > 0) {
        subscriber.next(hb);
        subscriber.complete();
      }
      else {
        // debugger;
        console.log('getHeartbeatFromDictionaryOrServer.sitUserId = ' + sitUserId);
        this.getHeartbeatObservable(sitUserId, componentName).subscribe(data => {
          // debugger;
          subscriber.next(data);
          subscriber.complete();
        })
      }
    })
  }
  /*
   * ---------------------------------------------------------------
   * Ref:https:// www.positronx.io/angular-promises-example-manage-http-boxrequests/
   * Tested, works!
   * Note: this method uses a promise as well as an emitter
   *       to send the data upon receiving, and puts into dictionary
   * ---------------------------------------------------------------
   */
  // ---------------------------------------------------------------
  // Note: this XHR api call will trigger top to bottom changeDetection
  // ---------------------------------------------------------------
  getHeartbeatObservable (situserid : number, callername : string) : Observable<any> {
    // debugger;
    var sitUserId = situserid;
    var callerName = callername;
    var hbeat : Heartbeat = new Heartbeat();

    return new Observable((subscriber) => {
      // debugger;      
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUserId) && sitUserId > 0) {
        // debugger;
        this.heartbeat = new Heartbeat();
        this.heartbeat.sitUserId = sitUserId;
        this.heartbeat.callerName = callerName;
        this.heartbeat.signedInUserId = this.loginSuccess.signedInUserId;
        this.boxNonce = this.salt(this.heartbeat);
        // console.log('getHeartbeatObservable.sitUserId = ' + sitUserId);

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonce)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonce.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonce.nonce)) {
          this.httpService
            .postObservable(
              '/api/Member/GetHeartbeat',
              {
                box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonce.box),
                nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonce.nonce),
              },
              'json2text',
            ).subscribe((result) => {
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                // const bn = result as BoxNonceEntity;
                // debugger;

                if (result && result.box.length > 0 && result.nonce.length > 0) {
                  // debugger;
                  try {

                    hbeat = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(result)) as Heartbeat;
                    // debugger;

                    // debugger;
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hbeat)) {

                      this.heartbeat = JSON.parse(JSON.stringify(hbeat)) as Heartbeat; // deepcopy

                      // debugger;

                      // update heartbeatDictionary:
                      // ---------------------------                  
                      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.heartbeat)
                        && this.heartbeat.sitUserId > 0) {

                        this.dictionaryService.updateDictionary(this.heartbeat, 'heartbeat', this.heartbeat.sitUserId);
                        // debugger;
                        // DictionaryServiceStatic.updateDictionary(DictionaryServiceStatic.heartbeatDictionary, "Heartbeat", this.loginSuccess.signedInUserId)
                        this.sitUser = this.dictionaryService.sitUserDictionary.get(this.heartbeat.sitUserId);

                        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser)) {
                          this.sitUser.heartbeat = this.heartbeat;
                          this.dictionaryService.updateDictionary(this.sitUser, 'SitUser', this.sitUser.sitUserId);
                          EmitterSubjectService.setSitUserModel(this.sitUser);
                        }
                      }

                      // update loginSuccess if applicable :
                      // ---------------------------------
                      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
                      if (this.heartbeat.sitUserId === this.loginSuccess.signedInUserId) {
                        this.loginSuccess.heartbeat = this.heartbeat;
                        EmitterSubjectService.setLoginSuccess(this.loginSuccess);
                      }

                      // emit the result:
                      // ----------------
                      EmitterSubjectService.emitHeartbeat(this.heartbeat);
                      // debugger;

                      // set the result on promise:
                      // --------------------------
                      subscriber.next(this.heartbeat);
                      subscriber.complete();
                    }
                  }
                  catch {
                    this.message = StringServiceStatic.stringBuilder(new Date().getTime() + ': boxUnsalt failed! @heartbeatService.getHeartbeatObservable()');
                  } finally {

                  }
                } else {
                  this.message = 'heartbeatService.getHeartbeatPromise(sitUserId : ' + sitUserId + ') returned  result.box,nonce were null or undefined. ';
                  console.log(this.message);
                }
              }
              else {
                this.message = 'heartbeatService.getHeartbeatPromise(sitUserId : ' + sitUserId + ') returned a null or undefined result. ';
                console.log(this.message);
              }
            })
        }
        else {
          this.message = 'boxNonceEntity\'s data is null or undefined for sitUserId;: ' + sitUserId + '  in getHeartbeatPromise()';
          console.log(this.message);
        }
      }
      else {
        this.message = 'sitUserId: ' + sitUserId + ' is 0 or null or undefined in getHeartbeatPromise()';
        console.log(this.message);
      }
    })
  }
  // ---------------------------------------------------------------
  // Note: this XHR api call will trigger top to bottom changeDetection
  // ---------------------------------------------------------------
  getBatchOfHeartbeatObservable (sitIds : number[], clrName : string) : Observable<any> {
    var sitUserIds = sitIds;
    var callerName = clrName;
    return new Observable((subscriber) => {
      // debugger;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      let heartBeatMap = new Map<number, Heartbeat>();
      //debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUserIds) && sitUserIds.length > 0) {
        // this.heartbeat = new Heartbeat();
        this.clientApiModel.sitUserIdArr = sitUserIds;
        this.clientApiModel.callerName = callerName;
        this.clientApiModel.sitUserId = this.clientApiModel.signedInUserId = this.loginSuccess.signedInUserId;
        // debugger;
        this.boxNonce = this.salt(this.clientApiModel);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonce)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonce.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonce.nonce)) {
          this.httpService
            .postObservable(
              '/api/Member/GetBatchOfHeartbeat',
              {
                box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonce.box),
                nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonce.nonce),
              },
              'json2text',
            ).subscribe(
              (result) => {
                /*
                 * debugger;
                 * Success
                 */
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                  const bn = result as BoxNonceEntity;
                  // debugger;

                  if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
                    const hbeats = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as Heartbeat[];
                    // debugger;
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hbeats) && hbeats.length > 0) {
                      hbeats.forEach(e => {
                        heartBeatMap.set(e.sitUserId, e);

                        // update heartbeatDictionary:
                        // ---------------------------                       
                        if (e.sitUserId > 0 && !this.dictionaryService.heartbeatDictionary.has(e.sitUserId)) {
                          this.dictionaryService.heartbeatDictionary.set(e.sitUserId, e);
                          // debugger;
                        }
                      })
                    }
                    // set the result on promise:
                    // --------------------------
                    // debugger;
                    subscriber.next(heartBeatMap);
                    subscriber.complete();
                  } else {
                    console.log('Error getting batchOfheartbeat');
                  }
                }
              },
              (error) => console.log(error),
            );
        }
      }
    });
  }
  // ---------------------------------------------------------------
  getDistanceUnit (sitUsr : SitUser) : Observable<any> {
    var sitUser = sitUsr;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) && sitUser.sitUserId > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0
      && this.loginSuccess.signedInUserId !== sitUser.sitUserId) { // computeDistance if non-signedInUser

      var srcLatitude = this.loginSuccess?.latitude != 0 ? this.loginSuccess?.latitude : 0;
      var srcLongitude = this.loginSuccess?.longitude != 0 ? this.loginSuccess?.longitude : 0;
      var destLatitude = sitUser?.heartbeat?.latitude != 0 ? sitUser?.heartbeat?.latitude : 0;
      var destLongitude = sitUser?.heartbeat?.longitude != 0 ? sitUser?.heartbeat?.longitude : 0;
      // debugger;
      return new Observable<any>(subscriber => {
        let distanceUnit = new Unit();
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destLatitude) && destLatitude != 0
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destLongitude) && destLongitude != 0
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(srcLatitude) && srcLatitude != 0
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(srcLongitude) && srcLongitude != 0) {

          distanceUnit = this.computeDistance(destLatitude, destLongitude, srcLatitude, srcLongitude, true);
          // debugger;
          subscriber.next(distanceUnit);

        }
        else {
          subscriber.next(null);
        }
        subscriber.complete();
      })
    }
  }
  // ---------------------------------------------------------------
  isDefaultDistanceUnit (dUnit : Unit) : any {
    return dUnit?.distance < 0;
  }
  // ---------------------------------------------------------------
  public processDistanceUnit (distanceUnit : Unit) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit.distance)
      && distanceUnit.distance >= 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit.unit)) {


      if (distanceUnit.unit.indexOf('km') && distanceUnit.distance < 1) {
        distanceUnit.distance = distanceUnit.distance * 1000;
        distanceUnit.unit = 'm';
      }
      if ((distanceUnit.unit.toLowerCase().indexOf('meter') || distanceUnit.unit.toLowerCase().indexOf('m')) && distanceUnit.distance > 1000) {
        distanceUnit.distance = distanceUnit.distance / 1000;
        distanceUnit.unit = 'km';
        return distanceUnit;
      }
    }
  }
  // ---------------------------------------------------------------
  returnHeartbeat () : any {
    return this.heartbeat;
  }
  // ---------------------------------------------------------------
  resetHeartbeat () : any {
    this.timerMap.forEach((e) => clearInterval(e));
    this.heartbeat = new Heartbeat();
    EmitterSubjectService.setHeartbeat(this.heartbeat);
    return this.heartbeat;
  }

  // ---------------------------------------------------------------
  salt (model : any) : any {
    if (model) {
      return (this.boxNonce = SlakezSaltServiceStatic.boxSalt(JSON.stringify(model)));
    }
    return null;
  }
  // ---------------------------------------------------------------
  setHeartbeat (hb : Heartbeat) : any {
    this.heartbeat = hb;
    return true;
  }

  // ---------------------------------------------------------------
  //  Note: we check the following @Input() variables to setup sitUser.heartbeat:
  //        1. SitUser
  //        2. Heartbeat
  //        3. ProfileTile
  //
  // ---------------------------------------------------------------
  setupSitUserHeartbeat (sitUsr : SitUser) : Observable<any> {
    this.sitUser = sitUsr;
    var sitUser = sitUsr;
    return new Observable<any>(subscriber => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) && sitUser.sitUserId > 0) {
        debugger;
        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser.heartbeat)
          || this.sitUser?.heartbeat?.sitUserId === 0
          || this.sitUser?.heartbeat?.latitude === 0
          || this.sitUser?.heartbeat?.longitude === 0) {
          // debugger;
          this.getHeartbeatFromDictionaryOrServer(sitUser.sitUserId, 'HeartbeatService').subscribe(hb => {
            debugger;
            // this.heartbeat = hb; // sitUser with latitude & longitude data
            sitUser.heartbeat = hb;
            subscriber.next(sitUser);
            // subscriber.complete();
          })
        }
        //else {
        //  debugger;
        //  subscriber.next(null);
        //  subscriber.complete();
        //}
      }
      //else {
      //  subscriber.next(null);
      //  subscriber.complete();
      //}
    })
  }
  
  // ---------------------------------------------------------------
  // TODO: Change function name to processDistanceUnit()
  // Note: It does not process OffOn indicator.
  // TODO : remove before deployment.
  //        This method is not in use.
  // ---------------------------------------------------------------
  // processOffOnIndicatorAndDistanceUnit ( pTile : ProfileTile ) : ProfileTile
  // {
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pTile ) )
  //  {
  //    // compute distance and create offOn indicator:
  //    // --------------------------------------------
  //    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pTile.heartbeat ) && pTile.heartbeat.heartbeatTime > -1 )
  //    {
  //      // debugger;
  //      pTile.distanceUnit = this.computeDistanceAndUnit( pTile.heartbeat );

  //      // debugger;
  //      // TODO: check to see if the below is needed:
  //      // TODO: remove before deployment:
  //      // -----------------------------------------
  //      // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) && this.profileTile.distance > 0 )
  //      // {
  //      //  // debugger;
  //      //  let distanceUnit = { distance: this.profileTile.distance, unit: this.profileTile.unit };

  //      //  distanceUnit = this.processDistanceUnit( distanceUnit );

  //      //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( distanceUnit ) )
  //      //  {
  //      //    this.profileTile.distance = distanceUnit.distance; //  = result.profileTile;
  //      //    this.profileTile.unit = distanceUnit.unit;
  //      //    this.profileTile.distanceStr = distanceUnit.distance + ' ' + distanceUnit.unit;
  //      //  }
  //      // }
  //    }
  //  }
  //  return pTile;
  // }
  
  /*
   * ---------------------------------------------------------------
   * Note: this method should be used to start the heartbeat
   * ---------------------------------------------------------------
   */
  startSendingHeartbeat (sitId : number, clrName : string) : Observable<any> {
    let tHeartbeat : any;
    var sitUserId = sitId;
    var callerName = clrName;
    // debugger;
    return new Observable((subscriber) => {
      if (sitId > 0) {
        // debugger;
        this.timer = setTimeout(() => {
          this.sendHeartbeat(sitUserId, callerName).subscribe(data => {
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
              tHeartbeat = data;
              // debugger;
              EmitterSubjectService.emitHeartbeat(tHeartbeat);
              subscriber.next(tHeartbeat);
              subscriber.next(this.timer);
              subscriber.complete();
            }
            else {
              this.message = 'heartbeatService.sendHeartbeat(sitId :  ' + sitId + ') returned data null or undefined result; heartbeat: ' + JSON.stringify(tHeartbeat);
              console.log(this.message);
            }
            clearTimeout(this.timer);
          })
          console.log(new Date().getTime() + '-timestamp-' + new Date().toTimeString())
        }, 300000); // 60000 * 5 //every 5 minutes
        clearTimeout(this.timer);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.timer) && !this.timerMap.has(sitUserId)) {
          this.timerMap.set(sitUserId, this.timer);
        }
      }      
    })   
  }
  
  
  // ---------------------------------------------------------------
   sendHeartbeat(sitId: number, clrName: string): Observable<any> {
    // debugger;
     var sitUserId = sitId;
     var callerName = clrName;
     return new Observable<any>(subscriber => {
       if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUserId) && sitUserId > 0) {
         // debugger;
         this.sendWithGeoLocation(sitUserId, callerName).subscribe(data => {
           subscriber.next(data);
           subscriber.complete();
         })
       }
       
     })
  }
  // ---------------------------------------------------------------
   sendWithGeoLocation(sitId: number, clrName: string): Observable<any> {
    // debugger;
     var sitUserId = sitId;
     var callerName = clrName;
     this.loginSuccess = EmitterSubjectService.getLoginSuccess();
     return new Observable((subscriber) => {
       if (navigator.geolocation && sitUserId > 0) {
         navigator.geolocation.getCurrentPosition((position : any) => {
           if (position) {
             console.log('Latitude: ' + position.coords.latitude + '; Longitude: ' + position.coords.longitude);
             this.heartbeat.latitude = position.coords.latitude;
             this.heartbeat.longitude = position.coords.longitude;
             this.heartbeat.sitUserId = sitUserId;
             this.heartbeat.callerName = callerName;
             this.heartbeat.signedInUserId = this.loginSuccess.signedInUserId;
             this.heartbeat.jsDateForCs = DateStringServiceStatic.getTicks(new Date()).toString();
             this.salt(this.heartbeat);
             // debugger;
             if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonce)
               && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonce.box)
               && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonce.nonce)) {
               this.httpService
                 .postObservable(
                   '/api/Member/Heartbeat',
                   {
                     box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonce.box),
                     nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonce.nonce),
                   },
                   'json',
                 )
                 .subscribe(
                   (result) => {
                     this.loginSuccess.heartbeat = this.processHeartbeatResult(result);
                     EmitterSubjectService.setLoginSuccess(this.loginSuccess);
                     // EmitterSubjectService.emitLoginSuccess(this.loginSuccess);
                     if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)
                       && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.heartbeat)) {
                       subscriber.next(this.loginSuccess.heartbeat);
                       subscriber.complete();
                     }
                   },
                   (error) => {
                     // alert ('Error occured in GetArticleContent(' + idArr[i] + ');\n Error-mag: ' + error);
                     /*EmitterSubjectService.emitMyErrorLog( { feedbackMessage: 'Error occured in Heartbeat();\n Error-mag: ' + error.message } );*/
                     // EmitterSubjectService.emitMessage({ feedbackMessage: 'Error occured in Heartbeat();\n Error-mag: ' + error.message });
                   });
             }             
           }           
         });
       }       
     })
  }
  // ---------------------------------------------------------------
   processHeartbeatResult(result: any): any {
    // debugger;
      const bn = result as BoxNonceEntity;
      // debugger;

    if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
      const hbeat = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as Heartbeat;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hbeat)) {
        this.heartbeat = this.copyFromHeartbeatResult(hbeat);
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.heartbeat)) {
        // debugger;
        if (!this.dictionaryService.heartbeatDictionary.has(this.heartbeat.sitUserId)) {
          this.dictionaryService.heartbeatDictionary.set(this.heartbeat.sitUserId, this.heartbeat);
        }
        // EmitterSubjectService.emitHeartbeat(this.heartbeat);
        return this.heartbeat;
      }
    }
  }
  // ---------------------------------------------------------------
   toRad(deg: number): number {
    return (deg * Math.PI) / 180;
  }
  // ---------------------------------------------------------------
   numToRad(num: number): any {
    let numRad : any;
    let neg = -1;

    this.toRad = () => {
      return (num * Math.PI) / 180;
    };

    if (num < 0) {
      numRad = this.toRad(Math.abs(num));
      numRad = numRad * neg;
    } else {
      numRad = this.toRad(Math.ceil(num));
    }
    return numRad;
  }
  /*
   * ---------------------------------------------------------------
   * Ref: http:// www.movable-type.co.uk/scripts/latlong.html
   * NOTE: returns the result as either KiloMetres or Miles though it computes both
   */
  public computeDistance (destinationLatitude : number, destinationLongitude : number, originLatitude : number, originLongitude : number, isKm : boolean) : any {
    /*
     * alert ('Calculating Distance: \n originLatitude: ' + originLatitude + '\n originLongitude: ' + originLongitude +
     * '\n destinationLatitude' + destinationLatitude + '\n destinationLongitude:' + destinationLongitude);
     */
    const distanceUnit = new Unit();
    distanceUnit.unit = 'km';
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destinationLatitude)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destinationLongitude)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(originLatitude)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(originLongitude)) {
      var destLat = parseFloat(destinationLatitude.toString());
      var destLon = parseFloat(destinationLongitude.toString());
      var oLat = parseFloat(originLatitude.toString());
      var oLon = parseFloat(originLongitude.toString());

      if (oLat !== 0 && oLon !== 0
        && destLat !== 0 && destLon !== 0
        && oLat !== destLat
        && oLon !== destLon) {
        const R = 6371; // km; Earth's radius
        const M = 0.621371; // miles; 1km = 0.621371 miles

        /*
         * ------------------------------------------------
         * Number.prototype.toRad = function () { return this * Math.PI / 180; }
         * ------------------------------------------------
         * var dLon = (destinationLongitude - originLongitude).toRad();
         */
        const dLat = this.numToRad(destLat - oLat);
        const dLon = this.numToRad(destLon - oLon);
        /*
         * var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
         * Math.cos(originLatitude.toRad()) * Math.cos(destinationLatitude.toRad()) *
         * Math.sin(dLon / 2) * Math.sin(dLon / 2);
         */
        const a1a = Math.sin(dLat / 2);
        const a1 = a1a * a1a;

        const a2a = Math.cos(this.numToRad(oLat));
        const a2b = Math.cos(this.numToRad(destLat));
        const a2 = a2a * a2b;

        const a3a = Math.sin(dLon / 2);
        const a3 = a3a * a3a;

        const a = a1 + (a2 * a3);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distanceK = R * c;
        const distanceM = distanceK * M;
        debugger;
        if (isKm === true) {
          if (distanceK < 1 && distanceK > 0) {

            distanceUnit.distance = distanceK * 1000;
            distanceUnit.unit = 'm';
          }
          else {

            distanceUnit.distance = distanceK;
            distanceUnit.unit = 'km';
          }
        } else {
          distanceUnit.distance = distanceM;
          distanceUnit.unit = 'mile';
        }
        distanceUnit.distanceStr = distanceUnit.distance + ' ' + distanceUnit.unit;
      }
    }
    return distanceUnit;  
  }
  // ---------------------------------------------------------------
  public computeDistanceFromStringInputs (destinationLatitude : string, destinationLongitude : string, originLatitude : string, originLongitude : string, isKm : boolean) : any {
    /*
     * alert ('Calculating Distance: \n originLatitude: ' + originLatitude + '\n originLongitude: ' + originLongitude +
     * '\n destinationLatitude' + destinationLatitude + '\n destinationLongitude:' + destinationLongitude);
     */
    const distanceUnit = new Unit();
    distanceUnit.unit = 'km';
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destinationLatitude)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destinationLongitude)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(originLatitude)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(originLongitude)) {
      var destLat = parseFloat(destinationLatitude);
      var destLon = parseFloat(destinationLongitude);
      var oLat = parseFloat(originLatitude);
      var oLon = parseFloat(originLongitude);

      if (oLat !== 0 && oLon !== 0
        && destLat !== 0 && destLon !== 0
        && oLat !== destLat
        && oLon !== destLon) {
        const R = 6371; // km; Earth's radius
        const M = 0.621371; // miles; 1km = 0.621371 miles

        /*
         * ------------------------------------------------
         * Number.prototype.toRad = function () { return this * Math.PI / 180; }
         * ------------------------------------------------
         * var dLon = (destinationLongitude - originLongitude).toRad();
         */
        const dLat = this.numToRad(destLat - oLat);
        const dLon = this.numToRad(destLon - oLon);
        /*
         * var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
         * Math.cos(originLatitude.toRad()) * Math.cos(destinationLatitude.toRad()) *
         * Math.sin(dLon / 2) * Math.sin(dLon / 2);
         */
        const a1a = Math.sin(dLat / 2);
        const a1 = a1a * a1a;

        const a2a = Math.cos(this.numToRad(oLat));
        const a2b = Math.cos(this.numToRad(destLat));
        const a2 = a2a * a2b;

        const a3a = Math.sin(dLon / 2);
        const a3 = a3a * a3a;

        const a = a1 + (a2 * a3);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distanceK = R * c;
        const distanceM = distanceK * M;
        debugger;
        if (isKm === true) {
          if (distanceK < 1 && distanceK > 0) {

            distanceUnit.distance = distanceK * 1000;
            distanceUnit.unit = 'm';
          }
          else {

            distanceUnit.distance = distanceK;
            distanceUnit.unit = 'km';
          }
        } else {
          distanceUnit.distance = distanceM;
          distanceUnit.unit = 'mile';
        }
        distanceUnit.distanceStr = distanceUnit.distance + ' ' + distanceUnit.unit;
      }
    }
    return distanceUnit;
  }
  /*
   * ===============================================================
   * Tested calculateDistance(), it works!
   * NOTE: distance is calculated in KM/Mile
   * ---------------------------------------------------------------
   */
  public calculateDistance (signInHeartbeat : Heartbeat, heartbeat : Heartbeat, isKm: boolean): any {
    let distanceUnit = new Unit();

     if (heartbeat && signInHeartbeat) {
       const destinationLatitude = signInHeartbeat.latitude;
       const destinationLongitude = signInHeartbeat.longitude;
       const originLatitude =  heartbeat.latitude;
       const originLongitude =  heartbeat.longitude;
       // ---------------------------------------------------------------------------------------------------------

       distanceUnit = this.computeDistance(destinationLatitude, destinationLongitude, originLatitude, originLongitude, isKm);
       return distanceUnit;
     }
     else return null;
  }
  /*
   * ---------------------------------------------------------------
   * If 5 minutes or more passed since last heartbeatTime, then offline
   * NOTE: The Heartbeat on Winhost database takes Pacific Time for DateTime.Now()
   *     But at the client's browser, the heartbeatService.js takes a local time for now(),
   *     for example, here in Ottawa, it takes a local time that is 3 hours ahead of Pacific Time.
   *     In this cause the isOnline at heartbeatService to be always false for Ottawa client.
   *     Therefore, use the server-provided isOnline status
   *     Do Not Delete This!!
   *     
   *     Note: this can be circumvented if the heartbeat sent from client to server is client's local time sent,
   *           and subsiquently that heartbeat compared to the client's local time now.
   * ---------------------------------------------------------------
   */
  // ---------------------------------------------------------------
  isOnlineFromHeartbeat ( heartbeatTime : any ) : boolean
  {
     return DateStringServiceStatic.isOnlineFromDateTimeTicks(heartbeatTime);
  }
  // ---------------------------------------------------------------
}
