<!--ref: https://www.syncfusion.com/kb/10416/how-to-get-started-easily-with-syncfusion-angular-7-accordion -->
<ul id="appFeaturesComponentId" [@rotateInSlideOutVanish] class="flex-text shadowSilverInner"
    style="list-style: none;
    width: 94%;
    margin-top: 0.25rem;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
    margin-left: 1.4rem;
    padding: 0px;
    opacity: 0.9;
    background-color: azure; overflow: hidden;">
  <li expandMenu style="margin: 0; padding: 0; list-style: none;">
    <a (click)="isAppFeatureClosedBool.isClosed=!isAppFeatureClosedBool.isClosed;isOpen(isAppFeatureClosedBool.isClosed);">
      <p class="kvBttnxSInv whiteBlueInvB" style="width: 100%; margin: 0.01rem; padding: 0.15rem 2rem 0.15rem 2rem;">
        <span *ngIf="kvLegend[0].length > 0" style="float: left;opacity: 1;">
          {{kvLegend[0]}}
        </span>
        <span *ngIf="isAppFeatureClosedBool.isClosed" class="glyphicon glyphicon-plus"
              style="float: right;
                      font-size: 1rem;
                      padding-top: 0.2rem;
                      opacity: 1;"></span>
        <span *ngIf="!isAppFeatureClosedBool.isClosed" class="glyphicon glyphicon-minus"
              style="float: right;
                      font-size: 1rem;
                      padding-top: 0.2rem;
                      opacity: 1;"></span>
      </p>
    </a>
  </li>

  <li style="margin:0;padding:0;">
    <ul *ngIf="!isAppFeatureClosedBool?.isClosed" [ngClass]="{'submenu':true}" align="left" style="list-style: none;width:100%; height: auto;">
      <li class="flex-container" style="margin:0;padding:0;">        
        <div *ngFor="let appFeature of appFeatureArr let i=index" style="width:100%;">
          <app-feature-value-display [appFeature]="appFeature" class="flex-item-main" style="width: 100%; display:inline-block;"></app-feature-value-display>
        </div>
      </li>
    </ul>
  </li>
</ul>

