
import { Injectable, OnDestroy } from '@angular/core';
import { Dictionary } from 'dictionaryjs';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DbDexieToDictionaryServiceInterface } from '../../interfaces/serviceInterfaces/dbServicesInterfaces/dbDexieToDictionaryServiceInterface';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { DexieBne } from '../../models/boxNonce/dexieBne.model';
import { CommunicationActivity } from '../../models/communication/communicationActivity.model';
import { RemoteDataModel } from '../../models/data/remoteDataModel.model';
import { MemberActivity } from '../../models/member/memberActivity.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { MessageBuilderServiceStatic } from '../staticServices/messgeBuilderServiceStatic.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';
import { DbDexieService } from './dbDexieService.service';

@Injectable({ providedIn: 'root' })
export class DbDexieToDictionaryService implements OnDestroy, DbDexieToDictionaryServiceInterface {
  
  
  public date = new Date();
  public tables : any;
  public dictionarySubscription : any;

  public sitUserIdDictionaryFromDexieDbCounter = 0;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public message : any = "";
  public modelArr : any;
  public rdm : RemoteDataModel = new RemoteDataModel()
  public sitUser : SitUser = new SitUser();
  constructor (public dbDexieService : DbDexieService,

    public dictionaryService : DictionaryService
    ) {  
    
    this.tables = this.dbDexieService._db.tables;
     // debugger;
    // -----------------------------------------------------------
    EmitterSubjectService.photoBnEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
         debugger;
        if (result) {
          let pBn = result as any;
          if ((result as any).sitUserId > 0) {
            // let userPhotoIds = this.dictionaryService.userPhotoIdsDictionary.getValue( result.sitUserId ) as number[];
            // this.dictionaryService.updatePhotoBnDictionaryForPhotoBn( result, result.sitUserId );
            this.dictionaryService.updateDictionary(result, 'PhotoBn', pBn.photoBnId);
          }
        }
        this.emitterDestroyed$.next(true);
        this.emitterDestroyed$.complete();
        this.emitterDestroyed$.unsubscribe();
      });
    // --------------------------------------------------------------
    EmitterSubjectService.indexDbSaveSuccessfulEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {

         debugger;
        console.log(this.date.getTime() + ': saved data for : ' + result);
        this.emitterDestroyed$.next(true);
        this.emitterDestroyed$.complete();
        // this.emitterDestroyed$.unsubscribe();

      });
    // --------------------------------------------------------------
    //EmitterSubjectService.backgroundProcessMethodDataForServiceEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe((rdm : RemoteDataModel) => {
    //     debugger;
    //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rdm) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rdm.methodName)) {
    //      switch (rdm.methodName) {
    //        case 'populateProfileTileDictionaryFromIndexedDb': // TODO: Test
    //          debugger;
    //          this.dictionaryService.profileTileDictionary = rdm.data;
    //          break;

    //        case 'getBatchOfSitUserIdsFromServer': 
    //          break;
    //        case 'getProfileTile': 

    //          // debugger;
    //          break;

    //        default:
    //          break;
    //      }
    //      // this.ngAfterViewInit();
    //    }
    //  });
    // --------------------------------------------------------------
    // --------------------------------------------------------------
  }
  //  ---------------------------------------------------------------
  ngOnDestroy () {
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    // debugger;
  }
  // ============================================================
  public createAllChatDictionariesFromDbDexie () : void {
    // this.createChatDictionaryFromDbDexie();
    // this.createChatIdDictionaryFromDbDexie();

    // this.createMyChatDictionaryFromDbDexie();
    // this.createMyChatIdDictionaryFromDbDexie();
  }
  // ---------------------------------------------------------------
  public createAllDictionariesFromDbDexie () : void {
    this.createAllChatDictionariesFromDbDexie();
    this.createAllEmailDictionariesFromDbDexie();
    this.createAllEnoteDictionariesFromDbDexie();
    this.createAllSitUserRelatedDictionariesFromDbDexie();
    this.createAllProfileRelatedDictionariesFromDbDexie();
    this.createAllMemberActivityDictionariesFromDbDexie();
  }
  // ============================================================
  public createAllEmailDictionariesFromDbDexie () : void {
    this.createEmailTileIdDictionaryFromDbDexie();
    this.createEmailTileDictionaryFromDbDexie();
    this.createMyEmailIdDictionaryFromDbDexie();
    this.createMyEmailDictionaryFromDbDexie();
  }
  // ============================================================
  public createAllEnoteDictionariesFromDbDexie () : void {
    this.createEnoteTileDictionaryFromDbDexie();
    this.createEnoteTileIdDictionaryFromDbDexie();
    this.createMyNotificationDictionaryFromDbDexie();
    this.createMyNotificationIdDictionaryFromDbDexie();
  }
  // ---------------------------------------------------------------
  public createLoginSuccessDictionaryFromIndexedDb () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    //  this.getDictionaryFromIndexedDb("LoginSuccess", 1).subscribe(mActDict => {
    //    this.dictionaryService.loginSuccessDictionary = mActDict;
    //  });
  }
  // ---------------------------------------------------------------
  public createAllMemberActivityDictionariesFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    let sitUserId = this.loginSuccess.signedInUserId;
    this.createMemberActivityDictionariesFromDbDexie(sitUserId);
    this.createMemberLockingActivityDictionariesFromDbDexie(sitUserId);
    this.createMemberStarTrackWinkActivityDictionariesFromDbDexie(sitUserId);
  }
  // ============================================================
  public createAllProfileRelatedDictionariesFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.createPreferenceDictionaryFromDbDexie();
    this.createProfileContentDictionaryFromDbDexie();
    this.createProfileInfoDictionaryFromDbDexie();
    this.createProfileTileDictionaryFromDbDexie();
    this.createProfilePicsDictionaryFromDbDexie();

    //this.rdm = new RemoteDataModel();
    //this.rdm.url = '';
    //this.rdm.data = '';
    //this.rdm.headerType = 'json'
    //this.rdm.methodName = 'populateProfileTileDictionariesFromIndexedDb';
    //this.rdm.id = this.loginSuccess.signedInUserId;
    //this.rdm.timestamp = new Date().getTime().toString();
    //this.rdm.options = '';
    //this.httpBackgroundService.loadRemoteDataInBackground(this.rdm );
    
    if (this.dictionaryService.kvPhotoDictionary.size > 0) {
      // debugger;
      this.createKvPhotoDictionaryFromDbDexie();
		}
  }
  // ============================================================
  public createAllSitUserRelatedDictionariesFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.createSitUserDictionaryFromDbDexie();
    this.createSitUserIdDictionaryFromDbDexie();   

    debugger;
    //this.rdm = new RemoteDataModel();
    //this.rdm.url = '';
    //this.rdm.data = '';
    //this.rdm.headerType = 'json'
    //this.rdm.methodName = 'populateSitUserIdDictionariesFromIndexedDb';
    //this.rdm.id = this.loginSuccess.signedInUserId;
    //this.rdm.timestamp = new Date().getTime().toString();
    //this.rdm.options = '';
    //this.httpBackgroundService.loadRemoteDataInBackground(this.rdm);

    //this.rdm.methodName = 'populateSitUserDictionariesFromIndexedDb';
    //this.httpBackgroundService.loadRemoteDataInBackground(this.rdm);
    // debugger;
  }
  // ---------------------------------------------------------------
  public doesStoreHaveData (entityName : string) : boolean {
    return this.dbDexieService.doesStoreHaveData(entityName);
    // return true;
  }
  // ===============================================================
  // input: array;
  // output: a zagged array of two arrays in an wrapper array consisting
  //          the left-half of inputArray as leftArray=lArr, and 
  //          the right-half of inputArray as rightArray=rArr
  //
  // TODO:20230316: write a method that 
  //        1. automatically equally divides the dictionary.size in half
  //        if the dictionary.size > max;
  //        2. saves the leftDictionary as 1 and the rightDictionary as 2 as a postfix of it's name.
  //        3. 
  // ===============================================================
  public equalDivisionAlgorithm (inArr : any[]) : any[] {
    var lArr : any[] = [];
    var rArr : any[] = [];

    var outArr : any[] = [];
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inArr) && inArr.length >= 2) {
      let mid = Math.floor(inArr.length % 2);
      for (var i = 0; i < inArr.length; i++) {
        if (i <= mid) {
          lArr.push(inArr[ i ]);
        }
        else {
          rArr.push(inArr[ i ]);
        }
      }
      outArr.push(lArr);
      outArr.push(rArr);
    }
    return outArr;
  }
  // ---------------------------------------------------------------
  //  Note: this method will be used to create a particular 
  //        dictionary on demand with the entityName and id.
  // ---------------------------------------------------------------
  public getDictionary (entityName : string, key : string) : Observable<Dictionary<any, any>> | any {
    // debugger;
    return new Observable<Dictionary<any, any>>((subscriber) => {
      // debugger
      let tkey = key;
      let tEntityName = entityName;
      // Note: sitUserId can be used to check whether it is signedInUser
      let dictionaryData : any;
      let dictionary = new Dictionary<any, any>();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tEntityName) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tkey)) {

        this.dbDexieService.getStoreData(tEntityName, tkey).subscribe(data => { // data is a request promise

          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
            // debugger;
            let bne : BoxNonceEntity = data;
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bne) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bne.box) && bne.box.length > 0) {
              bne.box = FrequentlyUsedFunctionsServiceStatic.angArrBufferToB64(bne.box);
              bne.nonce = FrequentlyUsedFunctionsServiceStatic.angArrBufferToB64(bne.nonce);
              // debugger;
              dictionary = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bne)) as Dictionary<any, any>;
              // debugger;
              subscriber.next(dictionary);
            }
            else {
              // debugger;
              this.message = this.date.getTime() + ': getStoreData(entityName: ' + entityName + ') retured a null or undefined result.-3';
              MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, this.message);
            }
          }
        })
      }
      else {
        // debugger;
        this.message = this.date.getTime() + ': dbDexieService.doesStoreHaveData(entityName: ' + entityName + ') returned count === 0; -6';
        MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, this.message);
      }
    })
  }
  
  // ---------------------------------------------------------------
  public isObjectStoreNameFoundInTables (name : string) : boolean {
    return this.dbDexieService.isObjectStoreNameFoundInTables(name);
  } 

  // ---------------------------------------------------------------
  public saveChatConversationDictionaryToDbDexie (dictionary : Dictionary<any, any>, entityName : string, sitUserId : number) : any {
    let date = new Date();
    let model : any = {};
    let chatHistoryDictionary: Dictionary<any, any> = new Dictionary<any, any>();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionary)
      && sitUserId > 0) {

      let values = dictionary.values();
      // debugger;
      let keys = dictionary.keys();

      // debugger;

      let boxNonce = SlakezSaltServiceStatic.boxSalt(JSON.stringify(dictionary));
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.box)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.nonce)) {
        // debugger;

        boxNonce.date = DateStringServiceStatic.getTicks(new Date()).toString();
        boxNonce.id = sitUserId;
        boxNonce.key = sitUserId.toString();
        boxNonce.entityName = entityName;
        boxNonce.sitUserId = sitUserId;

        model = new DexieBne();
        model.id = boxNonce.id,
          // model.bne = boxNonceEntity,
          model.bneStr = FrequentlyUsedFunctionsServiceStatic.strTobase64(JSON.stringify(boxNonce));
        model.key = boxNonce.key
        model.entityName = entityName;
        // debugger;

        try {
          // debugger;
          this.dbDexieService.updateObjectStoreData(model).subscribe(
            val => {
              this.message = 'timestamp-' + new Date().getTime() + ': saveDictionaryToIndexedDb() is successful for: ' + entityName;              
              console.log(this.message);
            }, //next callback
            error => { console.log('timestamp-' + new Date().getTime() + " :error occured while updating ObjectStore. error-message:" + error) }, //error callback
            () => { console.log('timestamp-' + new Date().getTime() + " :updating ObjectStore Completed") } //complete callback
          );

          return true;
        }
        catch {
          this.message = 'timestamp: ' + new Date().getTime() + ' : the data is too large for the ' + entityName + 'ObjectStore in saveKvPhotoDictionaryToDbDexie().\n Applying evenSplitAlgorithm at finally';
          console.log(this.message);
        }
        finally {
          let keys = []; // original-dictionary
          let values = []; // original-dictionary
          if (this.dictionaryService.chatConversationDictionary.size > 0) {
            keys = this.dictionaryService.chatConversationDictionary.keys(); // original-dictionary
            values = this.dictionaryService.chatConversationDictionary.values(); // original-dictionary
          }        
        }
      }
    }
  }
  // ---------------------------------------------------------------
  public saveKvPhotoDictionaryToDbDexie (dictionary : Dictionary<any, any>, entityName : string, sitUserId : number) : any {
    let date = new Date();
    let model : any = {};
    let kvPhotoDictionary : Dictionary<any, any> = new Dictionary<any, any>();
    
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionary)
      && sitUserId > 0) {
      
      let values = dictionary.values();
      // debugger;
      let keys = dictionary.keys();

      // debugger;
      
      let boxNonce = SlakezSaltServiceStatic.boxSalt(JSON.stringify(dictionary));
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.box)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.nonce)) {
        // debugger;

        boxNonce.date = DateStringServiceStatic.getTicks(new Date()).toString();
        boxNonce.id = sitUserId;
        boxNonce.key = sitUserId.toString();
        boxNonce.entityName = entityName;
        boxNonce.sitUserId = sitUserId;

        model = new DexieBne();
        model.id = boxNonce.id,
          // model.bne = boxNonceEntity,
          model.bneStr = FrequentlyUsedFunctionsServiceStatic.strTobase64(JSON.stringify(boxNonce));
        model.key = boxNonce.key
        model.entityName = entityName;
        // debugger;
        // this.dbDexieService.createObjectStore(this.dbDexieService, entityName).subscribe(store => {

        //save the original dictionary if space allows, otherwise split in half and save to 1...6th dictionaries
        // -----------------------------------------------------------------------------------------------------
        try {
          // debugger;
          this.dbDexieService.updateObjectStoreData(model).subscribe(
            val => {
              this.message = 'timestamp-' + new Date().getTime() + ': saveDictionaryToIndexedDb() is successful for: ' + entityName;//  + ' with data: ' + JSON.stringify(val);
              console.log(this.message);
            }, //next callback
            error => { console.log('timestamp-' + new Date().getTime() + " :error occured while updating ObjectStore. error-message:" + error) }, //error callback
            () => { console.log('timestamp-' + new Date().getTime() + " :updating ObjectStore Completed") } //complete callback
          );

          return true;
        }
        catch {
          this.message = 'timestamp: ' + new Date().getTime() + ' : the data is too large for the ' + entityName + 'ObjectStore in saveKvPhotoDictionaryToDbDexie().\n Applying evenSplitAlgorithm at finally';
          console.log(this.message);
        }
        finally {
          //  here we divide the original-dictionary in equal-half and create two new dictionaries and save them.
          //  Note that the orifinal KvPhotoDictionary is empty now.
          // ---------------------------------------------------------------------------------------------------
          let keys = []; // original-dictionary
          let values = []; // original-dictionary
          if (this.dictionaryService.kvPhotoDictionary.size > 0) {
            keys = this.dictionaryService.kvPhotoDictionary.keys(); // original-dictionary
            values = this.dictionaryService.kvPhotoDictionary.values(); // original-dictionary
          }
        }
      }
    }
  }
  // ---------------------------------------------------------------
  public saveProfileTileDictionaryToDbDexie (dictionary : Dictionary<any, any>, entityName : string, sitUserId : number) : any {
    let date = new Date();
    let model : any = {};
    let profileTileDictionary : Dictionary<any, any> = new Dictionary<any, any>();
    
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionary)
      && sitUserId > 0) {

      let values = dictionary.values();
      // debugger;
      let keys = dictionary.keys();
      var dictionaryLength = keys.length;
      debugger;

      // debugger;

      let boxNonce = SlakezSaltServiceStatic.boxSalt(JSON.stringify(dictionary));
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.box)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.nonce)) {
        // debugger;

        boxNonce.date = DateStringServiceStatic.getTicks(new Date()).toString();
        boxNonce.id = sitUserId;
        boxNonce.key = sitUserId.toString();
        boxNonce.entityName = entityName;
        boxNonce.sitUserId = sitUserId;

        model = new DexieBne();
        model.id = boxNonce.id,
        // model.bne = boxNonceEntity,
        model.bneStr = FrequentlyUsedFunctionsServiceStatic.strTobase64(JSON.stringify(boxNonce));
        model.key = boxNonce.key
        model.entityName = entityName;
        // debugger;
        // this.dbDexieService.createObjectStore(this.dbDexieService, entityName).subscribe(store => {

        //save the original dictionary if space allows, otherwise split in half and save to 1...6th dictionaries
        // -----------------------------------------------------------------------------------------------------
        try {
           debugger;
          this.dbDexieService.updateObjectStoreData(model).subscribe(
            val => {
              this.message = 'timestamp-' + new Date().getTime() + ': saveDictionaryToIndexedDb() is successful for: ' + entityName;//  + ' with data: ' + JSON.stringify(val);
              console.log(this.message);
            }, //next callback
            error => { console.log('timestamp-' + new Date().getTime() + " :error occured while updating ObjectStore. error-message:" + error) }, //error callback
            () => { console.log('timestamp-' + new Date().getTime() + " :updating ObjectStore Completed") } //complete callback
          );

          return true;
        }
        catch {
          this.message = 'timestamp: ' + new Date().getTime() + ' : the data is too large for the ' + entityName + 'ObjectStore in saveKvPhotoDictionaryToDbDexie().\n Applying evenSplitAlgorithm at finally';
          console.log(this.message);
        }
        finally {
          //  here we divide the original-dictionary in equal-half and create two new dictionaries and save them.
          //  Note that the orifinal KvPhotoDictionary is empty now.
          // ---------------------------------------------------------------------------------------------------
          let keys = []; let values = [];
          if (this.dictionaryService.profileTileDictionary.size > 0) {
            keys = this.dictionaryService.profileTileDictionary.keys(); // original-dictionary
            values = this.dictionaryService.profileTileDictionary.values(); // original-dictionary
          }         
          
          var mod = Math.floor(keys.length / 2) as number;
          var leftHalfLength = Math.floor(keys.length / 2) as number;
          debugger;
        }
      }
    }
  }
  
  // ============================================================
  private createChatConversationIdDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('ChatConversationId', this.loginSuccess.signedInUserId.toString())
      .subscribe((chatConversationIdDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatConversationIdDictionary)) {
          // debugger;
          this.dictionaryService.chatConversationIdDictionary = JSON.parse(JSON.stringify(chatConversationIdDictionary));
          // debugger;
          console.log(this.date.getTime() + ': chatConversationIdDictionary of.size: ', this.dictionaryService.chatConversationIdDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createChatConversationIdDictionaryFromIndexedDb() was null or undefined.');
        }
      })
  }
  // ------------------------------------------------------------
  public createChatConversationDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('ChatConversation', this.loginSuccess.signedInUserId.toString())
      .subscribe((chatConversationDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatConversationDictionary)) {
          // debugger;
          this.dictionaryService.chatConversationDictionary = JSON.parse(JSON.stringify(chatConversationDictionary));
          // debugger;
          console.log(this.date.getTime() + ': ChatConversationDictionary of.size: ', this.dictionaryService.chatConversationDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createChatConversationDictionaryFromIndexedDb() was null or undefined.');
        }
      })
  }
  // ------------------------------------------------------------
  public createMyChatIdDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('MyChatId', this.loginSuccess.signedInUserId.toString())
      .subscribe((myChatIdDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myChatIdDictionary)) {
          // debugger;
          this.dictionaryService.myChatIdDictionary = JSON.parse(JSON.stringify(myChatIdDictionary));
          // debugger;
          console.log(this.date.getTime() + ': myChatIdDictionary of.size: ', this.dictionaryService.myChatIdDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createMyChatIdDictionaryFromIndexedDb() was null or undefined.');
        }
      })
  }
  // ------------------------------------------------------------
  public createMyChatDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('MyChat', this.loginSuccess.signedInUserId.toString())
      .subscribe((myChatDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myChatDictionary)) {
          // debugger;
          this.dictionaryService.myChatDictionary = JSON.parse(JSON.stringify(myChatDictionary));
          // debugger;
          console.log(this.date.getTime() + ': myChatDictionary of.size: ', this.dictionaryService.myChatDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createMyChatDictionaryFromIndexedDb() was null or undefined.');
        }
      })
  }
  
  // ============================================================
  public createEmailTileIdDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('EmailTileId', this.loginSuccess.signedInUserId.toString())
      .subscribe((emailTileIdDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(emailTileIdDictionary)) {
          // debugger;
          this.dictionaryService.emailTileIdDictionary = JSON.parse(JSON.stringify(emailTileIdDictionary));
          // debugger;
          console.log(this.date.getTime() + ': emailTileIdDictionary of.size: ', this.dictionaryService.emailTileIdDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createEmailTileIdDictionaryFromIndexedDb() was null or undefined.');
        }
      })
  }
  // ------------------------------------------------------------
  public createEmailTileDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('EmailTile', this.loginSuccess.signedInUserId.toString())
      .subscribe((emailTileDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(emailTileDictionary)) {
          // debugger;
          this.dictionaryService.emailTileDictionary = JSON.parse(JSON.stringify(emailTileDictionary));
          // debugger;
          console.log(this.date.getTime() + ': emailTileDictionary of.size: ', this.dictionaryService.emailTileDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createEmailTileDictionaryFromIndexedDb() was null or undefined.');
        }
      })
  }
  // ------------------------------------------------------------
  public createMyEmailIdDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('MyEmailId', this.loginSuccess.signedInUserId.toString())
      .subscribe((myEmailIdDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myEmailIdDictionary)) {
          // debugger;
          this.dictionaryService.myEmailIdDictionary = JSON.parse(JSON.stringify(myEmailIdDictionary));
          // debugger;
          console.log(this.date.getTime() + ': myEmailIdDictionary of.size: ', this.dictionaryService.myEmailIdDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createMyEmailIdDictionaryFromIndexedDb() was null or undefined.');
        }
      })
  }
  // ------------------------------------------------------------
  public createMyEmailDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('MyEmail', this.loginSuccess.signedInUserId.toString())
      .subscribe((myEmailDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myEmailDictionary)) {
          // debugger;
          this.dictionaryService.myEmailDictionary = JSON.parse(JSON.stringify(myEmailDictionary));
          // debugger;
          console.log(this.date.getTime() + ': myEmailDictionary of.size: ', this.dictionaryService.myEmailDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createMyEmailDictionaryFromIndexedDb() was null or undefined.');
        }
      })
  }
  
  // ============================================================
  public createEnoteTileDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('enoteTile', this.loginSuccess.signedInUserId.toString())
      .subscribe((enoteTileDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enoteTileDictionary)) {
          // debugger;
          this.dictionaryService.enoteTileDictionary = JSON.parse(JSON.stringify(enoteTileDictionary));
          // debugger;
          console.log(this.date.getTime() + ': enoteTileDictionary of.size: ', this.dictionaryService.enoteTileDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createEnoteTileDictionaryFromIndexedDb() was null or undefined.');
        }
      })
  }
  // ------------------------------------------------------------
  public createEnoteTileIdDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('enoteTileId', this.loginSuccess.signedInUserId.toString())
      .subscribe((enoteTileIdDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enoteTileIdDictionary)) {
          // debugger;
          this.dictionaryService.enoteTileIdDictionary = JSON.parse(JSON.stringify(enoteTileIdDictionary));
          // debugger;
          console.log(this.date.getTime() + ': enoteTileIdDictionary of.size: ', this.dictionaryService.enoteTileIdDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createEnoteTileIdDictionaryFromIndexedDb() was null or undefined.');
        }
      });
  }
  // ------------------------------------------------------------
  public createMyNotificationIdDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('myNotificationId', this.loginSuccess.signedInUserId.toString())
      .subscribe((myNotificationIdDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myNotificationIdDictionary)) {
          // debugger;
          this.dictionaryService.myNotificationIdDictionary = JSON.parse(JSON.stringify(myNotificationIdDictionary));
          // debugger;
          console.log(this.date.getTime() + ': myNotificationIdDictionary of.size: ', this.dictionaryService.myNotificationIdDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createMyNotificationIdDictionaryFromIndexedDb() was null or undefined.');
        }
      });
  }
  // ------------------------------------------------------------
  public createMyNotificationDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('myNotification', this.loginSuccess.signedInUserId.toString())
      .subscribe((myNotificationDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myNotificationDictionary)) {
          // debugger;
          this.dictionaryService.myNotificationDictionary = JSON.parse(JSON.stringify(myNotificationDictionary));
          // debugger;
          console.log(this.date.getTime() + ': myNotificationDictionary of.size: ', this.dictionaryService.myNotificationDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createMyNotificationDictionaryFromIndexedDb() was null or undefined.');
        }
      });
  }
  
  // ---------------------------------------------------------------
  public createSitUserDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('sitUser', this.loginSuccess.signedInUserId.toString())
      .subscribe((sitUserDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUserDictionary)) {
          // debugger;
          this.dictionaryService.sitUserDictionary = JSON.parse(JSON.stringify(sitUserDictionary));
          // debugger;
          console.log(this.date.getTime() + ': sitUserDictionary of.size: ', this.dictionaryService.sitUserDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createSitUserDictionaryFromIndexedDb() was null or undefined.');
        }
      });
  }
  // ------------------------------------------------------------
  public createSitUserIdDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('sitUserId', this.loginSuccess.signedInUserId.toString())
      .subscribe((sitUserIdDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUserIdDictionary)) {
          // debugger;
          this.dictionaryService.sitUserIdDictionary = JSON.parse(JSON.stringify(sitUserIdDictionary));
          // debugger;
          console.log(this.date.getTime() + ': sitUserIdDictionary of.size: ', this.dictionaryService.sitUserIdDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createSitUserIdDictionaryFromIndexedDb() was null or undefined.');
        }
      });
  }

  // ---------------------------------------------------------------
  public createKvPhotoDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('kvPhoto', this.loginSuccess.signedInUserId.toString())
      .subscribe((kvPhotoDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoDictionary)) {
          // debugger;
          this.dictionaryService.kvPhotoDictionary = JSON.parse(JSON.stringify(kvPhotoDictionary));
          // debugger;
          console.log(this.date.getTime() + ': kvPhotoDictionary of.size: ', this.dictionaryService.kvPhotoDictionary?.size + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createKvPhotoDictionaryFromIndexedDb() was null or undefined.');
        }
      });
  }

  // ---------------------------------------------------------------
  public createPhotoBnIdDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('PhotoBnId', this.loginSuccess.signedInUserId.toString())
      .subscribe((photoBnIdDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBnIdDictionary)) {
          this.dictionaryService.userPhotoBnIdDictionary = JSON.parse(JSON.stringify(photoBnIdDictionary));
          console.log(this.date.getTime() + ': userPhotoBnIdDictionary of.size: ', photoBnIdDictionary.size() + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createPhotoBnIdDictionaryFromIndexedDb() was null or undefined.');
        }
      });
  }
  // ---------------------------------------------------------------
  public createPreferenceDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('Preference', this.loginSuccess.signedInUserId.toString())
      .subscribe((preferenceDictionary : any) => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(preferenceDictionary)) {
          // this.dictionaryService.preferenceDictionary = StringServiceStatic.deepCloneJson(preferenceDictionary);
          // debugger;
          this.dictionaryService.preferenceDictionary = JSON.parse(JSON.stringify(preferenceDictionary));

          // debugger;
          console.log(this.date.getTime() + ': preferenceDictionary of.size: ', preferenceDictionary.size() + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createPreferenceDictionaryFromIndexedDb() was null or undefined.');
        }
      });
  }
  // ---------------------------------------------------------------
  public createProfileContentDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('ProfileContent', this.loginSuccess.signedInUserId.toString())
      .subscribe((profileContentDictionary : any) => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileContentDictionary)) {
          // debugger;
          this.dictionaryService.profileContentDictionary = JSON.parse(JSON.stringify(profileContentDictionary));

          // debugger;
          console.log(this.date.getTime() + ': profileContentDictionary of.size: ', profileContentDictionary.size() + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createProfileContentDictionaryFromIndexedDb() was null or undefined.');
        }
      });
  }
  // ---------------------------------------------------------------
  public createProfileInfoDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('ProfileInfo', this.loginSuccess.signedInUserId.toString())
      .subscribe((profileInfoDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileInfoDictionary)) {
          // this.dictionaryService.profileInfoDictionary = StringServiceStatic.deepCloneJson(profileInfoDictionary);
          // debugger;
          this.dictionaryService.profileInfoDictionary = JSON.parse(JSON.stringify(profileInfoDictionary));
          // debugger;

          console.log(this.date.getTime() + ': profileInfoDictionary of.size: ', profileInfoDictionary.size() + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createProfileInfoDictionaryFromIndexedDb() was null or undefined.');
        }
      });
  }
  // ---------------------------------------------------------------
   public createProfilePicsDictionaryFromDbDexie () : void {
     this.loginSuccess = EmitterSubjectService.getLoginSuccess();
     this.getDictionary('ProfilePics', this.loginSuccess.signedInUserId.toString())
       .subscribe((profilePicsDictionary : any) => {
         if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePicsDictionary)) {
           this.dictionaryService.profilePicsDictionary = JSON.parse(JSON.stringify(profilePicsDictionary));
           console.log(this.date.getTime() + ': profilePicsDictionary of.size: ', profilePicsDictionary.size() + 'has been created from indexedDb');
         }
         else {
           console.log(this.date.getTime() + ': result of createProfilePicsDictionaryFromIndexedDb() was null or undefined.');
         }
       });
   }
  // ---------------------------------------------------------------
  public createProfileTileDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('ProfileTile', this.loginSuccess.signedInUserId.toString())
      .subscribe((profileTileDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileTileDictionary)) {
          // debugger;
          this.dictionaryService.setProfileTileDictionary(profileTileDictionary);
          // debugger;
          console.log(this.date.getTime() + ': profileTileDictionary of.size: ', profileTileDictionary.size() + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createProfileTileDictionaryFromIndexedDb() was null or undefined.');
        }
      });
  }
  
  // ---------------------------------------------------------------
  public createProfileTileIdDictionaryFromDbDexie () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.getDictionary('ProfileTileId', this.loginSuccess.signedInUserId.toString())
      .subscribe((profileTileIdDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileTileIdDictionary)) {
          this.dictionaryService.profileTileIdDictionary = JSON.parse(JSON.stringify(profileTileIdDictionary));
          console.log(this.date.getTime() + ': profileTileIdDictionary of.size: ', profileTileIdDictionary.size() + 'has been created from indexedDb');
        }
        else {
          console.log(this.date.getTime() + ': result of createProfileTileIdDictionaryFromIndexedDb() was null or undefined.');
        }
      });
  }

  // ---------------------------------------------------------------
  // public createPhotoDictionaryFromDbDexie () : void {
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  this.getDictionaryFromDbDexieObservable('Photo', this.loginSuccess.signedInUserId.toString())
  //    .subscribe((photoDictionary : any) =>{
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoDictionary)) {
  //        this.dictionaryService.photoDictionary = StringServiceStatic.deepCloneJson(photoDictionary);
  //        console.log(this.date.getTime() + ': photoDictionary.size: ', photoDictionary.size());
  //      }
  //      else {
  //        console.log(this.date.getTime() + ': result of createPhotoDictionaryDictionaryFromIndexedDb() was null or undefined.');
  //      }
  //    });
  // }
  // ---------------------------------------------------------------
  //public createPhotoBnDictionaryFromDbDexie () : void {
   // this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  this.getDictionaryFromDbDexieObservable('PhotoBn', this.loginSuccess.signedInUserId.toString())
  //    .subscribe((photoBnDictionary : any) => {
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBnDictionary)) {
  //        this.dictionaryService.photoBnDictionary = StringServiceStatic.deepCloneJson(photoBnDictionary);
  //        console.log(this.date.getTime() + ': photoBnDictionary.size: ', photoBnDictionary.size());
  //      }
  //      else {
  //        console.log(this.date.getTime() + ': result of createPhotoBnDictionaryDictionaryFromIndexedDb() was null or undefined.');
  //      }
  //    });
  //}
  // ===============================================================
  
  // ---------------------------------------------------------------
  // ===============================================================
  private createMemberActivityDictionariesFromDbDexie (sitUserId: number): void
  {
     if ( sitUserId > 0 )
     {
       this.loginSuccess = EmitterSubjectService.getLoginSuccess();
       let activitiesDictionary: any;
       if ( sitUserId === this.loginSuccess.signedInUserId )
       {
         // debugger;
         this.tables.myMemberActivityObjectStore?.count().then(async (c : any) => {
           // debugger;
           if (c > 0) {
             this.getDictionary("myMemberActivity", this.loginSuccess.signedInUserId.toString())
               .subscribe((mActDict : any) => {
                 activitiesDictionary = mActDict;
               });
           }
         }).catch((error : any) => {
           this.message = this.date.getTime() + ' dbDexieService.db.myMemberActivityObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
           // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
           console.log(this.message);
         });
       }
       else
       {
         this.tables.yourMemberActivityObjectStore?.count().then(async (c : any) => {
           // debugger;
           if (c > 0) {

             this.getDictionary("YourMemberActivity", this.loginSuccess.signedInUserId.toString()).subscribe((mActDict : any) => {
                 activitiesDictionary = mActDict;
             })
           }
         }).catch((error : any) => {
           this.message = this.date.getTime() + ' dbDexieService.db.yourMemberActivityObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
             // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
             console.log(this.message);
           });
       }
       if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(activitiesDictionary) && activitiesDictionary.size() > 0)
       {
         this.populateMemberActivitiesDictionaries( activitiesDictionary.values() );
       }       
     }
  }
  // ---------------------------------------------------------------
  private createMemberLockingActivityDictionariesFromDbDexie ( sitUserId: number): void
  {
     if ( sitUserId > 0 )
     {
       this.loginSuccess = EmitterSubjectService.getLoginSuccess();
       let activitiesDictionary: any;
       if ( sitUserId === this.loginSuccess.signedInUserId )
       {
         this.tables.myMemberLockingActivityObjectStore?.count().then(async (c : any) => {
           // debugger;
           if (c > 0) {
             this.getDictionary("MyMemberLockingActivity", this.loginSuccess.signedInUserId.toString())
               .subscribe((mActDict : any) => {
                 activitiesDictionary = mActDict;
               })
           }
         }).catch((error : any) => {
           this.message = this.date.getTime() + ' dbDexieService.db.myMemberLockingActivityObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
           console.log(this.message);
         });
       }
       else
       {
         this.tables.yourMemberLockingActivityObjectStore?.count().then(async (c : any) => {
           // debugger;
           if (c > 0) {
             this.getDictionary('yourMemberLockingActivity', this.loginSuccess.signedInUserId.toString())
           .subscribe((mActDict : any) =>
         {
           activitiesDictionary = mActDict;
           }).catch((error : any) => {
             this.message = this.date.getTime() + ' dbDexieService.db.yourMemberLockingActivityObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
             // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
             console.log(this.message);
             // debugger;
           });
           }
         }).catch((error : any) => {
           this.message = this.date.getTime() + ' dbDexieService.db.yourMemberLockingActivityObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
           console.log(this.message);
         });
       }
       if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(activitiesDictionary) && activitiesDictionary.size() > 0)
       {
         this.populateMemberActivitiesDictionaries( activitiesDictionary.values() );
       }
     }
  }
  // ---------------------------------------------------------------
  private createMemberStarTrackWinkActivityDictionariesFromDbDexie ( sitUserId: number): void
  {
    if ( sitUserId > 0 )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      let activitiesDictionary: any;
      if ( sitUserId === this.loginSuccess.signedInUserId )
      {
        this.tables.myMemberStarTrackWinkActivityObjectStore?.count().then(async (c : any) => {
          // debugger;
          if (c > 0) {
            // debugger;
            this.getDictionary("MyMemberStarTrackWinkActivity", this.loginSuccess.signedInUserId.toString()).subscribe((mActDict : any) =>{
              activitiesDictionary = mActDict;
            })
          }
        }).catch((error : any) => {
          this.message = this.date.getTime() + ' dbDexieService.db.myMemberStarTrackWinkActivityObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
          // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
          console.log(this.message);
        });
      }
      else
      {
        this.tables.yourMemberStarTrackWinkActivityObjectStore?.count().then(async (c : any) => {
          // debugger;
          if (c > 0) {
           // debugger;
            this.getDictionary("yourMemberStarTrackWinkActivity", this.loginSuccess.signedInUserId.toString())
              .subscribe((mActDict : any) =>
            {
              activitiesDictionary = mActDict;
              })
            }
        }).catch((error : any) => {
            this.message = this.date.getTime() + ' dbDexieService.db.yourMemberStarTrackWinkActivityObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
            // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
            console.log(this.message);
        });
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(activitiesDictionary) && activitiesDictionary.size() > 0)
      {
        this.populateMemberActivitiesDictionaries( activitiesDictionary.values() );
      }
    }
  }
  // ===============================================================
  // ---------------------------------------------------------------
  //  MemberActivitiesDictionary-System:
  //  ----------------------------------
  //  MemberActivitiesDitionary(my or your) is the only dictionary that
  //  persists in the backend-DB as well as indexedDb.
  //
  //  The Block/UnblockDictionary(my or your), Like/DislikeDictionary.
  //  Friend/UnfriendDictionary are not persisted in neither backendDB
  //  or the indexedDb.
  //
  //  These Dictionaries (block/unblock, friend/unfriend, like/dislike)
  //  are for the View to render these activities related results to the user.
  //
  //  This method will create/populate these dictionaries from MemberActivitesDictionary
  //
  //  Similarly MemberLock/UnlockDictionary is persisted in the backendDB as well as
  //  indexedDb only for these activities since they consists of expiration date for the action.
  //
  //  This method will create lock/unlock Dictionaries for the View.
  //
  //  Similarly MemberStarTrackWinkDictionary is persisted on both backendDB and indexedDb,
  //  and is used here to produce the Star/Track/Wink dictionaries for the View.
  //
  //  This method will be used for both flows of dictionary data:
  //  1)  populate dictionaries: from-indexedDB-to-Dictionaries and
  //  2)  when data arrives from backendDB: from-backendDB-to-dictionaries-to-indexedDB
  // ---------------------------------------------------------------
  private populateMemberActivitiesDictionaries ( modelArr: CommunicationActivity[] ): void
  {
    // MemberActivity Dictionaries:
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.sitUser = EmitterSubjectService.getSitUserModel();   

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( modelArr ) && modelArr.length > 0 )
    {
      // this.dictionaryService.modelArrayToMemberActivityDictionary(
      //  modelArr.map( ( e ) => SlakezSaltServiceStatic.boxUnsalt( e ) as MemberActivity ),
      //  this.dictionaryService.myMemberActivityDictionary,
      // );
      const dbMyMActs = modelArr;// this.dictionaryService.myMemberActivityDictionary.values();

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dbMyMActs ) && dbMyMActs.length > 0 )
      {
        dbMyMActs.map( ( f ) =>
        {
          /*
            * ----------------------------------------------------------------------------------------------------------
            * In myDictionaries (when I do some activity on someone), the doer is non-unique signedInUserId, but doee is unique sitUserId
            * Hence, in order to insert MemberActivity into my-dictionaries, the key must be doee==sitUser.sitUserId
            * -------------------------------------------------------------------e---------------------------------------
            */
          if ( f.receiverSitUserId === this.loginSuccess.signedInUserId )
          {
            // if I am the doer in this tMAct
            if ( f.senderSitUserId === this.sitUser.sitUserId )
            {
              // if doee is this sitUser
              switch ( f.action.toLowerCase() )
              {
                case 'block':
                  if (!this.dictionaryService.myBlockDictionary.has(f.senderSitUserId ) )
                  {
                    // if this doee cannot  be found in myBlockDictionary
                    this.dictionaryService.myBlockDictionary.set(f.senderSitUserId, f );
                  } else
                  {
                    // nothing to do
                  }
                  break;
                case 'unblock':
                  if (this.dictionaryService.myBlockDictionary.has(f.senderSitUserId ) )
                  {
                    // since doee.sitUerId==key and doee is found in myBlockDictionary
                    this.dictionaryService.myBlockDictionary.remove(f.senderSitUserId );
                  } else
                  {
                    /*
                      * cannot unblock/remove a non-existing sitUser in myBlockDictionary
                      * nothing to do
                      */
                  }
                  break;
                case 'friend':
                  if (!this.dictionaryService.myFriendDictionary.has(f.senderSitUserId ) )
                  {
                    this.dictionaryService.myFriendDictionary.set(f.senderSitUserId, f );
                  } else
                  {
                    // nothing to do
                  }
                  break;
                case 'unfriend':
                  if (this.dictionaryService.myFriendDictionary.has(f.senderSitUserId ) )
                  {
                    // since doee.sitUerId==key
                    this.dictionaryService.myFriendDictionary.remove(f.senderSitUserId );
                  } else
                  {
                    /*
                      * cannot unFriend/remove a non-existing sitUser in myFriendDic
                      * nothing to do
                      */
                  }
                  break;
                case 'like':
                  //if ( !this.dictionaryService.myLikeDictionary.has( f.doeeSitUserId ) )
                  //{
                  debugger;
                  this.dictionaryService.myLikeDictionary.set(f.senderSitUserId, f );
                  //} else
                  //{
                  //  // nothing to do
                  //}
                  break;
                case 'dislike':
                  if (this.dictionaryService.myLikeDictionary.has(f.senderSitUserId ) )
                  {
                    // since doee.sitUerId==key
                    this.dictionaryService.myLikeDictionary.remove(f.senderSitUserId );
                  } else
                  {
                    /*
                      * cannot dislike/remove a non-existing sitUser in myLikeDic
                      * nothing to do
                      */
                  }
                  break;
                case 'tala': case 'lock':
                  if (this.dictionaryService.myUnlockDictionary.has(f.senderSitUserId ) )
                  {
                    this.dictionaryService.myUnlockDictionary.remove(f.senderSitUserId );
                  } else
                  {
                    /*
                      * if does not exist in myUnlockDic, there is nothing to lock
                      * nothing to do
                      */
                  }
                  break;
                case 'unlock':
                  if (!this.dictionaryService.myUnlockDictionary.has(f.senderSitUserId ) )
                  {
                    this.dictionaryService.myUnlockDictionary.set(f.senderSitUserId, f );
                  } else
                  {
                    // if this sitUser exists, check the expiry date:
                    const today = new Date();

                    if ( DateStringServiceStatic.compareDate( f.expiryDate, today.toLocaleString() ) < 0 )
                    {
                      // if expired
                      this.dictionaryService.myUnlockDictionary.remove(f.senderSitUserId ); // remove expired entry
                      this.dictionaryService.myUnlockDictionary.set(f.senderSitUserId, f ); // add new entry
                    }
                  }
                  break;
                case 'star':
                  if (!this.dictionaryService.myStarDictionary.has(f.senderSitUserId ) )
                  {
                    this.dictionaryService.myStarDictionary.set(f.senderSitUserId, f );
                  } else
                  {
                    // nothing to do
                  }
                  break;
                case 'track':
                  if (!this.dictionaryService.myTrackDictionary.has(f.senderSitUserId ) )
                  {
                    this.dictionaryService.myTrackDictionary.set(f.senderSitUserId, f );
                  } else
                  {
                    // nothing to do
                  }
                  break;
                case 'wink':
                  if (!this.dictionaryService.myWinkDictionary.has(f.senderSitUserId ) )
                  {
                    this.dictionaryService.myWinkDictionary.set(f.senderSitUserId, f );
                  } else
                  {
                    // nothing to do
                  }
                  break;
              }
            } else
            {
              /*
                * if doee is NOT this sitUser
                * nothing to do or some other logical action here
                */
            }
          } else
          {
            /*
              * if I am NOT the doer in this tMAct
              * nothing to do or some other logical action here
              */
          }
        } );
      }


      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( modelArr ) && modelArr.length > 0 )
      {
        // this.dictionaryService.modelArrayToMemberActivityDictionary(
        //  modelArr.map( ( e ) => SlakezSaltServiceStatic.boxUnsalt( e ) as MemberActivity ),
        //  this.dictionaryService.yourMemberActivityDictionary,
        // );

        const dbYourMActs = modelArr;// this.dictionaryService.yourMemberActivityDictionary.values();

        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dbYourMActs ) && dbYourMActs.length > 0 )
        {
          dbYourMActs.map( ( y ) =>
          {
            /*
              * ----------------------------------------------------------------------------------------------------------
              * In yourDictionaries (when others do some activity on my-profile), the doee is non-unique signedInUserId, but doer is unique sitUserId
              * Hence, in order to insert MemberActivity into your-dictionaries, the key must be doer==sitUser.sitUserId
              * ----------------------------------------------------------------------------------------------------------
              */
            if (y.senderSitUserId === this.loginSuccess.signedInUserId )
            {
              // if I am the doee in this tMAct
              if (y.receiverSitUserId === this.sitUser.sitUserId )
              {
                // if doer is this sitUser
                switch ( y.action.toLowerCase() )
                {
                  case 'block':
                    if (!this.dictionaryService.yourBlockDictionary.has(y.receiverSitUserId ) )
                    {
                      // if this doer cannot  be found in yourBlockDic
                      this.dictionaryService.yourBlockDictionary.set(y.receiverSitUserId, y );
                    } else
                    {
                      // nothing to do
                    }
                    break;
                  case 'unblock':
                    if (this.dictionaryService.yourBlockDictionary.has(y.receiverSitUserId ) )
                    {
                      // since doer.sitUerId==key and doer is found in yourBlockDic
                      this.dictionaryService.yourBlockDictionary.remove(y.receiverSitUserId );
                    } else
                    {
                      /*
                        * cannot unblock/remove a non-existing sitUser in yourBlockDic
                        * nothing to do
                        */
                    }
                    break;
                  case 'friend':
                    if (!this.dictionaryService.yourFriendDictionary.has(y.receiverSitUserId ) )
                    {
                      this.dictionaryService.yourFriendDictionary.set(y.receiverSitUserId, y );
                    } else
                    {
                      // nothing to do
                    }
                    break;
                  case 'unfriend':
                    if (this.dictionaryService.yourFriendDictionary.has(y.receiverSitUserId ) )
                    {
                      // since doer.sitUerId==key
                      this.dictionaryService.yourFriendDictionary.remove(y.receiverSitUserId );
                    } else
                    {
                      /*
                        * cannot unFriend/remove a non-existing sitUser in yourFriendDic
                        * nothing to do
                        */
                    }
                    break;
                  case 'like':
                    if (!this.dictionaryService.yourLikeDictionary.has(y.receiverSitUserId ) )
                    {
                      this.dictionaryService.yourLikeDictionary.set(y.receiverSitUserId, y );
                    } else
                    {
                      // nothing to do
                    }
                    break;
                  case 'dislike':
                    if (this.dictionaryService.yourLikeDictionary.has(y.receiverSitUserId ) )
                    {
                      // since doer.sitUerId==key
                      this.dictionaryService.yourLikeDictionary.remove(y.receiverSitUserId );
                    } else
                    {
                      /*
                        * cannot dislike/remove a non-existing sitUser in yourLikeDic
                        * nothing to do
                        */
                    }
                    break;
                  case 'tala': case 'lock':
                    if (this.dictionaryService.yourUnlockDictionary.has(y.receiverSitUserId ) )
                    {
                      this.dictionaryService.yourUnlockDictionary.remove(y.receiverSitUserId );
                    } else
                    {
                      /*
                        * if does not exist in myUnlockDic, there is nothing to lock
                        * nothing to do
                        */
                    }
                    break;
                  case 'unlock':
                    if (!this.dictionaryService.yourUnlockDictionary.has(y.receiverSitUserId ) )
                    {
                      this.dictionaryService.yourUnlockDictionary.set(y.receiverSitUserId, y );
                    } else
                    {
                      // if this sitUser exists, check the expiry date:
                      const today = new Date();

                      if ( DateStringServiceStatic.compareDate( y.expiryDate, today.toLocaleString() ) < 0 )
                      {
                        // if expired
                        this.dictionaryService.yourUnlockDictionary.remove(y.receiverSitUserId ); // remove expired entry
                        this.dictionaryService.yourUnlockDictionary.set(y.receiverSitUserId, y ); // add new entry
                      }
                    }
                    break;
                  case 'star':
                    if (!this.dictionaryService.yourStarDictionary.has(y.receiverSitUserId ) )
                    {
                      this.dictionaryService.yourStarDictionary.set(y.receiverSitUserId, y );
                    } else
                    {
                      // nothing to do
                    }
                    break;
                  case 'track':
                    if (!this.dictionaryService.yourTrackDictionary.has(y.receiverSitUserId ) )
                    {
                      this.dictionaryService.yourTrackDictionary.set(y.receiverSitUserId, y );
                    } else
                    {
                      // nothing to do
                    }
                    break;
                  case 'wink':
                    if (!this.dictionaryService.yourWinkDictionary.has(y.receiverSitUserId ) )
                    {
                      this.dictionaryService.yourWinkDictionary.set(y.receiverSitUserId, y );
                    } else
                    {
                      // nothing to do
                    }
                    break;
                }
              } else
              {
                /*
                  * if doer is NOT this sitUser
                  * nothing to do or some other logical action here
                  */
              }
            } else
            {
              /*
                * if I am NOT the doee in this tMAct
                * nothing to do or some other logical action here
                */
            }
          } );
        }
      }
    }
  }
  // ---------------------------------------------------------------
  //   Deprecated:
  // ---------------------------------------------------------------
  //this.dbDexieToDictionaryService.getDictionaryFromDbDexieObservable('photoBn', this.loginSuccess.signedInUserId)
  //  .subscribe((photoBnDictionary: any) => {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBnDictionary) && photoBnDictionary.values().length > 0) {
  //      this.dictionaryService.sitUserIdDictionary = StringServiceStatic.deepCloneJson(photoBnDictionary);
  //      console.log(date.getTime() + ': photoBnDictionary.size: : ', photoBnDictionary.values().length);
  //    }
  //    else {
  //      console.log(date.getTime() + ': getDictionaryFromIndexedDb();  returned an null of undefined result.');
  //    }
  //  })
  // ---------------------------------------------------------------
  //  Note: This is the only method that will save a Dictionary's
  //        values to the indexedDb.
  //
  //        Dictionary's entries are <key,value> paired. We create
  //        a 'toSaltString' with the following data of the dictionary:
  //        toSaltString = keyArr.toString () + '<%&%>' + valueArr.toString()
  //        where '<%&%>' is a delimiter to separate keyArrString from valueArrString.
  //        after salting the 'toSaltString' we store the boxNonceEntity to indexedDb.
  //
  //        Upon retrieval of any dictionary, we unsalt first, then we split the string-result
  //        by the '<%&%>' separator to get the keyArrStr and valueArrStr.
  //        After getting the arrStrs, we use JSON.parse(keyArrStr) to get keyArr.
  //        Similarly we create valueArr and put these values into the
  //        respective dictionary.
  // ---------------------------------------------------------------
  public saveDictionaryToDbDexie (dictionary : Dictionary<any, any>, entityName : string, sitUserId : number) : any {
    let date = new Date();
    let model : any = {};

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionary)
      && sitUserId > 0) {

      let boxNonce = SlakezSaltServiceStatic.boxSalt(JSON.stringify(dictionary, StringServiceStatic.getCircularReplacer()));
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.box)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.nonce)) {
        // debugger;

        boxNonce.date = DateStringServiceStatic.getTicks(new Date()).toString();
        boxNonce.id = sitUserId;
        boxNonce.key = sitUserId.toString();
        boxNonce.entityName = entityName;
        boxNonce.sitUserId = sitUserId;

        // debugger;

        //save the original dictionary
        try {
          this.dbDexieService.updateObjectStoreData(boxNonce).subscribe(
            val => {
              this.message = 'timestamp-' + new Date().getTime() + ': saveDictionaryToIndexedDb() is successful for: ' + entityName;//  + ' with data: ' + JSON.stringify(val);
              console.log(this.message);
              return true;
            }, //next callback
            error => { console.log('timestamp-' + new Date().getTime() + " :error occured while updating ObjectStore. error-message:" + error) }, //error callback
            () => { console.log('timestamp-' + new Date().getTime() + " :updating ObjectStore Completed") } //complete callback
          );
          return true;
        }
        catch {
          this.message = 'timestamp: ' + new Date().getTime() + ' : the data is too large for the object store. Applying evenSplitAlgorithm at finally';
          console.log(this.message);
        }
        finally {
          return true;
        }
      }
    }
  }

  // ---------------------------------------------------------------
  private saveDictionaryToDbDexieSchema1 (dictionary : Dictionary<any, any>, entityName : string, sitUserId : number) : any {
    let date = new Date();
    let model : any = {};
    let max = 133169152;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionary)
      && sitUserId > 0) {
      // if (entityName.toString().toLowerCase().indexOf('situserid') === -1 && entityName.toString().toLowerCase().indexOf('situser') !== -1) {
      //  debugger;
      // }

      let values = dictionary.values();
      // debugger;
      let keys = dictionary.keys();

      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(values) && values.length > 0
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(keys) && values.length == keys.length) {
        let toSaltStr = JSON.stringify(keys, StringServiceStatic.getCircularReplacer()) + "<%&%>" + JSON.stringify(values, StringServiceStatic.getCircularReplacer());
        // debugger;

        let boxNonce = SlakezSaltServiceStatic.boxSalt(toSaltStr);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.nonce)) {
          // debugger;
          let boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
          boxNonceEntity.box = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(boxNonce.box);
          boxNonceEntity.nonce = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(boxNonce.nonce);
          boxNonceEntity.date = DateStringServiceStatic.getTicks(new Date()).toString();
          boxNonceEntity.id = sitUserId;
          boxNonceEntity.key = sitUserId.toString();
          boxNonceEntity.entityName = entityName;
          boxNonceEntity.sitUserId = sitUserId;
          // debugger;

          model = new DexieBne();
          model.id = boxNonceEntity.id,
            model.bne = boxNonceEntity,
            model.bneStr = JSON.stringify(boxNonceEntity);
          model.key = boxNonceEntity.key
          model.entityName = entityName;
          debugger;
          this.dbDexieService.updateObjectStoreData(model).subscribe(data => {
            debugger;
            //requestAnimationFrame(() => {
            //  console.log(this.date.getTime() + ': saveDictionaryToIndexedDb() is successful for: ' + entityName );
            //});
            this.message = new Date().getTime() + ': saveDictionaryToIndexedDb() is successful for: ' + entityName + ' with data: ' + JSON.stringify(data);
            console.log(this.message);
            // EmitterSubjectService.emitMessage(this.message);
            return true;
          })
        }
      }
    }
  }
  // ---------------------------------------------------------------
  //saveDictionaryToIndexedDbM (dictionary : Collections.Dictionary<any, any>, entityName : string, sitUserId : number) : void {
  //  let date = new Date();
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionary)
  //    && sitUserId > 0) {
  //    // if (entityName.toString().toLowerCase().indexOf('situserid') === -1 && entityName.toString().toLowerCase().indexOf('situser') !== -1) {
  //    //  debugger;
  //    // }

  //    let values = dictionary.values();
  //    // debugger;
  //    let keys = dictionary.keys();

  //    // debugger;
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(values) && values.length > 0
  //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(keys) && values.length == keys.length) {
  //      let toSaltStr = JSON.stringify(keys, StringServiceStatic.getCircularReplacer()) + "<%&%>" + JSON.stringify(values, StringServiceStatic.getCircularReplacer());
  //      // debugger;

  //      let boxNonce = SlakezSaltServiceStatic.boxSalt(toSaltStr);
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce)
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.box)
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.nonce)) {
  //        // debugger;
  //        let boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  //        boxNonceEntity.box = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(boxNonce.box);
  //        boxNonceEntity.nonce = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(boxNonce.nonce);
  //        boxNonceEntity.date = DateStringServiceStatic.getTicks(new Date()).toString();
  //        boxNonceEntity.id = sitUserId;
  //        boxNonceEntity.key = sitUserId.toString();
  //        boxNonceEntity.entityName = entityName;
  //        boxNonceEntity.sitUserId = sitUserId;

  //        IndexedDbAngularService.saveStore(entityName, boxNonceEntity);
  //      }
  //    }
  //  }
  //}


  // ------------------------------------------------------------
  public updateDictionaryToObjectStore (dictionary : Dictionary<any, any>, entityName : string, sitUserId : number) : any {
    let model : any = {};

    let boxNonce = SlakezSaltServiceStatic.boxSalt(JSON.stringify(dictionary));

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.box)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.nonce)) {
      // debugger;

      boxNonce.date = DateStringServiceStatic.getTicks(new Date()).toString();
      boxNonce.id = sitUserId;
      boxNonce.key = sitUserId.toString();
      boxNonce.entityName = entityName;
      boxNonce.sitUserId = sitUserId;

      model = new DexieBne();
      model.id = boxNonce.id,
        // model.bne = boxNonceEntity,
        model.bneStr = FrequentlyUsedFunctionsServiceStatic.strTobase64(JSON.stringify(boxNonce));
      model.key = boxNonce.key
      model.entityName = entityName;
      // debugger;
      // this.dbDexieService.createObjectStore(this.dbDexieService, entityName).subscribe(store => {
      try {
        // debugger;
        this.dbDexieService.updateObjectStoreData(model).subscribe(
          val => {
            this.message = 'timestamp-' + new Date().getTime() + ': updateDictionaryToObjectStore() is successful for: ' + entityName;//  + ' with data: ' + JSON.stringify(val);
            console.log(this.message);
          }, //next callback
          error => { console.log('timestamp-' + new Date().getTime() + " :error occured while updating ObjectStore. error-message:" + error) }, //error callback
          () => { console.log('timestamp-' + new Date().getTime() + " :updating ObjectStore Completed") } //complete callback
        );

        return true;
      }
      catch {
        // debugger;
        this.message = 'timestamp: ' + new Date().getTime() + ' : the data is too large for ' + entityName + 'ObjectStore in updateDictionaryToObjectStore().\n Applying evenDivisionAlgorithm at finally';
        console.log(this.message);
        return false;
      }
      //finally {
      //  debugger;
      //  this.message = 'timestamp: ' + new Date().getTime() + ' : @ finally for ' + entityName + 'ObjectStore in updateDictionaryToObjectStore()';
      //  console.log(this.message);
      //}
    }
  }


  // ---------------------------------------------------------------
  // Note: This is a test case for creating a dictionary from dbDexie
  // ---------------------------------------------------------------
  public populateSitUserIdDictionariesFromIndexedDb () : any {
    let date = new Date();
    this.sitUserIdDictionaryFromDexieDbCounter++;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    // debugger;
    this.getDictionary('sitUserId', this.loginSuccess.signedInUserId.toString())
      .subscribe((data : Dictionary<number, number[]>) => {
        // debugger;
        this.dictionaryService.setSitUserIdDictionary(data);
        MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, ': sitUserIdDictionary');
        return true;
      })
  }
  // ---------------------------------------------------------------
  public populateProfileTileDictionaryFromIndexedDb () : any {
    let date = new Date();
    this.sitUserIdDictionaryFromDexieDbCounter++;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    // debugger;
    this.getDictionary('profileTile', this.loginSuccess.signedInUserId.toString())
      .subscribe((data : Dictionary<number, ProfileTile>) => {
        // debugger;

        this.dictionaryService.setProfileTileDictionary(data);
        // let pTable = this.dbDexieService.getTable('profileTile') as any;
        // debugger;
        // var dictionarySize = this.dbDexieService._db[ pTable ].count;
        // debugger;
        // console.log(date.getTime() + ': profileTileDictionary.size');

        // MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, ': profileTileDictionary.size ' + this.dictionaryService.profileTileDictionary.size());
      })
  }
  // ---------------------------------------------------------------
  private getDictionaryFromDbDexieObservable1 (entityName : string, id : number) : Observable<Dictionary<any, any>> | any {

    // debugger;
    return new Observable<Dictionary<any, any>>((subscriber) => {
      // debugger;
      let bne = new BoxNonceEntity();
      // debugger
      // Note: sitUserId can be used to check whether it is signedInUser
      let dicData : any;
      let dictionary = new Dictionary<any, any>();
      let dictionaryObserver : any;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName) && id > 0) {
        // debugger;
        this.dbDexieService.getStoreData(entityName, id.toString()).subscribe(data => { // data is a request promise
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && data.length > 0 && data.result) {
            // debugger;
            dicData = data.result;
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dicData) && dicData.Length > 0
              && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dicData[ 0 ])) {
              // debugger;

              let bne : BoxNonceEntity = new BoxNonceEntity();
              bne = JSON.parse(FrequentlyUsedFunctionsServiceStatic.base64ToStr(dicData.bneStr)) as BoxNonceEntity;
              // debugger;
              let unsalted = SlakezSaltServiceStatic.boxUnsalt(bne);
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
                // debugger;
                let parts = unsalted.split("<%&%>");
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(parts) && parts.length == 2) {
                  let keys = JSON.parse(parts[ 0 ]) as any[];
                  // debugger;
                  let values = JSON.parse(parts[ 0 ]) as any[];
                  if (keys.length == values.length) {
                    let i = 0;
                    keys.map(k => {
                      dictionary.set(k, values[ i ]);
                      i++;
                    });
                  }
                  // debugger;
                  subscriber.next(dictionary);
                  subscriber.complete();
                }
                else {
                  // debugger;
                  this.message = this.date.getTime() + ': error occured in  getDictionaryFromIndexedDb(entityName: ' + entityName + ')-1';
                  MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, this.message);
                }
              }
              else {
                // debugger;
                this.message = this.date.getTime() + ': error occured in  getDictionaryFromIndexedDb(entityName: ' + entityName + ') while unsalting. unsalted == null or undefined.-2';
                MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, this.message);
              }
            }
            else {
              // debugger;
              this.message = this.date.getTime() + ': getStoreData(entityName: ' + entityName + ') retured a null or undefined result.-3';
              MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, this.message);
            }
          }
          else {
            // debugger;
            this.message = this.date.getTime() + ': getStoreData(entityName: ' + entityName + ') retured a null or undefined result.-4';
            MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, this.message);
          }
        })
      }
      else {
        // debugger;
        this.message = this.date.getTime() + ': dbDexieService.doesStoreHaveData(entityName: ' + entityName + ') returned count === 0; -6';
        // console.log(this.message);
        MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, this.message);
      }
    })
  }
  // ========================================================================
  // ---------------------------------------------------------------
  // getDictionaryFromDbDexieObservable2 (entityName : string, key : string) : Promise<Collections.Dictionary<any, any>> | any {
  //  let bne = new BoxNonceEntity();

  //  //bne.id = id;
  //  // Note: sitUserId can be used to check whether it is signedInUser
  //  let dicData : any;
  //  let dictionary = new Collections.Dictionary<any, any>();
  //  let dictionaryObserver : any;
  //  // debugger;
  //  // if (this.dbDexieService.doesStoreHaveData(entityName)) {
  //  return new Promise<Collections.Dictionary<any, any>>((resolve, reject) => {
  //    // debugger;
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)
  //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(key)) {
  //      // debugger;
  //      bne.entityName = entityName;
  //      bne.key = key;

  //      this.dbDexieService.doesStoreHaveData(entityName)?.then((c:any) => {
  //        if (c > 0) {
  //         debugger;
  //        this.dbDexieService.getStoreData(bne).then(data => { // data is a request promise
  //           debugger;
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && data.length > 0 && data.result) {
  //            // debugger;
  //            dicData = data.result;
  //             debugger;
  //            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dicData) && dicData.Length > 0
  //              && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dicData[ 0 ])) {
  //              // debugger;
  //              let bne : BoxNonceEntity = new BoxNonceEntity();
  //              bne.box = dicData[ 0 ].bne.box;
  //              bne.nonce = dicData[ 0 ].bne.nonce;
  //              let unsalted = SlakezSaltServiceStatic.boxUnsalt(bne);
  //              // debugger;
  //              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
  //                // debugger;
  //                let parts = unsalted.split("<%&%>");
  //                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(parts) && parts.length == 2) {
  //                  let keys = JSON.parse(parts[ 0 ]) as any[];
  //                   debugger;
  //                  let values = JSON.parse(parts[ 0 ]) as any[];
  //                  if (keys.length == values.length) {
  //                    let i = 0;
  //                    keys.map(k => {
  //                      dictionary.setValue(k, values[ i ]);
  //                      i++;
  //                    });
  //                  }
  //                   debugger;
  //                  resolve(dictionary);
  //                }
  //                else {
  //                  // debugger;
  //                  this.message = this.date.getTime() + ': error occured in  getDictionaryFromIndexedDb(entityName: ' + entityName + ')-1';
  //                  reject(this.message);
  //                }
  //              }
  //              else {
  //                // debugger;
  //                this.message = this.date.getTime() + ': error occured in  getDictionaryFromIndexedDb(entityName: ' + entityName + ') while unsalting. unsalted == null or undefined.-2';
  //                reject(this.message);
  //              }
  //            }
  //            else {
  //              // debugger;
  //              this.message = this.date.getTime() + ': getStoreData(entityName: ' + entityName + ') retured a null or undefined result.-3';
  //              reject(this.message);
  //            }
  //          }
  //          else {
  //            // debugger;
  //            this.message = this.date.getTime() + ': getStoreData(entityName: ' + entityName + ') retured a null or undefined result.-4';
  //            reject(this.message);
  //          }
  //        }).catch((error) => {
  //          // debugger;
  //          this.message = this.date.getTime() + ': the promise of getStoreData () has some error; error: ' + error + '-5';
  //          reject(this.message);
  //        });
  //        }
  //        else {
  //          // debugger;
  //          this.message = this.date.getTime() + ': dbDexieService.doesStoreHaveData(entityName: ' + entityName + ') returned count === 0; -6';
  //          reject(this.message);
  //        }
  //      }).catch((err:any) => {
  //        this.message = this.date.getTime() + ': dbDexieService.doesStoreHaveData(entityName: ' + entityName + ') returned a null or undefined result; -7';
  //      })
  //    }
  //    else {
  //      // debugger;
  //      this.message = this.date.getTime() + ': entityName: ' + entityName + ', or  key: ' + key + '  were null or undefined result.-8';
  //      reject(this.message);
  //    }
  //  }).catch((error: any) => {
  //    // debugger;
  //    this.message = this.date.getTime() + ': the promise of getDictionaryFromDbDexieObservable () has some error; error: ' + error + '-9';
  //    console.log(this.message);
  //  })
    //}
    //else {
    //  this.message = this.date.getTime() + ': creating dictionary from DbDexie failed because getDictionaryFromDbDexieObservable.doesStoreHaveData(entityName: ' + entityName + ') was null or undefined.';
    //  console.log(this.message);
    //}
    /// How to retrieve the data from this observer:
    // ---------------------------------------------
    //// Call subscribe() to start listening for updates.
    //const dictionarySubscription = dictionaryObserver.subscribe({
    //  next (dictionary) {
    //    console.log('dictionary.size: : ', dictionary.size());
    //  },
    //  error (msg) {
    //    console.log('Error Getting Location: ', msg);
    //  },
    //  complete () { console.log('dictionarySubscription is done'); }
    //});

    //// Stop listening for location after 10 seconds
    //setTimeout(() => {
    //  dictionarySubscription.unsubscribe();
    //}, 10000);
  // }
  // ---------------------------------------------------------------
  // Note: All data model should get their data via this function
  // ---------------------------------------------------------------
  //getDictionaryFromIndexedDbM (entityName : string, key : string) : Promise<Collections.Dictionary<any, any>> | any {
  //  // Note: sitUserId can be used to check whether it is signedInUser

  //  let bne = new BoxNonceEntity();
  //  bne.entityName = entityName;
  //  bne.key = key;

  //  let dicData : any;
  //  let dictionary = new Collections.Dictionary<any, any>();
  //  let dictionaryObserver : any;
  //  // let mdb = IndexedDbAngularService.createDbStoreM(entityName, key);

  //  return new Promise<Collections.Dictionary<any, any>>((resolve, reject) => {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(key)) {
  //      // debugger;

  //      requestAnimationFrame(() => {
  //        console.log(this.date.getTime() + ': entityName: ' + entityName);
  //        // debugger;
  //      });

  //      // debugger;
  //      IndexedDbAngularService.getItem(bne).then(result => {
  //        dicData = result;
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dicData) && dicData.Length > 0
  //          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dicData[ 0 ])) {
  //          let bne2 : BoxNonceEntity = new BoxNonceEntity();
  //          bne2.box = dicData[ 0 ].bne.box;
  //          bne2.nonce = dicData[ 0 ].bne.nonce;
  //          let unsalted = SlakezSaltServiceStatic.boxUnsalt(bne);
  //          debugger;
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
  //            let parts = unsalted.split("<%&%>");
  //            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(parts) && parts.length == 2) {
  //              let keys = JSON.parse(parts[ 0 ]) as any[];
  //              debugger;
  //              let values = JSON.parse(parts[ 0 ]) as any[];
  //              if (keys.length == values.length) {
  //                let i = 0;
  //                keys.map(k => {
  //                  dictionary.setValue(k, values[ i ]);
  //                  i++;
  //                });
  //              }
  //              debugger;
  //              resolve(dictionary);
  //            }
  //            else {
  //              this.message = this.date.getTime() + ': error occured in  getDictionaryFromIndexedDb(entityName: ' + entityName + ')';
  //              reject(this.message);
  //            }
  //          }
  //          else {
  //            this.message = this.date.getTime() + ': error occured in  getDictionaryFromIndexedDb(entityName: ' + entityName + ') while unsalting. unsalted == null or undefined.';
  //            reject(this.message);
  //          }
  //        }
  //        else {
  //          this.message = this.date.getTime() + ': getStoreData(entityName: ' + entityName + ') retured a null or undefined result.';
  //          reject(this.message);
  //        }
  //      }).catch((error) => {
  //        // debugger;
  //        this.message = this.date.getTime() + ': the promise of getDictionaryFromIndexedDb (entityName: ' + entityName + ', key: ' + key + ') has some error; error: ' + error;

  //        requestAnimationFrame(() => {
  //          console.log(this.message);
  //        });
  //      })
  //    }
  //    else {
  //      this.message = this.date.getTime() + ': getStoreData(entityName: ' + entityName + ') retured a null or undefined result.';
  //      reject(this.message);
  //    }
  //  }).catch((error) => {
  //    // debugger;
  //    this.message = this.date.getTime() + ': the promise of getDictionaryFromIndexedDb (entityName: ' + entityName + ', key: ' + key + ') has some error; error: ' + error;

  //    requestAnimationFrame(() => {
  //      console.log(this.message);
  //    });
  //  });
  //}
  // ---------------------------------------------------------------
  // Note: All data model should get their data via this function
  // ---------------------------------------------------------------
  //getDictionaryFromIndexedDb (entityName : string, key : string) : Promise<Collections.Dictionary<any, any>>| any {
  //  // Note: sitUserId can be used to check whether it is signedInUser
  //  let bne = new BoxNonceEntity();
  //  bne.entityName = entityName;
  //  bne.key = key;

  //  let dicData : any;
  //  let dictionary = new Collections.Dictionary<any, any>();
  //  let dictionaryObserver : any;

  //  return new Promise<Collections.Dictionary<any, any>>((resolve, reject) => {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(key)) {
  //      // debugger;

  //      requestAnimationFrame(() => {
  //        console.log(this.date.getTime() + ': entityName: ' + entityName);
  //        // debugger;
  //      });

  //      // debugger;
  //      IndexedDbAngularService.getItem(bne).then(data => {
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && data.length > 0) {
  //          debugger;
  //          dicData = data.result;
  //          debugger;
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dicData) && dicData.Length > 0
  //            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dicData[ 0 ])) {
  //            let bne : BoxNonceEntity = new BoxNonceEntity();
  //            bne.box = dicData[ 0 ].bne.box;
  //            bne.nonce = dicData[ 0 ].bne.nonce;
  //            let unsalted = SlakezSaltServiceStatic.boxUnsalt(bne);
  //            debugger;
  //            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
  //              let parts = unsalted.split("<%&%>");
  //              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(parts) && parts.length == 2) {
  //                let keys = JSON.parse(parts[ 0 ]) as any[];
  //                debugger;
  //                let values = JSON.parse(parts[ 0 ]) as any[];
  //                if (keys.length == values.length) {
  //                  let i = 0;
  //                  keys.map(k => {
  //                    dictionary.setValue(k, values[ i ]);
  //                    i++;
  //                  });
  //                }
  //                debugger;
  //                resolve(dictionary);
  //                //requestAnimationFrame(() => {
  //                //  console.log('error occured in  getDictionaryFromIndexedDb(entityName: ' + entityName);
  //                //});
  //              }
  //              else {
  //                this.message = this.date.getTime() + ': error occured in  getDictionaryFromIndexedDb(entityName: ' + entityName + ')';
  //                reject(this.message);
  //              }
  //            }
  //            else {
  //              this.message = this.date.getTime() + ': error occured in  getDictionaryFromIndexedDb(entityName: ' + entityName + ') while unsalting. unsalted == null or undefined.';
  //              reject(this.message);
  //            }
  //          }
  //          else {
  //            this.message = this.date.getTime() + ': getStoreData(entityName: ' + entityName + ') retured a null or undefined result.';
  //            reject(this.message);
  //          }
  //        }
  //        else {
  //          this.message = this.date.getTime() + ': getStoreData(entityName: ' + entityName + ') retured a null or undefined result.';
  //          reject(this.message);
  //        }
  //      }).catch((error) => {
  //        // debugger;
  //        this.message = this.date.getTime() + ': the promise of getStoreData () has some error; error: ' + error;

  //        requestAnimationFrame(() => {
  //          console.log(this.message);
  //        });
  //      });
  //    }
  //    else {
  //      this.message = this.date.getTime() + ': in getDictionaryFromIndexedDb (entityName: ' + entityName + ', key: ' + key + ') either storeName or the key or both were null or undefined.';
  //      reject(this.message);
  //    }
  //  }).catch((error) => {
  //    // debugger;
  //    this.message = this.date.getTime() + ': the promise of getDictionaryFromIndexedDb (entityName: ' + entityName + ', key: ' + key + ') has some error; error: ' + error;

  //    requestAnimationFrame(() => {
  //      console.log(this.message);
  //    });
  //  });
  //}
  // ------------------------------------------------------------------------

  // ------------------------------------------------------------------------
}
