'use-strict'
import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../../models/boxNonce/boxNonceEntity.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { EmitCheckboxResult } from '../../../models/common/emitCheckboxResult.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { SpinnerModel } from '../../../models/common/spinnerModel.model';
import { UserAndPageTitle } from '../../../models/common/userAndPageTitle.model';
import { Preference } from '../../../models/profile/preference.model';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { HttpService } from '../../../services/coreServiceService/httpService.service';
import { DbServiceService } from '../../../services/dbServiceService/dbServiceService.service';
import { MemberService } from '../../../services/memberServiceService/memberService.service';
import { MemberServiceService } from '../../../services/memberServiceService/memberServiceService.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { ModuleComponentLoaderServiceStatic } from '../../../services/staticServices/commonStaticServices/moduleComponentLoaderServiceStatic.service';
import { ScrollTopServiceStatic } from '../../../services/staticServices/commonStaticServices/scrollTopServiceStatic.service';
import { SlakezSaltServiceStatic } from '../../../services/staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { InMemoryDataServiceStatic } from '../../../services/staticServices/inMemoryDataServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';


declare let jQuery: any;

declare let $: any;

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ Animations, slideInOutAnimation,
  ],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '' }
})
export class PreferenceComponent implements OnInit, OnDestroy, AfterViewInit {
  public httpService! : HttpService;
  public memberService!: MemberService;
  private rendererService!: RendererService;
  public renderer! : Renderer2;
  public redirectionService! : RedirectionService;
  // -------------------------------------------

  public boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  public click : boolean = false;
  public checkArr: any[] = [];

  // checkboxNames are same in preference model as well.
  public checkboxNames: string[] = ['lookingFor', 'relationshipPreference', 'meetingLocation', 'whenLookingToMeet', 'hobby', 'pet', 'petPeev', 'into'];
  public data : any;
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public hobbyCheckboxResult: EmitCheckboxResult = new EmitCheckboxResult();
  public hobbyCheckboxLegend = 'My hobbies  : ';
  public hobbyArr: any[] = [];
  public hobbiesGroup! : FormGroup;
  public intervalMsg: any;
  public intoCheckboxResult: EmitCheckboxResult = new EmitCheckboxResult();
  public intoCheckboxLegend = 'What I am into  : ';
  public intoArr: any[] = [];
  public isEditProfile = false;
  public isFormValid = false;
  public isFormSubmitted = false;
  public isMobilevar = false;
  public isOnLine = false;
  public isSubmitBttnFocus = false;
  public lookingForArr : any[] = [];
  public lookingForCheckboxLegend = 'What I am looking for  : ';
  public lookingForCheckboxResult : EmitCheckboxResult = new EmitCheckboxResult();
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public message = '';
  public meetingLocationArr : any[] = [];
  public meetingLocationCheckboxLegend = 'Where would you like to meet up : ';
  public meetingLocationCheckboxResult: EmitCheckboxResult = new EmitCheckboxResult();
  public minRequired = 0;
  public pagerBrdCrm : PagerBreadcrum = new PagerBreadcrum();
  public paramId = 0;
  public petArr : any[] = [];
  public petCheckboxLegend = 'What about the pets?  : ';
  public petCheckboxResult: EmitCheckboxResult = new EmitCheckboxResult();
  public petPeevArr: any[] = [];
  public petPeevCheckboxLegend = 'My petPeevs  : ';
  public petPeevCheckboxResult: EmitCheckboxResult = new EmitCheckboxResult();
  public preference: Preference = new Preference();
  public relationshipPreferenceArr: any[] = [];
  public relationshipPreferenceCheckboxLegend = 'My preferences for relationship  : ';
  public relationshipPreferenceCheckboxResult: EmitCheckboxResult = new EmitCheckboxResult();
  public returnUrl = '';
  public selectedIntosArr : any[] = [];
  public selectedIntosString = '';
  public selectedHobbiesArr: any[] = [];
  public selectedHobbiesString = '';
  public selectedLookingForsArr: any[] = [];
  public selectedLookingForsString = '';
  public selectedMeetingLocationsArr: any[] = [];
  public selectedMeetingLocationsString = '';
  public selectedPetsArr : any[] = [];
  public selectedPetsString = '';
  public selectedPetPeevsArr : any[] = [];
  public selectedPetPeevsString = '';
  public selectedRelationshipPreferencesArr: any[] = [];
  public selectedRelationshipPreferencesString = '';
  public selectedWhenLookingToMeetsArr: any[] = [];
  public selectedWhenLookingToMeetsString = '';
  
  public spinnerModel : SpinnerModel = new SpinnerModel();
  public timer : any;
  public timerArray : any[] = [];
  public userAndPageTitle : UserAndPageTitle = new UserAndPageTitle();
  public whenLookingToMeetArr : any[] = [];
  public whenLookingToMeetsStr : string = '';
  public whenLookingToMeetCheckboxLegend : string = 'When I am looking to meet up';
  public whenLookingToMeetCheckboxResult: EmitCheckboxResult = new EmitCheckboxResult();
  @ViewChild('preferenceForm', { static: false})
  preferenceForm: any;
  // ---------------------------------------------------------------
  constructor(
    private activatedRoute: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    private location: Location,
    private router : Router,
    private commonServiceService: CommonServiceService,
    private coreServiceService: CoreServiceService,
    private dbServiceService: DbServiceService,
    private memberServiceService : MemberServiceService, // private searchSortService : SearchSortService
    private rendererServiceService: RendererServiceService
  ) {
    this.redirectionService = this.commonServiceService.getRedirectionService();
    if (this.coreServiceService) {
      // this.printService = this.coreServiceService.getPrintService();
    }
    if ( this.coreServiceService ) this.httpService = this.coreServiceService.getHttpService();
    if ( this.rendererServiceService ) this.rendererService = this.rendererServiceService.getRendererService();
    if (this.memberServiceService) {
      this.memberService = this.memberServiceService.getMemberService() as MemberService;
    }
    if (this.rendererService) {
      this.renderer = this.rendererService.getRenderer();
    }
    // debugger; // TODO: check for params.id
    this.activatedRoute.params.subscribe((params) => (this.paramId = params.id));
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference) && this.paramId > 0) {
      this.preference.sitUserId = this.preference.signedInUserId = this.paramId
    }
    // debugger;
    this.getPreference();
    this.isFormValid = this.checkFormValidity();
    // ---------------------------------------------------------------
    this.setPageTitle();

    // -------------------------------------------------
    this.hobbyArr = InMemoryDataServiceStatic.hobbyArr; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.hobbies);
    this.intoArr = InMemoryDataServiceStatic.intoArr; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.intos);
    this.lookingForArr = InMemoryDataServiceStatic.lookingForArr; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.lookingFors);
    this.meetingLocationArr = InMemoryDataServiceStatic.meetingLocationArr; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.meetingLocations);
    this.petArr = InMemoryDataServiceStatic.petArr; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.pets);
    this.petPeevArr = InMemoryDataServiceStatic.petPeevArr; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.petPeevs);
    this.relationshipPreferenceArr = InMemoryDataServiceStatic.relationshipPreferenceArr; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.relationshipPreferences);
    this.whenLookingToMeetArr = InMemoryDataServiceStatic.whenLookingToMeetArr; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.whenLookingToMeets);
  }
  // ---------------------------------------------------------------
  ngOnInit() {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    /*
     * -----------------------------------------------------------
     * EmitterSubjectService.localStorageLoginSuccessEmitter
     *
     * .subscribe(result => {
     *   debugger;
     *   this.loginSuccess = result;
     *   this.determineRedirection();
     * });
     * -----------------------------------------------------------
     */
    EmitterSubjectService.emitSetBreadcrumForPageSwitch( true );
    // ----------------------------------------------------------------------------------

    // ----------------------------------------------------------------------------------
    // this.timer = setTimeout( () =>
    // {
    //  //debugger;
    //  this.mcLoader.componentName = 'profileComponentId';
    //  this.mcLoader.moduleName = 'profilem';
    //  EmitterSubjectService.emitRemoveComponent( this.mcLoader );
    // }, 500 );

    // debugger;
    // this.timerArray.push( this.timer )
    /*
     * -----------------------------------------------------------
     * debugger;
     */
    this.data = InMemoryDataServiceStatic.enums;

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.data ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.data.enums ) )
    {
      // this.hobbiesArr = this.data.enums.hobbies;// this.binarySearchService.sort(this.data.enums.hobbies);
      // this.intosArr = this.data.enums.intos;// this.binarySearchService.sort(this.data.enums.intos);
      // this.lookingForsArr = this.data.enums.lookingFors; // this.binarySearchService.sort(this.data.enums.lookingFors);
      // this.meetingLocationsArr = this.data.enums.meetingLocations; // this.binarySearchService.sort(this.data.enums.meetingLocations);
      // this.petsArr = this.data.enums.pets; // this.binarySearchService.sort(this.data.enums.pets);
      // this.petPeevsArr = this.data.enums.petPeevs; // this.binarySearchService.sort(this.data.enums.petPeevs);
      // this.relationshipPreferencesArr = this.data.enums.relationshipPreferences; // this.binarySearchService.sort(this.data.enums.relationshipPreferences);
      // this.whenLookingToMeetsArr = this.data.enums.whenLookingToMeets; // this.binarySearchService.sort(this.data.enums.whenLookingToMeets);
      // debugger;
      this.hobbyArr = this.data.hobbArr; // InMemoryDataServiceStatic.enums.hobbies;// this.binarySearchService.sort(InMemoryDataServiceStatic.enums.hobbies);
      this.intoArr = this.data.intoArr; // InMemoryDataServiceStatic.enums.intos;// this.binarySearchService.sort(InMemoryDataServiceStatic.enums.intos);
      this.lookingForArr = this.data.lookingForArr; // InMemoryDataServiceStatic.enums.lookingFors; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.lookingFors);
      this.meetingLocationArr = this.data.meetingLocationArr; // InMemoryDataServiceStatic.enums.meetingLocations; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.meetingLocations);
      this.petArr = this.data.petArr; // InMemoryDataServiceStatic.enums.pets; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.pets);
      this.petPeevArr = this.data.petPeevArr; // InMemoryDataServiceStatic.enums.petPeevs; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.petPeevs);
      // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.relationshipPreferences);
      this.relationshipPreferenceArr = this.data.relationshipPreferenceArr; // InMemoryDataServiceStatic.enums.relationshipPreferences;
      this.whenLookingToMeetArr = this.data.whenLookingToMeetArr; // InMemoryDataServiceStatic.enums.whenLookingToMeets; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.whenLookingToMeets);
    }
    /*
     * -----------------------------------------------------------
     * reset the background-image and glyphicon-menu
     */
      EmitterSubjectService.emitResetDbgi(true);
      // EmitterSubjectService.emitDisplayGlyphiconMenu([]);
    // -----------------------------------------------------------
    EmitterSubjectService.isEditProfileEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
      this.loginSuccess.isEditProfile = result as boolean;
      this.loginSuccess.isPreferenceComplete = false;
      EmitterSubjectService.setLoginSuccess(this.loginSuccess);
    });
    // -----------------------------------------------------------
    EmitterSubjectService.checkboxResultEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
      const tempResult = result as EmitCheckboxResult;
      // debugger;

      switch (tempResult.checkboxName.toLowerCase()) {
        case 'into':
          this.intoCheckboxResult = tempResult;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)) {
            this.preference.selectedIntosString = this.commaSeparatedCheckboxSelectionsString(this.intoCheckboxResult.selectedCheckboxsArr);
          }
          break;
        case 'lookingfor':
          this.lookingForCheckboxResult = tempResult;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)) {
            this.preference.selectedLookingForsString = this.commaSeparatedCheckboxSelectionsString(this.lookingForCheckboxResult.selectedCheckboxsArr);
          }
          break;
        case 'relationshippreference':
          this.relationshipPreferenceCheckboxResult = tempResult;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)) {
            this.preference.selectedRelationshipPreferencesString = this.commaSeparatedCheckboxSelectionsString(this.relationshipPreferenceCheckboxResult.selectedCheckboxsArr);
          }
          break;
        case 'meetinglocation':
          this.meetingLocationCheckboxResult = tempResult;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)) {
            this.preference.selectedMeetingLocationsString = this.commaSeparatedCheckboxSelectionsString(this.meetingLocationCheckboxResult.selectedCheckboxsArr);
          }
          break;
        case 'whenlookingtomeet':
          this.whenLookingToMeetCheckboxResult = tempResult;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)) {
            this.preference.selectedWhenLookingToMeetsString = this.commaSeparatedCheckboxSelectionsString(this.whenLookingToMeetCheckboxResult.selectedCheckboxsArr);
          }
          break;
        case 'pet':
          this.petCheckboxResult = tempResult;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)) {
            this.preference.selectedPetsString = this.commaSeparatedCheckboxSelectionsString(this.petCheckboxResult.selectedCheckboxsArr);
          }
          break;
        case 'petpeev':
          this.petPeevCheckboxResult = tempResult;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)) {
            this.preference.selectedPetPeevesString = this.commaSeparatedCheckboxSelectionsString(this.petPeevCheckboxResult.selectedCheckboxsArr);
          }
          break;
        case 'hobby':
          this.hobbyCheckboxResult = tempResult;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)) {
            this.preference.selectedHobbiesString = this.commaSeparatedCheckboxSelectionsString(this.hobbyCheckboxResult.selectedCheckboxsArr);
            // debugger;
          }
          break;
        default:
          break;
      }
      this.isFormValid = this.checkFormValidity();
      this.isFormSubmitted = false;
      this.ngAfterViewInit();
      } );

    ScrollTopServiceStatic.scrollToLocation( 'allScrollTotId', 'start' );
    this.redirectionBeforeSubmit();
  }
  // ---------------------------------------------------------------------------------
  ngAfterViewInit() {
    // debugger;
    this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.timerArray.forEach( ( timer ) => clearInterval( timer ) );

    this.resetPageTitle();
  }
  // ---------------------------------------------------------------
  cancel(): void {
    this.router.navigate(['profilem/profile/' + this.loginSuccess.signedInUserId]);
  }
  /*
   * ---------------------------------------------------------------
   * get isFormInvalid = return this.isFormValid();
   * ---------------------------------------------------------------
   */
  checkFormValidity(): boolean {
    const isTrue = !!(
      this.preference.selectedIntosString?.length > 0 &&
      this.preference.selectedLookingForsString?.length > 0 &&
      this.preference.selectedRelationshipPreferencesString?.length > 0 &&
      this.preference.selectedMeetingLocationsString?.length > 0 &&
      this.preference.selectedWhenLookingToMeetsString?.length > 0 &&
      this.preference.selectedPetsString?.length > 0 &&
      this.preference.selectedPetPeevesString?.length > 0 &&
      this.preference.selectedHobbiesString?.length > 0
    );

    return isTrue;
  }
  // ---------------------------------------------------------------
  commaSeparatedCheckboxSelectionsString(selectedArr: any[]): string {
    let str = '';

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(selectedArr) && selectedArr.length > 0) {
      selectedArr.forEach((item) => {
        str += item + ',';
      });
    }
    return str;
  }
  // ---------------------------------------------------------------
  deserializeSelectedStr(selectedStr: string): any[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(selectedStr) && selectedStr.length > 0) {
      const selections = selectedStr.split(',');

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(selections) && selections.length > 0) {
        selections.splice(selections.length - 1, 1); // remove the last empty index-value
        return selections;
      }
    }
    return [];
  }
  // ---------------------------------------------------------------
  redirectionBeforeSubmit(): void {
    /*
     * debugger;
     * Note : if isEditProfile==true, preference must be visited even when its completed before
     */
    this.loginSuccess = EmitterSubjectService.getLoginSuccess(); // get the updated data
    this.isEditProfile = EmitterSubjectService.getIsEditProfile();
    if (this.loginSuccess && this.loginSuccess.signedInUserId > 0) {
      this.mcLoader.sitUserId = this.mcLoader.id = this.loginSuccess.signedInUserId;
      this.mcLoader.router = this.router;        
      // debugger;
      if (!this.isEditProfile)
      {
        if (this.loginSuccess.isPreferenceComplete)
        {
          if (!this.loginSuccess.isProfileContentComplete ) {
            this.mcLoader.componentName = 'profileContent';
            this.mcLoader.moduleName = 'profilem';
            // debugger;
            // Note: setupMcLoderModel before fireMcLoaderRoute()!!
            // ---------------------------------------------------
            this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.loginSuccess.signedInUserId).subscribe(data => {
              this.pagerBrdCrm = data;
            });
          }
          else if (this.loginSuccess.isProfileComplete) {
            // debugger;
            this.mcLoader.componentName = 'myPhotoMgmt';
            this.mcLoader.moduleName = 'mym';
            // debugger;
            // Note: setupMcLoderModel before fireMcLoaderRoute()!!
            // ---------------------------------------------------
            this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.loginSuccess.signedInUserId).subscribe(data => {
              this.pagerBrdCrm = data;
            });
          } 
          else {
            // complete the step
            // debugger;
          }
        }
        else {
          // complete the step
          // debugger;
        }
      }
      else {                 
        // complete the step
       //  debugger;
      }      
    }
  }
  // ---------------------------------------------------------------
  redirectionAfterSubmit () : void
  {
    if ( this.loginSuccess && this.loginSuccess.signedInUserId > 0 )
    {
      if ( this.loginSuccess.isPreferenceComplete  )
      {
        this.mcLoader = new ModuleComponentLoader();
        this.mcLoader.componentName = 'profileContent';
        this.mcLoader.moduleName = 'profilem';
        this.mcLoader.sitUserId = this.loginSuccess.signedInUserId;
        this.mcLoader.router = this.router;
        // ---------------------------------------------------
        this.redirectionService.setRoute(this.mcLoader.router, this.mcLoader.componentName, this.mcLoader.sitUserId).subscribe(data => {
          this.pagerBrdCrm = data;
        });
      }
    }
  }

  // ---------------------------------------------------------------
  getPreference(): void {
    // debugger;
    if (this.preference.sitUserId > 0) {
      this.salt();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
        this.httpService
          .postObservable(
            '/Member/GetPreference',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            },
            'json2text'
          )
          .subscribe(
            (result) => {
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                const bn = result;

                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bn)
                    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bn.box) && bn.box.length > 0
                    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bn.nonce) && bn.nonce.length > 0) {
                  this.preference = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as Preference;
                  // debugger;
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference) /*&& this.preference.signedInUserId > 0*/) {
                    this.preference.isSuccess = true;
                    // debugger;
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.hobbies)) {
                      this.selectedHobbiesArr = this.deserializeSelectedStr(this.preference.hobbies);
                    }
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.intos)) {
                      this.selectedIntosArr = this.deserializeSelectedStr(this.preference.intos);
                    }
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.lookingFors)) {
                      this.selectedLookingForsArr = this.deserializeSelectedStr(this.preference.lookingFors);
                    }
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.meetingLocations)) {
                      this.selectedMeetingLocationsArr = this.deserializeSelectedStr(this.preference.meetingLocations);
                    }
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.pets)) {
                      this.selectedPetsArr = this.deserializeSelectedStr(this.preference.pets);
                    }
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.petPeeves)) {
                      this.selectedPetPeevsArr = this.deserializeSelectedStr(this.preference.petPeeves);
                    }
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.relationshipPreferences)) {
                      this.selectedRelationshipPreferencesArr = this.deserializeSelectedStr(this.preference.relationshipPreferences);
                    }
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.whenLookingToMeets)) {
                      this.selectedWhenLookingToMeetsArr = this.deserializeSelectedStr(this.preference.whenLookingToMeets);
                    }
                    // debugger;
                    this.ngAfterViewInit();
                  } else {
                    if (this.preference) {
                      this.message = 'Error occured in getPreference();\n Error-mag : ' + this.preference.message;
                      EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.message});
                    } else {
                      EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured in getPreference();\n Error-mag : '});
                    }
                  }
                } else {
                  EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured in getPreference();\n Error-mag : '});
                }
              }
            },
            (error) => {
              EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured in getPreference();\n Error-mag : ' + error.message});
            },
          );
      }
  }
    }
  // ---------------------------------------------------------------
  onMouseOut(e: any): void {
    this.isSubmitBttnFocus = false;
  }
  // ---------------------------------------------------------------
  onSubmitBttnMouseOut (e : any): void {
    if (e.type.value === 'mouseout') {
      this.isSubmitBttnFocus = false;
      // if (!(this.isFormValid = this.checkFormValidity())) { this.isFormSubmitted = false;
    } else {
      this.isSubmitBttnFocus = true;
    }
  }
  // ---------------------------------------------------------------
  resetPageTitle(): any {
    this.userAndPageTitle.pageTitle = '';
    this.userAndPageTitle.sitUserId = 0;
    // debugger;
    EmitterSubjectService.emitUserAndPageTitle(this.userAndPageTitle);
    return true;
  }
  // ---------------------------------------------------------------
  setPageTitle(): any {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.profileName) && this.loginSuccess.profileName.length > 0) {
      this.setUserPageTitle(this.loginSuccess.profileName);
    }
    else {
      this.userAndPageTitle.sitUserId = this.loginSuccess.signedInUserId;
      let msg = this.loginSuccess.isEditProfile === true ? '-: Edit ' : '-: Create';

      msg += ' Profile; Step-2 of 3 :-';
      this.userAndPageTitle.pageTitle = msg;
      this.userAndPageTitle.isPrepared = true;
      // debugger;
      EmitterSubjectService.emitUserAndPageTitle(this.userAndPageTitle);
    }
    return true;
  }
  // ---------------------------------------------------------------
  setUserPageTitle (profileName : string) : any {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileName) && profileName.length > 0) {
      this.userAndPageTitle.sitUserId = this.loginSuccess.signedInUserId;
      let msg = this.loginSuccess.isEditProfile === true ? '-: Edit ' : '-: Create';

      msg += ' Profile of <span style=\'font-size:0.75rem;\'>(</span><span class=\'grnBluGldxS\'>';
      msg += profileName + '</span><span style=\'font-size:0.75rem;\'>)</span> <br/> Step-3 of 4 :-';
      this.userAndPageTitle.pageTitle = msg;
      this.userAndPageTitle.isPrepared = true;

      // debugger;
      EmitterSubjectService.emitUserAndPageTitle(this.userAndPageTitle);
    }
    return true;
  }
  // ---------------------------------------------------------------
  salt(): void {
    // debugger;
    this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt(JSON.stringify(this.preference));
  }
  // ---------------------------------------------------------------
  showInvalidFormMessage (e : any): void {
    this.isFormValid = this.checkFormValidity();
    this.isFormSubmitted = true;
    // debugger;
    this.onSubmitBttnMouseOut(e);
   }
  // ---------------------------------------------------------------
  submitPreferenceForm(): void {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.preference.signedInUserId = this.loginSuccess.signedInUserId;
    if (this.preferenceForm.valid) {
      // debugger;
      this.click = !this.click;
      EmitterSubjectService.emitShowSpinner("Updating your Preferences...");
      this.salt();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
        this.isFormSubmitted = true;
        this.httpService
          .postObservable(
            '/Member/UpdatePreference',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            },
            'json2text'
          )
          .subscribe(
            (result : any) => {
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                const tPreference = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(result as BoxNonceEntity)) as Preference;
                // debugger;

                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPreference)) {
                  this.preference = tPreference as Preference;
                }
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference) && this.preference.signedInUserId > 0) {
                  this.preference.isSuccess = true;
                  this.loginSuccess.isPreferenceComplete = true;
                  EmitterSubjectService.setLoginSuccess(this.loginSuccess);
                  this.message = this.preference.message = 'Updating your Step-3 of 4 of your Profile (Preferences) is successful!';

                  this.ngAfterViewInit();
                  this.setMessageTimer();
                  ScrollTopServiceStatic.scrollToTop('allScrollToTopId');

                  EmitterSubjectService.emitHideSpinner(true);

                  this.redirectionAfterSubmit();
                  // debugger;
                } else {
                  if (tPreference) {
                    this.message = 'Error occured in submitPreferenceForm();\n Error-mag : ' + tPreference.message;
                    EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.message });
                  } else {
                    EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured in submitPreferenceForm();\n Error-mag : ' });
                  }
                  EmitterSubjectService.emitHideSpinner(true);
                }
              } else {
                EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured in submitPreferenceForm();\n Error-mag : ' });
                EmitterSubjectService.emitHideSpinner(true);
              }
            },
            (error) => {
              // alert ('Error occured in GetArticleContent(' + idArr[i] + ');\n Error-mag : ' + error);
              EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured in submitPreferenceForm();\n Error-mag : ' + error.message });
              EmitterSubjectService.emitHideSpinner(true);
            }
          );
      }
      else {
        EmitterSubjectService.emitHideSpinner(true);
      }
    }
  }
  // ---------------------------------------------------------------------------------
  setMessageTimer(): void {
    this.intervalMsg = setInterval(() => {
      this.message = this.preference.message = '';
      clearInterval(this.intervalMsg);
      // this.router.navigate(['/profileContent/' + this.loginSuccess.signedInUserId]);
    }, 60000);

    clearInterval(this.intervalMsg);
  }
  /*
   * ---------------------------------------------------------------
   * Note : This may not be needed, but do not delete it since it handles all cases
   */
  serializeSelections (arr: any[]): string {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr)) {
      if (arr.length > 0) {
        let arrStr = '';

        arr.map((e) => {
          arrStr += e + ',';
        });
        return arrStr;
      }
      return '';
    }
    return '';
  }
  /*
   * ---------------------------------------------------------------
   * Note : This may not be needed, but do not delete it since it handles all cases
   * Toggle selection for a given Checkbox
   */
  toggleSelection (selected : any, checkbocName : any, index : any): void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(checkbocName)) {
      switch (checkbocName.toLowerCase()) {
        case 'hobby':
          if (index > -1) {
            // If currently deselected
            this.selectedHobbiesArr.splice(index, 1);
          } else {
            // Is newly selected
            this.selectedHobbiesArr.push(selected);
          }
          this.selectedHobbiesString = this.serializeSelections(this.selectedHobbiesArr);
          break;
        case 'into':
          if (index > -1) {
            // Is currently deselected
            this.selectedIntosArr.splice(index, 1);
          } else {
            // Is newly selected
            this.selectedIntosArr.push(selected);
          }
          this.selectedIntosString = this.serializeSelections(this.selectedIntosArr);
          break;
        case 'lookingfor':
          if (index > -1) {
            // Is currently deselected
            this.selectedLookingForsArr.splice(index, 1);
          } else {
            // Is newly selected
            this.selectedLookingForsArr.push(selected);
          }
          this.selectedLookingForsString = this.serializeSelections(this.selectedLookingForsArr);
          break;
        case 'meetinglocation':
          if (index > -1) {
            // Is currently deselected
            this.selectedMeetingLocationsArr.splice(index, 1);
          } else {
            // Is newly selected
            this.selectedMeetingLocationsArr.push(selected);
          }
          this.selectedMeetingLocationsString = this.serializeSelections(this.selectedMeetingLocationsArr);
          break;
        case 'pet':
          if (index > -1) {
            // Is currently deselected
            this.selectedPetsArr.splice(index, 1);
          } else {
            // Is newly selected
            this.selectedPetsArr.push(selected);
          }
          this.selectedPetsString = this.serializeSelections(this.selectedPetsArr);
          break;
        case 'petpeev':
          if (index > -1) {
            // Is currently deselected
            this.selectedPetPeevsArr.splice(index, 1);
          } else {
            // Is newly selected
            this.selectedPetPeevsArr.push(selected);
          }
          this.selectedPetPeevsString = this.serializeSelections(this.selectedPetPeevsArr);
          break;
        case 'whenlookingtomeet':
          if (index > -1) {
            // Is currently deselected
            this.selectedWhenLookingToMeetsArr.splice(index, 1);
          } else {
            // Is newly selected
            this.selectedWhenLookingToMeetsArr.push(selected);
          }
          this.selectedWhenLookingToMeetsString = this.serializeSelections(this.selectedWhenLookingToMeetsArr);
          break;
      }
    }
  }
  // ---------------------------------------------------------------
}
