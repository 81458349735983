<span class="col-sm-2 drkRedGrnxS" style="display:inline;">{{appFeature.featureName}}</span>
<span class="col-sm-2 whiteDrkRedxS" style="display:inline;">
  <span *ngIf="appFeature.isPrimary" class="glyphicon glyphicon-ok-circle" style="font-size: medium;color: forestgreen;" aria-hidden="true"></span>
  <span *ngIf="appFeature.maxPrimaryCount.length > 0">{{appFeature.maxPrimaryCount}}</span>
</span>
<span class="col-sm-2 whiteDrkRedxS" style="display:inline;">
  <span *ngIf="appFeature.maxValuedCount.length === 0" class="glyphicon glyphicon-remove-circle" style="font-size: medium;color: darkred;" aria-hidden="true"></span>
  <span *ngIf="appFeature.maxValuedCount.length > 0">{{appFeature.maxValuedCount}}</span>
</span>
<span class="col-sm-5 whiteDrkRedxS wordwrap" style="display:inline;">{{ appFeature.description}}</span>
<hr class="hrGray" style="display:inline-block;width:98%;padding:0;margin:0 2.5rem 0 0rem;" />

