
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { PagerBreadcrum } from '../../models/breadcrum/pagerBreadcrum.model';
import { ModuleComponentLoader } from '../../models/common/moduleComponentLoader.model';
import { ModuleComponentLoaderServiceStatic } from './commonStaticServices/moduleComponentLoaderServiceStatic.service';
import { EmitterSubjectService } from './emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from './frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from './stringServiceStatic.service';



 /*
  *   --------------------------------------------------------------
  *       IMPORTANT!!:
  *       ------------
  *       The BreadCrumServiceStatic and RedirectionServieStatic are two disparate services with disparate behaviors.
  *       RedirectionServieStatic uses BreadCrumServiceStatic, but RedirectionServieStatic does NOT use BreadCrumServiceStatic
  *
  *       The setBreadcrumPage ( actionName : string, router: Router) method does NOT user RedirectionServiceStatic for it's navigation.
  *       Hence any navigational behavior that is related to RedirectionServiceStatic.fireMcLoader() method will NOT be reflected here.
  *       
  * --------------------------------------------------------------
  */
@Injectable({
  providedIn: 'root',
})
export abstract class BreadcrumServiceStatic implements OnDestroy {
  // ---------------------------------------------------------
  constructor () {
  }
  // breadcrumRing will be used for getting pagedBrdCrmArr
  // breadcrumRing will contain all routed-paths, this will only be used for adding, but can be cleared.
  public static  breadcrumRing : ModuleComponentLoader[] = [];
  public static currentPage = 0;
  public static nextPage = 0;
  public static pagerBrdCrm: PagerBreadcrum = new PagerBreadcrum();
  public static pagedBrdCrmArr : ModuleComponentLoader[] = [];
  protected static pageSize = 10;
  protected static previousPage = 0;
  protected static totalItems = 0;
  protected emitterDestroyed$ : Subject<boolean> = new Subject();
  public static loginSuccess : LoginSuccess = new LoginSuccess();
  // -----------------------------------------------------------------
  static clearRoutes () : any
  {
    this.breadcrumRing = [];
  }

  // --------------------------------------------------------------
  public static emitProfilePageSelectorBoolean (page : string) : boolean {
    // debugger;
    let isEmitted = false;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(page)) {
      if (page.toLowerCase().indexOf('myphotomgmt') !== -1) {
        EmitterSubjectService.emitIsPhotoMgmt(true);
         // debugger;
        isEmitted = true;
      }
      else if (page.toLowerCase().indexOf('memberviewmgmt') !== -1) {
        EmitterSubjectService.emitIsViewMember(true);
        // debugger;
        isEmitted = true;
      }
      else if (page.toLowerCase().indexOf('myprofileviewmgmt') !== -1) {
        EmitterSubjectService.emitIsMyProfile(true)
        // debugger;
        isEmitted = true;
      }
    }
    
    return isEmitted;
  }
  // ---------------------------------------------------------
  static getCurrentPage () : ModuleComponentLoader {
    return this.pagerBrdCrm.currentBrdCrmPage;
  }
  // ---------------------------------------------------------
  static getPreviousPage () : ModuleComponentLoader {
    return this.pagerBrdCrm.previousBrdCrmPage;
  }

  // ---------------------------------------------------------
  static getNextPage () : ModuleComponentLoader {
    return this.pagerBrdCrm.nextBrdCrmPage;
  }
  // ---------------------------------------------------------
  static getLength(): number {
    return this.breadcrumRing.length;
  }
  // ---------------------------------------------------------
  static getRing () : ModuleComponentLoader[] {
    return this.breadcrumRing;
  }
  // ---------------------------------------------------------
  static setRing ( ring : ModuleComponentLoader[]): void {
    this.breadcrumRing = ring;
  }
  // ---------------------------------------------------------
  static getPagerBreadcrum () : PagerBreadcrum
   {
     return this.pagerBrdCrm;
   }
  // ---------------------------------------------------------
  // TODO: Remove before deployment
  // ---------------------------------------------------------
  // static isValidModelComponentLoader ( mcl : ModuleComponentLoader ) : boolean
  // {
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mcl )
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mcl.componentName )
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcl.moduleName)
  //    && (mcl.id > 0 || mcl.sitUserId > 0) )
  //  {
  //    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mcl.url ) )
  //    {
  //      // this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //      mcl.url = mcl.getUrl();
  //    }
  //    return true;
  //  }
  //  else return false;
  // }
  // ---------------------------------------------------------------------------------
  static isCurrentUrlAProfilePage (url : string) : boolean {
    let isAProfilePage = false;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)) {
      if (url.toLowerCase().indexOf('memberviewmgmt') !== -1 || url.toLowerCase().indexOf('myprofileviewmgmt') !== -1 || url.toLowerCase().indexOf('myphotos') !== -1) {
        isAProfilePage = true;
      }
    }
    return isAProfilePage;
  }
  // ---------------------------------------------------------
  // Note:  this method is used to determine a component that is
  //        used for different users, such as primarily for
  //        memberViewComponent which is used to display any member
  // ---------------------------------------------------------
  //        Thia method check this condition or back-to-baack scenario
  // ---------------------------------------------------------
  static isSameComponent ( mcl : ModuleComponentLoader ) : boolean
  {
    // debugger;
    // prevent same page back to back
    if ( this.breadcrumRing.length > 0 )
    {
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 1 ] )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 1 ].moduleName )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 1 ].componentName ) )
      {
        if ( this.breadcrumRing[ this.breadcrumRing.length - 1 ].moduleName.toLowerCase().indexOf( mcl.moduleName.toLowerCase() ) !== -1 ) // if moduleName matches
        {
          if ( this.breadcrumRing[ this.breadcrumRing.length - 1 ].componentName.toLowerCase().indexOf( mcl.componentName.toLowerCase() ) !== -1 ) // if componentName matches
          {
            return true;
          }
          else
          {
            return false;
          }
        }
        else
        {
          return false;
        }
      }
      else
      {
        return false;
      }
    }
    else // for the first time/very first entry
    {
      // debugger;
      return false;
    }
  }
  // ---------------------------------------------------------
  // Note:  this method is used to determine if the component's
  //        user is the same or not, such as primarily for
  //        memberViewComponent which is used to display any member
  // ---------------------------------------------------------
  //        Thia method check this condition or back-to-baack scenario
  //        This is to be used in conjunction with isSameComponent()
  //        such as first check if it is the same component, if so then is it the same user?
  // ---------------------------------------------------------
  static isSameUser ( mcl : ModuleComponentLoader ) : boolean
  {
    // debugger;
    // prevent same page back to back
    if ( this.breadcrumRing.length > 0 )
    {
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 1 ] )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 1 ].moduleName )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 1 ].componentName ) )
      {
        if ( this.breadcrumRing[ this.breadcrumRing.length - 1 ].moduleName.toLowerCase().indexOf( mcl.moduleName.toLowerCase() ) !== -1 ) // if moduleName matches
        {
          if ( this.breadcrumRing[ this.breadcrumRing.length - 1 ].componentName.toLowerCase().indexOf( mcl.componentName.toLowerCase() ) !== -1 ) // if componentName matches
          {
            if ( mcl.sitUserId > 0 && this.breadcrumRing[ this.breadcrumRing.length - 1 ].sitUserId === mcl.sitUserId ) // if sitUserId matches
            {
              // debugger;

              return true;
            }
            else
            {
              return false;
            }
          }
          else
          {
            return false;
          }
        }
        else
        {
          return false;
        }
      }
      else
      {
        return false;
      }
    }
    else // for the first time/very first entry
    {
      // debugger;
      return false;
    }
  }
  // ---------------------------------------------------------
  static isDifferentMemberView ()
  {
    if ( this.breadcrumRing.length > 0 )
    {
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 1 ] )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 2 ] )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 1 ].moduleName )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 2 ].moduleName )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 1 ].componentName )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 2 ].componentName ) )
      {
        // if moduleName matches
        if ( this.breadcrumRing[ this.breadcrumRing.length - 1 ].moduleName.toLowerCase().indexOf( 'memberm' ) !== -1
          && this.breadcrumRing[ this.breadcrumRing.length - 2 ].moduleName.toLowerCase().indexOf( 'memberm' ) !== -1 )
        {
          // if componentName matches
          if ( this.breadcrumRing[ this.breadcrumRing.length - 1 ].componentName.toLowerCase().indexOf( 'memberViewMgmt' ) !== -1
            && this.breadcrumRing[ this.breadcrumRing.length - 2 ].componentName.toLowerCase().indexOf( 'memberViewMgmt' ) !== -1 )
          {
            // if sitUserIds do not matche
            if ( this.breadcrumRing[ this.breadcrumRing.length - 2 ].sitUserId > 0 && this.breadcrumRing[ this.breadcrumRing.length - 1 ].sitUserId > 0
              && this.breadcrumRing[ this.breadcrumRing.length - 1 ].sitUserId !== this.breadcrumRing[ this.breadcrumRing.length - 2 ].sitUserId )
            {
              // debugger;
              EmitterSubjectService.emitIsViewMember(true);
              return true;
            }
            else
            {
              return false;
            }
          }
          else
          {
            return false;
          }
        }
        else
        {
          return false;
        }
      }
      else
      {
        return false;
      }
    }
    else // for the first time/very first entry
    {
      // debugger;
      return false;
    }
  }
  // ---------------------------------------------------------
  static isPreviousPagePhotoMgmt () {
    if (this.breadcrumRing.length > 0) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.breadcrumRing[ this.breadcrumRing.length - 1 ])
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.breadcrumRing[ this.breadcrumRing.length - 2 ])
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.breadcrumRing[ this.breadcrumRing.length - 1 ].moduleName)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.breadcrumRing[ this.breadcrumRing.length - 2 ].moduleName)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.breadcrumRing[ this.breadcrumRing.length - 1 ].componentName)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.breadcrumRing[ this.breadcrumRing.length - 2 ].componentName)) {
        // if moduleName matches
        if (this.breadcrumRing[ this.breadcrumRing.length - 1 ].moduleName.toLowerCase().indexOf('memberm') !== -1
          && this.breadcrumRing[ this.breadcrumRing.length - 2 ].moduleName.toLowerCase().indexOf('memberm') !== -1) {
          // if componentName matches
          if (this.breadcrumRing[ this.breadcrumRing.length - 1 ].componentName.toLowerCase().indexOf('myphotos') !== -1
            && this.breadcrumRing[ this.breadcrumRing.length - 2 ].componentName.toLowerCase().indexOf('myphotos') !== -1) {
            // if sitUserIds do not matche
            if (this.breadcrumRing[ this.breadcrumRing.length - 2 ].sitUserId > 0 && this.breadcrumRing[ this.breadcrumRing.length - 1 ].sitUserId > 0
              && this.breadcrumRing[ this.breadcrumRing.length - 1 ].sitUserId !== this.breadcrumRing[ this.breadcrumRing.length - 2 ].sitUserId) {
              // debugger;
              EmitterSubjectService.emitIsPhotoMgmt(true);
              return true;
            }
            else {
              return false;
            }
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
    else // for the first time/very first entry
    {
      // debugger;
      return false;
    }
  }
  // ---------------------------------------------------------
  static isBackToBack (mcl : ModuleComponentLoader) : boolean
  {
    // debugger;
    // prevent same page back to back
    if ( this.breadcrumRing.length > 0 )
    {
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 1 ] )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 1 ].moduleName )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.breadcrumRing[ this.breadcrumRing.length - 1 ].componentName ) )
      {
        if ( this.breadcrumRing[ this.breadcrumRing.length - 1 ].moduleName.toLowerCase().indexOf( mcl.moduleName.toLowerCase() ) === -1 )
        {
          return false;
        }
        else if ( this.breadcrumRing[ this.breadcrumRing.length - 1 ].componentName.toLowerCase().indexOf( mcl.componentName.toLowerCase() ) === -1 )
        {
          return false;
        }
        else if ( this.breadcrumRing[ this.breadcrumRing.length - 1 ].sitUserId !== mcl.sitUserId )
        {
          // debugger;
          return false;
        }
        else
        {
          // debugger;
          return true;
        }
      }
      else
      {
        return false;
      }
    }
    else // for the first time/very first entry
    {
      // debugger;
      return false;
    }
  }
  // ---------------------------------------------------------
  //  Note: if mcl is valid, then PagerBreadCrum will be returned,
  //        else it will return an empty pagerBreadCrum.
  //        Also, this is the only place where breadcrumRing is updated.
  // ---------------------------------------------------------
  static setNewPath ( mcl : ModuleComponentLoader ) : PagerBreadcrum
  {
    // this.breadcrumRing = ( this.emitterService as EmitterService ).getBreadcrumRing();
    if (ModuleComponentLoaderServiceStatic.isValidModuleComponentLoader( mcl ) )
    {
      // debugger;
      // prevent same page back to back
      if ( this.breadcrumRing.length > 0 )
      {
        let isBtb = this.isBackToBack( mcl );

        // debugger;
        if ( !isBtb )
        {
          // debugger;
          this.pagerBrdCrm = this.updateBreadcrumRing( mcl );
        }
      }
      else // for the first time/very first entry
      {
        // debugger;
        this.pagerBrdCrm = this.updateBreadcrumRing( mcl );
      }
    }
    else // mcl was returned, which is most likely invalid, meaning lacks either moduleName or componentName
    {
      // debugger;
      let tMcl = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(mcl); 
      this.pagerBrdCrm = this.updateBreadcrumRing(tMcl);
    }
    // debugger;
    return this.pagerBrdCrm;
  }
  // ---------------------------------------------------------
  static addUpdate ( kvAny : ModuleComponentLoader): PagerBreadcrum {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvAny) && kvAny.id > -1 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvAny.url)) {
      const len = this.breadcrumRing.length;

      if (len === 0) {
        /*
         * Empty-Case:
         * step-3-A:
         * ==========
         * debugger;
         * var startPage = new BrdCrmKvAny();
         * startPage.date = kvAny.date.toString();
         * startPage.id = 1;
         * startPage.pageNo = 1; //This implies that index does not start at '0' but at '1'
         * startPage.returnUrl = '/';
         * startPage.value = kvAny.returnUrl;
         * this.breadcrumRing.push(startPage);
         */

        kvAny.index = 0; // This implies that index does not start at '0' but at '1'; in pagerBrdCrm 'index'=='page'
        this.breadcrumRing.push(kvAny);
        // debugger;

        // set the pagerBrdCrm
        this.pagerBrdCrm = this.setBreadcrumPager( kvAny.index); // Note: Pager is set with 'page'=='index'
      } else {
        /*
         * Not-Empty-Case:
         * step-3-B-i:
         * ============
         * debugger;
         */

        // Search-Case:
        let tempCrumPage = this.searchByValue(kvAny.url);

        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempCrumPage)) {
          tempCrumPage = this.searchById(kvAny.id);
        }

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempCrumPage)) {
          this.breadcrumRing[ tempCrumPage.index ].id = kvAny.id; // since 'pageNo' does not start at '0' but at '1'
          this.breadcrumRing[ tempCrumPage.index ].url = kvAny.url;

          // set the pagerBrdCrm
          this.pagerBrdCrm = this.setBreadcrumPager( tempCrumPage.index);
          // debugger;
        } else {
          /*
           * Not-Found-Case:
           * step-3-B-ii:
           * =============
           * debugger;
           */

          kvAny.index = this.breadcrumRing.length + 1;
          this.breadcrumRing.push(kvAny);

          // set the pagerBrdCrm
          this.pagerBrdCrm = this.setBreadcrumPager(kvAny.index);
          // debugger;
        }
      }
    }
    // debugger;
    return this.pagerBrdCrm;
  }
  /*
   * =========================================================
   * ---------------------------------------------------------
   */
  static searchById ( id : number ) : ModuleComponentLoader {
    // debugger;
    let found : any;

    if (this.breadcrumRing && this.breadcrumRing.length > 0 && id >= 0) {
      let i = 0;

      this.breadcrumRing.forEach((e) => {
        if (e && e.id === id) {
          found = e;
          found.index = i;
        }
        i++;
      });
    }
    // debugger;
    return found;
  }
  // ---------------------------------------------------------
  static searchByIndex ( index : number ) : ModuleComponentLoader {
    let found;

    if (this.breadcrumRing && this.breadcrumRing.length > 0 && index >= 0) {
      found = this.breadcrumRing[index];
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(found)) {
        found.index = index;
        return found;
      }
    }
    return null as any;
  }
  // ---------------------------------------------------------
  static searchByKey ( key : string ) : ModuleComponentLoader {
    let found : any;

    if (this.breadcrumRing && this.breadcrumRing.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(key)) {
      this.breadcrumRing.map((e) => {
        if (e && e.componentName === key) {
          found = e;
        }
      });
    }
    return found;
  }
  // ---------------------------------------------------------
  static searchByPageNo ( page : number ) : ModuleComponentLoader {
    let found : any;

    if (this.breadcrumRing && this.breadcrumRing.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(page)) {
      this.breadcrumRing.map((e) => {
        if (e && e.index === page) {
          found = e;
        }
      });
    }
    return found;
  }

  // ---------------------------------------------------------
  static searchByUrl ( url : string ) : ModuleComponentLoader {
    let found : any;

    if (this.breadcrumRing && this.breadcrumRing.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)) {
      this.breadcrumRing.map((e) => {
        if (e && e.url === url) {
          found = e;
        }
      });
    }
    return found;
  }
  // ---------------------------------------------------------
  static searchByValue ( url : string ) : ModuleComponentLoader {
    let found : any;
    // debugger;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.breadcrumRing) && this.breadcrumRing.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)) {
      this.breadcrumRing.map((e) => {
        if (e && e.url === url) {
          found = e;
        }
      });
    }
    return found;
  }
  /*
   * ---------------------------------------------------------
   * Note: !!!!Caution!!!
   *       This index is should be the index of item in breadcrumRing array.
   *       DO NOT use this method if not sure about the index of item in array.
   * ---------------------------------------------------------
   */
  static removeByIndex ( index : number ) : ModuleComponentLoader[] {
    if (this.breadcrumRing && this.breadcrumRing.length > 0 && index >= 0) {
      const tKvAny = this.breadcrumRing[index];

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tKvAny)) {
        return this.breadcrumRing.splice(index, 1);
      }
    }
    return null as any;
  }
  // ---------------------------------------------------------
  static removeById ( id : number ) : ModuleComponentLoader[] {
    if (this.breadcrumRing && this.breadcrumRing.length > 0 && id >= 0) {
      const kvAny = this.searchById(id);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvAny) && kvAny.index >= 0) {
        return this.breadcrumRing.splice(kvAny.index, 1);
      }
    }
    return null as any;
  }
  // ---------------------------------------------------------
  // removeByKey ( key : string ) : ModuleComponentLoader[] {
  //  if (this.breadcrumRing && this.breadcrumRing.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(key)) {
  //    const kvAny = this.searchByKey(key);

  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvAny) && kvAny.key && kvAny.key.length >= 0 && kvAny.index >= 0) {
  //      return this.breadcrumRing.splice(kvAny.index, 1);
  //    }
  //  }
  //  return null as any;
  // }
  // ---------------------------------------------------------
  static removeByValue ( url : string ) : ModuleComponentLoader[] {
    if (this.breadcrumRing && this.breadcrumRing.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)) {
      const kvAny = this.searchByValue(url);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvAny) && kvAny.index >= 0) {
        return this.breadcrumRing.splice(kvAny.index, 1);
      }
    }
    return null as any;
  }
  /*
   * --------------------------------------------------------------
   * Use Case: User-action -->setBreadcrum() -->this.router.navigate()
   * -->this.router.events --> setup a new page if necessary --> setCurrentPage();
   *
   * Note: This method should (/NOT) update the ParerBreadcrum or breadcrumRing.
   *       It only traverses over breadcrumRing entries.
   *
   *       IMPORTANT!!:
   *       ------------
   *       The method does NOT user RedirectionServiceStatic for routing it's navigation.
   *       Hence any navigational behavior that is related to RedirectionServiceStatic.fireMcLoader() method
   *       will NOT be reflected here.
   *
   *       the BreadCrumServiceStatic and RedirectionServieStatic are two disparate services with disparate behaviors.
   *       RedirectionServieStatic uses BreadCrumServiceStatic, but RedirectionServieStatic does NOT user BreadCrumServiceStatic
   * --------------------------------------------------------------
   */
  public static setBreadcrumPage ( actionName : string, router: Router) : PagerBreadcrum
  {
    let brdCrmPager : any;
    // step-1:
    // debugger;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(actionName)) {
      if (actionName.toString().toLowerCase().indexOf('prev') !== -1) {
        brdCrmPager = this.setBreadcrumPager(this.pagerBrdCrm.previousPage);
        // debugger;
      } else if (actionName.toString().toLowerCase().indexOf('next') !== -1) {
        brdCrmPager = this.setBreadcrumPager(this.pagerBrdCrm.nextPage);
        // debugger;
      }
    }

    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(brdCrmPager) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(brdCrmPager.currentBrdCrmPage)) {
      // debugger;
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(brdCrmPager.currentBrdCrmPage.url)) {
        // debugger;
        brdCrmPager.currentBrdCrmPage.url = brdCrmPager.currentBrdCrmPage.path();
      }
      // debugger;
      router.navigate([ brdCrmPager.currentBrdCrmPage.url ]);
    }
    // NOTE: the following may be incorrect! Watch the pager action!!
    else  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerBrdCrm)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerBrdCrm.currentBrdCrmPage)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerBrdCrm.currentBrdCrmPage.url)) {
        // debugger;
        router.navigate([ this.pagerBrdCrm.currentBrdCrmPage.url ]);   
    }

    let mcLoader = EmitterSubjectService.getMCLoader();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader.url)
      && !this.isCurrentUrlAProfilePage(mcLoader.url)) {

      EmitterSubjectService.setIsMyProfile(false);
      EmitterSubjectService.setIsPhotoMgmt(false);
      EmitterSubjectService.setIsViewMember(false);
    }

    // debugger;
    // NOTE: the following may be incorrect! Watch the pager action!!
    return this.pagerBrdCrm;

  }
  // ---------------------------------------------------------
  // Note: This method should NOT update the emitterService.breadCrumPager
  //       Only the caller(s) of this method should do that.
  // ---------------------------------------------------------
  static setBreadcrumPager(page: number): PagerBreadcrum {
    /*
     * step-4:
     * =======
     * debugger;
     */

    if (page < 1) {
      return null as any;
    }
    this.currentPage = page > 0 ? page : 1;

    this.pageSize = 10;

    if (this.breadcrumRing && this.breadcrumRing.length > 0) {
      this.totalItems = this.breadcrumRing.length;

      /*
       * get pager object
       * (totalItems, currentPage, pageSize):
       */
      this.pagerBrdCrm = this.GetPager(this.totalItems, this.currentPage, this.pageSize > 0 ? this.pageSize : 10);
      this.pagerBrdCrm.breadcrumRing = this.breadcrumRing;

      /*
       * get current items of page:
       */
      // debugger;
      this.pagedBrdCrmArr = this.breadcrumRing.slice( this.pagerBrdCrm.pagedStartIndex, this.pagerBrdCrm.pagedEndIndex + 1);
      if ( this.pagedBrdCrmArr && this.pagedBrdCrmArr.length > 0) {
        this.pagerBrdCrm.pagedBrdCrmArr = this.pagedBrdCrmArr;
      }
    }
    // debugger;
    return this.pagerBrdCrm;
  }

  // ---------------------------------------------------------
  static updateBreadcrumRing (mcl : any) : any {
    if (ModuleComponentLoaderServiceStatic.isValidModuleComponentLoader(mcl)) {
      // debugger;
      mcl.index = this.breadcrumRing.length;

      this.breadcrumRing.push(mcl);
      this.pagerBrdCrm = this.setBreadcrumPager(mcl.index + 1);
      // debugger;
    }
    else {
      this.pagerBrdCrm = this.getPagerBreadcrum();
    }
    return this.pagerBrdCrm;
  }
  // =====================================================================================================================================
  //  Begin of Pager-related-functions:
  // -------------------------------------------------------------------------------------------------------------------------------------
  // Note: the original pager was like this: <| <<| current |>> |> where <<| is previous and |>> is next and <| is first and |> is last
  //        However, here for pagerBreadcrum, we are using <| as previous and |> as next, hence it's missing the first and last steps.
  //        The view needs to compansate this difference by adding the following logic:
  //        if (pagerBrdCrm.breadcrumRing.length >= 2)  then show previous
  //        if (pagerBrdCrm.breadcrumRing.length >= 3) then show next
  // -------------------------------------------------------------------------------------------------------------------------------------
  static GetPager (totalItems : any, currentPage : any, pageSize : any) {
    // default to first page
    this.currentPage = currentPage > 0 ? currentPage : 1;
    this.totalItems = totalItems > 0 ? totalItems : 1;
    // default page size is 10
    this.pageSize = pageSize > 0 ? pageSize : 10;

    let totalPagedBlocks = 1;
    // calculate total pages
    if ( this.totalItems > this.pageSize )
    {
      totalPagedBlocks = Math.ceil( this.totalItems / this.pageSize ); // how many block of paged==currently-displayed-pages
    }

    let pagedStartPage; // paged==currently-displayed-pages
    let pagedEndPage;

    if ( totalItems <= 10) {
      // less than 10 total pages so show all
      pagedStartPage = 1;
      pagedEndPage = totalItems;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (this.currentPage <= 6) {
        pagedStartPage = 1;
        pagedEndPage = 10;
      } else if ( this.currentPage + 4 >= totalPagedBlocks) {
        pagedStartPage = totalPagedBlocks - 9;
        pagedEndPage = totalPagedBlocks;
      } else {
        pagedStartPage = this.currentPage - 5;
        pagedEndPage = this.currentPage + 4;
      }
    }

    // create an array of pages to ng-repeat in the pager control
    const pages = this.range( pagedStartPage, pagedEndPage, 1 );
    this.pagerBrdCrm.pagedPages = pages;



    // Original version:
    // -----------------
    // var startIndex = ( currentPage - 1 ) * pageSize;
    // var endIndex = Math.min( startIndex + pageSize - 1, totalItems - 1 )

    /*
     * calculate start and end item indexes
     * Updated version:
     * ------------------------------------
     */
    // const startIndex = totalItems <= this.pageSize ? this.currentPage - 1 : this.currentPage > this.pageSize ? (this.currentPage - 1) * this.pageSize : 0;
    // const endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1 : this.pageSize), this.totalItems > 1 ? this.totalItems - 1 : this.totalItems);


    /*
     * original version with modification:
     * -----------------------------------
     */
    var startIndex = this.currentPage <= this.pageSize ? 0 : ( this.currentPage - 1 ) * this.pageSize;
    var endIndex = Math.min(startIndex + this.pageSize - 1,  this.totalItems - 1);




    // return object with all the pager properties required by the view
    this.pagerBrdCrm = new PagerBreadcrum();
    this.pagerBrdCrm.totalItems = this.totalItems;
    this.pagerBrdCrm.firstPage = 1;
    this.pagerBrdCrm.lastPage = this.totalItems;

    this.pagerBrdCrm.currentPage = this.currentPage;
    this.pagerBrdCrm.currentBrdCrmPage = this.breadcrumRing[ this.currentPage - 1 ];

    this.pagerBrdCrm.previousPage = this.currentPage - 1 >= 1 ? this.currentPage - 1 : 1;
    if ( this.pagerBrdCrm.previousPage >= 1 )
    {
      this.pagerBrdCrm.previousBrdCrmPage = this.breadcrumRing[ this.pagerBrdCrm.previousPage - 1 ];
    }
    else
    {
      this.pagerBrdCrm.previousBrdCrmPage = this.breadcrumRing[0];
    }

    this.pagerBrdCrm.nextPage = this.currentPage + 1 <= totalItems ? this.currentPage + 1 : totalItems;
    if ( this.pagerBrdCrm.nextPage <= this.totalItems )
    {
      this.pagerBrdCrm.nextBrdCrmPage = this.breadcrumRing[ this.pagerBrdCrm.nextPage - 1 ];
    }
    else
    {
      this.pagerBrdCrm.nextBrdCrmPage = this.breadcrumRing[ this.breadcrumRing.length - 1];
    }

    this.pagerBrdCrm.pageSize = this.pageSize;
    this.pagerBrdCrm.totalPagedBlocks = totalPagedBlocks;
    this.pagerBrdCrm.pagedStartPage = pagedStartPage;
    this.pagerBrdCrm.pagedEndPage = pagedEndPage;
    this.pagerBrdCrm.pagedStartIndex = startIndex;
    this.pagerBrdCrm.pagedEndIndex = endIndex;
    // debugger;
    return this.pagerBrdCrm;
  }
  /*
   * --------------------------------------------------
   * --------------------------------------------------
   */
  static range (min : any, max : any, delta : any) {
    let arr : number[] = [];
    let stepCount;
    let i = 0;

    if (min && max && !delta) {
      stepCount = max - min;
      for (let i = 0; i <= stepCount; i++) {
        arr.push(i + min);
      }
    } else {
      stepCount = Math.floor((max - min) / delta);
      for (let i = 0; i <= stepCount; i++) {
        arr.push(i * delta + min);
      }
    }
    return arr;
  }
  /*
   * ---------------------------------------------------------
   * This method is deprecated.
   *
   * Note: page is set by the angular navigation as well as other methods
   * ----------------------------------------------------------
   */
  static setCurrentPage ( page : any ) : ModuleComponentLoader {
    if (page > 0) {
      // page is a number
      let tCurrentCrumPage: any;
      /*
       * step-5:
       * =======
       * debugger;
       */

      // get the currentCrumPage
      if (page <= this.breadcrumRing.length) {
        /*
         * 'page' starts at '1' not '0' in pagerBrdCrm
         * step-5-A:page == id set by navigation:
         * ======================================
         * debugger;
         */

        tCurrentCrumPage = this.searchByIndex(page);

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tCurrentCrumPage) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerBrdCrm)) {
          this.pagerBrdCrm.currentBrdCrmPage = tCurrentCrumPage;

          // get the preiousCrumPage
          if (page - 1 >= 0) {
            this.pagerBrdCrm.previousBrdCrmPage = this.searchByIndex(page - 1);
          } else {
            this.pagerBrdCrm.previousBrdCrmPage = this.breadcrumRing[0];
          }

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerBrdCrm.previousBrdCrmPage)) {
            this.pagerBrdCrm.previousPage = this.pagerBrdCrm.previousBrdCrmPage.index;
          }

          // get the nextCrumPage
          if (page + 1 <= this.breadcrumRing.length) {
            this.pagerBrdCrm.nextBrdCrmPage = this.searchByIndex(page + 1);
          } else {
            this.pagerBrdCrm.nextBrdCrmPage = null as any;
          }

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerBrdCrm.nextBrdCrmPage)) {
            this.pagerBrdCrm.nextPage = this.pagerBrdCrm.nextBrdCrmPage.index;
          }
        }
      }
    } else if (page.id > -1 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(page.value)) {
      /*
       * page is KvAny
       * step-5-B:using page.value:
       * ==========================
       * debugger;
       */

      // set the currentCrumPage
      const tCurrentCrumPage = this.searchByValue(page.value);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tCurrentCrumPage) && tCurrentCrumPage.index > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerBrdCrm)) {
        this.pagerBrdCrm.currentBrdCrmPage = tCurrentCrumPage;

        // get the previousCrumPage
        if (tCurrentCrumPage.index - 1 >= 1) {
          this.pagerBrdCrm.previousBrdCrmPage = this.searchByIndex(tCurrentCrumPage.index - 1);
        } else {
          this.pagerBrdCrm.previousBrdCrmPage = this.breadcrumRing[0];
        }

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerBrdCrm.previousBrdCrmPage)) {
          this.pagerBrdCrm.previousPage = this.pagerBrdCrm.previousBrdCrmPage.index;
        }

        // get the nextCrumPage
        if (tCurrentCrumPage.index + 1 <= this.breadcrumRing.length) {
          this.pagerBrdCrm.nextBrdCrmPage = this.searchByIndex(tCurrentCrumPage.index + 1);
        } else {
          this.pagerBrdCrm.nextBrdCrmPage = null as any;
        }

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerBrdCrm.nextBrdCrmPage)) {
          this.pagerBrdCrm.nextPage = this.pagerBrdCrm.nextBrdCrmPage.index;
        }
      }
    }
    if (this.pagerBrdCrm.currentBrdCrmPage && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerBrdCrm.currentBrdCrmPage.url)) {
      /*
       * step-5-C:Check if currentCrumPage is valid:
       * ===========================================
       * debugger;
       */

      return this.pagerBrdCrm.currentBrdCrmPage;
    }
    // debugger;
    return null as any;
  }
  // =====================================================================================================================================
  //  End of Pager-related-functions:
  // -------------------------------------------------------------------------------------------------------------------------------------
  ngOnDestroy () : void
  {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
  }
}
