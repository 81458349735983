'use-strict'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { MessageModel } from '../../../models/common/messageModel.model';
import { UserAndPageTitle } from '../../../models/common/userAndPageTitle.model';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';

@Component({
  selector: 'app-message-board',
  templateUrl: './messageBoard.component.html',
  styleUrls: [ './messageBoard.component.scss' ],
  animations: [ Animations, slideInOutAnimation ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageBoardComponent implements OnInit, OnDestroy, AfterViewInit
{
  public deviceInfo : any;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public isMobilevar = false;
  public isPhotoMgmt = false;
  public isSuccess = false;
  public isViewMember = false;
  public index = -1;
  public loginSuccess : LoginSuccess = new LoginSuccess();
  @Input() messageModel : MessageModel = new MessageModel();
  public message = '';
  public messages : string[] = [];
  public pageTitle = '';
  public sitUser : SitUser = new SitUser();
  public signedInUserId = 0;
  public timer : any;
  public timerMap : Map<any,any> = new Map();
  public userAndPageTitle : UserAndPageTitle = new UserAndPageTitle();
  constructor (private cdr : ChangeDetectorRef,)
  {
  }

   //  -------------------------------------------------------------------------
  ngOnInit () : void
  {
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    //  -------------------------------------------------------------------------
    EmitterSubjectService.clearMessageEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
    {
      // debugger;
      this.message = '';
      this.messageModel = new MessageModel();
      //this.timerMap.forEach(t => clearTimeout(t));
      //this.timerMap.clear();
      this.ngAfterViewInit();
    } );

    //  -------------------------------------------------------------------------
    EmitterSubjectService.messageEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result: any ) =>
    {
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) )
      {
        // debugger;
        if (result instanceof MessageModel) {
          this.messageModel = result;
          this.message = result.message;
          this.isSuccess = result.isSuccess;
          this.resetMessageTimer(10000);
        }
        else {
          this.message = this.messageModel.message = result as string;
          this.isSuccess = false;
          this.resetMessageTimer(15000);
        }
      }
      });
    //  -------------------------------------------------------------------------
    this.cdr.detectChanges();
  }
  //  ---------------------------------------------------------------------------------
  ngAfterViewInit () : void
  {
    this.resetMessageTimer(1500);
  }
  //  ---------------------------------------------------------------
  ngOnDestroy (): void
  {
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.timerMap.forEach( ( timer ) => clearInterval( timer ) );
  }
  // ---------------------------------------------------------------------------------

  public resetMessageTimer (interval: number) : any
  {
    // debugger;
    this.timer = setTimeout( () =>
    {
      this.message = '';
      this.messageModel = new MessageModel();
      this.messageModel.message = this.message;
      //clearTimeout(this.timer);
      //if (this.timerMap.has(this.timer)) {
      //  this.timerMap.delete(this.timer);
      //}
      // debugger;

    }, interval && interval > 0 ? interval : 15000);
    return true;
  }
  // ----------------------------------------------------------------
}
