
// import { OVERLAY_PROVIDERS } from '@angular/cdk/overlay';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, RouterOutlet } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { ServiceWorkerModule } from '@angular/service-worker';
// import { Idle } from '@ng-idle/core';
// import { Keepalive } from '@ng-idle/keepalive';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { environment } from '../environments/environment';
import { AppServiceService } from '../services/appServiceService/appServiceService.service';
import { SWUpdateService } from '../services/appServiceService/swUpdateService.service';
import { CommonServiceService } from '../services/commonServiceService/commonServiceService.service';
import { CoreServiceService } from '../services/coreServiceService/coreServiceService.service';
import { DbServiceService } from '../services/dbServiceService/dbServiceService.service';
import { ActivityService } from '../services/memberServiceService/activityService.service';
import { MemberServiceService } from '../services/memberServiceService/memberServiceService.service';
import { PagerServiceService } from '../services/pagerServiceService/pagerServiceService.service';
import { ProfileServiceService } from '../services/profileServiceService/profileServiceService.service';
import { ProfileTileServiceService } from '../services/profileTileServiceService/profileTileServiceService.service';
import { RendererServiceService } from '../services/rendererServiceService/rendererServiceService.service';
import { SearchSortServiceService } from "../services/searchSortServiceService/searchSortServiceService.service";
// import { IDLE_TOKEN, KEEPALIVE_TOKEN } from '../services/staticServices/constantServiceStatic.service';
import { SignalRService } from '../services/communicationServiceService/signal-r.service.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppComponentsModule } from './appComponentsModule/appComponents.module';
import { AppFeatureModule } from './appFeatureModule/appFeature.module';
import { DirectiveFilterPipeModule } from './directiveFilterPipeModule/directiveFilterPipe.module';
import { ImageUploadModule } from "./imageUploadModule/imageUpload.module";
import { ModalModule } from './modalModule/modal.module';
import { PagerModule } from './pagerModule/pager.module';
import { SharedModule } from "./sharedModule/shared.module";
import { DateValueAccessor, DateValueAccessorModule } from 'angular-date-value-accessor';

// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { AngularIndexedDB } from "../assets/js/angular2-indexeddb/angular2-indexeddb";
// import { ROUTER_PROVIDERS, ROUTER_DIRECTIVES } from 'angular2/router'

@NgModule({
  
  imports: [   
    // Angular-modules:
    BrowserModule, // only for AppModule
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    RouterOutlet,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),

    // 3rd-party-modules
    // AngularMyDatePickerModule,

    // https://stackblitz.com/edit/angular-date-input?file=src%2Fapp%2Fapp.module.ts
    DateValueAccessor, 

    HammerModule,
    // MatProgressSpinnerModule,
    // NgxIndexedDBModule.forRoot(dbConfig),

    // Custom-lazy-modules:
    AppComponentsModule,
    AppFeatureModule,
    DirectiveFilterPipeModule,
    ImageUploadModule,
    ModalModule,
    PagerModule,
    SharedModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //  enabled: environment.production,
    //  // Register the ServiceWorker as soon as the application is stable
    //  // or after 30 seconds (whichever comes first).
    //  registrationStrategy: 'registerWhenStable:30000'
    // }),
    ServiceWorkerModule.register('/assets/js/slakezSW.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

    AppRoutingModule,

    // ServiceWorkerModule.register('ngsw-worker.js', {
    //  enabled: !isDevMode(),
    //  // Register the ServiceWorker as soon as the application is stable
    //  // or after 30 seconds (whichever comes first).
    //  registrationStrategy: 'registerWhenStable:30000'
    // }),
  ],
  declarations: [
    AppComponent,
  ],
  exports: [

    AppComponentsModule,
    AppFeatureModule,
    DirectiveFilterPipeModule,
    ModalModule,
    PagerModule,
    SharedModule,
    AppRoutingModule,

  ],
  // entryComponents: [AppComponent, SpinnerOverlayComponent],
  entryComponents: [ AppComponent ],
  bootstrap: [ AppComponent ],


  providers: [ 
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: ActivityService },
    { provide: AppServiceService},
    { provide: CommonServiceService},
    { provide: CoreServiceService},
    { provide: DbServiceService },
    { provide: MemberServiceService },
    // { provide: PhotoServiceService },
    // { provide: PhotoBnServiceService },
    { provide: PagerServiceService },
    { provide: ProfileServiceService },
    { provide: ProfileTileServiceService },
    { provide: RendererServiceService },
    { provide: SearchSortServiceService },
    { provide: SWUpdateService },
    { provide: Document, useValue: document },
    // { provide: DbDexieService },
    // { provide: DbDexieToDictionaryService },
    // { provide: DisplayServiceService },
    // { provide: DisplayProfileService },
    // { provide: IDLE_TOKEN, useValue: Idle, multi: true }, // custom-injector
    // { provide: KEEPALIVE_TOKEN, useValue: Keepalive, multi: true }, // custom-injector
    // { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },
    // { provide: PreferenceService },
    // { provide: ProfileContentService },
    // { provide: ProfileTileService },
    // { provide: ProfileNameService },
    // { provide: ProfilePicsService },
    // { provide: KvPhotoService },
    // { provide: PhotoBnServiceService },
    // { provide: PhotoAndPrimaryPhotoMgmtService },
    // { provide: PhotoBnService },
    // { provide: PhotoPrivacyService },
    // { provide: RendererService },

    //{
    //  provide: APP_INITIALIZER,
    //  useFactory: (SignalRService : SignalRService) => () => SignalRService.initialize(), // initiateSignalrConnection(),
    //  deps: [ SignalRService ],
    //  multi: true,
    //},
    // { provide: SignalRService },
    { provide: Window, useValue: window },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig, deps: [] },
    // { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class AppModule {
  public prepareRoute (outlet : RouterOutlet) : any {
    const anim = 'animation';
    debugger;
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData[anim];
  }
}
// https://stackoverflow.com/questions/52513466/angular-6-service-workers-are-disabled-or-not-supported-error
// NOTE: This shouls be in main.ts:
// --------------------------------
// TODO: if the app does not work, remove the below section/comment it out and change polyfills.js
// ref: https://indepth.dev/posts/1059/do-you-still-think-that-ngzone-zone-js-is-required-for-change-detection-in-angular
 //platformBrowserDynamic()
 //  .bootstrapModule(AppModule, {
 //    ngZone: 'noop',
 //  }).catch(err => console.log(err));

/*
 * ======================================================================
 * ---------------------------------------------------------------------------------
 * ngDoBootstrap(app) {
 *  fetch('http://localhost:44305/src/app/appComponents/landingPage')
 *    .then((name) => { this.bootstrapRootComponent(app, name) });
 *  // this.bootstrapRootComponent(app);
 * }
 * =====================================================================
 * public appComponentsFactory: any;
 * constructor(private loader: NgModuleFactoryLoader,
 *  private compiler: Compiler,
 *  private injector: Injector,
 *  private emitterService: EmitterService
 * ) {
 *  this.emitterService.setAppModuleFactory(this.appComponentsFactory = this.loadModuleFactory(AppModule));
 * }
 * public async loadModuleFactory(t: any) {
 *  if (t instanceof NgModuleFactory) {
 *    return t;
 *  } else {
 *    return await this.compiler.compileModuleAsync(t);
 *  }
 * }
 * }
 */

/*
 * ======================================================================
 * DO NOT DELETE-Begin
 * Note: When saving NaclPairLocal model, publicKey-- > maps to-- > out, and secretKey-- > maps to-- > in.
 * e.g. localSalty.out = salty.publicKey;
 * DO NOT DELETE-End
 */
