
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../../models/boxNonce/boxNonceEntity.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { EmitRadioboxResult } from '../../../models/common/emitRadioboxResult.model';
import { MessageModel } from '../../../models/common/messageModel.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { SpinnerModel } from '../../../models/common/spinnerModel.model';
import { UserAndPageTitle } from '../../../models/common/userAndPageTitle.model';
import { Profile } from '../../../models/profile/profile.model';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { HttpService } from '../../../services/coreServiceService/httpService.service';
import { DbServiceService } from '../../../services/dbServiceService/dbServiceService.service';
import { MemberService } from '../../../services/memberServiceService/memberService.service';
import { MemberServiceService } from '../../../services/memberServiceService/memberServiceService.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { ArrayServiceStatic } from '../../../services/staticServices/arrayServiceStatic.service';
import { ModuleComponentLoaderServiceStatic } from '../../../services/staticServices/commonStaticServices/moduleComponentLoaderServiceStatic.service';
import { ScrollTopServiceStatic } from '../../../services/staticServices/commonStaticServices/scrollTopServiceStatic.service';
import { SlakezSaltServiceStatic } from '../../../services/staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { InMemoryDataServiceStatic } from '../../../services/staticServices/inMemoryDataServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';

declare let jQuery: any;

declare let $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ Animations, slideInOutAnimation,
  ],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '' }
})
export class ProfileComponent implements AfterViewInit, OnInit, OnDestroy {
  // public binarySearchService: BinarySearchService;
  // public gestureService: GestureService;
  public httpService! : HttpService;
  public memberService! : MemberService;
  private rendererService! : RendererService;
  public redirectionService! : RedirectionService;
  // ----------------------------------------------------------
  public boxNonceEntity: BoxNonceEntity = new BoxNonceEntity();
  // checkboxNames are same in preference model as well.
  public checkboxNames : string[] = [ 'hobby', 'into', 'lookingFor', 'meetingLocation', 'pet', 'petPeev', 'relationshipPreference', 'whenLookingToMeet' ];
  public click : boolean = false;
  public counter = 0;
  public enums : any;
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public highestEducationArr: any[] = [];
  public highestEducationRadioLegend = 'What is the level of your highest education? : ';
  public highestEducationRadioboxResult: EmitRadioboxResult = new EmitRadioboxResult();
  public intervalMsg: any;
  public intervalMsgBanners: any[] = [];
  public isEditProfile = false;
  public isViewRendered = false;
  public isFormSubmitted = false;
  public isMobilevar = false;
  public isOnLine = false;
  public isProfileFormValid = false;
  public isProfileInfo = false;
  public isSubmitBttnFocus = false;
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public mc: any;
  public mcLoader: ModuleComponentLoader = new ModuleComponentLoader();
  public message = '';
  public messageModel : MessageModel = new MessageModel();
  public msgAnimCount = 0;
  public pagerBrdCrm : PagerBreadcrum = new PagerBreadcrum();
  public playSafeArr: any[] = [];
  public playSafeRadioboxResult: EmitRadioboxResult = new EmitRadioboxResult();
  public positionArr: any[] = [];
  public positionRadioboxResult: EmitRadioboxResult = new EmitRadioboxResult();
  public profile: Profile = new Profile();
  public radioboxNames: string[] = ['highestEducation', 'Religion', 'playSafe', 'position', 'relationshipStatus', 'smokingStatus'];
  public religionArr: any[] = [];
  public religionRadioboxResult: EmitRadioboxResult = new EmitRadioboxResult();
  public relationshipStatusArr: any[] = [];
  public relationshipStatusRadioboxResult: EmitRadioboxResult = new EmitRadioboxResult();
  public renderer!: Renderer2;
  public returnUrl = '';
  public smokingStatusArr: string[] = [];
  public smokingStatusRadioboxResult: EmitRadioboxResult = new EmitRadioboxResult();

  public selectedHiEduIndex = -1;
  public selectedPlaySafeIndex = -1;
  public selectedPositionIndex = -1;
  public selectedReligionIndex = -1;
  public selectedRelStatusIndex = -1;
  public selectedSmkStatusIndex = -1;
  public spinnerModel : SpinnerModel = new SpinnerModel();
  public userAndPageTitle: UserAndPageTitle = new UserAndPageTitle();
  @ViewChild('profileForm', { static: false}) profileForm: any;
  public yourLoginSuccess: LoginSuccess = new LoginSuccess();
  // ---------------------------------------------------------------
  constructor(
    private activatedRoute: ActivatedRoute,
    public cdr : ChangeDetectorRef,
    private commonServiceService: CommonServiceService,
    private coreServiceService: CoreServiceService,
    @Inject(DOCUMENT) document : any,
    private dbServiceService: DbServiceService,
    public memberServiceService : MemberServiceService,
    private rendererServiceService: RendererServiceService,
    private router: Router,
    private window: Window,
  ) {
    this.redirectionService = this.commonServiceService.getRedirectionService();
    if ( this.coreServiceService ) this.httpService = this.coreServiceService.getHttpService();
    if (this.memberServiceService) {
      this.memberService = this.memberServiceService.getMemberService();
    }
    if ( this.rendererServiceService ) this.rendererService = this.rendererServiceService.getRendererService();
    if (this.rendererService) {
      this.renderer = this.rendererService.getRenderer();
    }
    // ---------------------------------------------------------------
    // debugger;
    this.activatedRoute.params.subscribe((params) => (this.profile.signedInUserId = params.id));
    // ---------------------------------------------------------------

    /*
     * if the params.id is not provided, then get the yourLoginSucces and assign its singnedInUserId to profile.signedInUseId
     * debugger;
     */
    // if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profile.signedInUserId)) {
    //  this.yourLoginSuccess = EmitterSubjectService.getYourLoginSuccess();
    //  if (this.yourLoginSuccess.signedInUserId > 0) {
    //    this.profile.signedInUserId = this.yourLoginSuccess.signedInUserId;
    //    // debugger;
    //    this.getProfileForEdit();
    //  }
    // }

    // debugger;
    // if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.enums ) )
    // {
    //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.httpService ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.httpService.getEnumsFromServer() ) )
    //  {
    //    debugger;
    //    this.enums = this.httpService.getEnumsFromServer().then( enums =>
    //    {
    //      debugger;
    //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( enums ) )
    //      {
    //        this.highestEducationsArr = enums.highestEducations; //this.binarySearchService.sort(this.data.enums.highestEducations);
    //        this.playSafesArr = enums.playSafes; // this.binarySearchService.sort(this.data.enums.playSafes);
    //        this.positionsArr = enums.positions; // this.binarySearchService.sort(this.data.enums.positions);
    //        this.religionsArr = enums.religions; // this.binarySearchService.sort(this.data.enums.religions);
    //        this.relationshipStatusesArr = enums.relationshipStatuses; // this.binarySearchService.sort(this.data.enums.relationshipStatuses);
    //        this.smokingStatusesArr = enums.smokingStatuses; // this.binarySearchService.sort(this.data.enums.smokingStatuses);
    //      }
    //    } );
    //  }
    // }
    // if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.enums ) )
    // {
    //  debugger;
    //  this.enums = InMemoryDataServiceStatic.getEnums();
    // }
    // if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.enums ) )
    // {
    //  debugger;
    //  this.enums = EmitterSubjectService.getEnums(); // if available, get it from EmitterSubjectService
    // }

    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.enums) )
    {
      // debugger;
      this.highestEducationArr = InMemoryDataServiceStatic.highestEducationArr; // this.binarySearchService.sort(this.data.enums.highestEducations);
      this.playSafeArr = InMemoryDataServiceStatic.playSafeArr; // this.binarySearchService.sort(this.data.enums.playSafes);
      this.positionArr = InMemoryDataServiceStatic.positionArr; // this.binarySearchService.sort(this.data.enums.positions);
      this.religionArr = InMemoryDataServiceStatic.religionArr; // this.binarySearchService.sort(this.data.enums.religions);
      this.relationshipStatusArr = InMemoryDataServiceStatic.relationshipStatusArr; // this.binarySearchService.sort(this.data.enums.relationshipStatuses);
      this.smokingStatusArr = InMemoryDataServiceStatic.smokingStatusArr; // this.binarySearchService.sort(this.data.enums.smokingStatuses);
    }
    // ---------------------------------------------------------------
    this.isProfileFormValid = this.checkFormValidity();
    // ---------------------------------------------------------------
    this.setPageTitle();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    /*
     * debugger;
     * this.getProfileForEdit();
     */
  }
  // ---------------------------------------------------------------
  ngOnInit() {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isEditProfile = EmitterSubjectService.getIsEditProfile();
    this.getProfileForEdit();
    // debugger;
    //if (this.loginSuccess.isPreScreenComplete && (!this.loginSuccess.isProfileInfoComplete || this.isEditProfile)) {
    //  this.isProfileInfo = true;
    //  // debugger;
    //}
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    // -------------------------------------------------------------

    // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.data ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.data.enums ) ) {

    //  this.highestEducationsArr = this.data.enums.highestEducationsArr; // InMemoryDataServiceStatic.enums.hobbies;// this.binarySearchService.sort(InMemoryDataServiceStatic.enums.hobbies);
    //  this.playSafesArr = this.data.enums.playSafesArr; // InMemoryDataServiceStatic.enums.intos;// this.binarySearchService.sort(InMemoryDataServiceStatic.enums.intos);
    //  this.positionsArr = this.data.enums.positionsArr; // InMemoryDataServiceStatic.enums.lookingFors; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.lookingFors);
    //  this.religionsArr = this.data.enums.religionsArr; // InMemoryDataServiceStatic.enums.meetingLocations; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.meetingLocations);
    //  this.relationshipStatusesArr = this.data.enums.relationshipStatusesArr; // InMemoryDataServiceStatic.enums.pets; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.pets);
    //  this.smokingStatusesArr = this.data.enums.smokingStatusesArr; // InMemoryDataServiceStatic.enums.petPeevs; // this.binarySearchService.sort(InMemoryDataServiceStatic.enums.petPeevs);

    // }
    // -------------------------------------------------------------
    // ( ( this.emitterService as EmitterService ) as EmitterService ).emitClearGlyphiconMenu( true );

    /*
     * if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profile.signedInUserId)) {
     * this.yourLoginSuccess = EmitterSubjectService.getYourLoginSuccess();
     * if (this.yourLoginSuccess.signedInUserId > 0) {
     *   this.profile.signedInUserId = this.yourLoginSuccess.signedInUserId;
     *   debugger;
     *   this.getProfileForEdit();
     * }
     * }
     * -----------------------------------------------------------
     */
    // this.mc = this.gestureService.activateGesture('profileComponentId', '', true);
    /*
     * -----------------------------------------------------------
     * EmitterSubjectService.localStorageLoginSuccessEmitter
     *
     * .subscribe(result => {
     *   debugger;
     *   this.loginSuccess = result;
     *   this.determineRedirection();
     * });
     * -----------------------------------------------------------
     */
    EmitterSubjectService.emitSetBreadcrumForPageSwitch(true);
    /*
     * -----------------------------------------------------------
     * reset the background-image and glyphicon-menu
     */
      EmitterSubjectService.emitResetDbgi(true);
      EmitterSubjectService.emitClearGlyphiconMenu(true);

    // -----------------------------------------------------------
    EmitterSubjectService.isEditProfileEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
      // debugger;
      this.loginSuccess.isEditProfile = result as boolean;
      this.loginSuccess.isProfileInfoComplete = result as boolean === true ? false : true;
      EmitterSubjectService.setLoginSuccess(this.loginSuccess);
    });
    // -----------------------------------------------------------
    EmitterSubjectService.radioboxResultEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
      const tempResult = result as EmitRadioboxResult;
      // debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempResult) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempResult.radioboxName)) {
        switch (tempResult.radioboxName.toLowerCase()) {
          case 'highesteducation':
            this.highestEducationRadioboxResult = tempResult;
            this.profile.highestEducation = this.highestEducationRadioboxResult.radioboxSelection;
            this.selectedHiEduIndex = this.highestEducationRadioboxResult.index;
            // this.memberService.getIndexOfItemInArr(this.highestEducationsArr, this.profile.highestEducation );
            break;
          case 'playsafe':
            this.playSafeRadioboxResult = tempResult;
            this.profile.playSafe = this.playSafeRadioboxResult.radioboxSelection;
            this.selectedPlaySafeIndex = this.playSafeRadioboxResult.index;
            // this.memberService.getIndexOfItemInArr(this.playSafesArr, this.profile.playSafe);
            break;
          case 'position':
            this.positionRadioboxResult = tempResult;
            this.profile.position = this.positionRadioboxResult.radioboxSelection;
            this.selectedPositionIndex = this.positionRadioboxResult.index;
            // this.memberService.getIndexOfItemInArr(this.positionsArr, this.profile.position);
            break;
          case 'religion':
            // debugger;
            this.religionRadioboxResult = tempResult;
            this.profile.religion = this.religionRadioboxResult.radioboxSelection;
            this.selectedReligionIndex = this.religionRadioboxResult.index;
            /*
             * this.memberService.getIndexOfItemInArr(this.religionsArr, this.profile.religion);
             * debugger;
             */
            break;
          case 'relationshipstatus':
            this.relationshipStatusRadioboxResult = tempResult;
            this.profile.relationshipStatus = this.relationshipStatusRadioboxResult.radioboxSelection;
            this.selectedRelStatusIndex = this.relationshipStatusRadioboxResult.index;
            // this.memberService.getIndexOfItemInArr(this.relationshipStatusesArr, this.profile.relationshipStatus);
            break;
          case 'smokingstatus':
            this.smokingStatusRadioboxResult = tempResult;
            this.profile.smokingStatus = this.smokingStatusRadioboxResult.radioboxSelection;
            this.selectedSmkStatusIndex = this.smokingStatusRadioboxResult.index;
            // this.memberService.getIndexOfItemInArr(this.smokingStatusesArr, this.profile.smokingStatus);
            break;
          default:
            break;
        }
      }
      this.isProfileFormValid = this.checkFormValidity();
    });
    // -----------------------------------------------------------
    // debugger;
    // if (this.loginSuccess && this.loginSuccess.signedInUserId > 0) {
    // //debugger;
    // if (this.profile.signedInUserId === 0) {
    //   this.profile.signedInUserId = this.loginSuccess.signedInUserId;
    // }
    // debugger;
    // this.determineRedirection();
    // }
    // -----------------------------------------------------------
    // ScrollTopServiceStatic.scrollToTop('Top');
    ScrollTopServiceStatic.scrollToLocation( 'allScrollTotId', 'start' );
    // debugger;
    this.redirectionBeforeSubmit();
  }
  // ---------------------------------------------------------------------------------
  ngAfterViewInit() {
    // debugger;
    this.cdr.detectChanges();
    // debugger;
  }
  // ---------------------------------------------------------------
  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    clearInterval(this.intervalMsg);
    this.intervalMsgBanners.forEach((timer) => clearInterval(timer));
    this.resetPageTitle();
  }
  // ---------------------------------------------------------------
  cancel () : void
  {
    this.mcLoader = new ModuleComponentLoader();
    this.mcLoader.componentName = 'index';
    this.mcLoader.moduleName = 'app';
    this.mcLoader.sitUserId = this.loginSuccess.signedInUserId;
    this.mcLoader.router = this.router;
    // Note: setupMcLoderModel before fireMcLoaderRoute()!!
    // ---------------------------------------------------
    this.mcLoader = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(this.mcLoader);
    EmitterSubjectService.setMCLoader( this.mcLoader );

    // debugger;
    this.redirectionService.fireMcLoaderRoute(this.mcLoader).subscribe(data => {
      this.pagerBrdCrm = data;
    });
    // this.router.navigate(['/index']);
  }
  // ---------------------------------------------------------------
  checkFormValidity(): boolean {
    const isTrue = !!(
      ( this.highestEducationRadioboxResult
        && this.highestEducationRadioboxResult.radioboxSelection
        && this.highestEducationRadioboxResult.radioboxSelection.length > 0 || this.selectedHiEduIndex > -1 ) &&
      ( this.playSafeRadioboxResult
        && this.playSafeRadioboxResult.radioboxSelection
        && this.playSafeRadioboxResult.radioboxSelection.length > 0 || this.selectedPlaySafeIndex > -1 ) &&
      ( this.positionRadioboxResult
        && this.positionRadioboxResult.radioboxSelection
        && this.positionRadioboxResult.radioboxSelection.length > 0 || this.selectedPositionIndex > -1 ) &&
      ( this.religionRadioboxResult
        && this.religionRadioboxResult.radioboxSelection
        && this.religionRadioboxResult.radioboxSelection.length > 0 || this.selectedReligionIndex > -1 ) &&
      ( this.relationshipStatusRadioboxResult
        && this.relationshipStatusRadioboxResult.radioboxSelection
        && this.relationshipStatusRadioboxResult.radioboxSelection.length > 0 || this.selectedRelStatusIndex > -1 ) &&
      ( this.smokingStatusRadioboxResult
        && this.smokingStatusRadioboxResult.radioboxSelection
        && this.smokingStatusRadioboxResult.radioboxSelection.length > 0 || this.selectedSmkStatusIndex > -1 )
    );
    // debugger;
    return isTrue;
  }
  // ---------------------------------------------------------------
  redirectionAfterSubmit () : void
  {
     debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if ( this.loginSuccess && this.loginSuccess.signedInUserId > 0 )
    {
       debugger;
      if ( this.loginSuccess.isProfileInfoComplete )
      {
        this.mcLoader = new ModuleComponentLoader();
        this.mcLoader.componentName = 'preference';
        this.mcLoader.moduleName = 'profilem';
        this.mcLoader.sitUserId = this.loginSuccess.signedInUserId;
        this.mcLoader.router = this.router;
        
        debugger;
        this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.loginSuccess.signedInUserId).subscribe(data => {
          this.pagerBrdCrm = data;
        });
      }
    }
  }
  // ---------------------------------------------------------------
  redirectionBeforeSubmit (): void {
    /*
     * Note : if isEditProfile==true, profileInfo must be visited even when its completed before
     */
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess(); // get the updated data
    this.isEditProfile = EmitterSubjectService.getIsEditProfile();
    this.mcLoader = new ModuleComponentLoader();
    if (this.loginSuccess && this.loginSuccess.signedInUserId > 0)
    {
      // debugger;
      this.mcLoader.sitUserId = this.loginSuccess.signedInUserId;
      this.mcLoader.router = this.router;

      // During signup:
      // --------------
      if (!this.loginSuccess.isEditProfile)
      {
        if (this.loginSuccess.isProfileInfoComplete)
        {
          if (!this.loginSuccess.isPreferenceComplete) {
            this.mcLoader.moduleName = 'profilem';
            this.mcLoader.componentName = 'preference';
            // debugger;
            this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.loginSuccess.signedInUserId).subscribe(data => {
              this.pagerBrdCrm = data;
            });
          }
          else if (!this.loginSuccess.isProfileContentComplete) {
            this.mcLoader.moduleName = 'profilem';
            this.mcLoader.componentName = 'profileContent';
            // debugger;
            this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.loginSuccess.signedInUserId).subscribe(data => {
              this.pagerBrdCrm = data;
            });
          }
          else if (this.loginSuccess.isProfileComplete) {
            // debugger;
            this.mcLoader.componentName = 'myPhotoMgmt';
            this.mcLoader.moduleName = 'mym';
            // debugger;
            this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.loginSuccess.signedInUserId).subscribe(data => {
              this.pagerBrdCrm = data;
            });
          }
          else {
            // complete this step.
          }          
        }        
        else {
          // complete this step.
        }        
      }
      // During editing, we go through all steps but preScreen step:
      else {
        // complete this step.
      }
    }
  }
  // ---------------------------------------------------------------
  resetPageTitle(): any {
    this.userAndPageTitle.pageTitle = '';
    this.userAndPageTitle.sitUserId = 0;
    // debugger;
    EmitterSubjectService.emitUserAndPageTitle(this.userAndPageTitle);
    return true;
  }
  // ---------------------------------------------------------------
  setPageTitle(): any {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.profileName) && this.loginSuccess.profileName.length > 0) {
      this.setUserPageTitle(this.loginSuccess.profileName);
    }
    else {
      this.userAndPageTitle.sitUserId = this.loginSuccess.signedInUserId;
      let msg = this.loginSuccess.isEditProfile === true ? '-: Edit ' : '-: Create';

      msg += ' Profile; Step-2 0f 4 :-';
      this.userAndPageTitle.pageTitle = msg;
      this.userAndPageTitle.isPrepared = true;
      // debugger;
      EmitterSubjectService.emitUserAndPageTitle(this.userAndPageTitle);
    }
    return true;
  }
  // ---------------------------------------------------------------
  setUserPageTitle (profileName : string) : any {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileName) && profileName.length > 0) {
      this.userAndPageTitle.sitUserId = this.loginSuccess.signedInUserId;
      let msg = this.loginSuccess.isEditProfile === true ? '-: Edit ' : '-: Create';

      msg += ' Profile of <span style=\'font-size:0.75rem;\'>(</span><span class=\'grnBluGldxS\'>';
      msg += profileName + '</span><span style=\'font-size:0.75rem;\'>)</span> <br/> Step-2 of 4 :-';
      this.userAndPageTitle.pageTitle = msg;
      this.userAndPageTitle.isPrepared = true;

      // debugger;
      EmitterSubjectService.emitUserAndPageTitle(this.userAndPageTitle);
    }
    return true;
  }
  // ---------------------------------------------------------------
  salt(): void {
    // debugger;
    this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt(JSON.stringify(this.profile));
  }
  // ---------------------------------------------------------------
  getProfileForEdit(): void {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profile ) || this.profile.signedInUserId == 0 )
    {
      this.profile.signedInUserId = this.loginSuccess.signedInUserId;
    }
    if (this.profile.signedInUserId > 0) {
      this.salt();
      // debugger;
      EmitterSubjectService.emitShowSpinner('Getting your profile information....');

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
        this.httpService
          .postObservable(
            '/Member/GetProfileForEdit',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            },
            'json2text',
          )
          .subscribe(
            (result) => {
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                const tProfile = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(result as BoxNonceEntity)) as Profile;
                // debugger;

                this.profile = tProfile as Profile;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profile) && this.profile.signedInUserId > 0) {
                  /*
                   * this.profile.isSuccess = true;
                   * debugger;
                   */
                  this.highestEducationRadioboxResult.index = this.selectedHiEduIndex = ArrayServiceStatic.getIndexOfItemInArr(this.highestEducationArr, this.profile.highestEducation);
                  this.playSafeRadioboxResult.index = this.selectedPlaySafeIndex = ArrayServiceStatic.getIndexOfItemInArr(this.playSafeArr, this.profile.playSafe);
                  this.positionRadioboxResult.index = this.selectedPositionIndex = ArrayServiceStatic.getIndexOfItemInArr(this.positionArr, this.profile.position);
                  this.religionRadioboxResult.index = this.selectedReligionIndex = ArrayServiceStatic.getIndexOfItemInArr(this.religionArr, this.profile.religion);
                  this.relationshipStatusRadioboxResult.index = this.selectedRelStatusIndex = ArrayServiceStatic.getIndexOfItemInArr(this.relationshipStatusArr, this.profile.relationshipStatus);
                  this.smokingStatusRadioboxResult.index = this.selectedSmkStatusIndex = ArrayServiceStatic.getIndexOfItemInArr(this.smokingStatusArr, this.profile.smokingStatus);

                  this.highestEducationRadioboxResult.radioboxSelection = this.profile.highestEducation;
                  this.playSafeRadioboxResult.radioboxSelection = this.profile.playSafe;
                  this.positionRadioboxResult.radioboxSelection = this.profile.position;
                  this.religionRadioboxResult.radioboxSelection = this.profile.religion;
                  this.relationshipStatusRadioboxResult.radioboxSelection = this.profile.relationshipStatus;
                  this.smokingStatusRadioboxResult.radioboxSelection = this.profile.smokingStatus;
                  this.ngAfterViewInit();

                  EmitterSubjectService.emitHideSpinner(true);

                  // debugger;
                } else {
                  if (tProfile) {
                    this.message = 'Error occured in getProfileForEdit();\n Error-mag : ' + tProfile.message;
                    EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.message });
                  } else {
                    EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured in getProfileForEdit();\n Error-mag : ' });
                  }
                  EmitterSubjectService.emitHideSpinner(true);
                }
              } else {
                EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured in getProfileForEdit();\n Error-mag : ' });
                EmitterSubjectService.emitHideSpinner(true);
              }
            },
            (error) => {
              // alert ('Error occured in GetArticleContent(' + idArr[i] + ');\n Error-mag : ' + error);
              EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured in getProfileForEdit();\n Error-mag : ' + error.message });
              EmitterSubjectService.emitHideSpinner(true);
            }
          );
      }
      else {
        EmitterSubjectService.emitHideSpinner(true);
      }
    }
  }
  // ---------------------------------------------------------------
  submitProfileForm(): void {
    if (this.profile.signedInUserId > 0 && this.profileForm.valid && this.checkFormValidity()) {
      this.click = !this.click;
       debugger;
      this.spinnerModel = new SpinnerModel();
      this.spinnerModel.message = 'Updating your profile information....';
      EmitterSubjectService.emitShowSpinner(this.spinnerModel);

      this.salt();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
        this.isFormSubmitted = true;
        this.httpService
          .postObservable(
            '/Member/UpdateProfile',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            },
            'json2text',
          )
          .subscribe(
            (result) => {
               debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                debugger;
                var tempBn : BoxNonceEntity = new BoxNonceEntity() 
                tempBn.box = result.box;
                tempBn.nonce = result.nonce;
                const tProfile = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(tempBn as BoxNonceEntity)) as Profile;
                 debugger;

                this.profile = tProfile as Profile;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profile) && this.profile.signedInUserId > 0) {
                   debugger;
                  this.profile.isSuccess = true;
                  this.highestEducationRadioboxResult.index = this.selectedHiEduIndex = ArrayServiceStatic.getIndexOfItemInArr(this.highestEducationArr, this.profile.highestEducation);
                  this.playSafeRadioboxResult.index = this.selectedPlaySafeIndex = ArrayServiceStatic.getIndexOfItemInArr(this.playSafeArr, this.profile.playSafe);
                  this.positionRadioboxResult.index = this.selectedPositionIndex = ArrayServiceStatic.getIndexOfItemInArr(this.positionArr, this.profile.position);
                  this.religionRadioboxResult.index = this.selectedReligionIndex = ArrayServiceStatic.getIndexOfItemInArr(this.religionArr, this.profile.religion);
                  this.relationshipStatusRadioboxResult.index = this.selectedRelStatusIndex = ArrayServiceStatic.getIndexOfItemInArr(this.relationshipStatusArr, this.profile.relationshipStatus);
                  this.smokingStatusRadioboxResult.index = this.selectedSmkStatusIndex = ArrayServiceStatic.getIndexOfItemInArr(this.smokingStatusArr, this.profile.smokingStatus);

                  this.highestEducationRadioboxResult.radioboxSelection = this.profile.highestEducation;
                  this.playSafeRadioboxResult.radioboxSelection = this.profile.playSafe;
                  this.positionRadioboxResult.radioboxSelection = this.profile.position;
                  this.religionRadioboxResult.radioboxSelection = this.profile.religion;
                  this.relationshipStatusRadioboxResult.radioboxSelection = this.profile.relationshipStatus;
                  this.smokingStatusRadioboxResult.radioboxSelection = this.profile.smokingStatus;

                  this.loginSuccess.isProfileInfoComplete = this.profile.isSuccess = true;
                  EmitterSubjectService.setLoginSuccess(this.loginSuccess);
                  this.message = this.profile.message = 'Updating Step-2 of 4 of your Profile Information is successful!';
                  this.messageModel.message = this.message;
                  this.messageModel.isSuccess = true;
                  EmitterSubjectService.emitMessage(this.messageModel)
                  this.ngAfterViewInit();
                  this.setMessageTimer();
                  ScrollTopServiceStatic.scrollToTop('allScrollToTopId');

                  EmitterSubjectService.emitHideSpinner(true);
                  debugger;
                  this.redirectionAfterSubmit();
                  // debugger;
                } else {
                  if (tProfile) {
                    this.message = 'Error occured in submitProfileForm();\n Error-mag : ' + tProfile.message;
                    EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.message });
                  } else {
                    EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured in submitProfileForm();\n Error-mag : ' });
                  }

                  EmitterSubjectService.emitHideSpinner(true);
                }
              } else {
                EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured in submitProfileForm();\n Error-mag : ' });
                EmitterSubjectService.emitHideSpinner(true);
              }
            },
            (error) => {
              // alert ('Error occured in GetArticleContent(' + idArr[i] + ');\n Error-mag : ' + error);
              EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured in submitProfileForm();\n Error-mag : ' + error.message });
              EmitterSubjectService.emitHideSpinner(true);
            },
          );
      }
      else {
        EmitterSubjectService.emitHideSpinner(true);
      }
    }
  }
  // --------------------------------------------------------------
  setMessageTimer(): void {
    // debugger;
    this.intervalMsg = setTimeout(() => {
      this.message = this.profile.message = '';
      clearTimeout(this.intervalMsg);
       debugger;
      // this.redirectionAfterSubmit();
      // this.router.navigate(['/preference/' + this.loginSuccess.signedInUserId]);
    }, 6000);
    clearTimeout(this.intervalMsg);
  }
  // ---------------------------------------------------------------
  showInvalidFormMessage (e : any): void {
    this.isProfileFormValid = this.checkFormValidity();
    this.isFormSubmitted = true;
    this.onSubmitBttnMouseOut(e);
  }
  // ---------------------------------------------------------------
  onMouseOut (e : any): void {
    this.isSubmitBttnFocus = false;
  }
  // ---------------------------------------------------------------
  onSubmitBttnMouseOut (e : any): void {
    if (e.type.value === 'mouseout') {
      this.isSubmitBttnFocus = false;
      this.isProfileFormValid = this.checkFormValidity();
      if (!this.isProfileFormValid) {
        this.isFormSubmitted = false;
      }
    } else {
      this.isSubmitBttnFocus = true;
    }
  }
  /*
   * ==============================================================
   * Begin of Animate-Message-Banner-System:
   * var counter = 0;
   * function runAgain() {
   *   window.setTimeout(foo, 1000);
   * }
   */

  // function foo() {
  // // Do somthing
  // if ((++counter) < 5)
  //   runAgain();
  // }
  // ---------------------------------------------------------------
  animateAgain() {
    // foo
    if (this.msgAnimCount < 3) {
      /*
       * this.addRemoveClasses(this.msgAnimCount);
       * this.addFocusToElement('profileMsgId');
       * this.toggleClass('profileMsgId', 'messageBannerBigger-hover');
       */
      this.animateMessageBanner(this.msgAnimCount);
      ++this.msgAnimCount;
    }
  }
  // ---------------------------------------------------------------
  animateMessageBanner(count: number): void {
    // runAgain()
    if (count < 3) {
      /*
       * debugger;
       * call any function here.
       * this.addRemoveClasses(count);
       */
      const intervalMsgBanner = window.setTimeout(this.animateAgain, 2000);
      clearTimeout(intervalMsgBanner);

      this.intervalMsgBanners.push(intervalMsgBanner);
    }
  }
  /*
   * ===============================================================
   * End of Animate-Message-Banner-System:
   * ---------------------------------------------------------------
   * ---------------------------------------------------------------
   */
}
