import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { Bool } from '../../../models/common/bool.model';
import { PhotoBn } from '../../../models/common/photoBn.model';
import { KvPhoto } from '../../../models/keyValue/kvPhoto.model';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { HttpService } from '../../../services/coreServiceService/httpService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { JsRegExpServiceStatic } from '../../../services/staticServices/jsRegExpServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';
import { ImageUploadService } from './services/image-upload.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ Animations, slideInOutAnimation ],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '' }
})
export class ImageUploadComponent {
 
  public currentFile ?: File;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public fileInfos ?: Observable<any>;
  // public isCameraVisible = true;
  public isMobilevar = false;
  // public isOnLine = false;
  // public isFormValid = false;
  // public isMyProfile = false;
  // public isNewPhotoUploaded : boolean = false;
  // public isPhotoMgmt = false;
  // public isSubmitted = false;
  public isImageUploadFormVisible = false;
  public isShowPhotoUploadUi : Bool = new Bool();
  public isViewMember = false;
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public message = '';
  public progress = 0;
  public selectedFiles ?: FileList;
  public toggleCameraGlyphCounter = 0;

 

  constructor (
    private cdr : ChangeDetectorRef,
    public coreServiceService: CoreServiceService,
    private uploadService : ImageUploadService) { }

  ngOnInit () : void {
    // debugger;
    // this.fileInfos = this.uploadService.getFiles();
  }
  // ---------------------------------------------------------------
  ngAfterViewInit () : void {
    // debugger;
    this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------
  ngOnDestroy () : void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  selectFile (event : any) : void {
    this.selectedFiles = event.target.files;
  }

  upload () : void {
    this.progress = 0;

    if (this.selectedFiles) {
      const file : File | null = this.selectedFiles.item(0);
      var kvPhoto : KvPhoto = new KvPhoto();
      var photoBn : PhotoBn = new PhotoBn();
      if (file) {
        this.currentFile = file;
        kvPhoto = EmitterSubjectService.getCurrentKvPhoto();
        this.uploadService.upload(this.currentFile).subscribe({
          next: (event : any) => {
            // debugger;
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              this.message = event.body.message;
              // debugger;
              photoBn = event.body as PhotoBn;  /*this.uploadService.getFiles()*/;
              photoBn.pic = JsRegExpServiceStatic.prefixPngToImageData(photoBn.fileBytes);
              photoBn.image = photoBn.pic;
              // debugger;
              kvPhoto = new PhotoBn().cloneToCamelCasedKvPhoto(photoBn);
              // debugger;
              EmitterSubjectService.emitUploadedKvPhoto(kvPhoto);              
            }
          },
          error: (err : any) => {
            console.log(err);
            this.progress = 0;

            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'Could not upload the file!';
            }

            this.currentFile = undefined;
          }
        });
      }

      this.selectedFiles = undefined;
    }
  }
  // ---------------------------------------------------------------
  cancel () : void {
    this.cancelFileUpload();
    // debugger;
    this.isImageUploadFormVisible = true;
    // Note:   isCameraVisible===true :camera-gluph-menu is visible && UploadFilesComponent is hidden.
    // ------------------------------------------------------------------------------------------------

    this.toggleCameraGlyphCounter++;
    EmitterSubjectService.emitToggleCameraGlyph(this.toggleCameraGlyphCounter);
    EmitterSubjectService.emitIsCameraClosedBool(true);
  }
  // ---------------------------------------------------------------
  cancelFileUpload () : void {
    // this.content = new Content();  // TPDO: restore
    this.selectedFiles = undefined;
  }
  // ---------------------------------------------------------------
}
