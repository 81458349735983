
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root'})
export abstract class ScrollTopServiceStatic {
  static container: any;
  
  static scrollY = new BehaviorSubject( 0 );
  static scrollY$ = ScrollTopServiceStatic.scrollY.asObservable();

  constructor(@Inject(Window) window: Window, @Inject(Document) document: Document) { }
  // --------------------------------------------------------------
  static currentFocus (focus : any): any {
    if (focus) {
      const tempElem = document.querySelectorAll('#' + focus);

      if (tempElem && tempElem.length > 0) {
        this.container = tempElem[0];
        if (this.container) {
          window.scrollTo(0, this.container.parentNode.offsetTop - 100);
          return true;
        }
        /*
         * Note:  The following JQuery code will animate the scroll to top of the page
         *       But need to plug in JQuery into Angular
         * $('html, body').animate({
         * scrollTop: $('#body').offset().top
         * }, 1000);
         * returntrue;
         */
      }
    }
    return false;
  }

  // ---------------------------------------------------------------
  static scrollToLocation (focus : any, location : any): any {
    if (focus) {
      // debugger;
      const tempElem = document.querySelectorAll('#' + focus);

      if (tempElem && tempElem.length > 0) {
        // debugger;
        this.container = tempElem[0];
        if (this.container) {
          // window.scrollTo(0, this.container.offsetTop - 100);
          this.container.scrollIntoView({ behavior: 'smooth', block: location, inline: 'nearest'});
          return true;
        }

        /*
         * if (this.container) this.container.scrollIntoView({ behavior: 'smooth', block: location, inline: 'nearest' });
         * this.container = document.getElementById(focus);
         * if (this.container) this.container.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
         * Note: some browsers do not support this
         * this.container.scrollIntoView(false);//Note: All browsers support this
         */
      }
    }
    return false;
  }
  // ---------------------------------------------------------------
  static scrollToBottom (focus : any): any {
    if (focus) {
      // debugger;
      const tempElem = document.querySelectorAll('#' + focus);

      if (tempElem && tempElem.length > 0) {
        // debugger
        this.container = tempElem[0];
        if (this.container) {
          // window.scrollTo(0, this.container.parentNode.offsetTop - 200);
          this.container.parentNode.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest'});
          return true;
        }
        /*
         * this.container = document.getElementById(focus);
         * if (this.container) this.container.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
         * Note: some browsers do not support this
         * this.container.scrollIntoView(false);//Note: All browsers support this
         */
      }
    }
    return false;
  }
  // ---------------------------------------------------------------
  static scrollToTop (focus : any): any {
    if (focus) {
      // debugger;
      const tempElem = document.querySelectorAll('#' + focus);

      if (tempElem && tempElem.length > 0) {
        // window.scrollTo(0, this.container.parentNode.offsetTop - 200);
        tempElem[0].scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest'});
        return true;
      }
      /*
       * if (this.container) this.container.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
       * Note: some browsers do not support this
       * this.container.scrollIntoView(false);//Note: All browsers support this
       */
    }
    return false;
  }
  // ---------------------------------------------------------------
  // ref: https://itnext.io/4-ways-to-listen-to-page-scrolling-for-dynamic-ui-in-angular-ft-rxjs-5a83f91ee487
  static updateScrollY ( value : number ) : void
  {
    this.scrollY.next( value );
  }
  // ---------------------------------------------------------------
}
