import { FrequentlyUsedFunctionsServiceStatic } from "../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service";

export class Preference {
  public profileId = 0;
  public preferenceId = 0;
  public date : string = '';

  public hobby : string = '';
  public hobbies : string = '';
  public hobbyArr : string[] = [];

  public isSuccess = false;

  public into : string = '';
  public intos : string = '';
  public intoArr : string[] = [];

  public lookingFor : string = '';
  public lookingFors : string = '';
  public lookingForArr : string[] = [];

  public message = '';

  public meetingLocation : string = '';
  public meetingLocations: string = '';
  public meetingLocationArr : string[] = [];

  public pet : string = '';
  public pets : string = '';
  public petArr : string[] = [];

  public petPeeve : string = '';
  public petPeeves : string = '';
  public petpeeveArr : string[] = [];

  public relationshipPreference : string = '';
  public relationshipPreferences : string = '';
  public relationshipPreferenceArr : string[] = [];  

  // Note: since multiple-selections-allowed, we keep the var names plural:
  public selectedHobbiesString : string = '';
  public selectedIntosString : string = '';
  public selectedLookingForsString : string = '';
  public selectedMeetingLocationsString : string = '';
  public selectedPetsString : string = '';  
  public selectedPetPeevesString : string = '';
  public selectedRelationshipPreferencesString : string = '';
  public selectedWhenLookingToMeetsString : string = '';

  public sitUserId = 0;
  public signedInUserId = 0;
  public signedInUserKey = '';

  public whenLookingToMeet : string = '';
  public whenLookingToMeets : string = '';
  public whenLookingToMeetArr : string[] = [];
  // ----------------------------------------
  constructor () { }
  // ----------------------------------------
  doesModelHaveData (): boolean{
    let out = false;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this)  && ((!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.hobbies) && this.hobbies.length > 0)
      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.intos) && this.intos.length > 0)
      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.lookingFors) && this.lookingFors.length > 0)
      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.meetingLocations) && this.meetingLocations.length > 0)
      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pets) && this.pets.length > 0)
      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.petPeeves) && this.petPeeves.length > 0)
      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.relationshipPreferences) && this.relationshipPreferences.length > 0)
      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.relationshipPreferences) && this.relationshipPreferences.length > 0)
      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.whenLookingToMeets) && this.whenLookingToMeets.length > 0))
    ) {
      out = true;
    }
    return out;
  }
  // ----------------------------------------
}
