'use-strict'
import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../../models/boxNonce/boxNonceEntity.model';
import { ChatFileUpload } from '../../../models/chat/chatFileUpload.model';
import { Content } from '../../../models/common/content.model';
import { ProfilePics } from '../../../models/profile/profilePics.model';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { SignedInUserService } from '../../../services/commonServiceService/signedInUserService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { HttpService } from '../../../services/coreServiceService/httpService.service';
import { CopyServiceStatic } from '../../../services/staticServices/commonStaticServices/copyServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { UploadServiceStatic } from '../../../services/staticServices/uploadServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';
import { Login } from '../../../models/account/login.model';

const URL = '/FileUpload/ProfilePhotoUpload';

@Component({
  selector: 'app-file-upload',
  templateUrl: './fileUpload.component.html',
  styleUrls: [ './fileUpload.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,

  animations: [ Animations, slideInOutAnimation,
    trigger('growUp', [
      transition(':enter', [
        style({ transform: 'translateY(275%) scale(0.1)' }),
        // style({ transform: 'scale(0.1)'}),
        animate('700ms ease-in', style({ transform: 'translateY(0%) scale(1)' })),
        // animate('500ms ease-in', style({ transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        animate('700ms ease-in', style({ transform: 'translateX(275%) scale(0.1)' })),
        // animate('500ms ease-in', style({ transform: 'scale(0.1)' }))
      ]),
    ]),
    trigger('growXshrink', [
      transition(':enter', [ style({ transform: 'translateX(275%) scale(0.1)' }),
      animate('500ms ease-in-out', style({ transform: 'translateX(0%)' })),
      animate('500ms ease-in', style({ transform: 'scale(1)' })) ]),
      transition(':leave', [ animate('500ms ease-in-out', style({ transform: 'translateX(275%)' })),
      animate('500ms ease-in', style({ transform: 'scale(0.1)' })) ]),
    ]),
  ],
  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '' }
})
export class FileUploadComponent implements OnInit, OnDestroy, AfterViewInit {
  public httpService! : HttpService;
  public signedInUserService! : SignedInUserService;
  // ----------------------------------------------------------
  public boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  public buffer: any[] = [];
  @Input() content : Content = new Content();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public files : File[] = [];
  @ViewChild('fUpForm', { read: ElementRef, static: true }) fileUploadForm! : ElementRef;
  public chatFileUpload : ChatFileUpload = new ChatFileUpload();
  @Input() isToggleFileUpload = false;
  public isCurrentFile = false;
  public isCameraVisible = false;
  public isFormValid = false;
  public isMobilevar = false;
  public isSubmitted = false;
  @Input() isViewMember = false;
  public isUploadSuccess = false;
  @ViewChild('imageUploadForm', { read: ElementRef, static: true }) imageUploadForm! : ElementRef;
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public message = '';
  public percentDone = 0;
  public progress : any;
  public profilePics : ProfilePics = new ProfilePics();
  public formData = new FormData();
  public selectedFiles : File[] = [];
  public signedInUser : SitUser = new SitUser();
  public status : any;
  public tempPicStrArr: string[] = [];
  // ---------------------------------------------------------------
  constructor (
    private commonServiceService : CommonServiceService,
    private coreServiceService: CoreServiceService,
    private router: Router
  ) {
    if (coreServiceService) {
      this.httpService = this.coreServiceService.getHttpService();
    }
    if (commonServiceService) {
      this.signedInUserService = this.commonServiceService.getSignedInUserService();
    }
  }
  ngOnInit() {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    // -------------------------------------------------------------
    this.signedInUser = EmitterSubjectService.getSignedInUser();

    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.signedInUser)
      || this.signedInUser.sitUserId === 0
      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) && this.signedInUser.sitUserId !== this.loginSuccess.signedInUserId){
      this.signedInUserService.getSingedInSitUser().subscribe(data => {
        this.signedInUser = data;
        this.profilePics = CopyServiceStatic.createProfilePicsModelFromSitUser(this.signedInUser);
        EmitterSubjectService.setProfilePics(this.profilePics);
      });
    }
    // -------------------------------------------------------------
    
    EmitterSubjectService.fileImageEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
          if (typeof result === 'string') {
            this.tempPicStrArr.push(result);
          }
          // debugger;
        }
        this.content.chatFileUpload.picArr = this.tempPicStrArr;
        this.ngAfterViewInit();
      });
  
    // ------------------------------------------------------------------------------------------------
    EmitterSubjectService.isToggleFileUploadEmitter
        .pipe (takeUntil(this.emitterDestroyed$))
        .subscribe((result) => {
       // debugger;
    
      this.isToggleFileUpload = result as boolean;
      this.ngAfterViewInit();
    });
  }
  // ---------------------------------------------------------------
  ngAfterViewInit () {
    // prevent memory leak when component destroyed
    // this.isFormValid = this.uploadService.isFileUploadFormValid();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ---------------------------------------------------------------
  cancel () : void {
    this.chatFileUpload.fileMap = new Map();
    // debugger;
    this.isToggleFileUpload = false;
    this.isCameraVisible = !this.isCameraVisible;
    EmitterSubjectService.emitIsToggleFileUpload(this.isToggleFileUpload);
    EmitterSubjectService.emitIsCameraClosedBool(!this.isToggleFileUpload);
    // -------------------------------------------------------------------------
    // Note: Date: 20220508
    //       isCameraVisibleEmitter && isCameraGlypgVisibleEmitter work as a pair / tuple
    //       emitters that alters the isCameraVisible' value.
    //       the isCameraGlypgVisibleEmitter is emitted by FileUploadComponent and listened to by PagerVerticalPhotoMgmtComponent
    //       and isCameraVisibleEmitter is emitter by PagerVerticalPhotoMgmtComponent and listened to by FileUploadComponent
    //       so that there is no cyclic published and subscriber.
    // -------------------------------------------------------------------------
    EmitterSubjectService.emitIsCameraGlyphVisible(this.isCameraVisible);
  }
  // ---------------------------------------------------------------
  // cancelFileUpload () : void {
  //  // this.content = new Content();  // TPDO: restore
  //  this.selectedFiles = new FileList();
  // }
  
  // ---------------------------------------------------------------
  // TODO: necessity of this? or delete:
  // -----------------------------------
  // editFile(paragraph) {
  //  if (paragraph) {
  //    this.content = paragraph;
  //    this.content.heading = this.encodingService.trimParagraphTags(this.content.heading);
  //    this.content.heading = this.content.heading.replace(/Figure-\d+:/g, '');
  //    // We want a different styling for the file during editing so that it displays smaller on the fileUploadUI
  //    this.content.contents = this.content.contents.replace(/code-img/g, 'stamp');
  //    this.isCurrentFile = true;
  //    // this.content.writePadAction = 'EditImage';
  //    ScrollTopServiceStatic.scrollToBottom('upload');
  //  }
  // }
  // --------------------------------------------------------------
  getUrl(data: any): any {
    if (data.length > 0) {
      return 'url(\'data:image/jpeg;base64,' + data + '\')';
    }
  }
  // ---------------------------------------------------------------
  isFileUploadFormValid () : boolean {
    debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.fileUploadForm)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.fileUploadForm.nativeElement)
      && this.fileUploadForm.nativeElement.valid) {
      debugger;
      this.isFormValid = true;
    }
    return this.isFormValid;
  }
  // ---------------------------------------------------------------
  // Note : The fileUploadForm handdles two scenarios:
  //     1. When we actually load file(s) to be sent
  //     2. When we only update the heading/description of the file, not the actual file
  // ---------------------------------------------------------------

  submitFileUploadForm () {
     // debugger;
    UploadServiceStatic.sendWithGeoLocation(this.content, this.httpService).subscribe(data => {
      this.profilePics = data;
    });

     // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics) && this.profilePics.sitUserId > 0) {
      // debugger;
      // TODO : check if content.chatFileUplod is the only model should be used!!!
      // if (this.content.chatFileUpload.fileMap.size > 0) {
      //  let tFiles = this.content.chatFileUpload.fileMap.values as File[] | any;
      // UploadServiceStatic.saveFile(tFiles); // todo: restore
      // debugger;
      // Note: tempStringFiles did not capture the image's box-nonce properly:
      // this.profilePics = UploadServiceStatic.sendWithGeoLocation(this.content, this.profilePics, this.tempStringFiles, this.httpService);

      // experimental: begin
      // this.profilePics = UploadServiceStatic.sendWithGeoLocation(this.content, this.tempPicStrArr,  this.httpService);
      // experimental: end
      // } else if (this.content.heading.length > 0 && this.content.chatFileUpload.fileMap && this.content.chatFileUpload.fileMap.size > 0 ) {
      //  // we clear out the contents since the content-with-image-data does not contain contents-body
      //  this.content.contents = '';
      // }
      
    // }
  }
  // ---------------------------------------------------------------
  getDescription ($event:any) : string {
    // debugger;
    if ($event && $event.target && $event.target.value && $event.target.value.length > 0) {
      // debugger;
      this.content.heading = $event.target.value as string;     
    }
    // debugger;
    return this.content.heading;
  }
  // ---------------------------------------------------------------
  uploadFiles ($event:any) : any {
    // capture the files, and use upload() method. these two goes hand-in-hand:
    // ------------------------------------------------------------------------
    this.selectedFiles = $event.target.files;

    // campture formData:
    // ------------------
    // debugger;
    let formData = new FormData();
    let formDataArr : FormData[] = [];
    let files : any[] = [];
    //let imgeDataStrArr : string[] = [];

    if ($event && $event.target && $event.target.files && $event.target.files.length > 0) {
      // debugger;
      files = $event.target.files as File[]
      for (var i = 0; i < files.length; i++) {
        // capture the image of the file:
        // ------------------------------
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(files[ i ])) {
          // debugger;
          let imageData = UploadServiceStatic.fileToString(files[ i ]);
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)) {
            this.tempPicStrArr.push(imageData);
            // debugger;
          }
        }

      
        //formData = new FormData();
        //formData.append(files[ i ].name, files[ i ]);
        //formData.append('contentType', files[ i ].type);
        //formData.append('lastModified', files[ i ].lastModified);
        //formData.append('lastModifiedDate', files[ i ].lastModifiedDate);
        //formData.append('fileName', files[ i ].name);
        //formData.append('size', files[ i ].size);
        //// debugger;
        //formDataArr.push(formData);

        this.content.chatFileUpload.fileArr.push(files[ i ])
        this.content.chatFileUpload.fileMap.set(i, files[ i ]);
      }
      // this.content.chatFileUpload.formDataArr = formDataArr;
      
      // this.content.chatFileUpload.filesToStringArr = StringServiceStatic.createArrayFromMap(this.content.chatFileUpload.fileMap);
      // debugger;     
    }
    this.isCurrentFile = true;
    this.content.chatFileUpload.picArr = this.tempPicStrArr;
    return files;
  }
  // -----------------------------------------------
  selectFile ($event : any) : void {
    // debugger;
    this.selectedFiles = $event.target.files;
  }
  // -----------------------------------------------
  uploadFileArr () : void {
    // debugger;
    // this.progress = 0;
    // debugger;
    // let imgeDataStrArr : string[] = [];
    // // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.fileUploadForm)
    // //  && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.fileUploadForm.nativeElement)
    // //  && this.fileUploadForm.nativeElement.valid) {

    // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.selectedFiles) && this.selectedFiles.length > 0) {
    //  debugger;
    //  for (var i = 0; i < this.selectedFiles.length; i++) {
    //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.selectedFiles[i])) {
    //      debugger;
    //      let imageData = UploadServiceStatic.fileToString(this.selectedFiles[ i ]);
    //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)) {
    //        imgeDataStrArr.push(imageData);
    //        debugger;
    //      }
    //    }
    //  }
    // }
    // this.content.chatFileUpload.picArr = imgeDataStrArr;
    // debugger;
    UploadServiceStatic.sendWithGeoLocation(this.content, this.httpService).subscribe(data => {
      this.profilePics = data;
    });


      // OR
      // Note: To send file using formaData with progress:
      // -------------------------------------------------
      // this.uploadFilesService.upload( this.currentFile ).subscribe(
      //  ( event : any ) =>
      //  {
      //    if ( event.type === HttpEventType.UploadProgress )
      //    {
      //      this.progress = Math.round( 100 * event.loaded / event.total );
      //    } else if ( event instanceof HttpResponse )
      //    {
      //      this.message = event.body.message;
      //      this.fileInfos = this.uploadFilesService.getFiles();
      //    }
      //  },
      //  ( err : any ) =>
      //  {
      //    console.log( err );
      //    this.progress = 0;

      //    if ( err.error && err.error.message )
      //    {
      //      this.message = err.error.message;
      //    } else
      //    {
      //      this.message = 'Could not upload the file!';
      //    }

      //    this.currentFile = undefined;
      //  } );
    // }
  }
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
}
