'use-strict'
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { Bool } from '../../../models/common/bool.model';
import { Content } from '../../../models/common/content.model';
import { KeyValueResult } from '../../../models/keyValue/keyValueResult.model';
import { KvAny } from '../../../models/keyValue/kvAny.model';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { ProfileContentService } from '../../../services/profileServiceService/profileContentService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { Animations, slideInOutAnimation } from '../../animation';
import { DbServiceService } from '../../../services/dbServiceService/dbServiceService.service';
import { DictionaryService } from '../../../services/dictionaryServiceService/dictionaryService.service';
import { DbDexieToDictionaryService } from '../../../services/dbServiceService/dbDexieToDictionaryService.service';
import { DictionaryServiceService } from '../../../services/dictionaryServiceService/dictionaryServiceService.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { KvArrayService } from '../../../services/coreServiceService/kvArrayService.service';

@Component({
  selector: 'app-content-key-value-arr',
  templateUrl: './contentKeyValueArr.component.html',
  styleUrls: [ './contentKeyValueArr.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    Animations, slideInOutAnimation
  ],
})
export class ContentKeyValueArrComponent implements OnInit, OnDestroy {
  public dictionaryService! : DictionaryService;
  public dbDexieToDictionaryService! : DbDexieToDictionaryService;
  public kvArrayService! : KvArrayService;
  @Input() contentKvAnyArr: KvAny[] = [];
  @Input() kvAnyLegend = '';
  @Input() kvAnyName = '';
  @Input() isFormSubmitted = false;
  @Input() isRequired = false;
  public kvArrForm!: FormGroup;
  public kvArrResult: KeyValueResult = new KeyValueResult();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isMobilevar = false;
  public IsOpen = new Bool();
  public isProfContentClosedBool : Bool = new Bool();
  public loginSuccess!: LoginSuccess;
  public selectedKvAny!: FormControl;
  public selectedKvArrIndex = -1;
  public preferenceKvAnyArr : KvAny[] = [];
  public profileContent : Content = new Content();
  public sitUser : SitUser = new SitUser();
  @Input() sitUserId = 0;
  // ---------------------------------------------------------------
  constructor (
    private cdr : ChangeDetectorRef,
    private coreServiceService : CoreServiceService,
    private dbServiceService : DbServiceService,
    private dictionaryServiceService : DictionaryServiceService,
    private fb : FormBuilder,
    private profileContentService : ProfileContentService,
    private router: Router,
    @Inject(DOCUMENT) document: any,
  ) {
    if (this.dbServiceService) {
      this.dbDexieToDictionaryService = this.dbServiceService.getDbDexieToDictionaryService() as DbDexieToDictionaryService;
    }
    if (this.dictionaryServiceService) {
      this.dictionaryService = this.dictionaryServiceService.getDictionaryService();
    }
    if (coreServiceService) {
      this.kvArrayService = this.coreServiceService.getKvArrayService();
    }
    if (this.isRequired) {
      this.kvArrForm = this.fb.group({
        /*
         * 'radioSelection' : this.fb.control(this.radioboxName, [
         * Validators.required])
         */
      });
    }
    /*
     * else this.radioboxForm = this.fb.group({
     * 'radioSelection' : this.fb.control(this.radioboxName)
     * });
     */

    this.kvArrResult.kvName = this.kvAnyName;
    // debugger;
  }
  ngOnInit() {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    // debugger;
    if (this.sitUserId > 0) {
      // debugger;
      // get dictionary-value first:
      // ---------------------------
      this.profileContent = this.dictionaryService.profileContentDictionary.get(this.sitUserId);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileContent)) {
        this.contentKvAnyArr = this.kvArrayService.getProfileContentToKvArrArr(this.profileContent); 
        this.ngAfterViewInit();
        // debugger;
      }

      // get from the backend-server:
      // ----------------------------
      this.profileContentService.getProfileContent(this.sitUserId).subscribe(data => {
        this.profileContent = data;
        this.dictionaryService.updateDictionary(this.profileContent, 'profileContent', this.profileContent.sitUserId);

        this.dbDexieToDictionaryService
          .saveDictionaryToDbDexie(
            this.dictionaryService.profileContentDictionary, 'profileContent', this.loginSuccess.signedInUserId);
         // debugger;
        this.ngAfterViewInit();
      })
    }
    // --------------------------------------------------------------
    EmitterSubjectService.profileContentKvAnyArrEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        // debugger;
        this.contentKvAnyArr = result;
        this.ngAfterViewInit();
      });
  }
  // -------------------------------------------------------------
  public ngAfterViewInit () : void {
    // EmitterSubjectService.emitHideSpinner(true);    
    // debugger;
    this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.contentKvAnyArr = [];
  }
  /*
   * ---------------------------------------------------------------
   * ref:https:// www.positronx.io/angular-checkbox-tutorial/
   */
  onKeyValueChange(e:any): void {
    if (e.target.checked) {
      /*
       * this.selectedRadiobox = this.radioboxForm.get('radioSelection') as FormControl;
       * this.selectedRadiobox.patchValue('');
       * this.selectedRadiobox.patchValue(e.target.value);
       */

      this.kvArrResult.kvName = this.kvAnyName;
      this.kvArrResult.kvSelection = e.target.value;
      this.kvArrResult.isRequiredPassed = this.isRequired === true ? this.isRequiredPassed() : false;
      EmitterSubjectService.emitKeyValueResult(this.kvArrResult);
    }
  }
  // ---------------------------------------------------------------------------------
  //  Note: isOpen is opposite of isClosed.
  // ---------------------------------------------------------------------------------
  isOpen ( isClosed: any )
  {
    // debugger;
    let value = !isClosed;
    this.IsOpen.isClosed = value;
    this.IsOpen.name = 'contentKeyValueArrComponentId';
    if (!this.IsOpen.isClosed) {
      // debugger;
      if (this.sitUserId > 0) {
        this.profileContentService.getProfileContent(this.sitUserId).subscribe(data => {
          this.profileContent = data;
          // debugger;
          this.ngAfterViewInit();
        })
      }
    }

    EmitterSubjectService.emitIsViewMember(true); // TODO: may need to set instead of emitting
    
  }
  // ---------------------------------------------------------------
  isRequiredPassed(): any {
    return this.kvArrResult.kvSelection.length > 0;
  }
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  //public extractDataFromMemberViewMgmtModel (data : MemberViewMgmtModel) : void {
  //  // debugger;
  //  this.memberViewMgmtModel = data; // EmitterSubjectService.getMemberViewMgmtModel(); // Watch: location-View -4

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel)) {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.profileInfoView) && this.memberViewMgmtModel.profileInfoView.sitUserId > 0) {
  //      this.profileInfoView = this.memberViewMgmtModel.profileInfoView;
  //    }
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.kvAnyArr) && this.memberViewMgmtModel.kvAnyArr.length > 0) {
  //      this.profileInfoKvAnyArr = this.memberViewMgmtModel.kvAnyArr;
  //    }
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.profileContentKvAnyArr) && this.memberViewMgmtModel.profileContentKvAnyArr.length > 0) {
  //      this.contentKvAnyArr = this.memberViewMgmtModel.profileContentKvAnyArr;
  //    }
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.preferenceKvAnyArr) && this.memberViewMgmtModel.preferenceKvAnyArr.length > 0) {
  //      this.preferenceKvAnyArr = this.memberViewMgmtModel.preferenceKvAnyArr;
  //    }
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.profileTile)
  //      && this.memberViewMgmtModel.profileTile.sitUserId > 0) {
  //      this.profileTile = this.memberViewMgmtModel.profileTile;
  //    }
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.sitUser)
  //      && this.memberViewMgmtModel.sitUser.sitUserId > 0) {
  //      this.sitUser = this.memberViewMgmtModel.sitUser;
  //    }
  //  }
  //}
  // ---------------------------------------------------------------------------------
}
