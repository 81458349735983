'use-strict'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../../models/boxNonce/boxNonceEntity.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { Content } from '../../../models/common/content.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { SpinnerModel } from '../../../models/common/spinnerModel.model';
import { UserAndPageTitle } from '../../../models/common/userAndPageTitle.model';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { HttpService } from '../../../services/coreServiceService/httpService.service';
import { ModuleComponentLoaderServiceStatic } from '../../../services/staticServices/commonStaticServices/moduleComponentLoaderServiceStatic.service';
import { ScrollTopServiceStatic } from '../../../services/staticServices/commonStaticServices/scrollTopServiceStatic.service';
import { SlakezSaltServiceStatic } from '../../../services/staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';

// Note: this component will be used during creating/editing a profile only

@Component({
  selector: 'app-profile-content',
  templateUrl: './profileContent.component.html',
  styleUrls: ['./profileContent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ Animations, slideInOutAnimation,
  ],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '' }
})
export class ProfileContentComponent implements OnInit, OnDestroy, AfterViewInit {
  public redirectionService! : RedirectionService;
  // ----------------------------------------------------------
  public boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  public click : boolean = false;
  public profileContent: Content = new Content();
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public httpService! : HttpService;
  public intervalMsg : any;
  public isEditProfile = false;
  public isFormSubmitted = false;
  public isFormValid = false;
  public isMobilevar = false;
  public isOnLine = false;
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public message = '';
  public pagerBrdCrm : PagerBreadcrum = new PagerBreadcrum();
  public paramId = 0;
  @ViewChild('profileContentForm', { static: false})
  profileContentForm : any;
  public spinnerModel : SpinnerModel = new SpinnerModel();
  public timer : any;
  public timerArray : any[] = [];
  public userAndPageTitle: UserAndPageTitle = new UserAndPageTitle();
  // ---------------------------------------------------------------
  constructor(
    private activatedRoute: ActivatedRoute,
    public cdr : ChangeDetectorRef,
    private commonServiceService: CommonServiceService,
    private coreServiceService: CoreServiceService,
    // private dbServiceService: DbServiceService,
    private router: Router
  ) {
    // if (dbServiceService) {
    //  this.data = dbServiceService.getInMemoryDataService();
    // }
    this.redirectionService = this.commonServiceService.getRedirectionService();
    if (this.coreServiceService) this.httpService = this.coreServiceService.getHttpService();

    this.activatedRoute.params.subscribe((params) => (this.paramId = this.profileContent.signedInUserId = params.id));

    if (this.profileContent.signedInUserId > 0) {
      this.getProfileContent();
    }
    // ---------------------------------------------------------------
    this.setPageTitle();
  }
  // ---------------------------------------------------------------
  ngOnInit() {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    // EmitterSubjectService.emitSetBreadcrumForPageSwitch(true); //TODO: check if it is needed
    // -----------------------------------------------------------
    if (this.profileContent.signedInUserId === 0) {
      this.profileContent.signedInUserId = this.loginSuccess.signedInUserId;
    }
    // -----------------------------------------------------------
    this.getProfileContent();
    // -----------------------------------------------------------
    EmitterSubjectService.isEditProfileEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
    {
      this.loginSuccess.isEditProfile = result as boolean;
      this.loginSuccess.isProfileContentComplete = false;
      EmitterSubjectService.setLoginSuccess( this.loginSuccess );
    } );
    // ----------------------------------------------------------------------------------

    // ----------------------------------------------------------------------------------
    // this.timer = setTimeout( () =>
    // {
    //  // debugger;
    //  this.mcLoader.componentName = 'profileContentComponentId';
    //  this.mcLoader.moduleName = 'profilem';
    //  EmitterSubjectService.emitRemoveComponent( this.mcLoader );
    // }, 500 );

    //// debugger;
    // this.timerArray.push( this.timer );

    // -----------------------------------------------------------
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    /*
     * -----------------------------------------------------------
     * reset the background-image and glyphicon-menu
     */
     // EmitterSubjectService.emitResetDbgi(true);
     // EmitterSubjectService.emitDisplayGlyphiconMenu([]);


    // -----------------------------------------------------------
    /*
     * -----------------------------------------------------------
     * debugger;
     */
    if (this.loginSuccess && this.loginSuccess.signedInUserId > 0) {
      // debugger;
      if (this.profileContent.signedInUserId === 0) {
        this.profileContent.signedInUserId = this.loginSuccess.signedInUserId;
      }
      this.redirectionBeforeSubmit();
    }
    /*
     * -----------------------------------------------------------
     * ScrollTopServiceStatic.scrollToTop('Top');
     */
    ScrollTopServiceStatic.scrollToLocation( 'allScrollTotId', 'start' );
  }
  // ---------------------------------------------------------------------------------
  ngAfterViewInit() {
    // debugger;
    this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.resetPageTitle();
    this.timerArray.forEach( ( timer ) => clearInterval( timer ) );
  }
  // ---------------------------------------------------------------
  cancel(): void {
    this.mcLoader = new ModuleComponentLoader();
    this.mcLoader.componentName = 'memberScroll';
    this.mcLoader.moduleName = 'memberscrollm';
    this.mcLoader.sitUserId = this.loginSuccess.signedInUserId;
    this.mcLoader.router = this.router;
    // Note: setupMcLoderModel before fireMcLoaderRoute()!!
    // ---------------------------------------------------
    this.mcLoader = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(this.mcLoader);
    EmitterSubjectService.setMCLoader( this.mcLoader );
    this.redirectionService.fireMcLoaderRoute(this.mcLoader).subscribe(data => {
      this.pagerBrdCrm = data;
    });
  }
  // ---------------------------------------------------------------
  // determineRedirection(): void {
  //  /*
  //   * debugger;
  //   * Note: if isEditProfile==true, profileContent must be visited even when its completed before
  //   */
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess(); // get the updated data
  //  if (this.loginSuccess && this.loginSuccess.signedInUserId > 0) {
  //    if (this.loginSuccess.isProfileContentComplete && this.loginSuccess.isEditProfile) {
  //      /*
  //       * TODO: restore memberView after completing photoMgmt
  //       * this.router.navigate(['/memberView/' + this.loginSuccess.signedInUserId]);
  //       */
  //      this.router.navigate(['/myPhotos/' + this.loginSuccess.signedInUserId]);
  //    }
  //  }
  // }
  // ---------------------------------------------------------------
  redirectionBeforeSubmit () : void
  {
    /*
     * debugger;
     * Note : if isEditProfile==true, preference must be visited even when its completed before
     */
    this.loginSuccess = EmitterSubjectService.getLoginSuccess(); // get the updated data
    this.isEditProfile = EmitterSubjectService.getIsEditProfile();
    if ( this.loginSuccess && this.loginSuccess.signedInUserId > 0 )
    {
      if (!this.isEditProfile) {
        if (this.loginSuccess.isProfileContentComplete) {          
          this.mcLoader.componentName = 'myPhotoMgmt';
          this.mcLoader.moduleName = 'mym';
          this.mcLoader.sitUserId = this.loginSuccess.signedInUserId;
          this.mcLoader.router = this.router;
          this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.loginSuccess.signedInUserId).subscribe(data => {
            this.pagerBrdCrm = data;
          });
        }
        else {
          // complete this step
        }
      }
      else {
        // complete this step
      }
    }
  }
  // ---------------------------------------------------------------
  redirectionAfterSubmit () : void
  {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if ( this.loginSuccess && this.loginSuccess.signedInUserId > 0 )
    {      
      this.mcLoader = new ModuleComponentLoader();
      this.mcLoader.sitUserId = this.mcLoader.id = this.loginSuccess.signedInUserId;
      this.mcLoader.router = this.router;               
      this.mcLoader.componentName = 'myPhotoMgmt';
      this.mcLoader.moduleName = 'mym';
      this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.loginSuccess.signedInUserId).subscribe(data => {
        this.pagerBrdCrm = data;
      });    
    }
  }

  // ---------------------------------------------------------------
  /*
   * ---------------------------------------------------------------
   * Note: this will be used only during profileContent creating/editing
   */
  getProfileContent(): void {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (this.loginSuccess?.signedInUserId > 0) {
      this.profileContent.signedInUserId = this.loginSuccess.signedInUserId;
      this.profileContent.sitUserId = this.loginSuccess.signedInUserId;

      EmitterSubjectService.emitShowSpinner('Getting your profile Content & highlights....');

      this.salt();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
        this.httpService
          .postObservable(
            '/Member/GetProfileContent',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            },
            'json2text'
          )
          .subscribe(
            (result) => {
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                // debugger;
                const bn = result;

                if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
                  this.profileContent = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as Content;

                  // debugger; // TODO: check for profileId in Content!!!

                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileContent) && this.profileContent.signedInUserId > 0 && this.profileContent.profileId > 0) {
                    this.profileContent.isSuccess = true;
                    EmitterSubjectService.emitHideSpinner(true);
                    this.ngAfterViewInit();
                    /*
                     * var tProfileContent = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(result as BoxNonce)) as Content;
                     * debugger;
                     * this.profileContent = tProfileContent as Content;
                     * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileContent) && this.profileContent.signedInUserId > 0) {
                     * this.profileContent.isSuccess = true;
                     * debugger;
                     */
                  }
                } else {
                  if (this.profileContent) {
                    this.message = 'Error occured in getProfileContent();\n Error-mag: ' + this.profileContent.message;
                    EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.message });
                  } else {
                    this.message = 'Error occured in getProfileContent();\n Error-mag: ';
                    EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.message });
                  }
                  EmitterSubjectService.emitHideSpinner(true);
                }
              } else {
                this.message = 'Error occured in getProfileContent();\n Error-mag: ';
                EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.message });
                EmitterSubjectService.emitHideSpinner(true);
              }
            },
            (error) => {
              // alert ('Error occured in GetArticleContent(' + idArr[i] + ');\n Error-mag: ' + error);
              this.message = 'Error occured in getProfileContent();\n Error-mag: ' + error.message;
              EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.message });
              EmitterSubjectService.emitHideSpinner(true);
            }
        );

        EmitterSubjectService.emitHideSpinner(true);
      }
      else {
        EmitterSubjectService.emitHideSpinner(true);
      }
    }
  }
  // ---------------------------------------------------------------
  // isFormValid(): boolean {
  //  return this.profileContent.contents && this.profileContent.contents.length > 0 && this.profileContent.heading && this.profileContent.heading.length > 0;
  // }

  // ---------------------------------------------------------------
  resetPageTitle(): any {
    this.userAndPageTitle.pageTitle = '';
    this.userAndPageTitle.sitUserId = 0;
    // debugger;
    EmitterSubjectService.emitUserAndPageTitle(this.userAndPageTitle);
    return true;
  }
  // ---------------------------------------------------------------
  setPageTitle () : any {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.profileName) && this.loginSuccess.profileName.length > 0) {
      this.setUserPageTitle(this.loginSuccess.profileName);
    }
    else {
      this.userAndPageTitle.sitUserId = this.loginSuccess.signedInUserId;
      let msg = this.loginSuccess.isEditProfile === true ? '-: Edit ' : '-: Create';

      msg += ' Profile Highlights and Description <br/> Step-3 of 3 :-';
      this.userAndPageTitle.pageTitle = msg;
      this.userAndPageTitle.isPrepared = true;

      // debugger;
      EmitterSubjectService.emitUserAndPageTitle(this.userAndPageTitle);
    }
    return true;  
  }
  // ---------------------------------------------------------------
  setUserPageTitle (profileName: string) : any {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileName) && profileName.length > 0) {
      this.userAndPageTitle.sitUserId = this.loginSuccess.signedInUserId;
      let msg = this.loginSuccess.isEditProfile === true ? '-: Edit ' : '-: Create';

      msg += ' Profile Highlights and Description of <span style=\'font-size:0.75rem;\'>(</span><span class=\'grnBluGldxS\'>';
      msg += profileName + '</span><span style=\'font-size:0.75rem;\'>)</span> <br/> Step-3 of 3 :-';
      this.userAndPageTitle.pageTitle = msg;
      this.userAndPageTitle.isPrepared = true;

      // debugger;
      EmitterSubjectService.emitUserAndPageTitle(this.userAndPageTitle);
    }
    return true;
  }
  // ---------------------------------------------------------------
  salt(): void {
    // debugger;
    this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt(JSON.stringify(this.profileContent));
  }
  // ---------------------------------------------------------------
  submitProfileContentForm () : void {
    this.click = !this.click;
    // start spinner:
    this.spinnerModel = new SpinnerModel();
    this.spinnerModel.message = 'Updating is in progress...';
    EmitterSubjectService.emitShowSpinner(this.spinnerModel);
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    this.profileContent.signedInUserId = this.loginSuccess.signedInUserId;

    if (this.profileContentForm.valid) {
      // debugger;
      EmitterSubjectService.emitShowSpinner("Updating your Profile Content & Highlights...");
      this.salt();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
        this.isFormSubmitted = true;
        this.httpService
          .postObservable(
            '/Member/UpdateProfileContent',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            },
            'json2text'
          )
          .subscribe(
            (result) => {
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                const bn = result;

                if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
                  const tProfileContent = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as Content;
                  // debugger;

                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tProfileContent) && tProfileContent.signedInUserId > 0 && tProfileContent.profileId > 0 && tProfileContent.contentId > 0) {
                    this.profileContent = tProfileContent;
                    this.profileContent.isSuccess = true;                   

                    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
                    this.loginSuccess.isProfileContentComplete = true;
                    this.loginSuccess.isProfileComplete = this.loginSuccess.isPreScreenComplete && this.loginSuccess.isProfileInfoComplete && this.loginSuccess.isPreferenceComplete && this.loginSuccess.isProfileContentComplete;
                    EmitterSubjectService.setLoginSuccess(this.loginSuccess);
                    this.message = this.profileContent.message = 'Updating Step-4 of 4 (last step) of your Profile (more about you) is successful!';

                    this.ngAfterViewInit();
                    this.setMessageTimer();
                    ScrollTopServiceStatic.scrollToTop('allScrollToTopId');

                    EmitterSubjectService.emitHideSpinner(true);
                    // debugger;
                    this.redirectionAfterSubmit();
                    // debugger;
                  }
                } else {
                  if (this.profileContent) {
                    this.message = 'Error occured in submitProfileContent();\n Error-mag: ' + this.profileContent.message;
                    EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.message });
                  } else {
                    this.message = 'Error occured in submitProfileContent();\n Error-mag: ';
                    EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.message });
                  }
                  EmitterSubjectService.emitHideSpinner(true);
                }
              } else {
                this.message = 'Error occured in submitProfileContent();\n Error-mag: ';
                EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.message });
                EmitterSubjectService.emitHideSpinner(true);
              }
            },
            (error) => {
              // alert ('Error occured in GetArticleContent(' + idArr[i] + ');\n Error-mag: ' + error);
              this.message = 'Error occured in submitProfileContent();\n Error-mag: ' + error.message;
              EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.message });
              EmitterSubjectService.emitHideSpinner(true);
            }
          );
      }
      else {
        EmitterSubjectService.emitHideSpinner(true);
      }
    }
  }
  // ---------------------------------------------------------------------------------
  setMessageTimer(): void {
    this.intervalMsg = setInterval(() => {
      this.message = this.profileContent.message = '';
      clearInterval(this.intervalMsg);
      // this.router.navigate(['/memberView/' + this.loginSuccess.signedInUserId]);
    }, 60000);

    clearInterval(this.intervalMsg);
  }
  // ---------------------------------------------------------------
}
