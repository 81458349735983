'use-strict'
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component,
    ElementRef, Input, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { Heartbeat } from '../../../models/common/heartbeat.model';
import { Unit } from '../../../models/common/unit.model';
import { ProfileTile } from '../../../models/profile/profileTile.model';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { SitUserService } from '../../../services/commonServiceService/sitUserService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { HeartbeatService } from '../../../services/coreServiceService/heartbeatService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { StringServiceStatic } from '../../../services/staticServices/stringServiceStatic.service';


@Component({
  selector: 'app-distance',
  templateUrl: './distance.component.html',
  styleUrls: ['./distance.component.scss'],
  // 1)OnPush->requires an Observable to refresh the view; 2)Default->automatic refresh
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DistanceComponent implements OnInit, OnDestroy, AfterViewInit
{
  public heartbeatService! : HeartbeatService;
  public sitUserService! : SitUserService;
  private emitterDestroyed$: Subject<boolean> = new Subject();
  // ----------------------------------------------------------
  public isDefaultDistanceUnit = false;
  @Input() distanceUnit : Unit = new Unit();
  @Input() heartbeat: Heartbeat = new Heartbeat();
  public isMouseIn = false;
  public isMobilevar = false;
  public loginSuccess! : LoginSuccess;
  public message = '';
  @Input() profileTile : ProfileTile = new ProfileTile();
  @Input() sitUser : SitUser = new SitUser();
  public sitUserId = 0;
  public tempElem!: HTMLElement;
  public timer : any;
  public timerMap : Map<any, any> = new Map();
  public tempElemName = '';
 // -----------------------------------------------------------
  @ViewChild('distanceunit') distanceUnitElem! : ElementRef;
 // ---------------------------------------------------------------------------------
  constructor(private cdr: ChangeDetectorRef,
    private coreServiceService : CoreServiceService,
    private commonServiceService : CommonServiceService,
    private router : Router
     ) {
    if (this.coreServiceService) {
      this.heartbeatService = this.coreServiceService.getHeartbeatService();
    }
    if (this.commonServiceService) {
      this.sitUserService = this.commonServiceService.getSitUserService();
    }
    // --------------------------------
  }
 // ---------------------------------------------------------------------------------
  ngOnInit () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.heartbeat)) {
      this.distanceUnit = this.heartbeatService.computeDistance(this.loginSuccess.latitude, this.loginSuccess.longitude, this.heartbeat.latitude, this.heartbeat.longitude, true);
    }
    // --------------------------------------------------------------    
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();

    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) || this.sitUser.sitUserId === 0) {
      this.sitUser = EmitterSubjectService.getSitUserModel();
    }
    //if ((FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.distanceUnit) || this.distanceUnit.distance <= 0)) {
    //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {
    //    this.heartbeatService.getDistanceUnit(this.sitUser).subscribe(data => {
    //      this.distanceUnit = data;
    //    })
    //  }       
    //}
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {
      this.heartbeatService.getDistanceUnit(this.sitUser).subscribe(data => {
        this.distanceUnit = data;
      })
    } 
    // debugger
    // -------------------------------------------------------------------------
    EmitterSubjectService.distanceEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        debugger;
        this.distanceUnit = result as Unit;
        this.ngAfterViewInit();
      });
    // --------------------------------------------------------------
    // debugger;
    EmitterSubjectService.computeDistanceEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => { // signedInUser's heartbeat'
         debugger;
        this.loginSuccess.heartbeat = result;
        this.calcualateDistance(result, this.heartbeat);
        this.ngAfterViewInit();
      });
    // -------------------------------------------------------------
    this.isDefaultDistanceUnit = this.heartbeatService.isDefaultDistanceUnit(this.distanceUnit);
  }
  // ---------------------------------------------------------------
  ngAfterViewInit (): void
  {
    // debugger;
    // DO NOT get ProfileTile from Emitter here!
    this.cdr.detectChanges();
    // this.getDistanceUnit(this.sitUser).subscribe(data => {
    //  this.distanceUnit = data;
    // })
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.heartbeat)) {
      this.distanceUnit = this.heartbeatService.computeDistance(this.loginSuccess.latitude, this.loginSuccess.longitude, this.heartbeat.latitude, this.heartbeat.longitude, true);
    }
  }
 // ---------------------------------------------------------------------------------
  ngOnDestroy () : void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    
    this.timerMap.forEach(timer => clearTimeout(timer));
    this.timerMap.clear();
    // debugger;
  }
  // --------------------------------------------------------------
  checkMouseEvent ( event : Event ) : boolean
  {
    if ( event ) { this.isMouseIn = event.type.indexOf( 'MouseEvent' ) !== -1 ? true : false; }
    this.isMouseIn = !this.isMouseIn;
    return this.isMouseIn;
  }   
  // ---------------------------------------------------------------------------------
  clearTimerMap (): void {
    this.timerMap.clear();
  }
  // ---------------------------------------------------------------------------------
  calcualateDistance (destHeartbeat: any, sourceHeartbeat: any) : void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destHeartbeat) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceHeartbeat)) {
      this.heartbeatService.calculateDistance(destHeartbeat, sourceHeartbeat, true); // (dest, source, isKm)

    }
  }
  
  
  
  // ---------------------------------------------------------------
  setDistanceUnit (dUnit: Unit) : void {
    this.distanceUnit = dUnit;
    // debugger;
  }  
}

