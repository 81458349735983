
import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Chat } from '../../models/chat/chat.model';
import { ChatMessage } from '../../models/chat/chatMessage.model';
import { ChatConnectionInfo } from '../../models/communication/chatConnectionInfo.model';
import { ChatService } from '../communicationServiceService/chatService';
import { CoreServiceService } from '../coreServiceService/coreServiceService.service';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { FR } from 'angular-mydatepicker';

@Injectable({
  providedIn: 'any'
})
export class SignalRService implements OnInit, OnDestroy {
  public emitterDestroyed$: Subject<boolean> = new Subject();
  // ----------------------------------------------------------
  public cancellationToken: any;
  public chatConnectionInfo : ChatConnectionInfo = new ChatConnectionInfo();
  public isConnectionEstablished = false;
  public chat: Chat = new Chat();
  public newMessage: ChatMessage = new ChatMessage();
  public chatData: Chat[] = [];
  public count = 1;
  public delay = 500;
  public eventSource: any = null;
  public groupChatData : Chat[] = [];
  public hubConnection: signalR.HubConnection;
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public senderEmail = '';
  public timer: any;
  public timerArr: any[] = [];
  // -----------------------------------------------------
  constructor(
    private activatedRoute: ActivatedRoute,
    private chatService: ChatService,
    private coreServiceService: CoreServiceService,
    @Inject(Window) window: Window) {
    // this.activatedRoute.params.subscribe(params => this.signedInUserId = params.id);
    
  }
  // -----------------------------------------------------------------------
  ngOnInit () {    
    // Note: SignalR is initialized at ChatHistoryComponent.    
  }
  // ---------------------------------------------------------------
  ngOnDestroy () : void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.timerArr.map(e => clearInterval(e));
  }
  // -------------------------------------------------------------
  // Actively in use:
  // -------------------------------------------------------------
  // Note: the hubConnecction is initialized at each time a message is sent.
  public async initialize () : Promise<any> {
   // debugger;
   if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.hubConnection)
      || signalR.HubConnectionState[ 'Connected' ] !== 'Connected') {
      // debugger;
      this.createConnection().then(data => {
        this.hubConnection = data;
        // debugger;  
        this.registerOnEventsListener();

        // debugger;
        this.hubConnection.start().then(() => {
          // debugger;
          // Join method should be called from here: 20231030:
          // ------------------------------------------------
          this.hubConnection = this.join(this.hubConnection);
          console.log('Connection started');
        }).catch(err => { console.error(err); });

        this.isConnectionEstablished = true;
        // debugger;
        console.log('signal-r connection established.');
        return this.hubConnection;   
      }) 
   }
    else {
      return this.hubConnection;
    }
  }
  // --------------------------------------------------------------
  public getIsConnectionEstablished(): any {
    return this.isConnectionEstablished;
  }

  // -------------------------------------------------------------
  public getIsCancellationToken(): any  {
    return this.cancellationToken;
  }
  // -------------------------------------------------------------
  public clearTimers(): void {
    this.timerArr.map(e => clearInterval(e));
  }
  // -------------------------------------------------------------
  // Actively in use:
  // -------------------------------------------------------------
  public async createConnection () : Promise<any>
  {
    // ref: https://scientificprogrammer.net/2022/09/28/advanced-signalr-configuration-fine-tuning-the-server-side-hub-and-all-supported-client-types/
    if (!this.isConnectionEstablished || signalR.HubConnectionState[ 'Connected' ] !== 'Connected') {
      return this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl('/chatHub', {
          transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling,
          headers: { "Key": 'value' },
          accessTokenFactory: null,
          logMessageContent: true,
          skipNegotiation: false,
          withCredentials: true,
          timeout: 100000
        })
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect()
        .build();
    }
    else {
      // debugger;
      return this.hubConnection;
    }
  }
  // -------------------------------------------------------------
  public onDisconnect (hubConnection: any): void {
    hubConnection.disconnected(function () {
      setTimeout(function () {
        this.createConnection();
      }, 5000);
    });
  }
  // =============================================================
  // -------------------------------------------------------------
  // ChatHub-Functionalities:
  // Actively in use:
  // -------------------------------------------------------------
  // -------------------------------------------------------------
  public join(hubConnection: any): any {
    // debugger;

    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (this.isConnectionEstablished && this.loginSuccess.signedInUserId > 0)
    {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hubConnection))
      {
        // debugger;
        hubConnection.invoke('Join', this.loginSuccess.email)
          .then((data : any) => {
            // debugger;
            console.log('user joined successfully: ' + data);
            if (data) {
              // debugger;
              this.loginSuccess.hasJoinedSignalR = true;
              EmitterSubjectService.setLoginSuccess(this.loginSuccess);
            }
            return hubConnection;
          })
          .catch((err : any) => console.log(err));
      }
      else if (!this.loginSuccess.hasJoinedSignalR) {
        // debugger;
        this.timer = setTimeout(() => {
          // debugger;
          this.join(hubConnection);
        }, 1000);
        clearTimeout(this.timer);
        // Note:uniqueAddTimer
        if (!DateStringServiceStatic.isTimerFound(this.timerArr, this.timer)) {
          this.timerArr.push(this.timer);
        }
        return hubConnection;
      }
    }
    // else this.getConnectionToken();
    return hubConnection;
  }
  // -------------------------------------------------------------
  public leave(): any {
     if (this.hubConnection)
     this.hubConnection.invoke('Leave', this.loginSuccess.email)
        .catch(err => console.log(err));
     console.log('user left signal-r connection.')
    return true;
  }

  // -------------------------------------------------------------
  // Actively in use:
  // -------------------------------------------------------------
  public registerOnEventsListener = () => {
    // debugger;
    //  Reff: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/arguments
    // NOTE: IMPORTANT! Javascript in Strict-mode, `arguments` is a reserved word that represents and array of objects.
    //       It is to be used to retrieve the parameters of a function.
    //        for example: function(a,b,c){
    //          console.log(arguments[0]); // expected output is 1
    //          console.log(arguments[2]); // expected output is 2
    //          console.log(arguments[3]); // expected output is 3
    //        }
    //      Here, in our case, the 'chat message is a parameter, and the
    //       server-sent data is in the `arguments` where first index is `data` and the second index onward are the actual parameters
    //       In this case the message sent by the hub is the second index into the `arguments`, so
    //        arguments[0] = data;
    //        arguments[1] = passed value by the hub
    //  Not sure why the Hub does that, but this is the way it is and this is the only way to retrieve messages.
    // -----------------------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.hubConnection)) {
      this.hubConnection.on('SendMessage', (function (data : any) {
        // debugger;
        var result : any;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data.box)) {
          result = data;
          // debugger;
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 1 ]) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 1 ].box)) {
          result = arguments[ 1 ];
          // debugger;
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 0 ]) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 0 ].box)) {
          result = arguments[ 0 ];
          // debugger;
        }

        // debugger;

        EmitterSubjectService.emitChatMessage(result);
        // console.log('Sent-message data: ' + result);
        return true;
      }))
    }
  }
  // =============================================================
  // chat-functions:
  // Actively in use:
  // -------------------------------------------------------------
  // -------------------------------------------------------------
  public sendMessage(message: any): void {
    // debugger;
    
    var messageBne = message as BoxNonceEntity;   

     // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.hubConnection)
      && signalR.HubConnectionState[ 'Connected' ] === 'Connected') {
      // debugger;    
      this.hubConnection.send('SendMessage', messageBne)
        .catch(function (err) {
          console.error(err.toString());
        });
    }
    else {
      this.initialize().then(data => {
        this.hubConnection = data;
        debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.hubConnection)
          && signalR.HubConnectionState[ 'Connected' ] === 'Connected') {
          debugger;
          this.hubConnection.send('SendMessage', messageBne)
            .catch(function (err) {
              console.error(err.toString());
            });
        }
      })
    }
  }
  // -------------------------------------------------------------
  public chatFileMessage(chatFileMessage: string): void {
    //// debugger;
    if (this.isConnectionEstablished && this.loginSuccess.hasJoinedSignalR) {
      // console.log ('sending new-message:' + message);
      // debugger;
      this.hubConnection.invoke('ChatFileMessage', chatFileMessage).catch(function (err) {
        console.error(err.toString());
      })
      // debugger;
    }
    else if (!this.isConnectionEstablished) {
      // debugger;
      this.timer = setTimeout(() => this.createConnection(), 1000);
      clearTimeout(this.timer);
      // Note:uniqueAddTimer
      if (!DateStringServiceStatic.isTimerFound(this.timerArr, this.timer)) {
        this.timerArr.push(this.timer);
      }
    }
    else if (!this.loginSuccess.hasJoinedSignalR) {
       debugger;
      this.timer = setTimeout(() => {
        debugger;
        this.join(this.hubConnection);
      }, 1000);
      clearTimeout(this.timer);
      // Note:uniqueAddTimer
      if (!DateStringServiceStatic.isTimerFound(this.timerArr, this.timer)) {
        this.timerArr.push(this.timer);
      }
    }
  }
  // -------------------------------------------------------------
}
