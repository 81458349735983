import { CommunicationActivity } from './communicationActivity.model';
import { EnoteBox } from './enoteBox.model';

export class MailBox {
  public enoteBox : EnoteBox = new EnoteBox();
  public boxType = '';
  public communicationId = 0;
  public isInbox = true;
  public myEmails: CommunicationActivity[] = [];
  public myInboxEmails: CommunicationActivity[] = [];
  public mySavedEmails: CommunicationActivity[] = [];
  public mySentEmails: CommunicationActivity[] = [];
  public myTrashedEmails: CommunicationActivity[] = [];


  public myAllEnotes : CommunicationActivity[] = [];
  public myBuddyEnotes : CommunicationActivity[] = [];
  public myFriendEnotes : CommunicationActivity[] = [];
  public myLikeEnotes : CommunicationActivity[] = [];
  public myStarEnotes : CommunicationActivity[] = [];
  public myTrackEnotes : CommunicationActivity[] = [];
  public myUnlockEnotes : CommunicationActivity[] = [];
  public myWinkEnotes : CommunicationActivity[] = [];

  public sitUserId = 0;
  public signedInUserId = 0;

  public allTotal = 0;
  public buddyTotal = 0;
  public friendTotal = 0;
  public likeTotal = 0;
  public starTotal = 0;
  public trackTotal = 0;
  public unlockTotal = 0;
  public winkTotal = 0;
  constructor() { }
}
