
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KvPhotoServiceInterface } from '../../interfaces/serviceInterfaces/photoServiceIntefaces/kvPhotoServiceInterface';
import { PhotoServiceInterface } from '../../interfaces/serviceInterfaces/photoServiceIntefaces/photoServiceInterface';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Counters } from '../../models/common/counters.model';
import { Photo } from '../../models/common/photo.model';
import { Communication } from '../../models/communication/communication.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { ProfileImage } from '../../models/profile/profileImage.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { HttpService } from '../../services/coreServiceService/httpService.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { MemberService } from '../memberServiceService/memberService.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';

import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';
import { PhotoPrivacyService } from './photoPrivacyService.service';

@Injectable( {
  providedIn: 'root',
} )

// ------------------------------------------------------------------------------------------------
// Note:  DictionaryModule will populate all the dictionaries from the indexedDb if data exists.
//        Therefore, this service will NOT deal with indexedDb at all!
// TODO:  Move all indexedDb related functionalities into the DictionaryModuleComponent
//
// ------------------------------------------------------------------------------------------------
// Disclaimer:  this service accomplishes all activities regarding Photos only,
//              the photoBn is a model in which user's info as well as a box and nonce value of
//              encrypted( salted)  photo.
//               
//              this service will contain all the methods for getting photoBns from indexedDb,
//              have them available to photoBnDictionary with photo being unsalted and ready for dsiplay.
// ------------------------------------------------------------------------------------------------
export class KvPhotoService implements OnDestroy, KvPhotoServiceInterface
{
  public boxNonceEntity: BoxNonceEntity = new BoxNonceEntity();
  public counters: Counters = new Counters();
  public currentBn: BoxNonceEntity = new BoxNonceEntity();
  public currentKvPhoto: KvPhoto = new KvPhoto();
  public currentStateOfPhoto: Photo = new Photo();
  public emitterDestroyed$: Subject<boolean> = new Subject();
  public filteredKvPhotoArr: KvPhoto[] = [];
  public isBlocked = false;
  public isBuddy = false;
  public isFriend = false;
  public isIndexedDbData = true;
  public isLiked = false;
  public isMobilevar = false;
  public isOnLine = false;
  public isPhotoMgmt = false;
  public isPrivate = false;
  public isToggleCamera = false;
  public isBlockedByDictionaryData = false;
  public isBlockedByServerData = false;
  public isFriendByDictionaryData = false;
  public isFriendByServerData = false;
  public isLikedByDictionaryData = false;
  public isLikedByServerData = false;
  public isUnlockedByDictionaryData = false;
  public isUnlockedByServerData = false;
  public isViewMember = false;
  public isWaitingOnCurrStateOfUser = false;
  public kvPhotoArr: KvPhoto[] = [];
  public kvPhotoLock: KvPhoto = new KvPhoto();
  public kvPhotoUnLock: KvPhoto = new KvPhoto();
  public kvPhotoPrivate: KvPhoto = new KvPhoto();
  public kvPhotoPublic: KvPhoto = new KvPhoto();
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public message = '';
  public primaryKvPhoto: KvPhoto = new KvPhoto();
  public profileImageArr: ProfileImage[] = [];
  public profileImageKvPhotos: KvPhoto[] = [];
  public profilePics: ProfilePics = new ProfilePics();
  public profilePicsArr: ProfilePics[] = [];
  public profilePicsBoxNonceArr: BoxNonceEntity[] = [];
  // will contain member-selection of KvPhoto as final state of currentStateOfPhoto
  public selectedKvPhoto: KvPhoto = new KvPhoto();
  public sitUser: SitUser = new SitUser();
  public timer: any;
  public timerArray: any[] = [];
  public unsaltedIndexedDbData: any;
  public userPhotoIds: number[] = [];
  public userPhotos : Photo[] = [];

  constructor (

    public httpService: HttpService,
    public memberService: MemberService,
    public photoPrivacyService : PhotoPrivacyService,
  )
  {
    this.initialize();
    // -----------------------------------------------------------
    // TODO:  Move all indexedDb related functionalities into the triage...service
    // -----------------------------------------------------------
    //EmitterSubjectService.indexDbPhotoBnDictionaryEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( result =>
    //  {
    //    // debugger;
    //    DictionaryServiceStatic.profileImageDictionary = result;
    //    // debugger;
    //    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( DictionaryServiceStatic.profileImageDictionary ) )
    //    {
    //      // debugger;
    //      let pImageArr: ProfileImage[] = [];
    //      // debugger;
    //      pImageArr = DictionaryServiceStatic.profileImageDictionary.values();
    //      // }
    //      // debugger;
    //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pImageArr ) && pImageArr.length > 0 )
    //      {
    //        // debugger;
    //        this.memberService.setProfilePics( this.memberService.profilePics );
    //        EmitterSubjectService.emitIsPhotoMgmt( false );
    //        EmitterSubjectService.emitIsViewMember( true );
    //        const tpPics = this.createUserAlbumService.executeMemberViewProfilePicsTasks( this.memberService.profilePics );
    //        // debugger;
    //        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tpPics ) )
    //        {
    //          this.memberService.profilePics = this.profilePics = tpPics;
    //          // debugger;
    //        }
    //      }
    //    }
    //    // this.ngAfterViewInit();
    //    this.emitterDestroyed$.next( true );
    //    this.emitterDestroyed$.complete();
    //    this.emitterDestroyed$.unsubscribe();
    //    // debugger;
    //  } );
    // -----------------------------------------------------------
    // this indexedDb-system works. Tested 2020/August/05,
    // -----------------------------------------------------------
    // -----------------------------------------------------------
    // TODO:  Move all indexedDb related functionalities into the DictionaryModuleComponent
    // -----------------------------------------------------------
    EmitterSubjectService.indexDbProfilePicsEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( result =>
      {
        // debugger;
        this.profilePics = result as ProfilePics;
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.memberService.profilePics )
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.memberService.profilePics.profileImageArr ) )
        {
          if ( this.memberService.profilePics?.filteredKvPhotoArr?.length === this.memberService.profilePics?.profileImageArr?.length )
          {
            debugger;
            this.memberService.profilePics =
              this.photoPrivacyService.displayFilteredKvPhotosOfProfilePics( this.memberService.profilePics );
            // debugger;
          }
          else
          {
            // debugger;
            const pImageArr = this.memberService.profilePics.profileImageArr;
            // debugger;
            if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pImageArr ) && pImageArr.length > 0 )
            {
              // debugger;
              this.memberService.setProfilePics(this.memberService.profilePics);
              // Note: this emitters are correct:
              // EmitterSubjectService.emitIsPhotoMgmt( false );
              // EmitterSubjectService.emitIsViewMember( true );
              const tpPics = this.photoPrivacyService.executeMemberViewProfilePicsTasks( this.memberService.profilePics );
              // debugger;
              if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tpPics ) )
              {
                this.memberService.profilePics = this.profilePics = tpPics;
                this.profileImageKvPhotos = this.profilePics.filteredKvPhotoArr;
                // debugger;
              }
            }
          }
        }
        // debugger;
      } );

    // -----------------------------------------------------------
    // Note:this is not expected to run since we do not get userPhotoIds from indexedDb
    // -----------------------------------------------------------
    // -----------------------------------------------------------
    // TODO:  Move all indexedDb related functionalities into the DictionaryModuleComponent
    // -----------------------------------------------------------
  //  EmitterSubjectService.indexDbUserPhotoBnIdsEmitter
  //    .pipe( takeUntil( this.emitterDestroyed$ ) )
  //    .subscribe( result =>
  //    {
  //      // debugger;
  //      this.userPhotoBnIds = result;

  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics )
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics.profileImageArr )
  //        && this.profilePics.profileImageArr.length === 0
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.sitUser )
  //        && this.sitUser.sitUserId > 0 )
  //      {
  //        // debugger;
  //        // check if returned value is null
  //        this.fetchUserPhotoBns( this.userPhotoBnIds, this.sitUser ).then( data =>
  //        {
  //          let tProfilePics = data;
  //          if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tProfilePics )
  //            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tProfilePics.profileImageArr )
  //            && tProfilePics.profileImageArr.length > 0 )
  //          {
  //            this.profilePics = tProfilePics;
  //          }
  //        } );
  //      }
  //      this.emitterDestroyed$.next( true );
  //      this.emitterDestroyed$.complete();
  //      this.emitterDestroyed$.unsubscribe();
  //    } );

  }
  // ------------------------------------------------------------
  protected initialize (): void
  {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    this.sitUser = EmitterSubjectService.getSitUserModel();
  }
  // ---------------------------------------------------------------
  public ngOnDestroy (): any
  {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.profilePics = new ProfilePics(); // clear the profilePics on exit
    this.profileImageArr = [];
    this.timerArray.forEach( ( timer ) => clearInterval( timer ) );
    return true;
  }
  // ---------------------------------------------------------------
  public clearCounters (): Promise<any>
  {
    this.counters = new Counters();
    return new Promise( ( resolve ) =>
    {
      resolve( this.counters );
    } );
  }
  // -----------------------------------------------------------
  public createPhotoFromSitUser(sitUser:SitUser): Photo {
    // debugger;
    let photo = new Photo();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser.primaryImage)) {
      photo.sitUserId = sitUser.sitUserId;
      photo.sitUserKey = sitUser.sitUserKey;
      photo.cryptUserKey = sitUser.cryptUserKey;
      photo.photoId = sitUser.primaryPhotoBnId;
      photo.imageString = sitUser.primaryImage;
      photo.isPrimary = true;
      photo.profileId = sitUser.profileId;
      photo.pic = sitUser.primaryImage;
      photo.latitude = sitUser.latitude;
      photo.longitude = sitUser.longitude;
    }
    return photo;
  }
  // ---------------------------------------------------------------------------------
  public getCounters () : Counters {
    return this.counters;
  }
  // --------------------------------------------------------------
  getPrimaryImage (sitUserId : number) : Observable<any> {
    // debugger;
    let showSpinnerComp : any;
    const bnComm : Communication = new Communication();

    bnComm.sitUserId = sitUserId;
    this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)) {
      return new Observable((subscriber) => {
        // debugger;
        this.httpService.postObservable('/Photo/GetPrimaryImage',
          {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
          },
          'json2text',
        ).subscribe((result) => {
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
            var primaryImage = result.primaryImage
            // debugger;
            subscriber.next(primaryImage);           
          }
          else {
            subscriber.next(null); //message for result being null 
          }
          subscriber.complete();
        })
      });
    }    
  }
  // --------------------------------------------------------------
  getPrimaryPhoto (sitUserId : number) : Promise<any> {
    // debugger;
    let showSpinnerComp : any;
    const bnComm : Communication = new Communication();    
    
    bnComm.sitUserId = sitUserId;
    this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)) {
      return new Promise((resolve, reject) => {
        // debugger;
        this.httpService.postObservable('/Photo/PostUserPrimaryPhoto',
          {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
          },
          'json2text',
        ).subscribe((result) => {
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
            this.boxNonceEntity = new BoxNonceEntity();
            this.boxNonceEntity.box = result.box;
            this.boxNonceEntity.nonce = result.nonce;

            let tempPhoto : any;
            /*
              * do not directly assing to profileTile!! capture it in a let first
              */
            let unboxedPhoto = SlakezSaltServiceStatic.boxUnsalt(result as BoxNonceEntity);
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unboxedPhoto)) {
              tempPhoto = unboxedPhoto as Photo;
            }

            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempPhoto)) {
              tempPhoto = JSON.parse(tempPhoto); // convert string to model
              // debugger;
            }

            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempPhoto)) {
              resolve(tempPhoto);
            }
          }
          else {
            reject(null); //message for result being null 
          }
        })
      });
    }
    else {
      return FrequentlyUsedFunctionsServiceStatic.nullPromise();
    }
  }
  // ---------------------------------------------------------------------------------
  public getSitUserPhotoIds (sitUserId: number): Promise<number[]>
  {
    return new Promise<number[]>( ( resolve, reject ) =>
    {
      this.getUserPhotoIds( sitUserId ).then( data =>
      {
        this.userPhotoIds = data;
        // debugger;
        resolve( this.userPhotoIds );
      }, FrequentlyUsedFunctionsServiceStatic.nullPromise());
    });
  }

  // -----------------------------------------------------------
  async getUserPhoto (photoId : any, sitUserId : any) : Promise<any> {
    //debugger;

    return new Promise<any>((resolve, reject) => {
      if (photoId > 0) {
        //debugger;

        // debugger; // '/api/Member/GetPhotoIds',
        this.httpService.postPromise('/api/Photo/GetPhotoOpenly',
          photoId,
          'json',
        ).then((result) => {
          // debugger;
          let photoModel = JSON.parse(result);
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoModel) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoModel.image)) {
            // debugger;
            this.userPhotos.push(photoModel as Photo); //  = this.processUnsaltedPhotoIdsResult( sitUserId, result );
            // debugger;
            // --------------------------------------------------------------------
            // Note: while updating dictionary, it will save its data to indexedDb:
            // --------------------------------------------------------------------
            // DictionaryServiceStatic.updateDictionary( photoModel, 'photoDictionary', sitUserId );

            // this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt( result.toString() );
            // this.boxNonceEntity.box = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box );
            // this.boxNonceEntity.nonce = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce );
            // this.boxNonceEntity.date = DateStringServiceStatic.getTicks( new Date() ).toString();
            // this.boxNonceEntity.id = sitUserId;
            // this.boxNonceEntity.key = this.boxNonceEntity.id.toString();
            // this.boxNonceEntity.entityName = 'Photo';
            // this.boxNonceEntity.sitUserId = sitUserId;
            // // debugger;
            // // Note: PhotoDictionary contains unsalted Photo; but PhotoBnDictionary contains (salted Photo) == PhotoBn
            // this.triageAllDataForIndexedDbAndDictionaryService.savePhotoDictionaryToIndexedDb( this.boxNonceEntity ); 

            // debugger;
            resolve(photoModel);
            reject([]);
          }
          else {
            this.message = 'GetPhotoOpenly>result was null for (photoId:\'' + photoId + '\')';
            console.log(this.message);
            // EmitterSubjectService.emitMessage( this.message );
            reject(this.message);
          }
        }, (error) => {
          this.message = 'Error occured in GetPhotoOpenly(photoId:\'' + photoId + '\');\n Error message:  ' + error.message;
          console.log(this.message);
          // EmitterSubjectService.emitMessage( this.message );
          // EmitterSubjectService.emitMyErrorLog( this.message );
          reject(this.message);
        });
      }
      else {
        this.message = 'Error occured in GetPhotoOpenly(photoId:\'' + photoId + '\');\n Error message:  ';
        console.log(this.message);
        reject(this.message);
      }
    });
  }
  // -----------------------------------------------------------
  getUserPhotoIds (sitUserId : any ): Promise<any>
  {
    // debugger;
    return new Promise<any>( ( resolve, reject ) =>
    {
      if ( sitUserId > 0 )
      {
        // debugger;
        const photo : Photo = new Photo();
        photo.sitUserId = sitUserId;
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        // debugger;
        if ( this.loginSuccess.signedInUserId > 0 )
        {
          photo.signedInUserId = this.loginSuccess.signedInUserId;
        }

        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel( photo );

        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity )
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity.box )
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity.nonce ) )
        {
          // debugger; // '/api/Member/GetUserPhotoIds',
          this.httpService.postObservable( '/Photo/GetUserPhotoIds',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box ),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce ),
            },
            'json2text',
          ).subscribe( ( result ) =>
          {
            // debugger;

            if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) )
            {

              this.userPhotoIds = result as number[];
              // this.savePhotoIdsInDictionaryAndIndexedDb(sitUserId, this.userPhotoIds);
              // debugger;
              resolve( this.userPhotoIds);
            }
            else
            {
              this.message = 'GetUserPhotoBnIds=>result was null for (sitUserId:\'' + sitUserId + '\')';
              console.log( this.message );
              // EmitterSubjectService.emitMessage( this.message );
            }

          }, ( error ) =>
          {
            this.message = 'Error occured in GetProfilePics(sitUserId:\'' + sitUserId + '\');\n Error message:  ' + error.message;
            console.log( this.message );
            // EmitterSubjectService.emitMessage( this.message );
            // EmitterSubjectService.emitMyErrorLog( this.message );
            // debugger;
          } );
        }
      }
    } );
  }
  // ---------------------------------------------------------------
  // Usage
  // observable.subscribe ( value => console.log( value ));
  // Created on 20211010
  // ---------------------------------------------------------------
  public getUserPhotosObservable (photoIds : number[], sitUserId : any) : Observable<any> {
    debugger;
    const observable = new Observable(observer => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoIds) && photoIds.length > 0) {
        debugger;
        for (let e of photoIds) {
          debugger;
          // will store the photoBn in dictionary and return a promise:
          // ----------------------------------------------------------
          this.getUserPhoto(e, sitUserId).then(data => {
            debugger;
            let kvP = new KvPhoto();
            debugger;
            kvP = CopyServiceStatic.copyFromPhotoToKvPhoto(data);
            debugger;
            let fkvP = this.photoPrivacyService.filterKvPhoto(kvP);
            debugger;
            observer.next(fkvP);
          });
        }
      }
    });
    return observable;
  }

  // ---------------------------------------------------------------------------------
  public setCounters (value : Counters) : any {
    this.counters = value;
    return true;
  }
  // ----------------------------------------------------------------
  public setCurrentPageKvPhoto (kvp : KvPhoto) : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    kvp.returnUrl = '/mym/photoMgmt';
    kvp.elemId = 'bodyId';
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.primaryImage) && this.loginSuccess.primaryPhotoBnId > 0) {
      let tKvp = CopyServiceStatic.copyLoginSuccessToKvPhoto(this.loginSuccess);
      kvp = CopyServiceStatic.copyKvPhotoIfExists(kvp, tKvp);
    }
    // kvp.pageNum = 1; // Note: the calling method should set the pageNum

    // debugger;
    // set the kvp's image on the background:
    // --------------------------------------
    // this.bias.setKvPhotoBackgroundImage(kvp);

    // OR TODO: check which is faster?

    EmitterSubjectService.emitCurrentPage(kvp); // bias has a listener and will set the page on the background
  }

  // ---------------------------------------------------------------------------------
  public setupKvPhotoModel (kvp : KvPhoto) : KvPhoto | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.image) && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value)) {
        kvp.value = kvp.image;
      }
      else if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.image) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value)) {
        kvp.image = kvp.value;
      }
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.pic)) {
        kvp.pic = kvp.image;
      }

      if (kvp.isPrimary) {
        kvp.key = kvp.glyphName = "Primary";
        kvp.privacy = "Public";
      }
    }
    return kvp;
  }

  // ---------------------------------------------------------------------------------
  public setSitUserPhotoIds (value : number[]) : any {
    this.userPhotoIds = value;
    return true;
  }
  // ---------------------------------------------------------------
  // savePhotoIdsInDictionaryAndIndexedDb ( sitUserId: number, result: number[] ) : void
  // {
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) && result.length > 0 )
  //  {
  //    this.userPhotoIds = result;
  //    EmitterSubjectService.setUserPhotoIds( this.userPhotoIds );

  //    DictionaryServiceStatic.updateDictionary( this.userPhotoIds, 'UserPhotoId', sitUserId );

  //    this.indexedDbToDictionaryService
  //      .saveDictionaryToDbDexie(
  //        DictionaryServiceStatic.photoIdDictionary, 'UserPhotoId', this.loginSuccess.signedInUserId );

  //    // since successfully unsalted UserPhotoIds, we can save the BoxNonce of it in indexedDb:
  //    // --------------------------------------------------------------------------------------
  //    // this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt( result.toString() );
  //    // this.boxNonceEntity.box = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box );
  //    // this.boxNonceEntity.nonce = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce );
  //    // this.boxNonceEntity.date = DateStringServiceStatic.getTicks( new Date() ).toString();
  //    // this.boxNonceEntity.id = sitUserId;
  //    // this.boxNonceEntity.key = this.boxNonceEntity.id.toString();
  //    // this.boxNonceEntity.entityName = 'PhotoId';
  //    // this.boxNonceEntity.sitUserId = sitUserId;
  //    // debugger;
  //    // this.triageAllDataForIndexedDbAndDictionaryService.savePhotoIdDictionaryToIndexedDb( this.boxNonceEntity );

  //    // debugger; // TODO: check to see the values
  //    // return this.userPhotoIds;
  //    // debugger;
  //  }
  // }
  // ==================================================================
}

